import { type ReactElement } from 'react';

import { Stack } from '@mui/material';

import { Typography } from '@xeris/components';

type BasicFeatureSummaryHeaderProps = {
    name: string;
};
export const BasicFeatureSummaryHeader = ({
    name,
}: BasicFeatureSummaryHeaderProps): ReactElement | null => {
    return (
        <Stack spacing={1} marginBottom={1} justifyContent={'start'}>
            <Typography variant={'h3'} gutterBottom>
                {name}
            </Typography>
        </Stack>
    );
};
