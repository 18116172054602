import { type ReactElement, type ReactNode } from 'react';

import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Divider,
    type SxProps,
} from '@mui/material';

import { UpDownChevronIcon } from '@xeris/components';

import './index.scss';

type HandleCollapsePanelType = (collapsePanelId?: string) => void;

type CardHeaderProps = {
    collapsePanelId?: string;
    handleCollapsePanel: HandleCollapsePanelType;
    label?: ReactNode;
    headerButton?: ReactNode;
    isCollapsed?: boolean;
    className?: string;
};

const CardHeader = ({
    collapsePanelId,
    handleCollapsePanel,
    label,
    headerButton,
    isCollapsed,
    className,
}: CardHeaderProps): ReactElement => {
    const classNames = ['card-header-wrapper'];

    if (className) classNames.push(className);

    return (
        <CardActions className={classNames.join(' ')}>
            <div
                className="header-wrapper"
                onClick={(): void => handleCollapsePanel(collapsePanelId)}
            >
                <div className="product-group-name">
                    <Box sx={{ width: '24px', aspectRatio: 1 }}>
                        <UpDownChevronIcon isCollapsed={isCollapsed} />
                    </Box>
                    {label}
                </div>
                {headerButton}
            </div>
        </CardActions>
    );
};

export type CollapsePanelProps = {
    collapsePanelId?: string;
    description?: string;
    handleCollapsePanel: HandleCollapsePanelType;
    headerButton?: ReactNode;
    label?: ReactNode;
    /**
     * This is actually the opposite
     */
    isCollapsed?: boolean;
    className?: string;
    headerClassName?: string;
    contentClassName?: string;
    children?: ReactNode;
    sx?: SxProps;
};

const CollapsePanel = ({
    sx,
    collapsePanelId,
    description,
    handleCollapsePanel,
    headerButton,
    label,
    isCollapsed,
    className,
    headerClassName,
    contentClassName,
    children,
}: CollapsePanelProps): ReactElement => {
    const classNames = ['collapse-card-wrapper'];

    if (className) classNames.push(className);

    return (
        <Card
            sx={sx}
            variant={'outlined'}
            className={classNames.join(' ')}
            elevation={0}
        >
            <CardHeader
                collapsePanelId={collapsePanelId}
                handleCollapsePanel={handleCollapsePanel}
                headerButton={headerButton}
                label={label}
                isCollapsed={isCollapsed}
                className={headerClassName}
            />
            {/*NB: The property 'in' stands for 'slide IN content' - aka. same as 'is open'
                TODO: We should either negate og chnage the name if out parameter. 
                      Regardless, we should fix this feature, since this error is bleeding outside this component 
                      A task ahve been added for this
            */}
            <Collapse in={isCollapsed} unmountOnExit>
                <Divider />
                <CardContent className={contentClassName}>
                    {description && (
                        <div className="description">{description}</div>
                    )}
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default CollapsePanel;
