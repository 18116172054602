import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import { type TFunction } from 'i18next';

import { DataGrid, StatusTag, type TypeSafeColDef } from '@xeris/components';

import EditConnectionForm from '../../../../forms/EditConnectionForm';
import {
    type Connection,
    type ConnectionsTabVariantType,
    type ConnectionStatusType,
    type ConnectionTableEntry,
    type StatusTypesType,
    type UiConnectionType,
} from '../../../../types/connectionsTypes';

import ActionMenu from './ActionsMenu/ActionsMenu';
import { DeleteConnectionDialog } from './ActionsMenu/DeleteConnectionDialog';
import NoConnections from './NoConnections/NoConnections';

const statusTagLookup: Record<StatusTypesType, ConnectionStatusType> = {
    ACTIVE: { titleKey: 'connections.active', type: 'success' },
    CANCELLED: {
        titleKey: 'connections.cancelled',
        type: 'indeterminate',
    },
    DECLINED: { titleKey: 'connections.declined', type: 'error' },
    PENDING_CONSUMER_INITIATED: {
        titleKey: 'connections.invited',
        type: 'processing',
    },
    PENDING_PRODUCER_INITIATED: {
        titleKey: 'connections.invited',
        type: 'processing',
    },
};

const getColumns = (
    t: TFunction<'administration'>,
    setSelectedConnection: (connectionId: string | null) => void,
    setSelectedDeleteConnection: (connectionId: string | undefined) => void,
    isProducer: boolean
): TypeSafeColDef<ConnectionTableEntry>[] => [
    {
        field: 'partner',
        headerName: t('connections.connectionList.tradingPartner'),
        flex: 1,
        valueGetter: (value, row) => row.partner.name,
        renderCell: ({ row }) =>
            isProducer ? (
                <Typography component={Link} to={`${row.partner.id}`}>
                    {row.partner.name}
                </Typography>
            ) : (
                <Typography>{row.partner.name}</Typography>
            ),
    },
    {
        width: 200,
        field: 'brandCount',
        headerName: t('connections.connectionList.brandAccess'),
    },
    {
        width: 200,
        field: 'productCount',
        headerName: t('connections.connectionList.productAccess'),
    },
    {
        field: 'status',
        headerName: t('connections.connectionList.status'),

        renderCell: (params): ReactElement => {
            const status = params.value;

            return <StatusTag type={status.type} title={t(status.titleKey)} />;
        },
    },
    {
        field: 'id',
        headerName: ' ',
        align: 'right',
        sortable: false,
        width: 40,
        renderCell: (params): ReactElement => (
            <ActionMenu
                connectionId={params.row.id}
                tradingPartner={params.row.partner}
                setSelectedConnection={setSelectedConnection}
                setSelectedDeleteConnection={setSelectedDeleteConnection}
                isProducer={isProducer}
            />
        ),
    },
];

type ConnectionListProps = {
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionList: Connection[];
};

const ConnectionList = ({
    connectionType,
    connectionList,
    uiConnectionType,
}: ConnectionListProps): ReactElement => {
    const { t } = useTranslation('administration');
    const [selectedConnectionId, setSelectedConnectionId] = useState<
        string | null
    >(null);

    const [selectedDeleteConnectionId, setSelectedDeleteConnectionId] =
        useState<string | undefined>(undefined);

    const isNoConnections = connectionList.length === 0;

    if (isNoConnections) {
        return (
            <NoConnections
                uiConnectionType={uiConnectionType}
                connectionType={connectionType}
                connectionList={connectionList}
            />
        );
    }

    const isProducer = connectionType === 'channels';

    const nameKey = connectionType === 'channels' ? 'consumer' : 'producer';

    const augmentedConnectionList: ConnectionTableEntry[] = connectionList.map(
        (connection) => ({
            ...connection,
            partner: connection[nameKey],
            status: {
                id: connection.status,
                ...statusTagLookup[connection.status],
            },
            exports: connection.exports,
            brandCount: connection.availableProductCount.length,
            productCount: connection.availableProductCount.reduce(
                (sum, { productCount }) => sum + productCount,
                0
            ),
        })
    );

    const selectedConnection = augmentedConnectionList.find(
        (connection) => connection.id === selectedConnectionId
    );

    return (
        <>
            <DataGrid
                rows={augmentedConnectionList}
                columns={getColumns(
                    t,
                    setSelectedConnectionId,
                    setSelectedDeleteConnectionId,
                    isProducer
                )}
                autoHeight
                hideFooterPagination
                hideFooter
                disableRowSelectionOnClick
            />
            <EditConnectionForm
                isOpen={!!selectedConnection}
                connection={selectedConnection}
                connectionType={connectionType}
                uiConnectionType={uiConnectionType}
                handleClose={(): void => setSelectedConnectionId(null)}
            />

            <DeleteConnectionDialog
                isOpen={!!selectedDeleteConnectionId}
                connection={selectedDeleteConnectionId}
                handleCloseDialog={(): void =>
                    setSelectedDeleteConnectionId(undefined)
                }
            />
        </>
    );
};

export default ConnectionList;
