import { SafeRegexp } from '@xeris/utilities';

const brandCompare = (a: { name: string }, b: { name: string }): number => {
    const first = a.name.toLowerCase();
    const second = b.name.toLowerCase();

    return first.localeCompare(second);
};

export const sortBrands = <T extends { name: string }>(brands: T[]): T[] =>
    [...brands].sort(brandCompare);

export const filterBrands = <T extends { name: string }>(
    brands: T[],
    filterTerm: string
): T[] => brands.filter(({ name }) => SafeRegexp(filterTerm, 'i').test(name));
