import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Table,
    TablePagination,
    type TablePaginationProps,
    Typography,
} from '@mui/material';

import { SearchBar } from '@xeris/components';

import { importApi } from './api/importApi';
import {
    ImportFile,
    ImportTableBody,
    ImportTableHead,
    type ImportTableHeadProps,
} from './SupportComponents';

export type OrderBy = 'id' | 'source' | 'start' | 'duration' | 'status';
export type Order = 'asc' | 'desc';

const Import = (): ReactElement => {
    const { t } = useTranslation('administration');

    const { data: importList } = importApi.useGetImportBatchesQuery(
        {},
        {
            pollingInterval: 2500,
        }
    );

    const rowsPerPageOptions = [100, 500, 2000];

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<OrderBy>('start');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const [searchTerm, setSearchTerm] = useState('');

    const searchFilter = searchTerm.trim().toLowerCase();
    const rows =
        (searchFilter.length === 0
            ? importList?.batches
            : importList?.batches?.filter(
                  (row) =>
                      row.id?.includes(searchFilter) ||
                      row.source?.toLowerCase().includes(searchFilter)
              )) ?? [];

    const handleRequestSort: ImportTableHeadProps['onRequestSort'] = (
        event,
        property
    ) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSetSearchTerm = (value: string): void => {
        setSearchTerm(value);
    };

    const handleChangePage: TablePaginationProps['onPageChange'] = (
        event,
        newPage
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage: TablePaginationProps['onRowsPerPageChange'] =
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

    return (
        <div className="import">
            <Typography variant={'h1'}>{t('imports.importFiles')}</Typography>
            <ImportFile />
            <div className="search-bar-wrapper">
                <SearchBar
                    filter={searchTerm}
                    onChange={handleSetSearchTerm}
                    inputLabel={t('imports.searchForImport')}
                />
            </div>
            <Table className="import-table-wrapper">
                <ImportTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />

                <ImportTableBody
                    importList={rows}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
            </Table>

            <TablePagination
                className="users-table-pagination"
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('imports.rowsPerPage')}
            />
        </div>
    );
};

export default Import;
