import { type MouseEvent, type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';

import {
    AccountIcon,
    LogoutIcon,
    SettingsIcon,
    UserAccountAvatar,
} from '@xeris/components';
import { useWhoami } from '@xeris/hooks';

import { SettingsDrawer } from './SettingsDrawer';

const Profile = (): ReactElement => {
    const { t } = useTranslation('navigation');

    return (
        <MenuItem to="/UserSettings" component={NavLink}>
            <ListItemIcon>
                <AccountIcon />
            </ListItemIcon>
            <ListItemText primary={t('profileMenu.profile')} />
        </MenuItem>
    );
};
const Logout = (): ReactElement => {
    const { t } = useTranslation('navigation');

    return (
        <MenuItem to="/Logout" component={NavLink}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t('profileMenu.logOut')} />
        </MenuItem>
    );
};

const UserMenu = (): ReactElement => {
    const { t } = useTranslation('navigation');

    const [isOpen, setIsOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const { name, username } = useWhoami();

    return (
        <>
            <IconButton onClick={handleClick}>
                <UserAccountAvatar label={name ?? username} />
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Profile />
                <MenuItem
                    onClick={() => {
                        setIsOpen(true);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('profileMenu.settings')} />
                </MenuItem>
                <Divider />
                <Logout />
            </Menu>
            <SettingsDrawer
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
            />
        </>
    );
};

export default UserMenu;
