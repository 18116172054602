import { gql } from 'graphql-request';

import {
    baseProductEntityWithoutImagesFields,
    productEntityDataFields,
} from '../productFragments';

export const getProductQuery = gql`
    query GetProduct($productId: String!) {
        product(id: $productId) {
            __typename
            ...BaseProductEntityWithoutImagesFields
            ...ProductEntityDataFields
            masterProduct: master_product {
                id
                name
            }
            gtin
            source
            price {
                value
                currency
                priceUnit: price_unit
            }
            prices {
                value
                currency
                markets
                priceUnit: price_unit
            }
            relatedProducts: related_products {
                id
            }
            distinguishingFeatures: distinguishing_features {
                value
                name
                type
            }
        }
    }
    ${baseProductEntityWithoutImagesFields}
    ${productEntityDataFields}
`;

export const getProductSourceDataQuery = gql`
    query GetProductSourceData($productId: String!) {
        product(id: $productId) {
            __typename
            source_data {
                type
                data
            }
        }
    }
`;

export const getProductDataErrorsQuery = gql`
    query GetProductDataErrors($productId: String!) {
        product(id: $productId) {
            __typename
            data_errors {
                type
                message
            }
        }
    }
`;

export const getRelatedProductsQuery = gql`
    query GetRelatedProducts($productId: String!) {
        product(id: $productId) {
            __typename
            id
            relatedProducts: related_products {
                __typename
                id
                name
                description
                masterProduct: master_product {
                    id
                    name
                }
                idNumbers: id_numbers {
                    value
                }
                images {
                    title
                    url(sizeFormat: THUMBNAIL)
                    id
                    type
                    objectFit
                }
                specification(type: "Category") {
                    value
                }
                brand {
                    id
                    name
                }
                groups {
                    name
                }
                gtin
                lastUpdated: last_updated
                markets {
                    id
                    name
                    externalId: external_id
                }
                distinguishingFeatures: distinguishing_features {
                    name
                    type
                    value
                }
            }
        }
    }
`;
