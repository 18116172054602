import { type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import {
    MuiButton,
    ProductListItem,
    RightPanelOpenIcon,
    SelectButton,
} from '@xeris/components';

import { type MasterProductWithIds } from '../../../types';
import { getProductSelectedCount } from '../../../utilities/getSelectedCount';

type SelectProductListItemProps = {
    product: MasterProductWithIds & { isConfigurable: boolean };
    selectedProducts: string[];
    setSelectedProducts: Dispatch<SetStateAction<string[]>>;
    setProductToShow: Dispatch<SetStateAction<MasterProductWithIds | null>>;
    setShowProductDrawer: Dispatch<SetStateAction<boolean>>;
};

export const SelectProductListItem = ({
    product,
    selectedProducts,
    setSelectedProducts,
    setProductToShow,
    setShowProductDrawer,
}: SelectProductListItemProps): ReactElement => {
    const { t } = useTranslation('administration');

    const {
        allSelected,
        partiallySelected,
        handleToggle,
        productCount,
        selectedCount,
    } = getProductSelectedCount(product, selectedProducts, setSelectedProducts);

    return (
        <ProductListItem
            key={product.id}
            images={product.images}
            actions={
                <>
                    <SelectButton
                        isSelected={allSelected}
                        isPartiallySelected={partiallySelected}
                        onToggleOption={handleToggle}
                    />
                    {product.products.length > 1 && (
                        <MuiButton
                            variant={'outlinedSolid'}
                            color={'secondary'}
                            aria-label={t('connections.showVariants')}
                            round
                            onClick={() => {
                                setProductToShow(product);
                                setShowProductDrawer(true);
                            }}
                        >
                            <RightPanelOpenIcon color={'secondary'} />
                        </MuiButton>
                    )}
                </>
            }
        >
            <Typography>{product.name}</Typography>
            <Typography variant="body2">
                {product.isConfigurable
                    ? t('connections.configurable')
                    : t('connections.numberOfSelectedVariants', {
                          count: productCount,
                          selected: selectedCount,
                      })}
            </Typography>
        </ProductListItem>
    );
};
