import { type ReactElement, type ReactNode } from 'react';

import { Box, Paper } from '@mui/material';

import { Image, Typography } from '@xeris/components';

type OptionCardProps = {
    image: string | null;
    name: string;
    description?: string | null;
    isPreviewed?: boolean;
    isPreviewable?: boolean;
    handleCardClick: () => void;
    children: ReactNode;
};

export const BasicOptionCard = ({
    image,
    name,
    description,
    isPreviewed,
    isPreviewable,
    handleCardClick,
    children,
}: OptionCardProps): ReactElement => {
    return (
        <Paper
            variant={'outlined'}
            onClick={handleCardClick}
            sx={{
                'padding': 2,
                'cursor': isPreviewable && !isPreviewed ? 'pointer' : 'initial',
                'display': 'flex',
                'gap': '1rem',
                'width': '100%',
                'backgroundColor': isPreviewed
                    ? 'primary.soft'
                    : 'paper.default',
                '&:hover ': isPreviewable
                    ? {
                          '.preview': { opacity: 1 },
                          'backgroundColor': isPreviewed
                              ? 'primary.soft'
                              : 'grey.100',
                      }
                    : {},
                '&:focus-within': { '.preview': { opacity: 1 } },
            }}
        >
            {image && (
                <Image
                    src={image}
                    style={{
                        width: '64px',
                        height: 'fit-content',
                        minHeight: '64px',
                        border: '1px solid #d5d9e0',
                        borderRadius: '6px',
                        verticalAlign: 'top',
                        objectFit: 'contain',
                        backgroundColor: 'white',
                    }}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Typography
                    variant={'h6'}
                    component={'p'}
                    title={name}
                    gutterBottom={!!description}
                    maxLines={10}
                >
                    {name}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {description && (
                        <Typography variant={'body2'} gutterBottom>
                            {description}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};
