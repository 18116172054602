import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { MutationSnackbars, PlusIcon } from '@xeris/components';
import { FormDrawer, TextInput } from '@xeris/components/forms';
import { brandApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

const newBrandSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
    })
    .required();

export type NewBrandFormData = yup.InferType<typeof newBrandSchema>;

export const AddBrand = (): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [isOpen, setIsOpen] = useState(false);

    const activeOrganizationId = useActiveOrganizationId();

    const [addBrandMutation, addBrandResult] = brandApi.useAddBrandMutation();

    const { handleSubmit, control, reset } = useForm<NewBrandFormData>({
        resolver: yupResolver(newBrandSchema),
        defaultValues: {
            id: '',
            name: '',
        },
    });

    if (!activeOrganizationId) {
        return null;
    }

    const handleSubmitBrand = async (
        newBrandData: NewBrandFormData
    ): Promise<void> => {
        await addBrandMutation({
            brandOwner: activeOrganizationId,
            ...newBrandData,
        }).unwrap();

        setIsOpen(false);
        reset();
    };

    return (
        <>
            <Button
                variant={'contained'}
                startIcon={<PlusIcon color="white" />}
                onClick={(): void => setIsOpen(true)}
            >
                {t(`brand.addBrand.addNewBrand`)}
            </Button>

            <FormDrawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title={t(`brand.addBrand.addNewBrandLong`)}
                onSubmit={handleSubmit(handleSubmitBrand)}
                saveText={t('brand.common.create')}
                cancelText={t('common.cancel')}
                isLoading={addBrandResult.isLoading}
            >
                <TextInput
                    label={t('brand.addBrand.id')}
                    fieldName={'id'}
                    control={control}
                    required
                    showError
                    autoFocus
                    placeholder={t('brand.addBrand.idPlaceholder')}
                />
                <TextInput
                    label={t('brand.addBrand.name')}
                    fieldName={'name'}
                    control={control}
                    required
                    showError
                    placeholder={t('brand.addBrand.namePlaceholder')}
                />
            </FormDrawer>

            <MutationSnackbars
                isSuccess={addBrandResult.isSuccess}
                isError={addBrandResult.isError}
                errorText={t('brand.addBrand.errorOnAdd')}
                successText={t('brand.addBrand.addBrandSuccess')}
            />
        </>
    );
};
