import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';
import { simpleSortBy } from '@xeris/utilities';

type MarketProps = {
    brandId: string;
    marketId: string | null;
    markets: { id: string; name: string; externalId: string }[];
};

export const Market = ({
    marketId,
    brandId,
    markets,
}: MarketProps): ReactElement => {
    const { t } = useTranslation('products');
    const dispatch = useAppDispatch();

    return (
        <FormControl
            size={'small'}
            sx={{ marginBlock: 1, flexBasis: '200px', flexShrink: 1 }}
        >
            <InputLabel>{t('brand.market')}</InputLabel>
            <Select
                label={t('brand.market')}
                inputProps={{
                    'aria-label': t('brand.market'),
                }}
                value={marketId ?? '__notSelected'}
                onChange={(event) => {
                    const value = event.target.value;
                    const marketId = value === '__notSelected' ? null : value;

                    dispatch(
                        settingsActions.setMarket({
                            brandId,
                            externalMarketId:
                                markets.find((market) => market.id === marketId)
                                    ?.externalId ?? null,
                            market:
                                markets.find((market) => market.id === marketId)
                                    ?.id ?? marketId,
                        })
                    );
                }}
            >
                {simpleSortBy(markets, 'name').map((market) => (
                    <MenuItem key={market.id} value={market.id}>
                        {market.name}
                    </MenuItem>
                ))}
                <MenuItem value={'__notSelected'}>
                    {t('brand.notSelected')}
                </MenuItem>
            </Select>
        </FormControl>
    );
};
