import { type ReactElement, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { CloseIcon } from '@xeris/components';

import { type Importer } from '../types';

import { ImportFormatSelector, type ImportFormatSelectorProps } from './index';

export type SubmitImportFileProps = {
    fileDetails: File;
    submitFileHandler: () => void;
    abortUploadHandler: (event: SyntheticEvent) => void;
    importFormat: Importer | null;
    selectFormatHandler: ImportFormatSelectorProps['selectFormatHandler'];
};

const SubmitImportFile = ({
    fileDetails,
    submitFileHandler,
    abortUploadHandler,
    selectFormatHandler,
    importFormat,
}: SubmitImportFileProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
                onClick={abortUploadHandler}
                variant={'outlined'}
                color={'secondary'}
                sx={{
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    paddingInline: 2,
                }}
            >
                {fileDetails.name}
                <CloseIcon fontSize="small" />
            </Button>
            <ImportFormatSelector
                selectFormatHandler={selectFormatHandler}
                importFormat={importFormat}
            />
            <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={(): void => submitFileHandler()}
            >
                {t('imports.importFile')}
            </Button>
        </Box>
    );
};

export default SubmitImportFile;
