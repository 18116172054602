import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { type ConnectionCounters } from '@xeris/pages/admin/Connections/types/connectionsTypes';

import styles from './Header.module.scss';

type HeaderProps = {
    counters: ConnectionCounters;
};

const Header = ({ counters }: HeaderProps): ReactElement => {
    const { t } = useTranslation('administration');

    const { totalUsed, totalAvailable } = counters;

    return (
        <div className={styles.header}>
            <Typography variant="h1">{t('connections.connections')}</Typography>
            <div className={styles.counter}>
                {t('connections.usedConnectionsCount', {
                    usedConnectionsCount: totalUsed,
                    availableConnectionsCount: totalAvailable,
                })}
            </div>
        </div>
    );
};

export default Header;
