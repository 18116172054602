import { type ApiGetDatasetWithCategorizedProductsQuery } from '@xeris/pages/exportJob/Categorization/api/categorizationQueries.gql';
import type {
    CategorizationDataset,
    CategorizationExportConfig,
    CategorizationMasterProduct,
    CategorizationProduct,
    ConfigurationExportCategory,
} from '@xeris/pages/exportJob/Categorization/types';

export const transformDatasetForCategorization = (
    dataset: ApiGetDatasetWithCategorizedProductsQuery['dataset']
): CategorizationDataset | null => {
    if (!dataset) return null;

    return {
        id: dataset.id,
        name: dataset.name,
        totalCount: dataset.totalCount,
        products: dataset.selectedProductData.nodes
            .filter(
                (productOrMasterProduct) =>
                    productOrMasterProduct.__typename === 'SelectedProduct'
            )
            .map((node): CategorizationProduct | null => {
                if (!node.data || node.__typename !== 'SelectedProduct')
                    return null;

                return {
                    ...node.data,
                    categories: node.categories,
                };
            })
            .filter((product): product is CategorizationProduct => !!product),
        masterProducts: dataset.selectedProductData.nodes
            .map((node): CategorizationMasterProduct | null => {
                if (!node.data || node.__typename !== 'SelectedMasterProduct') {
                    return null;
                }

                return {
                    ...node.data,
                    categories: node.categories,
                };
            })
            .filter(
                (masterProduct): masterProduct is CategorizationMasterProduct =>
                    !!masterProduct
            ),
    };
};

type CategoryList = {
    id: string;
    name: string;
    children: CategoryList | null;
}[];

const transformCategories = (
    categoryList: CategoryList | null
): ConfigurationExportCategory[] => {
    if (!categoryList) {
        return [];
    }

    return categoryList.map((category) => ({
        id: category.id,
        name: category.name,
        children: transformCategories(category.children),
    }));
};

export const transformExportConfig = (
    exportConfig: ApiGetDatasetWithCategorizedProductsQuery['export']
): CategorizationExportConfig | null => {
    if (!exportConfig) return null;

    return {
        id: exportConfig.id,
        name: exportConfig.name,
        categories: transformCategories(exportConfig.categories),
    };
};
