import { type ReactElement, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import {
    CheckIcon,
    CloseIcon,
    MuiButton,
    PlusIcon,
    Tooltip,
    utilities,
} from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

export type SelectActionType = 'add' | 'remove';

export type SelectedStatusType =
    | 'unselected'
    | 'partiallySelected'
    | 'fullySelected';
export type HandleSelectActionType = (
    event: SyntheticEvent,
    selectAction: SelectActionType
) => void;

type IconSelectButtonProps = {
    selectedStatus: SelectedStatusType;
    handleSelectAction: HandleSelectActionType;
    //The visibility attributes have only effect when the selected status is 'unselected'
    // If 'isAlwaysVisible' is set to false, then 'isVisible' has to be set to 'true' for the iconButton to be visible
    isAlwaysVisible?: boolean;
    isVisible?: boolean;
    showDeleteDialog: () => void;
    masterProductId: string;
    isActionButtonsAlwaysVisible?: boolean;
};

const IconSelectButton = ({
    selectedStatus,
    handleSelectAction,
    isAlwaysVisible = true,
    isVisible = true,
    showDeleteDialog,
    masterProductId,
    isActionButtonsAlwaysVisible,
}: IconSelectButtonProps): ReactElement => {
    const { t } = useTranslation('product');
    const configurationSets = useAppSelector((state) =>
        datasetSelectors.active.selectConfigurationSets(state, masterProductId)
    );
    if (!isAlwaysVisible && !isVisible && selectedStatus === 'unselected') {
        return <div />;
    }

    const handleDeleteClick = (event: SyntheticEvent): void => {
        if (configurationSets.length < 1) {
            return handleSelectAction(event, 'remove');
        }
        showDeleteDialog();
    };

    if (selectedStatus === 'fullySelected') {
        return (
            <Tooltip title={t('productCard.removeFromDataset')}>
                <MuiButton
                    variant={'contained'}
                    sx={utilities.switchOnHover}
                    round
                    size={'large'}
                    onClick={handleDeleteClick}
                    data-testid="deselectProduct"
                >
                    <CheckIcon />
                    <CloseIcon />
                </MuiButton>
            </Tooltip>
        );
    } else if (selectedStatus === 'partiallySelected') {
        return (
            <Tooltip title={t('productCard.removeFromDataset')}>
                <Box
                    sx={{
                        backgroundColor: '#e5f4f3',
                        borderRadius: '100%',
                    }}
                >
                    <MuiButton
                        variant={'outlinedPartial'}
                        sx={utilities.switchOnHover}
                        round
                        size={'large'}
                        onClick={handleDeleteClick}
                        data-testid="deselectProduct"
                    >
                        <CheckIcon />
                        <CloseIcon />
                    </MuiButton>
                </Box>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={t('productCard.addToDataset')}>
            <Box sx={{ backgroundColor: 'white', borderRadius: '100%' }}>
                <MuiButton
                    variant={'outlinedSolid'}
                    round
                    size={'large'}
                    onClick={(event): void => {
                        handleSelectAction(event, 'add');
                    }}
                    data-testid="selectProduct"
                    className={'action'}
                    sx={{ opacity: isActionButtonsAlwaysVisible ? 1 : 0 }}
                >
                    <PlusIcon />
                </MuiButton>
            </Box>
        </Tooltip>
    );
};

export default IconSelectButton;
