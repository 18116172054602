import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Stack, Tooltip } from '@mui/material';

import { ChevronRightIcon } from '@xeris/components';

import Typography from '../Typography/Typography';

type ShowMoreProps = { maxCharacters: number; text: string };

const ShowMore = ({ text, maxCharacters }: ShowMoreProps): ReactElement => {
    const { t } = useTranslation('common');
    const [isExpanded, setIsExpanded] = useState(false);

    if (text.length <= maxCharacters) {
        return <Typography>{text}</Typography>;
    }

    return (
        <Stack
            alignItems={'flex-start'}
            spacing={1}
            justifyContent={'flex-start'}
        >
            <Tooltip title={isExpanded ? t('showLess') : t('showMore')}>
                <IconButton
                    color={'secondary'}
                    size={'small'}
                    edge={'start'}
                    onClick={(): void => setIsExpanded((expanded) => !expanded)}
                    disableRipple
                >
                    <ChevronRightIcon
                        sx={{
                            transform: isExpanded ? 'rotate(90deg)' : '',
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Typography gutterBottom>
                {text.substring(0, isExpanded ? Infinity : maxCharacters)}
                {!isExpanded && '…'}
            </Typography>
        </Stack>
    );
};

export default ShowMore;
