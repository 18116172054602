import { type ReactElement } from 'react';

import styles from './EmptyState.module.scss';

type EmptyStateProps = {
    title: string;
    message: string;
    ActionButton: ReactElement;
};

const EmptyState = ({
    title,
    message,
    ActionButton,
}: EmptyStateProps): ReactElement => {
    return (
        <div className={styles.emptyState}>
            <h2>{title}</h2>
            <span>{message}</span>
            {ActionButton}
        </div>
    );
};

export default EmptyState;
