import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { RadioMarkedIcon, RadioUnmarkedIcon } from '@xeris/components';
import { useAppDispatch } from '@xeris/reducers';

import { datasetActions } from '../../reducers';

type ActiveDatasetButtonProps = {
    isDatasetActive: boolean;
    datasetId: string;
};
const ActiveDatasetButton = ({
    isDatasetActive,
    datasetId,
}: ActiveDatasetButtonProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('dataset');

    if (isDatasetActive) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    p: '2px 12px 2px 6px',
                    borderRadius: '50px',
                }}
            >
                <RadioMarkedIcon color="primary" />
                <Typography variant={'button'} color={'primary'}>
                    {t('common.active')}
                </Typography>
            </Box>
        );
    }

    return (
        <Button
            color={'secondary'}
            onClick={(event) => {
                event.preventDefault();
                dispatch(datasetActions.changeActiveDataset(datasetId));
            }}
            startIcon={<RadioUnmarkedIcon />}
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
        >
            {t('common.setAsActive')}
        </Button>
    );
};
export default ActiveDatasetButton;
