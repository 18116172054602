import { memo, type ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { type MasterProduct } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import Header, { AllStages } from '../Panels/Header';
import Preview from '../Preview/Preview';
import { variantGeneratorSelectors } from '../reducer';

import SelectDataPanel from './SelectDataPanel';

import styles from './SelectData.module.scss';

type SelectDataProps = {
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    >;
};

const SelectData = ({ masterProduct }: SelectDataProps): ReactElement => {
    const navigate = useNavigate();
    const { brandId = '', masterProductId = '' } = useParams();

    const missingFeatureIds = useAppSelector(
        variantGeneratorSelectors.selectMissingFeatures
    );

    const previousStage = AllStages.selectData.previous;

    useEffect(() => {
        if (missingFeatureIds.length) {
            navigate({}, { state: { page: 'summary' } });
        }
    }, [brandId, missingFeatureIds, masterProductId, navigate]);

    const handleSelectionClose = (): void => {
        navigate(`/Products/${brandId}/MasterProducts/${masterProductId}`, {
            replace: true,
        });
    };

    return (
        <>
            <Header type="selectData" />
            <div className={styles.selectData}>
                <Preview stage="selectData" masterProduct={masterProduct} />
                <div className={styles.selector}>
                    <SelectDataPanel
                        previousStage={previousStage}
                        handleSelectionClose={handleSelectionClose}
                        masterProduct={masterProduct}
                    />
                </div>
            </div>
        </>
    );
};

export default memo(SelectData);
