import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { SnapshotIcon } from '@xeris/components';

import './ImageTools.scss';

type ImageToolsProps = {
    handleSnapshotClick?: () => void;
    handleExportModelClick?: () => void;
    handleExportFullModelClick?: () => void;
};

const ImageTools = ({
    handleSnapshotClick,
    handleExportModelClick,
    handleExportFullModelClick,
}: ImageToolsProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Grid
            className="image-tools"
            container
            alignItems="center"
            justifyContent="space-between"
        >
            <Grid item>
                <Grid
                    container
                    alignItems="center"
                    onClick={handleSnapshotClick}
                    className="pointer"
                >
                    <Grid item>{t('variantGenerator.snapshot')}</Grid>
                    <Grid item className="snapshot-icon">
                        <SnapshotIcon fontSize="small" />
                    </Grid>
                </Grid>
            </Grid>
            {handleExportModelClick && (
                <Grid
                    style={{ display: 'none' }}
                    item
                    className="pointer"
                    onClick={handleExportModelClick}
                >
                    {t('variantGenerator.exportModel')}
                </Grid>
            )}
            {handleExportFullModelClick && (
                <Grid
                    style={{ display: 'none' }}
                    item
                    className="pointer"
                    onClick={handleExportFullModelClick}
                >
                    {t('variantGenerator.exportFullModel')}
                </Grid>
            )}
        </Grid>
    );
};

export default ImageTools;
