import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import SearchBar from '@xeris/pages/product/ProductSearch/components/common/SearchBar';

type ProductSearchBarProps = {
    productCount?: number;
    showResult?: boolean;
    children?: ReactNode;
};

const ProductSearchBar = ({
    productCount,
    showResult,
    children,
}: ProductSearchBarProps): ReactElement => {
    const { t } = useTranslation('products');

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                gap: 2,
                marginBottom: showResult ? 2 : 5,
                justifyContent: showResult ? 'initial' : 'space-around',
            }}
        >
            <SearchBar
                sx={{ width: showResult ? 380 : 440 }}
                searchKey={'searchTerm'}
                label={t('common.search')}
            />
            {showResult && (
                <>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <Typography
                            sx={{ fontWeight: 'bold' }}
                            component={'span'}
                        >
                            {t('common.number', { count: productCount })}
                        </Typography>
                        <Typography component={'span'}>
                            {t('search.products', { count: productCount })}
                        </Typography>
                    </Box>
                    {children}
                </>
            )}
        </Box>
    );
};

export default ProductSearchBar;
