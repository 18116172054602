import { type AppDispatch } from '@xeris/reducers';
import { type StateType } from '@xeris/types';

import refreshLoginToken from './refreshLoginToken';

const refreshGracePeriodMS = 30000;

let refreshPromise: Promise<void> | null = null;

const getToken = async (
    dispatch: AppDispatch,
    getState: () => StateType
): Promise<string | undefined> => {
    if (!getState().login.isLoading && refreshPromise) {
        await refreshPromise.then();
    }

    const login = getState().login;

    if (!login.isLoggedIn) {
        return;
    }

    if (login.expires && login.token) {
        const currentDate: number = new Date().valueOf();
        const expiryDate: number = new Date(login.expires).valueOf();
        const msToExpiry = expiryDate - currentDate;

        if (msToExpiry > refreshGracePeriodMS) {
            return login.token;
        }
    }

    if (!getState().login.isLoading) {
        refreshPromise = refreshLoginToken(dispatch);
    }

    await refreshPromise?.then();
    return getState().login.token;
};

export default getToken;
