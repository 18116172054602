import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useWhoami } from '@xeris/hooks';

import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '../../../../../types/connectionsTypes';
import NewConnection from '../../NewConnection/NewConnection';

import styles from './NoConnections.module.scss';

type NoConnectionsProps = {
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionList: Connection[];
};

const NoConnections = ({
    uiConnectionType,
    connectionType,
    connectionList,
}: NoConnectionsProps): ReactElement => {
    const { t } = useTranslation('administration');

    const { isSuperAdmin } = useWhoami();

    return (
        <div className={styles.noConnections}>
            <div className={styles.container}>
                <h3 className={styles.title}>
                    {t(`connections.noConnections.${uiConnectionType}`)}
                </h3>
                <p>
                    {t(`connections.noConnectedPartners.${connectionType}`)}
                    {isSuperAdmin &&
                        t(`connections.addConnection.${connectionType}`)}
                </p>
                {isSuperAdmin && (
                    <div className={styles.buttonContainer}>
                        <NewConnection
                            connectionType={connectionType}
                            uiConnectionType={uiConnectionType}
                            connectionList={connectionList}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NoConnections;
