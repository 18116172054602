import { type ReactElement } from 'react';

import { productHooks } from '@xeris/pages/product/hooks';

import ProductEntityList from '../ProductEntityList/ProductEntityList';

type RelatedProductEntityProps = {
    productEntityId: string;
    isMasterProduct: boolean;
};

const RelatedProductEntities = ({
    productEntityId,
    isMasterProduct,
}: RelatedProductEntityProps): ReactElement => {
    const relatedProductQueryResult =
        productHooks.api.queries.useGetRelatedProducts(
            { productId: productEntityId },
            { skip: isMasterProduct }
        );

    const relatedMasterProductQueryResult =
        productHooks.api.queries.useGetRelatedMasterProducts(
            { masterProductId: productEntityId },
            { skip: !isMasterProduct }
        );

    const isLoading =
        relatedProductQueryResult.isFetching ||
        relatedProductQueryResult.isLoading ||
        relatedMasterProductQueryResult.isFetching ||
        relatedMasterProductQueryResult.isLoading;

    return (
        <ProductEntityList
            isLoading={isLoading}
            minimalProductList={relatedProductQueryResult.data}
            minimalMasterProductList={relatedMasterProductQueryResult.data}
        />
    );
};

export default RelatedProductEntities;
