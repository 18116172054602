import {
    type FormEvent,
    type ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Loading, LockPrivateIcon } from '@xeris/components';
import { useQueryParams } from '@xeris/hooks';
import { useChangeLanguage } from '@xeris/i18n/useChangeLanguage';
import { userVerificationApi } from '@xeris/pages/admin/api';

import {
    BrokenLinkMessage,
    CheckboxFormControl,
    LoginFormControl,
    PasswordInput,
    UserNameInput,
} from '../Common';
import { isValidPassword } from '../utillities';

import styles from './AcceptInviteForm.module.scss';

const AcceptInviteForm = (): ReactElement => {
    const { t } = useTranslation('login');
    const handleChangeLanguage = useChangeLanguage();

    const token = useQueryParams('token');
    const { data, isError } =
        userVerificationApi.useGetUserInformationFromTokenQuery(
            { token },
            { skip: !token }
        );

    useEffect(() => {
        handleChangeLanguage(data?.language);
    }, [data, handleChangeLanguage]);

    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [policyAgreement, setPolicyAgreement] = useState(false);

    const passwordInput = useRef<HTMLInputElement | null>(null);
    const form = useRef<HTMLFormElement | null>(null);

    const validatePassword = (): void => {
        const message = isValidPassword(password);
        passwordInput.current?.setCustomValidity(message);
    };

    const handleSubmit = (event: FormEvent): void => {
        validatePassword();
        form.current?.reportValidity();
        if (!form.current?.checkValidity()) {
            event.preventDefault();
        }
    };

    if (!token || isError) {
        return <BrokenLinkMessage variant="invite" />;
    }

    if (!data) {
        return <Loading loading backdrop />;
    }

    return (
        <div className={styles.acceptInviteForm}>
            <div className={styles.title}>
                <span>{t('invite.createAccount')}</span>
            </div>
            <form
                ref={form}
                action={`/login/user-info?token=${token}`}
                method="POST"
                onSubmit={handleSubmit}
            >
                <UserNameInput
                    value={data.username}
                    disabled
                    showAdornment={true}
                    adornment={<LockPrivateIcon fontSize={'small'} />}
                />

                <PasswordInput
                    ref={passwordInput}
                    value={password}
                    handleChange={setPassword}
                    name="password"
                />

                <LoginFormControl
                    formId="name"
                    formLabel={t('invite.fullName')}
                    formType="text"
                    required
                    formValue={name}
                    name="name"
                    setFunction={setName}
                    showAdornment={false}
                />

                <LoginFormControl
                    formId="phone"
                    formLabel={t('invite.phoneNumber')}
                    formType="text"
                    formValue={phone}
                    name="phone"
                    setFunction={setPhone}
                    showAdornment={false}
                />

                <CheckboxFormControl
                    checked={policyAgreement}
                    handleToggle={(): void =>
                        setPolicyAgreement(!policyAgreement)
                    }
                    name="agreement"
                    label={
                        <span className={styles.checkboxLabel}>
                            {t('invite.agreeToPrivacyPolicies')}{' '}
                            <a
                                href="https://www.xeris.no/legal/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('invite.privacyPolicies')}
                            </a>
                        </span>
                    }
                />

                <div className={styles.submitButton}>
                    <Button
                        variant="solid"
                        type="submit"
                        disabled={!name || !password || !policyAgreement}
                        minWidth={160}
                    >
                        {t('invite.save')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AcceptInviteForm;
