/* This file is being ignored by prettier */
import { type ComponentType, memo, type ReactElement } from 'react';

import Icon, { type IconProps } from './Icon/Icon';

export type IconFactoryProps = Omit<IconProps, 'icon'>;

const createIcon = (
    icon: string,
    otherProps?: IconFactoryProps
): ComponentType<IconFactoryProps> => {
    return memo(
        function IconComponent(passedProps: IconFactoryProps): ReactElement {
           return (
                <Icon {...otherProps} {...passedProps} icon={icon} />
            );
        }
    );
};

export const AccountIcon                      = createIcon('account-circle');
export const AccountMultipleIcon              = createIcon('account-multiple');
export const AccountRemoveIcon                = createIcon('account-remove');
export const AlertIcon                        = createIcon('alert-outline', { color: 'error' });
export const AllInclusiveIcon                 = createIcon('all-inclusive');
export const AlertCircleIcon                  = createIcon('alert-circle', { color: 'error' });
export const ArrowLeftIcon                    = createIcon('arrow-left');
export const ArrowRightIcon                   = createIcon('arrow-right');
export const ArrowRightBoldIcon               = createIcon('arrow-right-bold');

export const BusinessIcon                     = createIcon('business');

export const CardsIcon                        = createIcon('cards');
export const CheckIcon                        = createIcon('check');
export const ChecboxMarkedCircleIcon          = createIcon('checkbox-marked-circle');
export const CheckboxMarkedCircleOutlinedIcon = createIcon('checkbox-marked-circle-outline');
export const CheckCircleEmptyIcon             = createIcon('check-circle-empty');
export const CheckCircleIcon                  = createIcon('check-circle');
export const ChevronDownIcon                  = createIcon('chevron-down');
export const ChevronDownCircleOutlineIcon     = createIcon('chevron-down-circle-outline');
export const ChevronLeftIcon                  = createIcon('chevron-left');
export const ChevronRightIcon                 = createIcon('chevron-right');
export const ChevronUpIcon                    = createIcon('chevron-up');
export const ChevronUpCircleOutlineIcon       = createIcon('chevron-up-circle-outline');
export const CircleIcon                       = createIcon('circle-medium');
export const ClockFastIcon                    = createIcon('clock-fast');
export const CloseIcon                        = createIcon('close');
export const CloseFolderIcon                  = createIcon('folder-outlined-with-close');
export const CopyRightIcon                    = createIcon('copyright');
export const CubeOutlineIcon                  = createIcon('cube-outline');

export const DeleteIcon                       = createIcon('delete-forever');
export const DownIcon                         = createIcon('down');
export const DownloadIcon                     = createIcon('download');
export const DownloadOutlinedIcon             = createIcon('download-outlined');
export const DotsHorizontalIcon               = createIcon('dots-horizontal');
export const DragDotsIcon                     = createIcon('drag-dots');
export const DuplicateIcon                    = createIcon('duplicate');

export const EcoIcon                          = createIcon('eco');
export const EditIcon                         = createIcon('pencil');
export const EmailIcon                        = createIcon('email');
export const EyeIcon                          = createIcon('eye');
export const EyeOffIcon                       = createIcon('eye-off');
export const EyeOutlineOnIcon                 = createIcon('eye-outline-on');
export const EyeOutlineOffIcon                = createIcon('eye-outline-off');

export const FileIcon                         = createIcon('file');
export const FileOutlineIcon                  = createIcon('file-outline');
export const FolderIcon                       = createIcon('folder');
export const FolderOutlinedIcon               = createIcon('folder-outlined');
export const FullscreenIcon                   = createIcon('fullscreen');
export const FunctionIcon                     = createIcon('function');

export const HelpIcon                         = createIcon('help-circle-outline');
export const HistoryIcon                      = createIcon('history');

export const ImpersonateUserIcon              = createIcon('impersonate-user');
export const InformationOutlineIcon           = createIcon('information-outline');
export const InsertPhotoIcon                  = createIcon('insert-photo');

export const LinkIcon                         = createIcon('link');
export const ListIcon                         = createIcon('list');
export const LockPrivateIcon                  = createIcon('lock-private');
export const LogoutIcon                       = createIcon('logout-variant');

export const OpenInNewIcon                    = createIcon('open-in-new')

export const PlusIcon                         = createIcon('plus');
export const PlusFolderIcon                   = createIcon('folder-filled-with-plus');
export const PollIcon                         = createIcon('poll');
export const PuzzleIcon                       = createIcon('puzzle');

export const RadioSetAsActiveIcon             = createIcon('radio-set-as-active');
export const RadioUnmarkedIcon                = createIcon('radiobox-blank');
export const RadioMarkedIcon                  = createIcon('radiobox-marked');
export const RefreshIcon                      = createIcon('refresh');
export const ResetPasswordIcon                = createIcon('reset-password');
export const RightPanelOpenIcon               = createIcon('right-panel-open');

export const SettingsIcon                     = createIcon('cog');
export const SearchIcon                       = createIcon('search');
export const ShareIcon                        = createIcon('share');
export const SnapshotIcon                     = createIcon('photo-filter');
export const SortAscendingIcon                = createIcon('sort-ascending', { color: 'primary' });
export const SortDefaultIcon                  = createIcon('sort-default');
export const SortDescendingIcon               = createIcon('sort-descending', { color: 'primary' });
export const StarIcon                         = createIcon('star');
export const StarBorderIcon                   = createIcon('star-border');
export const SyncIcon                         = createIcon('sync');

export const TipsIcon                         = createIcon('tips');
export const TextFieldsIcon                   = createIcon('text_fields');

export const UnfoldLessIcon                   = createIcon('unfold-less');
export const UnfoldMoreIcon                   = createIcon('unfold-more');
export const UploadIcon                       = createIcon('upload');
export const UploadOutlinedIcon               = createIcon('upload-outlined');

export const ViewCardIcon                     = createIcon('view-card');
export const ViewDashboardIcon                = createIcon('view-dashboard');
export const ViewListIcon                     = createIcon('view-list');
