import { type ReactElement } from 'react';

import {
    Skeleton as MuiSkeleton,
    type SkeletonProps as MuiSkeletonProps,
} from '@mui/material';

import { createIndexArray } from '@xeris/utilities';

type SkeletonProps = MuiSkeletonProps & {
    count?: number;
};

const Skeleton = ({
    count = 1,
    ...otherProps
}: SkeletonProps): ReactElement => {
    const list = createIndexArray(count);

    return (
        <>
            {list.map((index) => (
                <MuiSkeleton
                    key={index}
                    variant="rectangular"
                    animation="wave"
                    {...otherProps}
                />
            ))}
        </>
    );
};
export default Skeleton;
