import type { ReactElement, ReactNode } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
} from '@mui/material';

import { ChevronDownIcon } from '@xeris/components';

import SectionHeader from './SectionHeader';

type SectionWrapperProps = {
    selectedCount: number;
    totalCount: number;
    children: ReactNode;
    title: string;
    handleToggleAll: () => void;
};

const SectionWrapper = ({
    selectedCount,
    totalCount,
    children,
    title,
    handleToggleAll,
}: SectionWrapperProps): ReactElement | null => {
    if (totalCount === 0) {
        return null;
    }

    return (
        <Accordion
            variant={'outlined'}
            disableGutters
            defaultExpanded
            sx={{
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
            }}
        >
            <AccordionSummary expandIcon={<ChevronDownIcon />}>
                <SectionHeader
                    selectedCount={selectedCount}
                    totalCount={totalCount}
                    handleToggleAll={handleToggleAll}
                    title={title}
                />
            </AccordionSummary>
            <Divider />
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};
export default SectionWrapper;
