import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Chip, Typography } from '@mui/material';

import { ChevronRightIcon } from '@xeris/components';

type HeaderProps = {
    tradingPartner: {
        id: string;
        name: string;
    };
    brandName: string;
};

export const Header = ({
    tradingPartner,
    brandName,
}: HeaderProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box display={'flex'} gap={1} alignItems={'center'} marginBottom={2}>
            <Box
                component={'h1'}
                display={'flex'}
                margin={0}
                gap={1}
                alignItems={'center'}
            >
                <Typography
                    variant={'h1'}
                    component={Link}
                    to={`/Admin/Connections/${tradingPartner.id}`}
                    noWrap
                >
                    {tradingPartner.name}
                </Typography>
                <ChevronRightIcon color={'icons'} />
                <Typography variant={'h1'} component={'span'} noWrap>
                    {brandName}
                </Typography>
            </Box>
            <Chip
                size={'small'}
                label={t('connections.brandAccess.productAccess')}
                sx={{ marginLeft: 1 }}
            />
        </Box>
    );
};
