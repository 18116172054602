import {
    memo,
    type ReactElement,
    type ReactNode,
    type SyntheticEvent,
} from 'react';

import { Badge, type ButtonProps, type IconButtonProps } from '@mui/material';

import {
    CheckIcon,
    CloseIcon,
    MuiButton,
    PlusIcon,
    Tooltip,
    utilities,
} from '@xeris/components';

type SelectButtonStateProps = {
    isSelected?: boolean;
    isPartiallySelected?: boolean;
    onToggleOption: (event: SyntheticEvent) => void;
    disabled?: boolean;
    size?: ButtonProps['size'];
    color?: ButtonProps['color'];
};

const SelectButtonState = ({
    isSelected,
    isPartiallySelected,
    disabled,
    size,
    color,
    onToggleOption,
}: SelectButtonStateProps): ReactElement => {
    const iconProps = {
        color,
        size,
        round: true,
        onClick: onToggleOption,
        disabled: disabled,
        sx: utilities.switchOnHover,
    };

    if (isSelected) {
        return (
            <MuiButton variant={'contained'} {...iconProps}>
                <CheckIcon />
                <CloseIcon />
            </MuiButton>
        );
    }

    if (isPartiallySelected) {
        return (
            <MuiButton variant={'outlinedPartial'} {...iconProps}>
                <CheckIcon />
                <CloseIcon />
            </MuiButton>
        );
    }

    if (disabled) {
        return (
            <MuiButton
                variant={'outlinedPartial'}
                {...iconProps}
                sx={{ opacity: 0.5 }}
            >
                <PlusIcon />
            </MuiButton>
        );
    }

    return (
        <MuiButton variant={'outlinedSolid'} {...iconProps}>
            <PlusIcon />
        </MuiButton>
    );
};

type SelectButtonProps = {
    className?: string;
    isSelected?: boolean;
    isPartiallySelected?: boolean;
    onToggleOption: (event: SyntheticEvent) => void;
    disabled?: boolean;
    disabledTooltip?: string;
    edge?: IconButtonProps['edge'];
    size?: ButtonProps['size'];
    color?: ButtonProps['color'];
    badgeContent?: ReactNode;
};

const SelectButton = ({
    className,
    onToggleOption,
    disabled,
    disabledTooltip,
    badgeContent,
    ...iconProps
}: SelectButtonProps): ReactElement => {
    return (
        <div className={className}>
            <Tooltip title={disabled && disabledTooltip}>
                <Badge
                    overlap={'circular'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={badgeContent}
                >
                    <SelectButtonState
                        onToggleOption={onToggleOption}
                        disabled={disabled}
                        {...iconProps}
                    />
                </Badge>
            </Tooltip>
        </div>
    );
};

export default memo(SelectButton);
