import { type ApiGetMasterProductQuery } from '@xeris/pages/product/api/masterProductApi/masterProductQueries.gql';
import type { ApiGetProductQuery } from '@xeris/pages/product/api/productApi/productQueries.gql';
import {
    type CertificateType,
    type DescriptionsType,
    type DocumentationsType,
    type EnvironmentalType,
    type GpcAttributesType,
    type IdNumbersType,
    type MarketsType,
    type MediaDocumentsType,
    type ModelsType,
    type PackagingType,
    type PhysicalMeasurementsType,
    type SpecificationsType,
    type SustainabilityType,
    type TextsType,
    type VideoType,
} from '@xeris/pages/product/types';
import { type ProductData } from '@xeris/pages/product/types/product';
import type { ApiEmbeddedMedia } from '@xeris/types/graphql';
import { getUniqueListBy } from '@xeris/utilities';

type ApiProductOrMasterProduct = NonNullable<
    ApiGetProductQuery['product'] | ApiGetMasterProductQuery['master_product']
>;

export const transformDescription = (
    data: ApiProductOrMasterProduct
): DescriptionsType => {
    return {
        type: 'descriptions',
        hasData: data.descriptions.length > 0,
        data: data.descriptions,
        count: data.descriptions.length,
    };
};

export const transformDocumentation = (
    data: ApiProductOrMasterProduct
): DocumentationsType => {
    return {
        type: 'documentation',
        hasData: data.documents.length > 0,
        data: data.documents,
        count: data.documents.length,
    };
};

export const transformEnvironmental = (
    data: ApiProductOrMasterProduct
): EnvironmentalType => {
    const hasData = data.certifications.length > 0;

    return {
        type: 'environmental',
        hasData,
        data: data.certifications.map(
            (certification) => certification.name as CertificateType
        ),
        count: data.certifications.length,
    };
};

export const transformGpcAttributes = (
    data: ApiProductOrMasterProduct
): GpcAttributesType => {
    const attributes =
        data.brick?.attributes.map(({ name, values }) => ({
            key: name,
            value: values.map(({ name }) => name).join(', '),
        })) ?? [];

    return {
        type: 'gpcAttributes',
        hasData: attributes.length > 0,
        data: attributes,
        count: attributes.length,
    };
};

export const transformIdNumbers = (
    data: ApiProductOrMasterProduct
): IdNumbersType => {
    const identifications = data.idNumbers;
    const classifications = data.classifications;

    return {
        type: 'idNumbers',
        hasData: classifications.length > 0 || identifications.length > 0,
        data: {
            classifications,
            identifications,
        },
        count: identifications.length + classifications.length,
    };
};

export const transformMarkets = (
    data: ApiProductOrMasterProduct
): MarketsType => {
    let parsedData: MarketsType['data'] = [];

    if (data.__typename === 'Product') {
        parsedData = data.markets;
    } else if (data.__typename === 'MasterProduct') {
        parsedData = getUniqueListBy(
            [...data.markets, ...data.products.flatMap((prod) => prod.markets)],
            'id'
        );
    }

    return {
        type: 'markets',
        data: parsedData,
        hasData: parsedData.length > 0,
        count: parsedData.length,
    };
};

export const transformPhysicalMeasurements = (
    data: ApiProductOrMasterProduct
): PhysicalMeasurementsType => {
    return {
        type: 'physicalMeasurements',
        hasData: data.physicalMeassurements.length > 0,
        data: data.physicalMeassurements,
        count: data.physicalMeassurements.length,
    };
};

export const transformMedia = (
    data: ApiProductOrMasterProduct
): MediaDocumentsType => {
    const images = data.images;
    const videos = transformVideos(data.embeddedMedia);

    return {
        type: 'media',
        hasData: videos.length > 0 || images.length > 0,
        data: {
            videos,
            images,
        },
        count: videos.length + images.length,
    };
};

export const transformPackaging = (
    data: ApiProductOrMasterProduct
): PackagingType => {
    const hasData =
        data.consumerUnit || data.distributionUnit || data.transportationUnit;

    return {
        type: 'packaging',
        hasData: !!hasData,
        data: data,
        count: hasData ? 1 : 0,
    };
};

export const transformSpecifications = (
    data: ApiProductOrMasterProduct
): SpecificationsType => {
    const hasData = data.specifications.length > 0;

    return {
        type: 'specifications',
        hasData,
        data: data.specifications.map((spec) => {
            return { key: [spec.name], value: [spec.value] };
        }),
        count: data.specifications.length,
    };
};

export const transformSustainabilityData = ({
    epdData,
}: ApiProductOrMasterProduct): SustainabilityType => {
    const hasData = !!epdData;

    return {
        type: 'sustainabilityData',
        hasData: hasData,
        data: epdData ? [epdData] : [],
        count: hasData ? 1 : 0,
    };
};

export const transformTexts = (data: ApiProductOrMasterProduct): TextsType => {
    return {
        type: 'texts',
        hasData: data.productTexts.length > 0,
        data: data.productTexts,
        count: data.productTexts.length,
    };
};

export const transformModels = (
    data: ApiProductOrMasterProduct
): ModelsType => {
    return {
        type: 'models',
        hasData: data.models.length > 0,
        data: data.models,
        count: data.models.length,
    };
};

export const transformVideos = (videos: ApiEmbeddedMedia[]): VideoType[] => {
    return videos
        .filter((media) => media.type === 'Youtube')
        .map((video) => {
            if (!video.reference) {
                return null;
            }
            return {
                reference: video.reference,
                type: 'youtube',
                thumbnail: `https://img.youtube.com/vi/${video.reference}/hqdefault.jpg`,
                url: `https://www.youtube.com/watch?v=${video.reference}`,
            };
        })
        .filter((video): video is VideoType => !!video);
};

export const transformProductEntity = (
    baseProduct: NonNullable<
        | ApiGetProductQuery['product']
        | ApiGetMasterProductQuery['master_product']
    >
): ProductData<'productData'> => {
    return {
        id: baseProduct.id,
        productData: [
            transformEnvironmental(baseProduct),
            transformDocumentation(baseProduct),
            transformIdNumbers(baseProduct),
            transformTexts(baseProduct),
            transformSpecifications(baseProduct),
            transformPhysicalMeasurements(baseProduct),
            transformPackaging(baseProduct),
            transformDescription(baseProduct),
            transformModels(baseProduct),
            transformMedia(baseProduct),
            transformGpcAttributes(baseProduct),
            transformSustainabilityData(baseProduct),
            transformMarkets(baseProduct),
        ],
    };
};
