import { type CSSProperties, memo, type ReactElement } from 'react';
import { Draggable, type DraggableProvided } from 'react-beautiful-dnd';
import { areEqual } from 'react-window';

import { Box, lighten } from '@mui/material';

import { DragDotsIcon, Image, Typography } from '@xeris/components';

import type { UncategorizedCardData } from '../reducers/types';

type ProductItemProps = {
    provided: DraggableProvided;
    listElement: UncategorizedCardData;
    isDragging?: boolean;
    style?: CSSProperties;
};

export const ProductItem = ({
    provided,
    listElement,
    isDragging,
    style,
}: ProductItemProps): ReactElement => {
    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...style, ...provided.draggableProps?.style }}
            ref={provided.innerRef}
        >
            <Box
                sx={(theme) => ({
                    'display': 'flex',
                    'padding': 1,
                    'border': 1,
                    'borderColor': 'divider',
                    'alignItems': 'center',
                    'height': 40,
                    'marginLeft': listElement.indentationLevel * 2,
                    'backgroundColor': isDragging
                        ? lighten(theme.palette.primary.main, 0.8)
                        : 'background.paper',
                    'gap': 1,
                    '&:hover': {
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.8
                        ),
                    },
                })}
            >
                <DragDotsIcon fontSize={'small'} />
                {listElement.type === 'product' &&
                    (listElement.image ? (
                        <Image
                            src={listElement.image.url}
                            objectFit={listElement.image.objectFit}
                            alt={''}
                            height={'100%'}
                            width={'22px'}
                        />
                    ) : (
                        <Box sx={{ width: '22px', height: '22px' }} />
                    ))}
                <Typography sx={{ flexGrow: 1 }}>{listElement.name}</Typography>
                {listElement.type === 'group' && (
                    <Typography sx={{ paddingRight: 1 }}>
                        {listElement.productCount}
                    </Typography>
                )}
            </Box>
        </div>
    );
};

type ProductListItemProps = {
    data: UncategorizedCardData[];
    index: number;
    style?: CSSProperties;
};

const ProductListItem = ({
    data: listElements,
    style,
    index,
}: ProductListItemProps): ReactElement => {
    const listElement = listElements[index];

    return (
        <Draggable
            draggableId={listElement.path}
            index={index}
            key={listElement.id}
        >
            {(provided): ReactElement => (
                <ProductItem
                    provided={provided}
                    listElement={listElement}
                    style={style}
                />
            )}
        </Draggable>
    );
};

export default memo(ProductListItem, areEqual);
