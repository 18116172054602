import { type FormEvent, type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@xeris/components';
import { userVerificationApi } from '@xeris/pages/admin/api';

import { BackToLoginButton, UserNameInput } from '../Common';

import styles from './ForgotPassword.module.scss';

type PasswordSentConfirmationProps = {
    setIsSent: (isSent: boolean) => void;
};

const PasswordSentConfirmation = ({
    setIsSent,
}: PasswordSentConfirmationProps): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <div className={styles.forgotPassword}>
            <span className={styles.title}>
                {t('forgotPassword.checkEmail')}
            </span>
            <div className={styles.description}>
                <p>{t('forgotPassword.passwordResetLinkSent')}</p>
                <p className={styles.note}>
                    {t('forgotPassword.needToRegisterToReceive')}
                </p>
                <div className={styles.buttonContainer}>
                    <BackToLoginButton />
                </div>

                <p className={styles.help}>
                    {t('forgotPassword.checkSpamIfNotReceived')} <br />
                    <Link
                        to="/login/?action=forgot-password"
                        onClick={(): void => setIsSent(false)}
                    >
                        {t('forgotPassword.tryOtherEmail')}
                    </Link>
                </p>
            </div>
        </div>
    );
};

type ForgotPasswordFormProps = {
    setIsSent: (isSent: boolean) => void;
};

const ForgotPasswordForm = ({
    setIsSent,
}: ForgotPasswordFormProps): ReactElement => {
    const { t } = useTranslation('login');

    const [email, setEmail] = useState('');

    const [resetUserPasswordMutation] =
        userVerificationApi.useResetUserPasswordMutation();

    const handleResetPasswordClick = (event: FormEvent): void => {
        event.preventDefault();
        resetUserPasswordMutation(email);
        setIsSent(true);
    };

    return (
        <div className={styles.forgotPassword}>
            <span className={styles.title}>
                {t('forgotPassword.forgotYourPassword')}
            </span>
            <p>{t('forgotPassword.enterMailBelow')}</p>

            <form onSubmit={handleResetPasswordClick} className={styles.form}>
                <UserNameInput value={email} handleChange={setEmail} />

                <div className={styles.buttonContainer}>
                    <Button disabled={!email} type="submit" minWidth={120}>
                        {t('forgotPassword.send')}
                    </Button>
                </div>
            </form>

            <BackToLoginButton />
        </div>
    );
};

const ForgotPassword = (): ReactElement => {
    const [isSent, setIsSent] = useState(false);

    if (isSent) {
        return <PasswordSentConfirmation setIsSent={setIsSent} />;
    }

    return <ForgotPasswordForm setIsSent={setIsSent} />;
};

export default ForgotPassword;
