import { type ImgHTMLAttributes, type ReactElement } from 'react';

import type { ApiObjectFit } from '@xeris/types';
import { ensuredImageSource } from '@xeris/utilities';

type ImageProps = {
    className?: string;
    objectFit?: ApiObjectFit;
} & ImgHTMLAttributes<HTMLImageElement>;

const Image = ({
    alt,
    className,
    objectFit = 'cover',
    src,
    title,
    style,
    ...otherProps
}: ImageProps): ReactElement => {
    return (
        <img
            className={className}
            src={ensuredImageSource(src)}
            style={{ objectFit: objectFit, objectPosition: 'center', ...style }}
            alt={alt ?? title}
            title={title}
            {...otherProps}
        />
    );
};

export default Image;
