import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGrid } from '@mui/x-data-grid';

import { NoExports } from '@xeris/pages/admin/Exports/components/NoExports';
import { EditExport } from '@xeris/pages/admin/Exports/forms/EditExport';
import { type ExportData } from '@xeris/pages/admin/Exports/forms/types';

import { DeleteExport } from '../forms/DeleteExport';

import { ExportActionMenu } from './ExportActionMenu';

type ExportsListData = {
    exports: ExportData[];
};

export const ExportsList = ({ exports }: ExportsListData): ReactElement => {
    const { t } = useTranslation('administration');

    const [exportToDelete, setExportToDelete] = useState<ExportData>();
    const [exportToEdit, setExportToEdit] = useState<ExportData>();

    if (exports.length === 0) {
        return <NoExports />;
    }

    return (
        <>
            <DataGrid
                rows={exports}
                disableRowSelectionOnClick
                columns={[
                    {
                        field: 'name',
                        headerName: t('exports.list.header.name'),
                        disableColumnMenu: true,
                        flex: 3,
                    },
                    {
                        field: 'type',
                        headerName: t('exports.list.header.type'),
                        disableColumnMenu: true,
                        flex: 1,
                        valueGetter: (value, row) => {
                            return row.type.name;
                        },
                    },
                    {
                        field: 'id',
                        headerName: '',
                        disableColumnMenu: true,
                        sortable: false,
                        width: 40,
                        renderCell: (params) => (
                            <ExportActionMenu
                                exportName={params.row.name}
                                handleEdit={() => setExportToEdit(params.row)}
                                handleDelete={() =>
                                    setExportToDelete(params.row)
                                }
                            />
                        ),
                    },
                ]}
            />
            <EditExport
                isOpen={!!exportToEdit}
                handleCloseDialog={() => setExportToEdit(undefined)}
                exportData={exportToEdit}
                exportNames={exports
                    // We want to allow it to have the same name as itself
                    .filter(({ id }) => id !== exportToEdit?.id)
                    .map(({ name }) => name)}
            />
            <DeleteExport
                isOpen={!!exportToDelete}
                handleCloseDialog={() => setExportToDelete(undefined)}
                exportData={exportToDelete}
            />
        </>
    );
};
