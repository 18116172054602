import { type ChangeEvent, type ReactElement } from 'react';

import { Button } from '@mui/material';

import { UploadIcon } from '@xeris/components';

type UploadButtonProps = {
    acceptTypes: string;
    handleFiles: (event: ChangeEvent<HTMLInputElement>) => void;
    name: string;
    uploadButtonId: string;
};
const UploadButton = ({
    acceptTypes,
    handleFiles,
    name,
    uploadButtonId,
}: UploadButtonProps): ReactElement => {
    return (
        <div>
            <input
                accept={acceptTypes}
                id={uploadButtonId}
                type={'file'}
                onChange={(event) => {
                    handleFiles(event);
                }}
                onClick={(event) => {
                    event.currentTarget.value = '';
                }}
                style={{ display: 'none' }}
            />
            <label htmlFor={uploadButtonId}>
                <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    endIcon={<UploadIcon fontSize="small" />}
                >
                    {name}
                </Button>
            </label>
        </div>
    );
};

export default UploadButton;
