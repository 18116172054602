import { type ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { refreshLoginToken } from '@xeris/api';
import { languagesSelectors, ThemeWrapper } from '@xeris/containers';
import { useQueryParams, useScrollToTop, useWhoami } from '@xeris/hooks';
import AdminPage from '@xeris/pages/admin';
import { organizationsApi } from '@xeris/pages/admin/api';
import DatasetPage from '@xeris/pages/dataset';
import { datasetsApi } from '@xeris/pages/dataset/api';
import { ExportJobPage } from '@xeris/pages/exportJob/ExportJobPage';
import { ImportsPage } from '@xeris/pages/imports/ImportsPage';
import LoginPage, {
    AcceptInvitation,
    ResetPasswordPage,
} from '@xeris/pages/login';
import Logout from '@xeris/pages/login/Logout';
import ProductPage from '@xeris/pages/product';
import Settings from '@xeris/pages/user/Settings/Settings';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

import { useChangeLanguage } from './i18n/useChangeLanguage';
import { datasetActions } from './pages/dataset/reducers';
import EcoSearchPage from './pages/ecoSearch';
import { type LoginPageType, loginSelectors } from './pages/login/reducers';
import { baseRtkApi } from './reducers/baseApi';

import './App.scss';

const useInitDatasets = (): void => {
    const dispatch = useAppDispatch();
    const { isLoggedIn, impersonating } = useAppSelector(
        loginSelectors.selectLoginSlice
    );

    const { refetch } = datasetsApi.useGetDatasetListQuery(
        {},
        {
            skip: !isLoggedIn,
        }
    );

    const activeDatasetId = useAppSelector(
        datasetSelectors.active.selectActiveId
    );

    const { data } = datasetsApi.useGetActiveDatasetQuery(
        { id: activeDatasetId ?? '' },
        { skip: !activeDatasetId }
    );

    useEffect(() => {
        if (data?.dataset) {
            dispatch(datasetActions.setActiveDataset(data.dataset));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (isLoggedIn && impersonating) {
            dispatch(datasetActions.resetDatasetState());
            refetch();
        }
    }, [dispatch, isLoggedIn, impersonating, refetch]);
};

const useInitPlatform = (): void => {
    const dispatch = useAppDispatch();

    //Set up platform language
    const { language, organizationId, isSuperAdmin } = useWhoami();
    const handleChangeLanguage = useChangeLanguage();

    useEffect(() => {
        handleChangeLanguage(language);
    }, [language, handleChangeLanguage]);

    //Set up data language and check login status
    const { isLoggedIn, isLoggingOut, impersonating } = useAppSelector(
        loginSelectors.selectLoginSlice
    );

    const dataLanguage = useAppSelector(
        languagesSelectors.selectActiveDataLanguage
    );

    organizationsApi.useGetOrganizationsQuery(
        {},
        {
            skip: !isLoggedIn || !isSuperAdmin,
        }
    );
    organizationsApi.useGetUserOrganizationQuery(
        { id: organizationId },
        {
            skip: !isLoggedIn || isSuperAdmin || !organizationId,
        }
    );

    useEffect(() => {
        if (!isLoggedIn && !isLoggingOut) {
            refreshLoginToken(dispatch);
        }
    }, [dispatch, isLoggedIn, isLoggingOut, impersonating, dataLanguage]);

    /*
     * TODO:
     * This is quite a brute force way of handling re-fetching when the
     * data language is changed. Should be refined with built in RTKQ
     * methods for invalidating the cache only for language dependant APIs.
     */
    useEffect(() => {
        dispatch(baseRtkApi.util.resetApiState());
    }, [dataLanguage, dispatch]);
};

const App = (): ReactElement => {
    const action = useQueryParams<LoginPageType>('action');

    const { isLoggedIn } = useAppSelector(loginSelectors.selectLoginSlice);

    useInitPlatform();

    useInitDatasets();

    useScrollToTop();

    if (!isLoggedIn) {
        return <LoginPage />;
    }

    if (action === 'reset-password') {
        return <ResetPasswordPage />;
    }
    if (action === 'invite') {
        return <AcceptInvitation />;
    }

    return (
        <Routes>
            <Route path="Login" element={<LoginPage />} />
            <Route path="Logout" element={<Logout />} />
            <Route path="*" element={<ThemeWrapper />}>
                <Route path="UserSettings" element={<Settings />} />
                <Route path="Products/*" element={<ProductPage />} />
                <Route path="EcoSearch/*" element={<EcoSearchPage />} />
                <Route path="Dataset/*" element={<DatasetPage />} />
                <Route path="Export/*" element={<ExportJobPage />} />
                <Route path="import/*" element={<ImportsPage />} />
                <Route path="Admin/*" element={<AdminPage />} />
                <Route path="*" element={<Navigate to="Products" replace />} />
            </Route>
        </Routes>
    );
};

export default App;
