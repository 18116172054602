import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Tooltip } from '@xeris/components';
import { useCategorizeProductsMutation } from '@xeris/pages/exportJob/Categorization/api/categorizationApi';
import { exportSelectors } from '@xeris/pages/exportJob/reducers';
import { useAppSelector } from '@xeris/reducers';

const SaveCategorizationButton = (): ReactElement => {
    const { t } = useTranslation('exports');

    const { exportId = '' } = useParams();

    const productCategorization = useAppSelector(
        exportSelectors.categorizationSelectors.selectProductCategorization
    );
    const [categorizeProducts, categorizeProductsResult] =
        useCategorizeProductsMutation({
            fixedCacheKey: 'useCategorizeProductsMutation',
        });

    const handleCompleteAndSend = (): void => {
        categorizeProducts({
            exportId: exportId,
            categorizations: productCategorization,
        });
    };

    return (
        <Tooltip title={t('exportButton.saveCategorization')}>
            <Button
                variant="outlinedNeutral"
                onClick={handleCompleteAndSend}
                disabled={categorizeProductsResult.isLoading}
            >
                {t('common.save')}
            </Button>
        </Tooltip>
    );
};

export default SaveCategorizationButton;
