import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography } from '@mui/material';

import { BrandCard, Image } from '@xeris/components';

type MarketTilePreviewProps = {
    brand: {
        name: string;
        theme: {
            logo: string | null;
            featureImage: string | null;
        };
    };
};

const MarketTilePreview = ({ brand }: MarketTilePreviewProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Stack
            direction={'column'}
            alignItems={'flex-start'}
            spacing={4}
            justifyContent={'flex-start'}
        >
            {brand.theme.logo && (
                <Box>
                    <Typography variant={'h4'} component={'h2'} gutterBottom>
                        {t('brand.identity.logo')}
                    </Typography>
                    <Image
                        src={brand.theme.logo}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            maxHeight: '100px',
                        }}
                        objectFit={'contain'}
                    />
                </Box>
            )}
            <Box>
                <Typography variant={'h4'} component={'h2'} gutterBottom>
                    {t('brand.identity.brandTilePreview')}
                </Typography>
                <Box sx={{ width: '350px', aspectRatio: '1 / 1' }}>
                    <BrandCard
                        name={brand.name}
                        href={''}
                        logo={brand.theme.logo || ''}
                        featureImage={brand.theme.featureImage || ''}
                    />
                </Box>
            </Box>
        </Stack>
    );
};

export default MarketTilePreview;
