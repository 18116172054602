import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText } from '@mui/material';

import { DeleteIcon, EditIcon, SelectMenu } from '@xeris/components';

type ExportActionMenuProps = {
    handleEdit: () => void;
    handleDelete: () => void;
    exportName: string;
};

export const ExportActionMenu = ({
    handleEdit,
    handleDelete,
    exportName,
}: ExportActionMenuProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <SelectMenu
            ariaLabel={t('exports.list.openActionMenu', {
                exportName,
            })}
            menuItemList={[
                {
                    key: 'edit',
                    handleClick: handleEdit,
                    content: (
                        <>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('exports.edit.editExport')}
                            />
                        </>
                    ),
                },
                {
                    key: 'delete',
                    handleClick: handleDelete,
                    content: (
                        <>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('exports.delete.deleteExport')}
                            />
                        </>
                    ),
                },
            ]}
        />
    );
};
