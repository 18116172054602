import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { LockPrivateIcon, MutationSnackbars } from '@xeris/components';
import { FormDrawer, TextInput } from '@xeris/components/forms';
import { brandApi } from '@xeris/pages/admin/api';

const editBrandSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
    })
    .required();

type EditBrandFormData = yup.InferType<typeof editBrandSchema>;

type EditBrandProps = {
    isOpen: boolean;
    brandData?: EditBrandFormData | null;
    handleClose: () => void;
};

export const EditBrand = ({
    isOpen,
    brandData,
    handleClose,
}: EditBrandProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [editBrandMutation, editBrandResult] =
        brandApi.useEditBrandMutation();

    const { handleSubmit, control, reset } = useForm<EditBrandFormData>({
        resolver: yupResolver(editBrandSchema),
        defaultValues: {
            id: brandData?.id ?? '',
            name: brandData?.name ?? '',
        },
    });

    const handleSubmitBrand = async (
        editBrandData: EditBrandFormData
    ): Promise<void> => {
        await editBrandMutation(editBrandData).unwrap();
        reset(editBrandData);
        handleClose();
    };

    useEffect(() => {
        reset(brandData ?? undefined);
    }, [reset, brandData]);

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(handleSubmitBrand)}
                title={t(`brand.editBrand.editBrand`)}
                cancelText={t('common.cancel')}
                saveText={t('brand.common.save')}
            >
                <TextInput
                    label={t('brand.editBrand.id')}
                    fieldName={'id'}
                    control={control}
                    required
                    disabled
                    showError
                    placeholder={t('brand.editBrand.idPlaceholder')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LockPrivateIcon fontSize={'small'} />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextInput
                    label={t('brand.editBrand.name')}
                    fieldName={'name'}
                    control={control}
                    required
                    showError
                    autoFocus
                    placeholder={t('brand.editBrand.namePlaceholder')}
                />
            </FormDrawer>

            <MutationSnackbars
                isSuccess={editBrandResult.isSuccess}
                successText={t('brand.editBrand.editBrandSuccess')}
                isError={editBrandResult.isError}
                errorText={t('brand.editBrand.errorOnEdit')}
            />
        </>
    );
};
