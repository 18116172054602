import {
    loginFailed,
    loginStarted,
    loginSuccess,
    logoutFailed,
    logoutStarted,
    logoutSuccess,
} from '@xeris/pages/login/reducers';
import { type AppDispatch } from '@xeris/reducers';
import { baseRtkApi } from '@xeris/reducers/baseApi';
import { clearLocalState } from '@xeris/utilities';

import { impersonateUrl, loginUrl } from './constants';

export const login =
    (username: string, password: string, rememberMe: boolean) =>
    async (dispatch: AppDispatch) => {
        dispatch(loginStarted());

        const response = await fetch(loginUrl, {
            method: 'post',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ username, password, remember: rememberMe }),
        });

        if (!response.ok) {
            const error = await response.text();

            dispatch(
                loginFailed({ noCredentials: error.includes('No cookie') })
            );

            return;
        }

        const user = await response.json();

        dispatch(loginSuccess(user));
    };

export const logout = () => async (dispatch: AppDispatch) => {
    dispatch(logoutStarted());

    clearLocalState();

    const response = await fetch(loginUrl, {
        method: 'delete',
    });

    if (!response.ok) {
        dispatch(logoutFailed());
        return;
    }

    dispatch(logoutSuccess());
};

export const impersonate =
    (userId: string) => async (dispatch: AppDispatch) => {
        const response = await fetch(`${impersonateUrl}?id=${userId}`);

        if (!response.ok) {
            console.error('Impersonate failed');
            return;
        }

        const user = await response.json();

        dispatch(baseRtkApi.util.resetApiState());

        dispatch(loginSuccess({ ...user, impersonating: true }));
    };
