import { type ReactElement, useId } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

import { CloseIcon, IconButton, SearchIcon } from '@xeris/components';

type SearchProps = {
    filter: string;
    onFilterChange: (filter: string) => void;
};

export const Search = ({
    onFilterChange,
    filter,
}: SearchProps): ReactElement => {
    const inputId = useId();
    const { t } = useTranslation('products');

    return (
        <FormControl sx={{ marginBlock: 1, flexShrink: 1 }} size={'small'}>
            <InputLabel htmlFor={inputId}>{t('brand.search')}</InputLabel>
            <OutlinedInput
                id={inputId}
                onChange={(event) => onFilterChange(event.target.value)}
                value={filter}
                label={t('brand.search')}
                endAdornment={
                    <InputAdornment position="end">
                        {filter ? (
                            <IconButton
                                aria-label={t('brand.clear')}
                                size={'small'}
                                onClick={() => onFilterChange('')}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <SearchIcon />
                        )}
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};
