import { type ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { masterProductUrl } from '@xeris/utilities/urls';

import Datasheet from '../Common/Datasheet/Datasheet';
import { productHooks } from '../hooks/productHooks';

const MasterProduct = (): ReactElement => {
    const { masterProductId = '', brandId = '' } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, isError, refetch } =
        productHooks.api.queries.useGetMasterProduct({
            masterProductId,
        });

    // Make sure the brand is the brand of the product
    // This can mismatch if users alter the url manually
    useEffect(() => {
        if (data && brandId !== data?.brand.id) {
            navigate(masterProductUrl(data), {
                replace: true,
            });
        }
    }, [brandId, data, navigate]);

    return (
        <Datasheet
            masterProduct={data}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
        />
    );
};

export default MasterProduct;
