import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type TFunction } from 'i18next';

import {
    DataGrid,
    EmptyState,
    SearchBar,
    Skeleton,
    type TypeSafeColDef,
} from '@xeris/components';
import { useFeatureFlag, useQueryParams, useWhoami } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';
import { formatDateAsDistanceIfRecent } from '@xeris/utilities';

import { datasetsApi } from '../../api';
import { DatasetActionsMenu } from '../../common';
import { CreateDataset } from '../../forms';
import { datasetSelectors } from '../../reducers';
import {
    type DatasetSharedWith,
    type SharingPrivilege,
} from '../../types/datasetTypes';

import AccessList from './AccessList';
import DatasetName from './DatasetName';
import DatasetStatus from './DatasetStatus';
import Preview from './Preview';

import styles from '../DatasetList.module.scss';

type RowData = {
    id: string;
    name: string;
    description: string | null;
    owner: {
        id: string;
        name: string | null;
        username: string;
    };
    totalCount: number;
    previewImages: string[];
    lastExported: string | null;
    lastModified: string;
    lastProductUpdate: string | null;
    privilege: SharingPrivilege | null;
    sharedWith: DatasetSharedWith[];
};

type ColumnDef = TypeSafeColDef<RowData>;

const getColumns = (
    t: TFunction<'dataset'>,
    featureFlagVisible: boolean
): ColumnDef[] => {
    const columns: ColumnDef[] = [
        {
            field: 'previewImages',
            headerName: ' ',
            sortable: false,
            width: 62,
            renderCell: (params): ReactElement => (
                <Preview dataset={params.row} />
            ),
        },
        {
            field: 'name',
            headerName: t('datasetList.datasetName'),
            flex: 2,
            minWidth: 300,
            renderCell: (params): ReactElement => (
                <DatasetName dataset={params.row} />
            ),
        },
        {
            field: 'lastExported',
            headerName: t('datasetList.exportStatus'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 160,
            align: 'center',
            renderCell: (params): ReactElement => (
                <DatasetStatus dataset={params.row} />
            ),
        },
        {
            field: 'lastModified',
            headerName: t('datasetList.lastModified'),
            flex: 1,
            minWidth: 160,
            renderCell: (params): string =>
                params && formatDateAsDistanceIfRecent(new Date(params.value)),
        },
        {
            field: 'actions',
            headerName: ' ',
            width: 60,
            sortable: false,
            renderCell: (params): ReactElement => (
                <DatasetActionsMenu dataset={params.row} />
            ),
        },
    ];

    if (featureFlagVisible) {
        columns.splice(4, 0, {
            field: 'sharedWith',
            headerName: t('datasetList.access'),
            minWidth: 160,
            renderCell: (params): ReactElement | null => (
                <AccessList dataset={params.row} />
            ),
        });
    }

    return columns;
};

type HeaderProps = {
    searchTerm: string;
    handleSetSearchTerm: (value: string) => void;
};

const Header = ({
    searchTerm,
    handleSetSearchTerm,
}: HeaderProps): ReactElement => {
    const { t } = useTranslation('dataset');
    return (
        <div className={styles.toolbar}>
            <div className={styles.left}>
                <SearchBar
                    filter={searchTerm}
                    onChange={handleSetSearchTerm}
                    inputLabel={t('datasetList.searchForDataset')}
                />
            </div>
            <div className={styles.right}>
                <CreateDataset />
            </div>
        </div>
    );
};

const SkeletonLoad = (): ReactElement => {
    return (
        <>
            <Header searchTerm="" handleSetSearchTerm={(): void => {}} />
            <div className={styles.skeletonLoad}>
                <Skeleton count={4} height={64} />
            </div>
        </>
    );
};

const NoDatasets = (): ReactElement => {
    const { t } = useTranslation('dataset');

    const tabIdFromUrl = useQueryParams('filter');

    const isSharedTab = tabIdFromUrl === 'shared';

    const messageKey = isSharedTab
        ? 'datasetList.noDatasets.noneIsSharedWithYou'
        : 'datasetList.noDatasets.wouldYouCreateOne';

    return (
        <div className={styles.noDataset}>
            <EmptyState
                title={t('datasetList.noDatasets.noDatasetsFound')}
                message={t(messageKey)}
                ActionButton={
                    <>{!isSharedTab && <CreateDataset showPlusIcon />}</>
                }
            />
        </div>
    );
};

const ListView = (): ReactElement => {
    const { t } = useTranslation('dataset');
    const tabIdFromUrl = useQueryParams('filter');
    const [shareDatasetVisible] = useFeatureFlag('shareDataset');

    const [searchTerm, setSearchTerm] = useState<string>('');

    const { username } = useWhoami();

    const datasetListFromStore = useAppSelector(
        datasetSelectors.minimal.selectAll
    );
    const getDatasetListResult = datasetsApi.useGetDatasetListQuery({});

    const datasetList: RowData[] = datasetListFromStore.filter((dataset) => {
        if (tabIdFromUrl === 'private') {
            return dataset.owner.username === username;
        }
        if (tabIdFromUrl === 'shared') {
            return dataset.owner.username !== username;
        }

        return true;
    });

    if (
        getDatasetListResult.isUninitialized &&
        getDatasetListResult.isFetching
    ) {
        return <SkeletonLoad />;
    }

    if (datasetList.length === 0) {
        return <NoDatasets />;
    }

    return (
        <>
            <Header
                searchTerm={searchTerm}
                handleSetSearchTerm={setSearchTerm}
            />

            <DataGrid
                rows={datasetList}
                filterModel={{
                    items: [
                        {
                            field: 'name',
                            operator: 'contains',
                            value: searchTerm,
                        },
                    ],
                }}
                disableColumnFilter={true}
                ignoreDiacritics={true}
                rowHeight={64}
                columns={getColumns(t, shareDatasetVisible)}
                autoHeight={true}
                pagination={undefined}
                hideFooter
                disableRowSelectionOnClick
            />
        </>
    );
};

export default ListView;
