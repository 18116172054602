import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

export const NoExports = (): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box padding={2} paddingTop={20}>
            <Typography variant={'h2'} textAlign={'center'} gutterBottom>
                {t('exports.list.noExportSetups')}
            </Typography>
            <Typography textAlign={'center'}>
                {t('exports.list.createNewSetup')}
            </Typography>
        </Box>
    );
};
