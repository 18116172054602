import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle, Box } from '@mui/material';

import { SearchHeader } from '@xeris/components/ProductSearchCard/ProductSearchCard';
import { useQueryParams } from '@xeris/hooks';
import { MultipleMasterProductsSelector } from '@xeris/pages/product/Common';
import { productHooks } from '@xeris/pages/product/hooks/productHooks';
import ProductCardBatch from '@xeris/pages/product/ProductSearch/components/Tabs/Search/ProductCardBatch';
import ProductSearchBar from '@xeris/pages/product/ProductSearch/components/Tabs/Search/ProductSearchBar';
import { type SearchMatchGroupType } from '@xeris/pages/product/types/search';

import SearchInfo from './SearchInfo';

const BATCH_SIZE = 20;

const chunkArray = <T,>(array: T[], size: number): T[][] => {
    return array.length > size
        ? [array.slice(0, size), ...chunkArray(array.slice(size), size)]
        : [array];
};

const Search = (): ReactElement => {
    const { t } = useTranslation('products');
    const searchTerm = useQueryParams('searchTerm');

    const { data, isError, error, isSuccess } =
        productHooks.api.queries.useSearchProductEntitiesData(
            {
                query: searchTerm,
            },
            {
                skip: !searchTerm,
            }
        );

    const productCount = data?.lookup.matches
        .map((match) => match.productIds.length || 1)
        .reduce((sum, count) => sum + count, 0);

    return (
        <Box>
            <ProductSearchBar
                productCount={productCount}
                showResult={!!data && !!searchTerm}
            >
                <MultipleMasterProductsSelector
                    showCounters={false}
                    masterProductList={(data?.lookup.matches ?? []).map(
                        (masterProduct) => ({
                            __typename: 'MasterProduct',
                            id: masterProduct.masterProductId,
                            isConfigurable:
                                masterProduct.productIds.length === 0,
                            products: masterProduct.productIds.map((id) => ({
                                id: id,
                                idNumbers: [],
                                markets: [],
                            })),
                        })
                    )}
                />
            </ProductSearchBar>
            {!searchTerm ? (
                <SearchInfo />
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {isError && (
                        <Alert severity={'error'}>
                            <AlertTitle>{t('common.error')}</AlertTitle>
                            <p>{JSON.stringify(error)}</p>
                        </Alert>
                    )}
                    {isSuccess && data?.lookup.matches.length === 0 && (
                        <Alert severity={'warning'}>
                            <AlertTitle>
                                {t('common.noResultsTitle')}
                            </AlertTitle>
                            {t('common.noResultsBody')}
                        </Alert>
                    )}
                    {!!data?.lookup.warnings?.length && (
                        <Alert severity={'warning'}>
                            <AlertTitle>{t('common.warning')}</AlertTitle>
                            {data?.lookup.warnings?.map((warning, index) => (
                                <p key={index}>
                                    {t(`search.warnings.${warning.type}`, {
                                        term: warning.term,
                                    })}
                                </p>
                            ))}
                        </Alert>
                    )}
                    <Box>
                        <SearchHeader sx={{ paddingTop: 0 }} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            {isSuccess &&
                                data?.lookup.matches.length > 0 &&
                                chunkArray<SearchMatchGroupType>(
                                    data?.lookup.matches,
                                    BATCH_SIZE
                                ).map((matches) => (
                                    <ProductCardBatch
                                        key={
                                            matches[0]?.masterProductId ?? 'key'
                                        }
                                        matches={matches}
                                    />
                                ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Search;
