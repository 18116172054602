import { Children, type ReactElement, type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, type SxProps, type Theme, Typography } from '@mui/material';

import { Button, Image, Link, Tooltip } from '@xeris/components';
import type { ApiObjectFit } from '@xeris/types';

import {
    MasterProductChildWrapper,
    MasterProductInfo,
    MasterProductLinkCard,
    MasterProductMoreWrapper,
    MasterProductSearchCardWrapper,
    ProductSearchCardWrapper,
    SearchHeaderWrapper,
} from './ProductSearchCardElement';

const COLLAPSED_PRODUCT_COUNT = 6;

const formatParams = {
    date: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    },
};

type SearchHeaderProps = {
    sx?: SxProps<Theme>;
};

export const SearchHeader = ({ sx }: SearchHeaderProps): ReactElement => {
    const { t } = useTranslation('common');

    return (
        <SearchHeaderWrapper sx={sx}>
            <Box sx={{ flexGrow: 1, paddingInlineStart: '52px' }}>
                <Typography variant={'subtitle1'}>
                    {t('components.productSearch.name')}
                </Typography>
            </Box>
            <Box
                sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                    flexBasis: 150,
                    textAlign: 'start',
                }}
            >
                <Typography variant={'subtitle1'}>
                    {t('components.productSearch.brand')}
                </Typography>
            </Box>
            <Box
                sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                    flexBasis: 100,
                    textAlign: 'center',
                }}
            >
                <Typography variant={'subtitle1'}>
                    {t('components.productSearch.variants')}
                </Typography>
            </Box>
            <Box
                sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                    flexBasis: 220,
                    textAlign: 'center',
                }}
            >
                <Typography variant={'subtitle1'}>
                    {t('components.productSearch.updated')}
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 0, flexShrink: 0, flexBasis: 150 }} />
        </SearchHeaderWrapper>
    );
};

type MasterProductSearchCardProps = {
    productName: string;
    brandName: string;
    variantCount?: number;
    lastUpdated?: string;
    image?: {
        url: string;
        objectFit: ApiObjectFit;
    };
    isConfigurable: boolean;
    href?: string;
    children?: ReactNode;
    isLoading?: boolean;
    Actions?: ReactNode;
    Status?: ReactNode;
};

export const MasterProductSearchCard = ({
    children,
    image,
    variantCount,
    brandName,
    lastUpdated,
    isConfigurable,
    href,
    productName,
    isLoading,
    Status,
    Actions,
}: MasterProductSearchCardProps): ReactElement => {
    const { t } = useTranslation('common');

    const [showAll, setShowAll] = useState(false);

    const childCount = Children.count(children);

    return (
        <MasterProductSearchCardWrapper>
            <MasterProductInfo>
                <Box sx={{ flexGrow: 1 }}>
                    <MasterProductLinkCard
                        to={href}
                        color={'inherit'}
                        underline={'none'}
                    >
                        <Box sx={{ paddingRight: 1.5 }}>
                            <Image
                                src={image?.url}
                                objectFit={image?.objectFit}
                                alt={''}
                                height={40}
                                width={40}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography>{productName}</Typography>
                        </Box>
                    </MasterProductLinkCard>
                </Box>
                <Box
                    sx={{
                        flexGrow: 0,
                        flexBasis: 150,
                        flexShrink: 0,
                        textAlign: 'start',
                    }}
                >
                    <Typography>{brandName}</Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 0,
                        flexBasis: 100,
                        flexShrink: 0,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {!isLoading ? (
                        isConfigurable ? (
                            <Tooltip
                                title={t(
                                    'components.productSearch.configurableProduct'
                                )}
                            >
                                <Typography variant={'body2'}>{'C'}</Typography>
                            </Tooltip>
                        ) : (
                            variantCount !== undefined && (
                                <Typography variant={'body2'}>
                                    {variantCount}
                                </Typography>
                            )
                        )
                    ) : null}
                </Box>
                <Box
                    sx={{
                        flexGrow: 0,
                        flexShrink: 0,
                        flexBasis: 220,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                    }}
                >
                    <Typography variant={'body2'}>
                        {!!lastUpdated &&
                            t('date', {
                                date: new Date(lastUpdated),
                                formatParams,
                            })}
                    </Typography>
                    {Status}
                </Box>
                <Box
                    sx={{
                        flexGrow: 0,
                        flexShrink: 0,
                        flexBasis: 150,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                    }}
                >
                    {!isLoading && Actions}
                </Box>
            </MasterProductInfo>
            {childCount > 0 && (
                <>
                    <MasterProductChildWrapper>
                        {Children.toArray(children).slice(
                            0,
                            showAll ? Infinity : COLLAPSED_PRODUCT_COUNT
                        )}
                    </MasterProductChildWrapper>
                    {childCount > COLLAPSED_PRODUCT_COUNT && (
                        <MasterProductMoreWrapper>
                            <Button
                                variant={'outlinedNeutral'}
                                onClick={(): void =>
                                    setShowAll((value) => !value)
                                }
                            >
                                {showAll ? t('showLess') : t('showMore')}
                            </Button>
                        </MasterProductMoreWrapper>
                    )}
                </>
            )}
        </MasterProductSearchCardWrapper>
    );
};

type ProductSearchCardProps = {
    productName: string;
    lastUpdated?: string;
    image?: {
        url: string;
        objectFit: ApiObjectFit;
    };
    href?: string;
    isLoading?: boolean;
    Actions?: ReactNode;
    Status?: ReactNode;
};

export const ProductSearchCard = ({
    image,
    lastUpdated,
    productName,
    href,
    isLoading,
    Actions,
    Status,
}: ProductSearchCardProps): ReactElement => {
    const { t } = useTranslation('common');

    return (
        <ProductSearchCardWrapper>
            <Link to={href} color={'inherit'} underline={'none'}>
                <Image
                    src={image?.url}
                    objectFit={image?.objectFit}
                    alt={''}
                    height={40}
                    width={40}
                />
            </Link>
            <Box sx={{ paddingLeft: 2, flexGrow: 1 }}>
                <Link to={href} color={'inherit'} underline={'none'}>
                    <Typography>{productName}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant={'body2'}>
                            {lastUpdated &&
                                t('updated', {
                                    date: new Date(lastUpdated),
                                    formatParams,
                                })}
                        </Typography>
                        {Status}
                    </Box>
                </Link>
            </Box>
            <Box
                sx={{
                    flexBasis: 100,
                    flexShrink: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 1,
                }}
            >
                {!isLoading && Actions}
            </Box>
        </ProductSearchCardWrapper>
    );
};
