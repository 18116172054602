import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Skeleton, Toolbar, Tooltip } from '@mui/material';

import { format } from 'date-fns';

import { CloseIcon, Typography } from '@xeris/components';
import { SearchHeader } from '@xeris/components/ProductSearchCard/ProductSearchCard';
import { datasetProductsApi } from '@xeris/pages/dataset/api';
import { NoProducts } from '@xeris/pages/dataset/Dataset/components/NoProducts';
import ProductCardBatch from '@xeris/pages/product/ProductSearch/components/Tabs/Search/ProductCardBatch';
import { chunkArray, formatDateAsDistanceIfRecent } from '@xeris/utilities';

import { DeletedProductsWarning } from '../../common';

type BodyHeaderProps = {
    dataset: {
        id: string;
        missingProductCount: number;
        lastExported: string | null;
    };
};

const BodyHeader = ({ dataset }: BodyHeaderProps): ReactElement => {
    const { t } = useTranslation('dataset');

    return (
        <>
            <Toolbar sx={{ minHeight: 4, mb: 2 }}>
                <Box sx={{ display: 'flex', flexGrow: 1 }} />
                <Tooltip
                    title={
                        dataset.lastExported
                            ? format(new Date(dataset.lastExported), 'PPPp ')
                            : ''
                    }
                >
                    <Box sx={{ paddingRight: '-8px' }}>
                        <Typography variant={'caption'}>
                            {t('datasetProductList.lastExported')}:{' '}
                        </Typography>
                        <Typography
                            variant={'caption'}
                            fontWeight={'fontWeightBold'}
                        >
                            {dataset.lastExported
                                ? formatDateAsDistanceIfRecent(
                                      new Date(dataset.lastExported)
                                  )
                                : t('datasetProductList.notExported')}
                        </Typography>
                    </Box>
                </Tooltip>
            </Toolbar>
            <DeletedProductsWarning dataset={dataset} />
        </>
    );
};

type DatasetProductsProps = {
    dataset: {
        id: string;
        missingProductCount: number;
        lastExported: string | null;
        lastProductUpdate: string | null;
    };
};

const DatasetProducts = ({ dataset }: DatasetProductsProps): ReactElement => {
    const [removeProductsFromDataset] =
        datasetProductsApi.useRemoveProductsFromDatasetMutation();

    const { data, isLoading } = datasetProductsApi.useGetProductIdsQuery({
        datasetId: dataset.id,
    });

    const masterProducts =
        data?.dataset?.selectedMasterProducts.map((masterProduct) => ({
            masterProductId: masterProduct.id,
            isConfigurable: true,
            productIds: [],
        })) ?? [];

    const products =
        Object.entries(
            data?.dataset?.selectedProducts.reduce<Record<string, string[]>>(
                (products, product) => {
                    const id = product.data?.masterProduct.id;

                    if (id) {
                        products[id] = [...(products[id] ?? []), product.id];
                    }

                    return products;
                },
                {}
            ) ?? []
        ).map(([masterProductId, productIds]) => ({
            masterProductId: masterProductId,
            isConfigurable: false,
            productIds: productIds,
        })) ?? [];

    const productList = [...products, ...masterProducts];

    if (isLoading || data?.dataset?.id !== dataset.id) {
        return <Skeleton height={200} />;
    }

    if (productList.length === 0) {
        return <NoProducts />;
    }

    return (
        <div>
            <BodyHeader dataset={dataset} />
            <SearchHeader />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                {chunkArray(productList, 20).map((matches) => (
                    <ProductCardBatch
                        key={matches[0]?.masterProductId ?? 'key'}
                        matches={matches}
                        lastExported={dataset.lastExported}
                        productAction={(id) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <IconButton
                                    onClick={() =>
                                        removeProductsFromDataset({
                                            datasetId: dataset.id,
                                            productIds: [id],
                                            masterProductIds: [],
                                        })
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        )}
                        masterProductAction={(id, productIds) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <IconButton
                                    onClick={() =>
                                        removeProductsFromDataset({
                                            datasetId: dataset.id,
                                            productIds,
                                            masterProductIds: [id],
                                        })
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        )}
                    />
                ))}
            </Box>
        </div>
    );
};

export default DatasetProducts;
