import { memo, type ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Dialog } from '@xeris/components';
import { useScrollToAnchor } from '@xeris/hooks';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import type { MasterProduct } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

import { productHooks } from '../hooks/productHooks';

import VariantGeneratorWrapper from './VariantGeneratorWrapper/VariantGeneratorWrapper';
import {
    initializeVariantGeneratorSlice,
    resetVariantGeneratorSlice,
    variantGeneratorSelectors,
} from './reducer';

import './index.scss';

const useSetUpVariantGenerator = (): {
    isLoading: boolean;
    isError: boolean;
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    > | null;
} => {
    const dispatch = useAppDispatch();

    const {
        masterProductId = '',
        datasetId,
        configurationSetId,
        brandId,
    } = useParams();

    const activeDatasetId = useAppSelector(
        datasetSelectors.active.selectActiveId
    );

    const selectedMarketExternalId = useAppSelector((state) =>
        settingsSelectors.selectExternalMarket(state, brandId ?? '')
    );

    const currentDatasetId = datasetId || activeDatasetId;

    const { data, isLoading, isError } =
        productHooks.api.queries.useGetConfigurableMasterProduct(
            {
                datasetId: currentDatasetId ?? '',
                hasDatasetId: !!currentDatasetId,
                masterProductId: masterProductId,
                marketId: selectedMarketExternalId,
            },
            { skip: !masterProductId }
        );

    const isInitialized = useAppSelector(
        variantGeneratorSelectors.selectIsInitialized
    );

    useEffect(() => {
        if (data) {
            dispatch(
                initializeVariantGeneratorSlice({
                    masterProduct: data,
                    configurationSetId,
                })
            );
        }

        return () => {
            dispatch(resetVariantGeneratorSlice());
        };
    }, [configurationSetId, data, dispatch]);

    // If no data, return null
    if (!data || !isInitialized) {
        return {
            isLoading: isLoading || !isInitialized,
            isError: isError,
            masterProduct: null,
        };
    }

    // If we are creating a new configuration set, make sure the product is in the dataset
    if (datasetId && !data.selectedData) {
        return {
            isLoading: isLoading,
            isError: isError,
            masterProduct: null,
        };
    }

    // If we are editing a configuration set, make sure it exist in the products configuration set
    if (
        configurationSetId &&
        !data.selectedData?.configurationSets.find(
            ({ id }) => id === configurationSetId
        )
    ) {
        return {
            isLoading: isLoading,
            isError: isError,
            masterProduct: null,
        };
    }

    // If all else is okay, return the data
    return {
        isLoading: isLoading,
        isError: isError,
        masterProduct: data,
    };
};

const VariantGenerator = (): ReactElement => {
    const { isLoading, isError, masterProduct } = useSetUpVariantGenerator();

    useScrollToAnchor();

    return (
        <div className="variant-generator-root">
            <Dialog open={true} fullScreen>
                <VariantGeneratorWrapper
                    isLoading={isLoading}
                    isError={isError}
                    masterProduct={masterProduct}
                />
            </Dialog>
        </div>
    );
};

export default memo(VariantGenerator);
