import { type ReactElement } from 'react';

import { Button, Typography } from '@mui/material';

import { CloseIcon } from '@xeris/components';

type SubmitImportFileProps = {
    abortUploadHandler: () => void;
    uploadFile: { name: string };
};

const SubmitImportFile = ({
    abortUploadHandler,
    uploadFile,
}: SubmitImportFileProps): ReactElement => (
    <Button
        onClick={abortUploadHandler}
        variant={'outlined'}
        color={'secondary'}
        sx={{ flexGrow: 1 }}
    >
        <Typography sx={{ flexGrow: 1 }} align={'left'}>
            {uploadFile.name}
        </Typography>
        <CloseIcon fontSize="small" />
    </Button>
);

export default SubmitImportFile;
