import type { ReactElement } from 'react';

import styles from './CountIcon.module.scss';

type ConnectionsTabLabelProps = {
    count: number;
    isActive: boolean;
};

const CountIcon = ({
    count,
    isActive,
}: ConnectionsTabLabelProps): ReactElement => {
    const classNames = [styles.counter];
    if (isActive) classNames.push(styles.isActive);

    return <div className={classNames.join(' ')}>{count}</div>;
};

export default CountIcon;
