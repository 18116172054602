import { type ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, type SxProps, type Theme } from '@mui/material';

import { SearchBar as SearchBarComponent } from '@xeris/components';
import { useQueryParams } from '@xeris/hooks';

type SearchBarProps = {
    label: string;
    searchKey: string;
    debounceTime?: number;
    sx?: SxProps<Theme>;
};

const SearchBar = ({
    label,
    searchKey,
    debounceTime,
    sx,
}: SearchBarProps): ReactElement => {
    const navigate = useNavigate();
    const searchTerm = useQueryParams(searchKey) ?? '';

    const handleSetFilter = (newSearchTerm: string): void => {
        const url = new URL(document.location.href);

        if (!newSearchTerm) {
            url.searchParams.delete(searchKey);
        } else {
            url.searchParams.set(searchKey, newSearchTerm);
        }

        navigate(url.pathname + url.search, { replace: true });
    };

    return (
        <Box sx={sx}>
            <SearchBarComponent
                debounceTime={debounceTime}
                filter={searchTerm}
                onChange={debounceTime ? handleSetFilter : undefined}
                onSubmit={debounceTime ? undefined : handleSetFilter}
                inputLabel={label}
            />
        </Box>
    );
};

export default SearchBar;
