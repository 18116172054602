import { type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type FeatureGroup } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import MissingSelections from '../Panels/MissingSelections/MissingSelections';
import ToNextStagePanel from '../Panels/ToNextStagePanel/ToNextStagePanel';
import { variantGeneratorSelectors } from '../reducer';

type InlineNavigationProps = {
    featureGroups: FeatureGroup[];
};

const InlineNavigation = ({
    featureGroups,
}: InlineNavigationProps): ReactElement => {
    const { t } = useTranslation('product');

    const missingFeatures = useAppSelector(
        variantGeneratorSelectors.selectMissingFeatures
    );

    const lastFeatureInList = useMemo(
        () => featureGroups[featureGroups.length - 1],
        [featureGroups]
    );

    if (missingFeatures.length === 0) {
        return (
            <ToNextStagePanel
                description={t('variantGenerator.selectDataAndSendConfig')}
                nextStageName={t('variantGenerator.stages.selectData')}
                nextStageType="selectData"
                hasPreviousFeatureGroup={true}
                previousFeatureGroupPath={lastFeatureInList?.id}
                previousStageType="configure"
                title={t('variantGenerator.happyWithSummary')}
            />
        );
    }
    return (
        <MissingSelections
            isConfigurePage={false}
            hasPreviousFeatureGroup={true}
            missingFeatures={missingFeatures}
            previousFeatureGroup={lastFeatureInList}
        />
    );
};

export default InlineNavigation;
