import { type ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, IconButton, ListItem, ListItemText } from '@mui/material';

import { CloseIcon, PreviewButton } from '@xeris/components';
import { type MaterialOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import {
    selectOptions,
    selectPreview,
    variantGeneratorSelectors,
} from '../../../reducer';

import styles from './MaterialOptions.module.scss';

type MaterialOptionProps = {
    option: MaterialOption;
    featureId: string;
};

const MaterialOptionCard = ({
    option,
    featureId,
}: MaterialOptionProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const { isPreviewed, isSelected, isPreviewable } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    const handleSelectPreview = useCallback((): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    }, [dispatch, featureId, option.id]);

    const handleRemoveOption = useCallback((): void => {
        dispatch(
            selectOptions({
                optionIds: [option.id],
                featureId: featureId,
                newValue: false,
            })
        );
    }, [dispatch, featureId, option.id]);

    if (!isSelected) return null;

    return (
        <ListItem
            className={styles.materialOption}
            sx={{ '&:hover ': { '.preview': { opacity: 1 } } }}
        >
            <div className={styles.option} onClick={handleSelectPreview}>
                <PreviewButton
                    className={'preview'}
                    isPreviewActive={isPreviewed}
                    isDisabled={!isPreviewable}
                    size="small"
                    onSelectPreview={handleSelectPreview}
                />

                <Avatar
                    alt={t('variantGenerator.previewOf', {
                        optionName: option.name,
                    })}
                    src={option.image ?? undefined}
                    sx={{ width: 30, height: 30 }}
                />
                <ListItemText>{option.name}</ListItemText>
            </div>
            <IconButton onClick={handleRemoveOption}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </ListItem>
    );
};

type MaterialOptionsProps = {
    options: MaterialOption[];
    featureId: string;
};

const MaterialOptions = ({
    options,
    featureId,
}: MaterialOptionsProps): ReactElement => {
    return (
        <div className={styles.materialOptions}>
            {options.map((option) => {
                return (
                    <MaterialOptionCard
                        key={option.id}
                        featureId={featureId}
                        option={option}
                    />
                );
            })}
        </div>
    );
};

export default MaterialOptions;
