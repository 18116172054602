import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DialogTitle, IconButton } from '@mui/material';

import { CloseIcon } from '@xeris/components';

type HeaderTitleProps = {
    handleClose: () => void;
};

const HeaderTitle = ({ handleClose }: HeaderTitleProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <DialogTitle
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {t('dataSelector.selectProductData')}
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};

type HeaderProps = {
    handleClose: () => void;
};

const Header = ({ handleClose }: HeaderProps): ReactElement => {
    return (
        <Box sx={{ width: '100%' }}>
            <HeaderTitle handleClose={handleClose} />
        </Box>
    );
};

export default Header;
