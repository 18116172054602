import { type ReactElement } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';

import { Box } from '@mui/material';

import { ChecboxMarkedCircleIcon } from '@xeris/components';
import { useElementSize } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import { categorizationSelectors } from '../reducers/categorizationSelectors';

import ProductListItem, { ProductItem } from './ProductListItem';

const AllProductsIsCategorized = (): ReactElement => {
    const { t } = useTranslation('dataset');

    return (
        <div className="all-products-is-sorted-message">
            <ChecboxMarkedCircleIcon />
            <div className="message">
                {t('categorization.nothingMoreToSort')}
            </div>
        </div>
    );
};

const CategorizedProducts = (): ReactElement => {
    const [ref, size] = useElementSize();

    const products = useAppSelector(
        categorizationSelectors.selectCategorizedProducts
    );

    if (products.length === 0) {
        return <AllProductsIsCategorized />;
    }

    return (
        <Box
            sx={{
                padding: 1,
                height: '100%',
                maxHeight: '100%',
                overflow: 'hidden',
            }}
            ref={ref}
        >
            <Droppable
                droppableId={'sorted'}
                mode={'virtual'}
                renderClone={(provided, snapshot, rubric): ReactElement => (
                    <ProductItem
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        listElement={products[rubric.source.index]}
                        index={rubric.source.index}
                    />
                )}
            >
                {({ innerRef }): ReactElement => (
                    <List
                        height={Math.floor((size.height ?? 17) - 16)}
                        itemCount={products.length}
                        itemSize={40}
                        outerRef={innerRef}
                        itemData={products}
                        width={'100%'}
                    >
                        {ProductListItem}
                    </List>
                )}
            </Droppable>
        </Box>
    );
};

export default CategorizedProducts;
