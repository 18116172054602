import { type ReactElement } from 'react';

import {
    DataGrid as MuiDataGrid,
    type DataGridProps,
    type GridColDef,
} from '@mui/x-data-grid';

/**
 * Override the classes of a component coming from a library is always risky
 * because if they change the classNames we have to go through a lot of css
 * files to update it. So it's better to maintain it only in one place.
 * And better as well for consistency
 */
const DataGrid = ({
    disableColumnMenu = true,
    showCellVerticalBorder = false,
    showColumnVerticalBorder = false,
    disableRowSelectionOnClick = true,
    ...otherProps
}: DataGridProps): ReactElement => {
    return (
        <MuiDataGrid
            disableColumnMenu={disableColumnMenu}
            showColumnVerticalBorder={showColumnVerticalBorder}
            showCellVerticalBorder={showCellVerticalBorder}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            {...otherProps}
        />
    );
};

export default DataGrid;

export type TypeSafeColDef<T> = GridColDef & { field: keyof T | 'actions' };

export type {
    DataGridProps,
    GridRowsProp,
    GridPaginationModel,
} from '@mui/x-data-grid';
