import { type DropResult } from 'react-beautiful-dnd';

import { createSlice, isAnyOf, type PayloadAction } from '@reduxjs/toolkit';

import {
    type CategorizationDataset,
    type CategorizationExportConfig,
} from '../types';

import type {
    CategorizationSlice,
    CategoryCardData,
    ProductCardData,
} from './types';
import {
    formatProducts,
    getChildProducts,
    getFlatCategories,
    getNearestCategory,
    getSubPaths,
    modifyIdSet,
} from './utilities';

export const initialState: CategorizationSlice = {
    computedCategorizedProductsList: [],
    computedProductsList: [],
    products: [],
    categorizedProducts: [],
    productsMap: {},
    categories: [],
    dataset: undefined,
    exportConfiguration: undefined,
    categorized: {},
    collapsed: {},
    hidden: {},
    showAllProducts: false,
};

const sort = (a: { path: string }, b: { path: string }): number =>
    a.path.localeCompare(b.path);

export const categorizationSlice = createSlice({
    name: 'categorization',
    initialState: initialState,
    reducers: {
        categorizeProducts(
            state,
            action: PayloadAction<Pick<DropResult, 'source' | 'destination'>>
        ) {
            const data = action.payload;

            const destinationIndex = data.destination?.index;

            if (destinationIndex === undefined) return;

            // Handle re-categorizing
            if (data.source.droppableId === 'sorted') {
                // When moving an element down, account for the hole it creates where it was moved from
                // Destination index is where it will be in the new list, but we haven't changed the list length yet
                const offset = destinationIndex >= data.source.index ? 1 : 0;

                const destinationElement = getNearestCategory(
                    state.computedCategorizedProductsList,
                    destinationIndex + offset
                );

                if (!destinationElement) return;

                const sourceElement =
                    state.computedCategorizedProductsList[data.source.index];

                const sourceCategory = getNearestCategory(
                    state.computedCategorizedProductsList,
                    data.source.index + 1
                );

                if (!sourceElement || !sourceCategory) return;

                state.categorized[sourceElement.id] = modifyIdSet(
                    state.categorized[sourceElement.id],
                    destinationElement.id,
                    sourceCategory.id
                );

                // Handle categorizing
            } else {
                const destinationElement = getNearestCategory(
                    state.computedCategorizedProductsList,
                    destinationIndex
                );

                if (!destinationElement) return;

                const sourceElement =
                    state.computedProductsList[data.source.index];

                const childProducts = getChildProducts(
                    state.computedProductsList,
                    sourceElement
                );

                childProducts.forEach((product) => {
                    state.categorized[product.id] = modifyIdSet(
                        state.categorized[product.id],
                        destinationElement.id
                    );
                });
            }
        },
        removeCategorizedProduct(
            state,
            action: PayloadAction<{ index: number }>
        ) {
            const elementToRemove =
                state.computedCategorizedProductsList[action.payload.index];

            const elementsToRemove = state.categorizedProducts.filter(
                (product): product is ProductCardData =>
                    product.type === 'product' &&
                    product.path.startsWith(elementToRemove.path)
            );

            elementsToRemove.forEach((element) => {
                const updatedCategories = modifyIdSet(
                    state.categorized[element.id],
                    undefined,
                    element.categoryId
                );

                if (updatedCategories.length === 0) {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete state.categorized[element.id];
                } else {
                    state.categorized[element.id] = updatedCategories;
                }
            });
        },
        resetCategorization() {
            return initialState;
        },
        toggleCategoryVisibility(
            state,
            action: PayloadAction<{ path: string }>
        ) {
            const path = action.payload.path;

            state.collapsed[path] = !state.collapsed[path];
        },
        toggleShowAllProducts(state) {
            state.showAllProducts = !state.showAllProducts;
        },
        toggleCategoryProductVisibility(
            state,
            action: PayloadAction<{ categoryId: string }>
        ) {
            const categoryId = action.payload.categoryId;

            state.hidden[categoryId] = !state.hidden[categoryId];
        },
        initializeCategorization(
            state,
            action: PayloadAction<{
                dataset: CategorizationDataset | null;
                exportConfiguration: CategorizationExportConfig | null;
            }>
        ) {
            const { dataset, exportConfiguration } = action.payload;

            if (!dataset || !exportConfiguration) return;

            state.collapsed = {};
            state.categorized = {};
            state.dataset = dataset;
            state.exportConfiguration = exportConfiguration;

            state.categories = getFlatCategories(
                exportConfiguration.categories,
                0,
                0,
                'categorized'
            ).map((category) => ({ ...category, isCategory: true }));

            const { products, groups, categorized } = formatProducts(
                dataset.masterProducts,
                dataset.products
            );

            state.productsMap = Object.fromEntries(
                products.map((product) => [product.id, product])
            );

            state.categorized = categorized;

            state.products = [...products, ...groups.values()].sort(sort);
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                initializeCategorization.match,
                resetCategorization.match,
                categorizeProducts.match,
                removeCategorizedProduct.match,
                toggleCategoryVisibility.match,
                toggleCategoryProductVisibility.match
            ),
            (state) => {
                const counts: { [key: string]: number } = {};
                const immediateCounts: { [key: string]: number } = {};

                const products: ProductCardData[] = Object.entries(
                    state.categorized
                ).flatMap(([productId, categoryIds]) =>
                    categoryIds.map((categoryId) => {
                        const category = state.categories.find(
                            (c) => c.id === categoryId
                        );

                        const product = state.productsMap[productId];
                        const path = [
                            category?.path,
                            product.name + product.id,
                        ].join('//a_');

                        getSubPaths(category?.path).forEach(
                            (path) => (counts[path] = (counts[path] ?? 0) + 1)
                        );

                        if (category) {
                            immediateCounts[category.id] =
                                (immediateCounts[category.id] ?? 0) + 1;
                        }

                        return {
                            type: 'product',
                            id: productId,
                            name: product.name,
                            image: product.image,
                            path: path,
                            categoryId: category?.id,
                            indentationLevel:
                                (category?.indentationLevel ?? 0) + 1,
                        };
                    })
                );

                const categories: CategoryCardData[] = state.categories.map(
                    (category) => ({
                        type: 'category',
                        ...category,
                        productCount: counts[category.path] ?? 0,
                        immediateProductCount:
                            immediateCounts[category.id] ?? 0,
                        isCollapsed: state.collapsed[category.path] ?? false,
                        isHidden: state.hidden[category.id] ?? false,
                    })
                );

                const allProducts = [...products, ...categories].sort(sort);

                state.categorizedProducts = allProducts;

                state.computedCategorizedProductsList = allProducts
                    .filter(
                        (product) =>
                            !Object.keys(state.collapsed)
                                .filter((path) => state.collapsed[path])
                                .some(
                                    (path) =>
                                        product.path.startsWith(path) &&
                                        product.path !== path
                                )
                    )
                    .filter(
                        (product) =>
                            !state.hidden[
                                'categoryId' in product
                                    ? product.categoryId ?? ''
                                    : ''
                            ]
                    );
            }
        );
        builder.addMatcher(
            isAnyOf(
                initializeCategorization.match,
                resetCategorization.match,
                categorizeProducts.match,
                removeCategorizedProduct.match,
                toggleShowAllProducts.match
            ),
            (state) => {
                const counts: { [key: string]: number } = {};

                const products = state.products.filter(
                    (product) =>
                        state.showAllProducts || !state.categorized[product.id]
                );

                products
                    .filter((product) => product.type === 'product')
                    .forEach((product) => {
                        getSubPaths(product?.path).forEach(
                            (path) => (counts[path] = (counts[path] ?? 0) + 1)
                        );
                    });

                state.computedProductsList = products
                    .map((product) => ({
                        ...product,
                        productCount: counts[product.path] ?? 0,
                    }))
                    .filter((product) => product.productCount > 0);
            }
        );
    },
});

export const {
    categorizeProducts,
    removeCategorizedProduct,
    resetCategorization,
    initializeCategorization,
    toggleCategoryVisibility,
    toggleCategoryProductVisibility,
    toggleShowAllProducts,
} = categorizationSlice.actions;

export default categorizationSlice.reducer;
