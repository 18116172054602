import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Collapse,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';

import { type Batch } from '../types';

import { ImportError, ImportNumbers } from './index';

type ImportDetailsProps = {
    row: Batch;
    isOpen: boolean;
};

const ImportDetails = ({ row, isOpen }: ImportDetailsProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                    className="import-table-cell"
                >
                    <Collapse
                        className="table-row-collapse"
                        in={isOpen}
                        unmountOnExit
                    >
                        {row.status === 'PROCESSING' ? (
                            <>
                                <LinearProgress
                                    className="import-loading-bar primary-loading-bar"
                                    variant="determinate"
                                    value={
                                        row?.progress && row?.count
                                            ? (row.progress / row.count) * 100
                                            : 0
                                    }
                                />
                                <LinearProgress className="import-loading-bar" />
                            </>
                        ) : (
                            <></>
                        )}
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="import-table-cell table-details-titles">
                                        {t('imports.fileName')}
                                    </TableCell>
                                    <TableCell className="import-table-cell">
                                        {row.filename ? (
                                            <a href={row.downloadLink || ''}>
                                                {row.filename}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className="import-table-cell table-details-titles">
                                        {t('imports.fullId')}
                                    </TableCell>
                                    <TableCell className="import-table-cell ">
                                        {row.id}
                                    </TableCell>
                                </TableRow>
                                {row.errors && Array.isArray(row.errors) ? (
                                    <ImportError
                                        error={row.errors.join(', ')}
                                    />
                                ) : (
                                    <ImportNumbers
                                        summary={row.summary}
                                        count={row.count}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ImportDetails;
