import { type ReactElement, useMemo } from 'react';

import { Loading } from '@xeris/components';
import { brandApi } from '@xeris/pages/admin/api';
import {
    settingsSelectors,
    settingsSlice,
} from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import BrandVisibilityContent from './BrandVisibilityContent';
import BrandVisibilityHeader from './BrandVisibilityHeader';

const BrandVisibility = (): ReactElement | null => {
    const dispatch = useAppDispatch();

    const { data, isLoading } = brandApi.useGetAdminBrandsQuery({});

    const handleSetVisibility = (ids: string[], newValue: boolean): void => {
        dispatch(settingsSlice.actions.setBrandVisibility({ ids, newValue }));
    };

    const brandIds = useMemo(
        () => data?.brands?.map((brand) => brand.id) ?? [],
        [data]
    );

    const brandVisibility = useAppSelector((state) =>
        settingsSelectors.selectIsBrandsVisible(state, brandIds)
    );

    const augmentedBrands = (data?.brands ?? []).map((brand) => ({
        ...brand,
        isVisible: brandVisibility[brand.id] ?? true,
    }));

    if (isLoading) {
        return <Loading loading />;
    }

    return (
        <>
            <BrandVisibilityHeader
                brands={augmentedBrands}
                handleSetVisibility={handleSetVisibility}
            />
            <BrandVisibilityContent
                brands={augmentedBrands}
                handleSetVisibility={handleSetVisibility}
            />
        </>
    );
};
export default BrandVisibility;
