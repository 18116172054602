import { type ReactElement } from 'react';

import { FormattedPrice } from '@xeris/components';

import styles from './Price.module.scss';

type PriceProps = {
    price: {
        value: number;
        currency: string;
    } | null;
};

const Price = ({ price }: PriceProps): ReactElement => {
    return (
        <span className={styles.optionPrice}>
            <FormattedPrice
                value={price?.value ?? null}
                currency={price?.currency}
                defaultValue=""
            />
        </span>
    );
};

export default Price;
