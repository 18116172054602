import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { PlusIcon } from '@xeris/components';
import { CreateExport } from '@xeris/pages/admin/Exports/forms/CreateExport';

type HeaderProps = {
    exports: {
        name: string;
    }[];
};

export const Header = ({ exports }: HeaderProps): ReactElement => {
    const { t } = useTranslation('administration');
    const [isCreatingExport, setIsCreatingExport] = useState(false);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant={'h1'}>
                {t('exports.header.exports')}
            </Typography>
            <Button
                variant={'contained'}
                onClick={() => setIsCreatingExport(true)}
                startIcon={<PlusIcon />}
            >
                {t('exports.header.newSetup')}
            </Button>
            <CreateExport
                isOpen={isCreatingExport}
                handleCloseDialog={() => setIsCreatingExport(false)}
                exportNames={exports.map((exportData) => exportData.name)}
            />
        </Box>
    );
};
