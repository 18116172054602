import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ListItem, Typography } from '@mui/material';

import { TipsBox } from '@xeris/components';

const SearchInfo = (): ReactElement => {
    const { t } = useTranslation('products');

    return (
        <Box
            sx={{
                textAlign: 'center',
                maxWidth: 440,
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography variant={'h5'} component={'h3'} gutterBottom>
                {t('search.productSearch')}
            </Typography>
            <Typography gutterBottom>{t('search.description')}</Typography>
            <Typography
                variant={'h6'}
                component={'h3'}
                gutterBottom
                paddingTop={2}
            >
                {t('search.suggestions')}
            </Typography>
            <Box component={'ul'} sx={{ margin: 'auto' }}>
                {t('search.suggestionList', { returnObjects: true }).map(
                    (suggestion: string, index: number) => (
                        <ListItem
                            key={index}
                            sx={{ display: 'list-item', padding: 0 }}
                        >
                            <Typography gutterBottom>{suggestion}</Typography>
                        </ListItem>
                    )
                )}
            </Box>

            <TipsBox title={t('search.tipsAndTricks')} sx={{ marginTop: 4 }}>
                <Typography variant={'subtitle2'} fontWeight={500}>
                    {t('search.tips.multipleTitle')}
                </Typography>
                <Typography variant={'body1'} gutterBottom paddingTop={1}>
                    {t('search.tips.multipleBody')}
                </Typography>
                <Typography
                    paddingTop={1}
                    variant={'subtitle2'}
                    fontWeight={500}
                >
                    {t('search.tips.quotationTitle')}
                </Typography>
                <Typography variant={'body2'}>
                    {t('search.tips.quotationBody')}
                </Typography>
            </TipsBox>
        </Box>
    );
};

export default SearchInfo;
