import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type StateType } from '@xeris/types';

import { type LoginSliceType } from './loginTypes';

export const initialState: LoginSliceType = {
    isLoading: false,
    loginFailed: false,
    noCredentials: false,
    isLoggedIn: false,
    isLoggingOut: false,
    impersonating: false,
    username: undefined,
    expires: undefined,
    token: undefined,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginStarted() {
            return {
                ...initialState,
                isLoading: true,
            };
        },
        loginSuccess(state, action) {
            return {
                ...initialState,
                ...action.payload,
                isLoggedIn: true,
            };
        },
        loginFailed(state, action: PayloadAction<{ noCredentials: boolean }>) {
            return {
                ...initialState,
                loginFailed: true,
                noCredentials: action.payload.noCredentials,
                isLoggedIn: false,
            };
        },
        logoutStarted() {
            return {
                ...initialState,
                isLoading: true,
                isLoggingOut: true,
            };
        },
        logoutSuccess() {
            return {
                ...initialState,
                isLoggingOut: true,
            };
        },
        logoutFailed() {
            return {
                ...initialState,
                isLoggingOut: true,
            };
        },

        refreshStarted(state) {
            state.isLoading = true;
        },
    },
});

export const loginSelectors = {
    selectIsLoggedIn: (state: StateType): LoginSliceType['isLoggedIn'] =>
        state.login.isLoggedIn,
    selectLoginSlice: (state: StateType): LoginSliceType => state.login,
};

export const {
    loginStarted,
    loginSuccess,
    loginFailed,
    logoutStarted,
    logoutSuccess,
    logoutFailed,
    refreshStarted,
} = loginSlice.actions;

export default loginSlice.reducer;
