import { type ReactElement, type ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import LightBox from 'yet-another-react-lightbox';

import { Image } from '@xeris/components';
import { type SimpleImageType } from '@xeris/components/ProductCard/ProductCard';

type ProductListItemProps = {
    images: SimpleImageType[] | null;
    url?: string | null;
    children?: ReactNode;
    actions?: ReactNode;
};

export const ProductListItem = ({
    images,
    url,
    children,
    actions,
}: ProductListItemProps): ReactElement => {
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

    return (
        <Box
            sx={{
                paddingTop: '4px',
                paddingBottom: '4px',
            }}
        >
            <Box
                sx={{
                    'display': 'flex',
                    'alignItems': 'center',
                    'gap': 1,
                    'border': 1,
                    'borderColor': 'divider',
                    'borderRadius': 1,
                    'overflow': 'hidden',
                    'height': '56px',
                    'paddingRight': 1,
                    '&:hover': {
                        '.action': {
                            opacity: 1,
                        },
                    },
                }}
            >
                {images && (
                    <LightBox
                        open={isImagePreviewOpen}
                        slides={images.map((image) => ({
                            src: image.url,
                            imageFit: image.objectFit,
                            alt: image.title ?? undefined,
                        }))}
                        close={(): void => setIsImagePreviewOpen(false)}
                    />
                )}
                <Image
                    src={images?.[0]?.url}
                    objectFit={images?.[0]?.objectFit ?? undefined}
                    loading={'lazy'}
                    title={''}
                    style={{
                        cursor: 'pointer',
                        aspectRatio: '1 / 1',
                        height: '100%',
                    }}
                    onClick={() => {
                        setIsImagePreviewOpen(true);
                    }}
                />
                <Box component={url ? Link : 'div'} to={url ?? ''} flexGrow={1}>
                    {children}
                </Box>
                <Box flexShrink={0} display={'flex'} gap={1}>
                    {actions}
                </Box>
            </Box>
        </Box>
    );
};
