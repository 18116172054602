import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    AlertTitle,
    CircularProgress,
    LinearProgress,
    Stack,
    Typography,
} from '@mui/material';

import {
    AlertIcon,
    ArrowRightIcon,
    BackButton,
    StatusTag,
} from '@xeris/components';
import { type StatusTagProps } from '@xeris/components/StatusTag/StatusTag';
import { type ExportJob } from '@xeris/pages/exportJob/types';

import styles from './Header.module.scss';

type DurationProps = {
    exportJob: ExportJob;
};

const Duration = ({ exportJob }: DurationProps): ReactElement | null => {
    const { t } = useTranslation('exports');

    const durationInMilliseconds =
        new Date(exportJob.endTime ?? Date.now()).getTime() -
        new Date(exportJob.startTime).getTime();

    const hoursWithDecimal = durationInMilliseconds / 3600 / 1000;
    const hours = Math.floor(hoursWithDecimal);

    const minutesWithDecimal = (hoursWithDecimal - hours) * 60;
    const minutes = Math.floor(minutesWithDecimal);

    const seconds = Math.floor((minutesWithDecimal - minutes) * 60);

    const durationList = [];
    if (hours > 0) durationList.push(`${hours}h`);
    if (minutes > 0) durationList.push(`${minutes}m`);
    if (seconds > 0 && hours === 0) durationList.push(`${seconds}s`);

    if (durationList.length === 0) {
        return null;
    }

    return (
        <aside className={styles.details}>
            {t('exportJob.duration', { duration: durationList.join(' ') })}
        </aside>
    );
};

type ExportStatusTagProps = {
    progress: ExportJob['productProgress'];
};

const ExportStatusTag = ({ progress }: ExportStatusTagProps): ReactElement => {
    const { t } = useTranslation('exports');

    const getStatus = (): StatusTagProps => {
        if (progress.processing > 0) {
            return { type: 'processing', title: t('common.processing') };
        }
        if (progress.failed === progress.total) {
            return { type: 'error', title: t('common.failed') };
        }
        if (progress.finished === progress.total) {
            return { type: 'success', title: t('common.completed') };
        }
        return { type: 'indeterminate', title: t('common.processing') };
    };

    const { type, title } = getStatus();

    return <StatusTag type={type} title={title} />;
};

type HeaderProps = {
    exportJob?: ExportJob | null;
};

const Header = ({ exportJob }: HeaderProps): ReactElement | null => {
    const { t } = useTranslation('exports');

    const classNames = [styles.header];

    if (!exportJob) {
        return null;
    }
    if (
        exportJob.productProgress.finished === exportJob.productProgress.total
    ) {
        classNames.push(styles.exportCompleted);
    }

    return (
        <section className={classNames.join(' ')}>
            <BackButton to={'/Export'}>{t('exportJob.allExports')}</BackButton>
            <header>
                <Stack spacing={2} marginTop={1}>
                    <Typography variant={'h1'}>
                        {t('exportJob.exportNumber', { number: exportJob.id })}
                    </Typography>
                    <ExportStatusTag progress={exportJob.productProgress} />
                </Stack>
                <Duration exportJob={exportJob} />
            </header>

            <div className={styles.exportEndpoints}>
                <div>
                    <div className={styles.title}>{t('common.dataset')}</div>
                    <div className={styles.name}>{exportJob.dataset.name}</div>
                </div>
                <div className={styles.iconWrapper}>
                    <div className={styles.icon}>
                        <ArrowRightIcon fontSize="small" />
                    </div>
                </div>
                <div>
                    <div className={styles.title}>
                        {t('exportJob.destination')}
                    </div>
                    <div className={styles.name}>{exportJob.export.name}</div>
                </div>
            </div>
            <div className={styles.progress}>
                <div className={styles.labelWrapper}>
                    <div className={styles.label}>
                        <CircularProgress size={16} />
                        {exportJob.productProgress.percent > 0 &&
                            t('exportJob.percentCompleted', {
                                percent: exportJob.productProgress.percent,
                            })}
                    </div>
                </div>

                <div className={styles.progressBar}>
                    <LinearProgress variant="determinate" value={1} />
                </div>
            </div>
            {!!exportJob.message && (
                <Alert
                    severity={'error'}
                    icon={<AlertIcon />}
                    sx={{ marginTop: 2 }}
                >
                    <AlertTitle>{t('exportJob.exportFailed')}</AlertTitle>
                    {exportJob.message}
                </Alert>
            )}
        </section>
    );
};

export default Header;
