import {
    type MasterProduct,
    type NameAndValue,
    type Product,
} from '@xeris/pages/product/types';

export const getProductDifferencesName = (data: {
    name: string;
    distinguishingFeatures?: NameAndValue[] | null;
}): string => {
    const differences = data.distinguishingFeatures
        ? data.distinguishingFeatures.map((feature) => feature.value)
        : [];
    return [data.name, ...differences].join(' ');
};

export const getProductIds = (
    masterProduct?:
        | (MasterProduct<'isConfigurable'> & { products: { id: string }[] })
        | null,
    product?: Product<'id'> | null
): string[] => {
    if (product) {
        return [product.id];
    }

    if (masterProduct && !masterProduct.isConfigurable) {
        return masterProduct.products.map((product) => product.id);
    }

    return [];
};
