import { type ReactElement } from 'react';

import { type ModalTypesType } from '../MultipleMasterProductsSelector';
import ProductAndVariantsCounter from '../ProductAndVariantsCounter';

import EditMenu from './EditMenu';
import SelectButton from './SelectButton';

import styles from './SelectActions.module.scss';

type SelectionActionsProps = {
    handleSelectModal: (modal: ModalTypesType) => void;
    productCount: number;
    selectedVariantCount: number;
    variantCount: number;
    showIconVersion: boolean;
    showCounters: boolean;
};

const SelectionActions = ({
    handleSelectModal,
    productCount,
    selectedVariantCount,
    variantCount,
    showIconVersion,
    showCounters,
}: SelectionActionsProps): ReactElement => {
    const classNames = [styles.selectionBar];

    const buttonClassNames = [styles.productButtons];
    const isAnyProductOrMasterProductSelected = selectedVariantCount > 0;

    if (!isAnyProductOrMasterProductSelected && showIconVersion) {
        buttonClassNames.push('add-master-products-icon');
    }

    if (showIconVersion) classNames.push(styles.miniEditmenu);
    return (
        <div className={classNames.join(' ')}>
            {showCounters ? (
                <ProductAndVariantsCounter
                    productCount={productCount}
                    variantCount={variantCount}
                />
            ) : (
                <div />
            )}
            <div className={buttonClassNames.join(' ')}>
                {isAnyProductOrMasterProductSelected && (
                    <EditMenu
                        className={'visibleOnHover'}
                        handleSelectModal={handleSelectModal}
                        unselectedVariantsCount={
                            variantCount - selectedVariantCount
                        }
                        showIconVersion={showIconVersion}
                    />
                )}
                <SelectButton
                    className={
                        isAnyProductOrMasterProductSelected
                            ? ''
                            : 'visibleOnHover'
                    }
                    handleSelectModal={handleSelectModal}
                    totalCount={variantCount}
                    selectedCount={selectedVariantCount}
                    showIconVersion={showIconVersion}
                />
            </div>
        </div>
    );
};

export default SelectionActions;
