import { useDispatch, useSelector } from 'react-redux';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { type EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import * as Sentry from '@sentry/react';

import languages from '@xeris/containers/LanguageSelector/reducer';
import { datasetReducer } from '@xeris/pages/dataset/reducers';
import { ecoSearcReducer } from '@xeris/pages/ecoSearch/reducer';
import exportSlice from '@xeris/pages/exportJob/reducers';
import loginSlice, {
    logoutFailed,
    logoutSuccess,
} from '@xeris/pages/login/reducers/loginSlice';
import { productSlice } from '@xeris/pages/product/reducers';
import { baseRtkRestApi } from '@xeris/reducers/baseRestApi';
import { errorLogger } from '@xeris/reducers/middleware/errorLogger';
import { verifyUserRtkApi } from '@xeris/reducers/verifyUserApi';
import collapse from '@xeris/utilities/reducers/collapse';

import { baseRtkApi } from './baseApi';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const mainReducers = combineReducers({
    collapse,
    dataset: datasetReducer,
    export: exportSlice,
    languages,
    login: loginSlice,
    product: productSlice,
    ecoSearch: ecoSearcReducer,
    [baseRtkApi.reducerPath]: baseRtkApi.reducer,
    [verifyUserRtkApi.reducerPath]: verifyUserRtkApi.reducer,
    [baseRtkRestApi.reducerPath]: baseRtkRestApi.reducer,
});

export type RootState = ReturnType<typeof mainReducers>;

const rootReducer: typeof mainReducers = (state, action) => {
    if (action === undefined) {
        throw Error('Error: An action is undefined in the root reducer');
    }
    if (
        action.type === logoutSuccess.type ||
        action.type === logoutFailed.type
    ) {
        return mainReducers(undefined, action);
    }

    return mainReducers(state, action);
};

type Store = EnhancedStore<RootState>;

const createStore = (preloadedState?: Partial<RootState>): Store => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat([
                baseRtkApi.middleware,
                verifyUserRtkApi.middleware,
                baseRtkRestApi.middleware,
                errorLogger,
            ]),
        enhancers: (getDefaultEnhancers) =>
            getDefaultEnhancers().concat(sentryReduxEnhancer),
    });
};

export type AppDispatch = Store['dispatch'];

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export const useAppSelector = useSelector.withTypes<RootState>();

export default createStore;
