import { type ReactElement } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import errorImage from '@xeris/assets/img/oops_something_went_wrong.png';
import { Image } from '@xeris/components';

type ErrorPageProps = {
    title: string;
    description?: string;
    actionText?: string;
    onClick?: () => void;
    to?: LinkProps['to'];
};

const ErrorPage = ({
    title,
    description,
    actionText,
    onClick,
    to,
}: ErrorPageProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '15vh',
                marginBottom: '15vh',
            }}
        >
            <Image src={errorImage}></Image>
            <Typography gutterBottom variant={'h2'}>
                {title}
            </Typography>
            <Typography gutterBottom maxWidth={'60ch'}>
                {description}
            </Typography>
            {onClick && (
                <Button
                    sx={{ margin: 2 }}
                    onClick={onClick}
                    variant={'contained'}
                >
                    {actionText}
                </Button>
            )}
            {to && (
                <Button
                    component={Link}
                    sx={{ margin: 2 }}
                    variant={'contained'}
                    to={to}
                >
                    {actionText}
                </Button>
            )}
        </Box>
    );
};

export default ErrorPage;
