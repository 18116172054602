import { type ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { productUrl } from '@xeris/utilities/urls';

import Datasheet from '../Common/Datasheet/Datasheet';
import { productHooks } from '../hooks';

const Product = (): ReactElement => {
    const { productId = '', masterProductId = '', brandId = '' } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, isError, refetch } =
        productHooks.api.queries.useGetProduct({
            productId,
        });

    // Make sure the brandId and masterProductId correspond to the product
    // This can mismatch if users alter the url manually
    useEffect(() => {
        if (
            data &&
            (brandId !== data.brand.id ||
                data.masterProduct.id !== masterProductId)
        ) {
            navigate(productUrl(data), { replace: true });
        }
    }, [brandId, data, masterProductId, navigate]);

    return (
        <Datasheet
            product={data}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
        />
    );
};

export default Product;
