import {
    type MouseEvent as ReactMouseEvent,
    type ReactElement,
    type SyntheticEvent,
    useMemo,
} from 'react';

import { IconButton, Stack, Tooltip } from '@mui/material';

import {
    FormattedPrice,
    InformationOutlineIcon,
    PreviewButton,
    SelectButton,
} from '@xeris/components';
import { type Material } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import {
    selectOptions,
    setMaterialInfo,
    variantGeneratorSelectors,
} from '../../../reducer';
import { numberOrDash } from '../../../utilities/numberOrDash';

import styles from './MaterialWrapper.module.scss';

type MaterialDetailsProps = {
    featureId: string;
    handleToggleCollapse: (event: SyntheticEvent<Element, Event>) => void;
    material: Material;
};
export const MaterialDetails = ({
    featureId,
    handleToggleCollapse,
    material,
}: MaterialDetailsProps): ReactElement => {
    const dispatch = useAppDispatch();

    const optionIds = useMemo(
        () => material.options.map(({ id }) => id),
        [material.options]
    );

    const { isSelectable, isPreviewed } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatuses(state, featureId, optionIds)
    );

    const { available, selected } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCountByIds(
            state,
            featureId,
            optionIds
        )
    );

    const handleInfoButtonClick = (
        event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
        event.stopPropagation();
        dispatch(
            setMaterialInfo({
                masterProductId: material.masterProduct?.id ?? null,
            })
        );
    };

    const handleToggleAllOptions = (
        event: SyntheticEvent<Element, Event>
    ): void => {
        event.stopPropagation();

        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: material.options.map(({ id }) => id),
                newValue: selected === 0,
            })
        );
    };

    return (
        <div className={styles.detailsWrapper} onClick={handleToggleCollapse}>
            <div title={material.name}>
                <div className={styles.name}>
                    <span>{material.name}</span>
                </div>

                <div
                    className={styles.supplier}
                    title={material.supplier?.name}
                >
                    {material.supplier?.name}
                </div>
            </div>

            <div className={styles.infoTag}>
                {material.masterProduct?.id && (
                    <Tooltip title={`Open specification for ${material.name}`}>
                        <IconButton
                            onClick={handleInfoButtonClick}
                            color={'info'}
                        >
                            <InformationOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            <div className={styles.counter}>
                <div>
                    <span>{numberOrDash(selected)}</span>
                    <span className={styles.total}>
                        {' / '} {numberOrDash(available)}
                    </span>
                </div>
            </div>

            <span className={styles.price}>
                <FormattedPrice
                    value={material.price?.value ?? null}
                    currency={material.price?.currency}
                    defaultValue="0"
                />
            </span>
            <Stack spacing={1}>
                <PreviewButton
                    size={'small'}
                    className={isPreviewed ? '' : styles.isHidden}
                    isPreviewActive={isPreviewed}
                    onSelectPreview={() => {}}
                />
                <SelectButton
                    size={'small'}
                    onToggleOption={handleToggleAllOptions}
                    disabled={!isSelectable}
                    isSelected={available === selected}
                    isPartiallySelected={selected > 0}
                />
            </Stack>
        </div>
    );
};
