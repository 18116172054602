import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Snackbar } from '@mui/material';

type UploadSnackbarProps = {
    errorMessage: string | null;
    setIfOpenSnackbar: (open: boolean) => void;
    snackBarIsOpen?: boolean;
    typeOfUpload?: 'Upload';
};

const UploadSnackbar = ({
    errorMessage,
    setIfOpenSnackbar,
    snackBarIsOpen,
    typeOfUpload = 'Upload',
}: UploadSnackbarProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={snackBarIsOpen}
            autoHideDuration={7000}
            onClose={() => setIfOpenSnackbar(false)}
        >
            <Alert
                onClose={() => setIfOpenSnackbar(false)}
                severity={!errorMessage ? 'success' : 'error'}
                elevation={6}
                variant="filled"
            >
                {!errorMessage
                    ? t('imports.typeSuccessful', { type: typeOfUpload })
                    : t('imports.errorType', {
                          type: typeOfUpload,
                          errorMessage: errorMessage,
                      })}
            </Alert>
        </Snackbar>
    );
};

export default UploadSnackbar;
