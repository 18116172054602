import { createSelector } from '@reduxjs/toolkit';

import { type SelectedMasterProduct } from '@xeris/pages/dataset/types/datasetTypes';
import { type StateType } from '@xeris/types';

const selectActiveId = createSelector(
    (state: StateType) => state.dataset,
    (dataset) => dataset.activeId
);
const selectDataset = createSelector(
    (state: StateType) => state.dataset,
    (dataset) => dataset.activeDataset
);

const selectProductEntityTotalCount = createSelector(
    (state: StateType) => state.dataset,
    (dataset) => dataset.activeDataset?.totalCount ?? 0
);

const selectProductEntityIdList = createSelector(
    (state: StateType) => state.dataset,
    (dataset): string[] =>
        dataset.activeDataset?.selectedProductData?.nodeList?.map(
            (node) => node.id
        ) ?? []
);

const selectProductEntitiesByIdListInDatasetCount = createSelector(
    [
        selectDataset,
        (
            state: StateType,
            productEntityIdList: string[] | undefined
        ): string[] | undefined => productEntityIdList,
    ],
    (activeDataset, productIdList: string[] | undefined): number => {
        if (!activeDataset || !productIdList || productIdList.length === 0) {
            return 0;
        }
        return productIdList.filter((productId) =>
            activeDataset.selectedProductData?.nodeList.some(
                (node) => node.id === productId
            )
        ).length;
    }
);

const selectConfigurableProductSelectionStatuses = createSelector(
    [
        selectDataset,
        (
            state: StateType,
            configurableProductId: string | undefined
        ): string | undefined => configurableProductId,
    ],
    (activeDataset, configurableProductId: string | undefined) => {
        const status = { isSelected: false, isConfigured: false };

        if (!configurableProductId) {
            return status;
        }

        const node = activeDataset?.selectedProductData?.nodeList?.find(
            (node) => node.id === configurableProductId
        );

        if (!node) {
            return status;
        }

        status.isSelected = true;
        status.isConfigured =
            node.__typename === 'SelectedMasterProduct' &&
            node.configurationSets.length > 0;
        return status;
    }
);

const selectConfigurationSets = createSelector(
    [
        selectDataset,
        (
            state: StateType,
            configurableProductId: string | undefined
        ): string | undefined => configurableProductId,
    ],
    (activeDataset, configurableProductId: string | undefined) => {
        return (
            activeDataset?.selectedProductData?.nodeList.find(
                (node): node is SelectedMasterProduct =>
                    node.__typename === 'SelectedMasterProduct' &&
                    node.id === configurableProductId
            )?.configurationSets ?? []
        );
    }
);

export const activeDatasetSelectors = {
    selectActiveId,
    selectDataset,
    selectProductEntityTotalCount,
    selectProductEntityIdList,
    selectProductEntitiesByIdListInDatasetCount,
    selectConfigurableProductSelectionStatuses,
    selectConfigurationSets,
};
