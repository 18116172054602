import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import { ArrowRightIcon } from '@xeris/components';
import { type FeatureGroup } from '@xeris/pages/product/types';
import { useURLState } from '@xeris/pages/product/variantGenerator/hooks';
import { useAppSelector } from '@xeris/reducers';

import MissingSelections from '../Panels/MissingSelections/MissingSelections';
import ToNextStagePanel from '../Panels/ToNextStagePanel/ToNextStagePanel';
import { variantGeneratorSelectors } from '../reducer';

import styles from './InlineNavigation.module.scss';

type NextFeatureButtonProps = {
    featureGroup: FeatureGroup;
};

const NextFeatureButton = ({
    featureGroup,
}: NextFeatureButtonProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <div className={styles.nextFeatureButton}>
            <Link
                className="element-wrapper"
                to={{ hash: featureGroup.features[0]?.id }}
                state={{ page: 'configure', featureGroup: featureGroup.id }}
            >
                <Button
                    variant={'contained'}
                    endIcon={<ArrowRightIcon fontSize="small" />}
                >
                    {t('variantGenerator.stages.next')}: {featureGroup.name}
                </Button>
            </Link>
        </div>
    );
};

type InlineNavigationProps = {
    featureGroups: FeatureGroup[];
};

const InlineNavigation = ({
    featureGroups,
}: InlineNavigationProps): ReactElement => {
    const { t } = useTranslation('product');
    const { featureGroupId } = useURLState();

    const missingFeatures = useAppSelector((state) =>
        variantGeneratorSelectors.selectMissingFeaturesByGroup(
            state,
            featureGroupId ?? featureGroups[0]?.id
        )
    );

    const currentFeatureIndex = Math.max(
        featureGroups.findIndex(
            (featureGroup) => featureGroup.id === featureGroupId
        ),
        0
    );

    if (currentFeatureIndex < featureGroups.length - 1) {
        return (
            <NextFeatureButton
                featureGroup={featureGroups[currentFeatureIndex + 1]}
            />
        );
    }

    if (missingFeatures.length === 0) {
        return (
            <ToNextStagePanel
                description={t('variantGenerator.checkOutSummary')}
                nextStageName={t('variantGenerator.summary')}
                nextStageType="summary"
                hasPreviousFeatureGroup={currentFeatureIndex > 0}
                previousFeatureGroupPath={
                    featureGroups[Math.max(currentFeatureIndex - 1, 0)]?.id
                }
                previousStageType="configure"
                title={t('variantGenerator.happyWithOptions')}
            />
        );
    }

    return (
        <MissingSelections
            isConfigurePage={true}
            missingFeatures={missingFeatures}
            hasPreviousFeatureGroup={currentFeatureIndex > 0}
            previousFeatureGroup={
                featureGroups[Math.max(currentFeatureIndex - 1, 0)]
            }
        />
    );
};

export default InlineNavigation;
