import { useAppSelector } from '@xeris/reducers';
import { SafeRegexp } from '@xeris/utilities';

import { settingsSelectors } from '../reducers/settingsSlice';

const getProductDifferencesName = (product: {
    name: string;
    distinguishingFeatures?: { name: string; value: string }[] | null;
}): string => {
    const differences =
        product.distinguishingFeatures?.map((feature) => {
            return ` • ${feature.name} ${feature.value}`;
        }) ?? '';

    return `${product.name}${differences}`;
};

const testName = (filter: string): ((filter: string) => boolean) => {
    const parts = filter.split(' ');
    const filterExpressions = parts.map((part) => SafeRegexp(part, 'i'));

    return (name) => filterExpressions.every((filter) => filter.test(name));
};

export const useFilterProductEntityListBySearchTerm = <
    Product extends {
        name: string;
        distinguishingFeatures?: { name: string; value: string }[] | null;
        idNumbers: { value: string }[];
        products?: {
            idNumbers: { value: string }[];
        }[];
    },
>(
    productEntityList?: Product[]
): Product[] => {
    const searchTerm = useAppSelector(settingsSelectors.selectSearchTerm);

    if (searchTerm === '' || !productEntityList) {
        return productEntityList ?? [];
    }

    const filterTest = testName(searchTerm);

    return productEntityList.filter((productEntity) => {
        return (
            filterTest(getProductDifferencesName(productEntity)) ||
            [
                ...productEntity.idNumbers,
                ...(productEntity.products?.flatMap(
                    (product) => product.idNumbers
                ) ?? []),
            ].some(({ value }) => filterTest(value))
        );
    });
};
