import type { ReactElement, ReactNode } from 'react';

import { Box } from '@mui/material';

import { useProductListSettings } from '../useProductListSettings';

import { Expand } from './Expand';
import { Group } from './Group';
import { ListType } from './ListType';
import { Market } from './Market';
import { Search } from './Search';

type ToolbarProps = {
    brandId: string;
    onFilterChange: (value: string) => void;
    searchFilter: string;
    toolbar?: ReactNode;
    groupTypes: {
        id: string;
        name: string;
        groups: { id: string; name: string }[];
    }[];
    markets: { id: string; name: string; externalId: string }[];
    disableMarketFilter: boolean;
};

export const Toolbar = ({
    brandId,
    onFilterChange,
    toolbar,
    groupTypes,
    searchFilter,
    markets,
    disableMarketFilter,
}: ToolbarProps): ReactElement => {
    const { viewType, groups, groupTypeId, marketId, noneExpanded } =
        useProductListSettings(brandId, groupTypes, markets);

    return (
        <Box sx={{ gap: 1, paddingBottom: 1 }}>
            <Box display={'flex'} gap={1}>
                {!!groupTypeId && (
                    <Expand noneExpanded={noneExpanded} groups={groups} />
                )}
                <Search filter={searchFilter} onFilterChange={onFilterChange} />
                <Box sx={{ flexGrow: 1 }}></Box>
                <ListType listType={viewType} />
                {!disableMarketFilter && (
                    <Market
                        brandId={brandId}
                        marketId={marketId}
                        markets={markets}
                    />
                )}
                <Group
                    brandId={brandId}
                    groupTypeId={groupTypeId}
                    groupTypes={groupTypes}
                />
            </Box>
            <Box display={'flex'}>
                <Box sx={{ flexGrow: 1 }}></Box>
                {toolbar}
            </Box>
        </Box>
    );
};
