import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
} from '@mui/material';

import {
    Button,
    CloseIcon,
    CubeOutlineIcon,
    FileOutlineIcon,
    InsertPhotoIcon,
} from '@xeris/components';

import CreateNewDataset, {
    type HandleSetNewDatasetType,
    type NewDatasetType,
} from '../CreateNewDataset/CreateNewDataset';
import SelectDataset from '../SelectDataset/SelectDataset';

import { type SelectionType } from './MultipleMasterProductsSelector';
import ProductAndVariantsCounter from './ProductAndVariantsCounter';

import styles from './SelectProductDataDialog.module.scss';

type SelectProductDataDialogProps = {
    newDataset: NewDatasetType;
    handleSetNewDataset: HandleSetNewDatasetType;
    isDialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSelectProducts: (selection: SelectionType) => void;
    productCount: number;
    variantCount: number;
    disabledDatasetMenu?: boolean;
};

const SelectProductDataDialog = ({
    newDataset,
    handleSetNewDataset,
    isDialogOpen,
    handleCloseDialog,
    handleSelectProducts,
    productCount,
    variantCount,
    disabledDatasetMenu,
}: SelectProductDataDialogProps): ReactElement => {
    const { t } = useTranslation('product');
    const [selectImages, setSelectImages] = useState(true);
    const [selectDocuments, setSelectDocuments] = useState(true);
    const [selectObjects, setSelectObjects] = useState(true);

    const selectAll = selectImages && selectDocuments && selectObjects;

    const handleSetShowCreateNewDataset = (showForm: boolean): void => {
        handleSetNewDataset({ ...newDataset, showForm });
    };
    const selection = {
        selectImages,
        selectDocuments,
        selectObjects,
    };
    const imagesClassNames = [styles.dataTypeItem];
    if (selectImages) {
        imagesClassNames.push(styles.dataTypeItemActive);
    }

    const documentsClassNames = [styles.dataTypeItem];
    if (selectDocuments) {
        documentsClassNames.push(styles.dataTypeItemActive);
    }

    const modelsClassNames = [styles.dataTypeItem];
    if (selectObjects) {
        modelsClassNames.push(styles.dataTypeItemActive);
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            className={styles.dialog}
        >
            <DialogTitle className={styles.dialogTitle}>
                <div className={styles.dialogTitleContent}>
                    <span>{t('dataSelector.selectProductData')}</span>
                    <IconButton onClick={handleCloseDialog} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <header className={styles.selectAllListHead}>
                    <div className={styles.selectAllHeader}>
                        <ProductAndVariantsCounter
                            productCount={productCount}
                            variantCount={variantCount}
                        />
                    </div>

                    <div className={styles.selectAllTypes}>
                        <Checkbox
                            onClick={(): void => {
                                setSelectImages(!selectAll);
                                setSelectDocuments(!selectAll);
                                setSelectObjects(!selectAll);
                            }}
                            checked={selectAll}
                        />
                        <span>{t('dataSelector.allData')}</span>
                    </div>
                </header>

                <ul className={styles.dataTypeList}>
                    <li
                        className={imagesClassNames.join(' ')}
                        onClick={(): void => setSelectImages(!selectImages)}
                    >
                        <div className={styles.ItemName}>
                            <div className={styles.dataTypeIcon}>
                                <InsertPhotoIcon />
                            </div>
                            <dl>
                                <dd>
                                    <span>{t('dataSelector.images')}</span>
                                </dd>
                                <dt>
                                    <span>
                                        {t(
                                            'dataSelector.everyAvailableOfType',
                                            { type: 'image' }
                                        )}
                                    </span>
                                </dt>
                            </dl>
                        </div>
                        <Checkbox checked={selectImages} />
                    </li>
                    <li
                        className={documentsClassNames.join(' ')}
                        onClick={(): void =>
                            setSelectDocuments(!selectDocuments)
                        }
                    >
                        <div className={styles.ItemName}>
                            <div className={styles.dataTypeIcon}>
                                <FileOutlineIcon />
                            </div>
                            <dl>
                                <dd>
                                    <span>{t('dataSelector.documents')}</span>
                                </dd>
                                <dt>
                                    <span>
                                        {t(
                                            'dataSelector.everyAvailableOfType',
                                            { type: 'document' }
                                        )}
                                    </span>
                                </dt>
                            </dl>
                        </div>
                        <Checkbox checked={selectDocuments} />
                    </li>
                    <li
                        className={modelsClassNames.join(' ')}
                        onClick={(): void => setSelectObjects(!selectObjects)}
                    >
                        <div className={styles.ItemName}>
                            <div className={styles.dataTypeIcon}>
                                <CubeOutlineIcon />
                            </div>
                            <dl>
                                <dd>
                                    <span>{t('dataSelector.3dObjects')}</span>
                                </dd>
                                <dt>
                                    <span>
                                        {t(
                                            'dataSelector.everyAvailableOfType',
                                            { type: '3D object' }
                                        )}
                                    </span>
                                </dt>
                            </dl>
                        </div>
                        <Checkbox checked={selectObjects} />
                    </li>
                </ul>

                {newDataset.showForm && <Divider />}
                <CreateNewDataset
                    handleSetNewDataset={handleSetNewDataset}
                    newDataset={newDataset}
                />
            </DialogContent>
            <footer className={styles.dialogFooter}>
                <DialogActions className={styles.dialogActions}>
                    <Button
                        variant="textNeutral"
                        onClick={handleCloseDialog}
                        sx={{ marginLeft: '-11px' }}
                    >
                        {t('common.cancel')}
                    </Button>

                    <div>
                        <SelectDataset
                            disabled={disabledDatasetMenu}
                            handleSetShowAddDataset={
                                handleSetShowCreateNewDataset
                            }
                            name={newDataset.name}
                            showAddDataset={newDataset.showForm}
                        />
                        <Button
                            minWidth={131}
                            minHeight={52}
                            onClick={(): void =>
                                handleSelectProducts(selection)
                            }
                        >
                            {t('dataSelector.save')}
                        </Button>
                    </div>
                </DialogActions>
            </footer>
        </Dialog>
    );
};

export default SelectProductDataDialog;
