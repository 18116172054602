import { type ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, PlusIcon } from '@xeris/components';
import NewConnectionForm from '@xeris/pages/admin/Connections/forms/NewConnectionForm';
import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '@xeris/pages/admin/Connections/types/connectionsTypes';

type NewConnectionProps = {
    className?: string;
    uiConnectionType: UiConnectionType;
    connectionType: ConnectionsTabVariantType;
    connectionList: Connection[];
};

const NewConnection = ({
    className,
    uiConnectionType,
    connectionType,
    connectionList,
}: NewConnectionProps): ReactElement => {
    const { t } = useTranslation('administration');
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = useCallback((): void => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <div className={className}>
            <Button
                startIcon={<PlusIcon fontSize="small" />}
                onClick={() => setIsOpen(true)}
            >
                {t(`connections.new.${uiConnectionType}`)}
            </Button>
            <NewConnectionForm
                isOpen={isOpen}
                handleClose={handleClose}
                uiConnectionType={uiConnectionType}
                connectionType={connectionType}
                connectionList={connectionList}
            />
        </div>
    );
};

export default NewConnection;
