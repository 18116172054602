import { type ReactElement, type ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import LightBox from 'yet-another-react-lightbox';

import { Image } from '@xeris/components';
import { type ApiObjectFit } from '@xeris/types';

export type SimpleImageType = {
    id: string;
    url: string;
    title: string | null;
    objectFit: ApiObjectFit;
};

export type ProductCardProps = {
    images: SimpleImageType[] | null;
    url?: string | null;
    children?: ReactNode;
    actions?: ReactNode;
};

const ProductCard = ({
    images,
    url,
    children,
    actions,
}: ProductCardProps): ReactElement | null => {
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

    return (
        <Box
            sx={{
                'overflow': 'hidden',
                'display': 'flex',
                'flexDirection': 'column',
                'border': 1,
                'borderColor': 'divider',
                'borderRadius': 1,
                'height': '100%',
                '&:hover': {
                    '.action': {
                        opacity: 1,
                    },
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    aspectRatio: 1,
                    maxWidth: '100%',
                }}
                component={url ? Link : 'div'}
                to={url ?? ''}
            >
                {images && (
                    <LightBox
                        open={isImagePreviewOpen}
                        slides={images.map((image) => ({
                            src: image.url,
                            imageFit: image.objectFit,
                            alt: image.title ?? undefined,
                        }))}
                        close={(): void => setIsImagePreviewOpen(false)}
                    />
                )}
                <Image
                    src={images?.[0]?.url}
                    objectFit={images?.[0]?.objectFit ?? undefined}
                    loading={'lazy'}
                    title={''}
                    style={{
                        cursor: 'pointer',
                        aspectRatio: '1 / 1',
                        width: '100%',
                    }}
                    onClick={() => {
                        if (!url) {
                            setIsImagePreviewOpen(true);
                        }
                    }}
                />
            </Box>
            <Box sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                        paddingRight: 1,
                        top: '-20px',
                        right: 0,
                    }}
                >
                    {actions}
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    gap: 0.5,
                    borderTop: 1,
                    borderColor: 'divider',
                    padding: theme.spacing(3, 2, 2, 2),
                })}
                component={url ? Link : 'div'}
                to={url ?? ''}
                style={{ textDecoration: 'none' }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default ProductCard;
