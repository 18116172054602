import { type ReactElement, useEffect, useState } from 'react';

import { Alert, Slide, Snackbar } from '@mui/material';

type SnackbarsProps = {
    isSuccess: boolean;
    successText: string;
    isError: boolean;
    errorText: string;
};

export const MutationSnackbars = ({
    isSuccess,
    successText,
    isError,
    errorText,
}: SnackbarsProps): ReactElement => {
    const [open, setOpen] = useState<'success' | 'error' | null>(null);

    useEffect(() => {
        setOpen(isError ? 'error' : null);
    }, [isError, setOpen]);

    useEffect(() => {
        setOpen(isSuccess ? 'success' : null);
    }, [isSuccess, setOpen]);

    return (
        <>
            <Snackbar
                open={open === 'success'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
                onClose={() => setOpen(null)}
            >
                <Alert elevation={2}>{successText}</Alert>
            </Snackbar>
            <Snackbar
                open={open === 'error'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
                onClose={() => setOpen(null)}
            >
                <Alert severity="error" elevation={2}>
                    {errorText}
                </Alert>
            </Snackbar>
        </>
    );
};
