import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import { FormDrawer, SelectInput, TextInput } from '@xeris/components/forms';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

import { exportsApi } from '../api/exportsApi';

import { Configuration } from './components/Configuration';
import { OptionField } from './fields/OptionField';
import { type ExportFormData, exportSchema } from './utilities/exportSchema';
import { formatDefaultValues } from './utilities/formatDefaultValues';
import { formatOptionsForApi } from './utilities/formatOptionsForApi';

type CreateExportDialogProps = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    exportNames: string[];
};

export const CreateExport = ({
    isOpen,
    handleCloseDialog,
    exportNames,
}: CreateExportDialogProps): ReactElement => {
    const { t } = useTranslation('administration');
    const activeOrganizationId = useActiveOrganizationId();

    const { handleSubmit, control, reset, resetField, watch, setError } =
        useForm<ExportFormData>({
            resolver: yupResolver(exportSchema),
            defaultValues: {
                name: '',
                description: '',
                type: '',
                options: [],
            },
        });

    const { data: exportTypesData } = exportsApi.useGetExportTypesQuery({});
    const [createExport, { isSuccess, isError, isLoading }] =
        exportsApi.useCreateExportMutation();

    const selectedExportType = watch('type');

    useEffect(() => {
        resetField('options', {
            defaultValue: formatDefaultValues(
                exportTypesData?.exportTypes.find(
                    (exportType) => exportType.id === selectedExportType
                )?.options ?? []
            ),
        });
    }, [exportTypesData?.exportTypes, resetField, selectedExportType]);

    const handleSubmitExport = async (data: ExportFormData): Promise<void> => {
        if (exportNames.includes(data.name)) {
            return setError('name', { type: 'unique', message: data.name });
        }

        await createExport({
            export: {
                name: data.name,
                description: data.description ?? null,
                type: data.type,
                options: formatOptionsForApi(data.options),
            },
            organizationId: activeOrganizationId,
        }).unwrap();

        reset();
        handleCloseDialog();
    };

    const exportType = exportTypesData?.exportTypes.find(
        (exportType) => exportType.id === selectedExportType
    );

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleCloseDialog}
                onSubmit={handleSubmit(handleSubmitExport)}
                isLoading={isLoading}
                title={t('exports.create.createExport')}
                saveText={t('exports.create.createSetup')}
                cancelText={t('common.cancel')}
            >
                <TextInput
                    control={control}
                    fieldName={'name'}
                    label={t('exports.form.name')}
                    showError
                />
                <TextInput
                    control={control}
                    fieldName={'description'}
                    label={t('exports.form.description')}
                    multiline
                />
                <SelectInput
                    control={control}
                    fieldName={'type'}
                    label={t('exports.form.type')}
                    showError
                    optionLabelField={'name'}
                    options={exportTypesData?.exportTypes ?? []}
                />
                <Configuration show={!!selectedExportType}>
                    {exportType?.options.map((option, index) => (
                        <OptionField
                            key={option.key}
                            control={control}
                            option={option}
                            index={index}
                        />
                    ))}
                </Configuration>
            </FormDrawer>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('exports.create.success')}
                isError={isError}
                errorText={t('exports.create.error')}
            />
        </>
    );
};
