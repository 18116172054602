import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Chip, Skeleton, Stack } from '@mui/material';

import { format } from 'date-fns';

import { EditIcon, Typography } from '@xeris/components';
import { datasetSelectors } from '@xeris/pages/dataset/reducers';
import { useAppSelector } from '@xeris/reducers';

import {
    accessIconLookup,
    getAccessLabel,
} from '../../DatasetList/components/AccessList';
import { EditDataset } from '../../forms';
import {
    type SharedWithType,
    type SharingPrivilege,
} from '../../types/datasetTypes';
import { getAccessStatus } from '../../utilities';

type DetailsEntryProps = {
    title: string;
    value?: string | null;
    children?: ReactElement;
    isLoading?: boolean;
    area: string;
};

const DetailsEntry = ({
    isLoading,
    title,
    value,
    children,
    area,
}: DetailsEntryProps): ReactElement => {
    return (
        <Stack
            direction={'column'}
            alignItems={'flex-start'}
            maxWidth={'60ch'}
            spacing={0.5}
            sx={{ gridArea: area }}
        >
            <Typography variant={'overline'} color={'text.secondary'}>
                {title}
            </Typography>
            {children ??
                (isLoading ? (
                    <Skeleton width={100} height={18}>
                        <Typography variant={'body1'} />
                    </Skeleton>
                ) : (
                    <Typography variant={'body1'}>{value}</Typography>
                ))}
        </Stack>
    );
};

type BrandChipsProps = {
    dataset?: {
        brands: {
            id: string;
            name: string;
        }[];
    };
};
const BrandChips = ({ dataset }: BrandChipsProps): ReactElement => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, width: '80%' }}>
            {dataset?.brands.map((brand) => (
                <Chip
                    key={brand.id}
                    size={'small'}
                    label={brand.name}
                    component={Link}
                    clickable
                    to={`/Products/${brand.id}`}
                    sx={{ minWidth: 'max-content' }}
                />
            ))}
        </Box>
    );
};

type DatasetDetailsProps = {
    dataset: {
        id: string;
        name: string;
        totalCount: number;
        description: string | null;
        created: string;
        lastModified: string;
        lastExported: string | null;
        owner: {
            name: string | null;
            username: string;
        };
        brands: {
            id: string;
            name: string;
        }[];
        privilege: SharingPrivilege | null;
        sharedWith: SharedWithType[];
    };
};

const DatasetDetails = ({ dataset }: DatasetDetailsProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const isLoading = !dataset;

    const accessStatus = getAccessStatus(dataset);
    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);

    return (
        <Box
            sx={(theme) => ({
                display: 'grid',
                rowGap: 2,
                gridTemplateAreas: `
                "owner products"
                "brands created"
                "sharingStatus modified" 
                "description exported"`,
                alignItems: 'start',
                [theme.breakpoints.down('sm')]: {
                    gridTemplateAreas: `
                "owner" 
                "brands" 
                "products"
                "sharingStatus" 
                "created"
                "modified" 
                "exported"
                "description"`,
                },
            })}
        >
            <DetailsEntry
                title={t('common.owner')}
                value={dataset.owner.name ?? dataset.owner.username}
                isLoading={isLoading}
                area={'owner'}
            />
            <DetailsEntry
                title={t('datasetList.products')}
                value={t('common.productsTotal', {
                    count: dataset.totalCount,
                })}
                isLoading={isLoading}
                area={'products'}
            />
            <DetailsEntry title={'Brands'} area={'brands'}>
                <BrandChips dataset={dataset} />
            </DetailsEntry>
            <DetailsEntry
                title={t('common.sharingStatus')}
                isLoading={isLoading}
                area={'sharingStatus'}
            >
                <Chip
                    size={'small'}
                    icon={accessIconLookup[accessStatus]}
                    label={getAccessLabel(t, accessStatus)}
                    sx={{ '.MuiChip-icon': { fontSize: 'inherit' } }}
                />
            </DetailsEntry>
            <DetailsEntry
                title={t('datasetPopUp.description')}
                isLoading={isLoading}
                area={'description'}
            >
                {dataset.description ? (
                    <Typography>{dataset?.description}</Typography>
                ) : (
                    <Stack spacing={1}>
                        <EditDataset
                            datasetNames={datasetList.map(({ name }) => name)}
                            dataset={dataset}
                            showButton
                            buttonText={t('datasetDetails.addDescription')}
                            sx={{
                                padding: 0,
                                fontSize: '0.875rem',
                                fontWeight: 400,
                            }}
                        />
                        <EditIcon fontSize={'inherit'} color={'icons'} />
                    </Stack>
                )}
            </DetailsEntry>

            <DetailsEntry
                title={t('common.created')}
                value={format(new Date(dataset.created), 'PPP')}
                isLoading={isLoading}
                area={'created'}
            />

            <DetailsEntry
                title={t('datasetList.lastModified')}
                value={format(new Date(dataset?.lastModified), 'PPP')}
                isLoading={isLoading}
                area={'modified'}
            />

            <DetailsEntry
                title={t('datasetProductList.lastExported')}
                value={
                    dataset.lastExported
                        ? format(new Date(dataset.lastExported), 'PPP')
                        : '-'
                }
                isLoading={isLoading}
                area={'exported'}
            />
        </Box>
    );
};

export default DatasetDetails;
