import { type ReactElement, type ReactNode } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import { Button, type SxProps } from '@mui/material';

import { ArrowLeftIcon } from '@xeris/components';

type BackButtonProps = {
    to: LinkProps['to'];
    children: ReactNode;
    sx?: SxProps;
};
export const BackButton = ({
    to,
    children,
    sx,
}: BackButtonProps): ReactElement => {
    return (
        <Button
            color={'secondary'}
            startIcon={<ArrowLeftIcon />}
            component={Link}
            to={to}
            sx={{ marginLeft: '-5px', ...sx }}
        >
            {children}
        </Button>
    );
};
