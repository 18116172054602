import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Slide,
    Snackbar,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { AllInclusiveIcon, ClockFastIcon, Typography } from '@xeris/components';
import {
    AutocompleteInput,
    AutocompleteMultipleInput,
} from '@xeris/components/forms';
import { RadioCard } from '@xeris/components/forms/RadioCard/RadioCard';
import {
    brandApi,
    connectionsApi,
    organizationsApi,
} from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '../types/connectionsTypes';
import { getAvailableOrganizations } from '../utilities/getAvailableOrganizations';

import { connectionSchema, type FormData } from './common/connectionSchema';
import { useGetFormData } from './common/useGetFormData';

type NewConnectionFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionList: Connection[];
};

const NewConnectionForm = ({
    isOpen,
    handleClose,
    uiConnectionType,
    connectionType,
    connectionList,
}: NewConnectionFormProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [isSubmitErrorOpen, setIsSubmitErrorOpen] = useState(false);

    const activeOrganizationId = useActiveOrganizationId();

    const { data } = organizationsApi.useGetOrganizationsQuery({});

    const organizationList = data?.organizations ?? [];

    const { data: brandData } = brandApi.useGetAdminBrandsForOrganizationQuery({
        organizationId: activeOrganizationId ?? '',
    });

    const brandCount = brandData?.organization?.brands.length ?? 0;
    const productCount = brandData?.organization?.brands.reduce(
        (count, brand) => count + (brand.productCounts.products ?? 0),
        0
    );

    const formData = useGetFormData(
        activeOrganizationId,
        null,
        connectionType,
        false
    );

    const [createConnection, { error, isLoading }] =
        connectionsApi.useCreateConnectionMutation();

    const { control, handleSubmit, reset } = useForm<FormData>({
        resolver: yupResolver(connectionSchema),
        defaultValues: {
            configs: [],
            giveAccess: 'none',
        },
    });

    const handleSubmitConnection = async (data: FormData): Promise<void> => {
        if (!activeOrganizationId) return;

        const producerId =
            connectionType === 'channels'
                ? activeOrganizationId
                : data.tradingPartner;

        const consumerId =
            connectionType === 'channels'
                ? data.tradingPartner
                : activeOrganizationId;

        const exportIds = data.configs.map(({ id }) => id);

        await createConnection({
            producerId: producerId,
            consumerId: consumerId,
            status: 'ACTIVE',
            exportIds: exportIds,
            hasExports: connectionType === 'channels' && exportIds.length > 0,
            giveAccess: data.giveAccess === 'allProducts',
            rules:
                brandData?.organization?.brands.map((brand) => ({
                    brandGroupMarketRule: null,
                    organizationRule: null,
                    brandProductRule: null,
                    marketRule: null,
                    groupRule: null,
                    brandRule: {
                        action: 'ALLOW',
                        targetBrand: brand.id,
                    },
                })) ?? [],
        });

        handleClose();
        reset();
    };

    useEffect(() => {
        if (error) {
            setIsSubmitErrorOpen(true);
        }
    }, [error, setIsSubmitErrorOpen]);

    return (
        <Drawer
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                'component': 'form',
                'aria-label': 'Create connection',
            }}
            onSubmit={handleSubmit(handleSubmitConnection)}
        >
            <DialogTitle>
                {t(`connections.new.${uiConnectionType}`)}
            </DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography variant={'body1'}>
                        {t(`connections.newTradingPartner.${connectionType}`)}
                    </Typography>
                    <AutocompleteInput
                        fieldName="tradingPartner"
                        label={t('connections.tradingPartner')}
                        control={control}
                        options={getAvailableOrganizations(
                            connectionList,
                            organizationList,
                            connectionType
                        )}
                    />
                    {connectionType === 'channels' && (
                        <AutocompleteMultipleInput
                            fieldName="configs"
                            label={t('connections.export')}
                            options={formData.exportConfigList}
                            control={control}
                            disableCloseOnSelect
                        />
                    )}
                    {connectionType === 'channels' && (
                        <>
                            <Divider />
                            <Box>
                                <Typography variant={'h3'} gutterBottom>
                                    {'Product access control'}
                                </Typography>
                                <Typography>
                                    {
                                        'Decide if you want to give your trading partner access to all your products across all brands, or if you wish to give more specific access later.'
                                    }
                                </Typography>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1.5}
                            >
                                <RadioCard
                                    fieldName="giveAccess"
                                    control={control}
                                    title={t(
                                        'connections.giveAccessLater.title'
                                    )}
                                    value={'none'}
                                    icon={<ClockFastIcon />}
                                >
                                    <Typography>
                                        {t(
                                            'connections.giveAccessLater.description'
                                        )}
                                    </Typography>
                                </RadioCard>
                                <RadioCard
                                    fieldName="giveAccess"
                                    control={control}
                                    title={t('connections.allProducts.title')}
                                    value={'allProducts'}
                                    icon={<AllInclusiveIcon />}
                                >
                                    <Typography>
                                        {t(
                                            'connections.allProducts.description',
                                            {
                                                productCount,
                                                brandCount,
                                                count: brandCount,
                                            }
                                        )}
                                    </Typography>
                                </RadioCard>
                            </Box>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                {handleClose && (
                    <Button
                        variant={'text'}
                        color={'secondary'}
                        onClick={handleClose}
                    >
                        {t('common.cancel')}
                    </Button>
                )}
                <Button
                    disabled={isLoading}
                    type={'submit'}
                    variant={'contained'}
                >
                    {t('connections.createConnection')}
                </Button>
            </DialogActions>
            <Snackbar
                open={isSubmitErrorOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSubmitErrorOpen(false)}
                >
                    {t('connections.error')}
                </Alert>
            </Snackbar>
        </Drawer>
    );
};

export default NewConnectionForm;
