import { type ReactElement } from 'react';

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
} from '@mui/material';

import { DownloadIcon } from '@xeris/components';
import { type DocumentType } from '@xeris/pages/product/types';
import { groupDocuments } from '@xeris/pages/product/utilities';

import './index.scss';

type DocumentGroupProps = {
    label: string;
    documentList: DocumentType[];
};

const DocumentGroup = ({
    label,
    documentList,
}: DocumentGroupProps): ReactElement => (
    <Box>
        <Typography
            variant={'subtitle1'}
            component={'h3'}
            sx={{ marginBottom: 2 }}
        >
            {label}
        </Typography>
        <div className="text-content document-description">
            <ul
                className="documents-pdf-list"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12,
                    padding: 0,
                    margin: 0,
                }}
            >
                {documentList.map((document, index) => (
                    <li key={index} style={{ listStyle: 'none' }}>
                        <Card variant={'outlined'}>
                            <CardActionArea
                                href={document.url}
                                download
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 2,
                                    }}
                                >
                                    <Typography
                                        variant={'button'}
                                        sx={{
                                            flexGrow: 1,
                                        }}
                                        noWrap
                                    >
                                        {document.title}
                                    </Typography>
                                    {/* TODO: Add upload date to this */}
                                    <Typography
                                        variant={'caption'}
                                        color={'text.secondary'}
                                        noWrap
                                    >
                                        {document.fileName}
                                    </Typography>
                                    <DownloadIcon />
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </li>
                ))}
            </ul>
        </div>
    </Box>
);

type DocumentationsProps = {
    data: DocumentType[];
};

const Documentations = ({
    data: documentList,
}: DocumentationsProps): ReactElement => {
    const groupedDocuments = groupDocuments(documentList);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {groupedDocuments.map(({ label, documents }) => (
                <DocumentGroup
                    key={label}
                    label={label}
                    documentList={documents}
                />
            ))}
        </Box>
    );
};

export default Documentations;
