import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SelectProductDataDialog.module.scss';

type ProductAndVariantsCounterProps = {
    productCount: number;
    variantCount: number;
};

const ProductAndVariantsCounter = ({
    productCount,
    variantCount,
}: ProductAndVariantsCounterProps): ReactElement => {
    const { t } = useTranslation('product');
    return (
        <div className={styles.selectAllHeader}>
            <div className={styles.counter}>
                {t('dataSelector.numberOfProducts', {
                    count: productCount,
                })}
            </div>
            {variantCount !== 0 && (
                <div className={styles.counter}>
                    {t('dataSelector.numberOfVariants', {
                        count: variantCount,
                    })}
                </div>
            )}
        </div>
    );
};

export default ProductAndVariantsCounter;
