import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { CloseIcon } from '@xeris/components';
import { productHooks } from '@xeris/pages/product/hooks/productHooks';
import { productActions } from '@xeris/pages/product/reducers';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import {
    type MasterProduct,
    type Product,
} from '@xeris/pages/product/types/product';
import { isMasterProduct } from '@xeris/pages/product/utilities';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { type ApiDataError } from '@xeris/types/graphql';

import './index.scss';

const useGetDataErrors = (
    productEntity: Product<'id'> | MasterProduct<'id'>
): ApiDataError[] => {
    const showDataErrors = useAppSelector(
        settingsSelectors.selectShowDataErrors
    );

    const productDataErrors = productHooks.api.queries.useGetProductDataErrors(
        { productId: productEntity.id },
        {
            skip: isMasterProduct(productEntity) || !showDataErrors,
        }
    );

    const masterProductDataErrors =
        productHooks.api.queries.useGetMasterProductDataErrors(
            { masterProductId: productEntity.id },
            {
                skip: !isMasterProduct(productEntity) || !showDataErrors,
            }
        );
    return [
        ...(productDataErrors.data ?? []),
        ...(masterProductDataErrors.data ?? []),
    ].filter((error): error is ApiDataError => !!error);
};

type DataErrorsProps = {
    productEntity: MasterProduct<'name'> | Product<'name'>;
};

const DataErrors = ({
    productEntity,
}: DataErrorsProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');
    const showDataErrors = useAppSelector(
        settingsSelectors.selectShowDataErrors
    );

    const importErrorsList = useGetDataErrors(productEntity);

    const handleToggleShowDataErrors = (): void => {
        dispatch(productActions.settings.toggleShowDataErrors());
    };

    if (!showDataErrors) {
        return null;
    }

    return (
        <div className="import-errors-box">
            <Grid container alignItems="center" className="header">
                <Grid className="title" item xs>
                    {t('datasheet.importErrors', { name: productEntity.name })}
                </Grid>
                <Grid
                    item
                    className="icon"
                    onClick={handleToggleShowDataErrors}
                >
                    <CloseIcon />
                </Grid>
            </Grid>

            <Grid container className="columns-header">
                <Grid item xs={6} sm={4}>
                    {t('datasheet.type')}
                </Grid>
                <Grid item xs={6} sm={8}>
                    {t('datasheet.message')}
                </Grid>
            </Grid>

            {importErrorsList.map((dataError, index) => (
                <Grid container key={index} className="import-error-line">
                    <Grid item xs={6} sm={4}>
                        {dataError.type}
                    </Grid>
                    <Grid item xs={6} sm={8}>
                        {dataError.message}
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

export default DataErrors;
