import { useTranslation } from 'react-i18next';

import { DownloadOutlinedIcon, type MenuElementType } from '@xeris/components';
import { useFeatureFlag, useWhoami } from '@xeris/hooks';

export const useImportMenu = (): MenuElementType[] => {
    const { t } = useTranslation('navigation');
    const [newImportVisible] = useFeatureFlag('newImport');
    const { isSuperAdmin } = useWhoami();

    const importsMenuElements: MenuElementType[] = [];

    if (isSuperAdmin && newImportVisible) {
        importsMenuElements.push({
            id: 'imports',
            title: t('menu.imports'),
            link: '/import',
            matches: ['/import'],
            startAdornment: <DownloadOutlinedIcon />,
            isAlwaysShown: true,
        });
    }

    return importsMenuElements;
};
