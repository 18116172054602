import { Button, type ButtonProps, styled } from '@mui/material';

const sizes = {
    small: {
        minWidth: '24px',
        height: '24px',
        padding: 0.1,
    },
    medium: {
        minWidth: '32px',
        height: '32px',
        padding: 0.5,
    },
    large: {
        minWidth: '40px',
        height: '40px',
        padding: 1,
    },
};

const iconSizes = {
    small: '20px',

    medium: '24px',

    large: '24px',
};

type MuiButtonProps = ButtonProps & {
    round?: boolean;
};

const MuiButton = styled(Button, {
    shouldForwardProp: (property) => property !== 'round',
})<MuiButtonProps>(({ round, size }) => ({
    ...(round && {
        ...sizes[size || 'medium'],
        'borderRadius': '100px',
        '& > .MuiSvgIcon-root': {
            fontSize: iconSizes[size || 'medium'],
        },
    }),
}));

export default MuiButton;
