import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { ProductCard } from '@xeris/components';
import { type MasterProduct, type Product } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';
import { urls } from '@xeris/utilities';

import type { SelectedStatusType } from '../IconSelectButton/IconSelectButton';

import ActionButtons from './ActionButtons/ActionButtons';
import Price from './Price';
import { getProductDifferencesName } from './utilities';

export const useGetSelectedStatus = (
    productIdList: string[],
    configurableProductId: string | undefined
): SelectedStatusType => {
    const selectedProductsCount = useAppSelector((state) =>
        datasetSelectors.active.selectProductEntitiesByIdListInDatasetCount(
            state,
            productIdList
        )
    );

    const configurableProductSelectionStatuses = useAppSelector((state) =>
        datasetSelectors.active.selectConfigurableProductSelectionStatuses(
            state,
            configurableProductId
        )
    );

    // For configurable products
    if (configurableProductId) {
        if (configurableProductSelectionStatuses.isSelected) {
            //If it is selected but not configured, then all options are selected
            return configurableProductSelectionStatuses.isConfigured
                ? 'partiallySelected'
                : 'fullySelected';
        } else {
            return 'unselected';
        }
    }

    // For regular products
    if (selectedProductsCount === 0) {
        return 'unselected';
    }

    if (selectedProductsCount === productIdList.length) {
        return 'fullySelected';
    }

    return 'partiallySelected';
};

type ProductEntityCardProps = {
    product?: Product<
        'images' | 'gtin' | 'price' | 'prices' | 'brand' | 'masterProduct'
    > | null;
    masterProduct?: MasterProduct<
        'images' | 'brand' | 'price' | 'prices' | 'isConfigurable' | 'products'
    > | null;
    minimalProduct?: Product<
        'name' | 'brand' | 'masterProduct' | 'distinguishingFeatures' | 'images'
    > | null;
    minimalMasterProduct?: MasterProduct<
        'name' | 'brand' | 'isConfigurable' | 'images' | 'products'
    > | null;
    isActionButtonsAlwaysVisible?: boolean;
};

const ProductEntityCard = ({
    product,
    masterProduct,
    minimalProduct,
    minimalMasterProduct,
    isActionButtonsAlwaysVisible = false,
}: ProductEntityCardProps): ReactElement | null => {
    const { t } = useTranslation('product');
    /* Note:
        The product entity card is adjusted to work for both product list and 
        for datasheet. The only difference is that product lists show the name 
        of the product entity, while datasheet show gtin and price information.
        The ProductEntityCard should be split into two or more subcomponents. 
    */
    const productEntity = masterProduct ?? product;
    const minimalProductEntity = minimalMasterProduct ?? minimalProduct;
    const combinedMasterProduct = masterProduct ?? minimalMasterProduct;

    if (!productEntity && !minimalProductEntity) {
        return null;
    }

    const images =
        productEntity?.images ?? minimalProductEntity?.images ?? null;

    const productDifferencesName = minimalProductEntity
        ? getProductDifferencesName(minimalProductEntity)
        : '';

    return (
        <ProductCard
            url={urls.datasheet(minimalMasterProduct, minimalProduct)}
            images={images}
            actions={
                <ActionButtons
                    product={minimalProduct ?? product}
                    masterProduct={minimalMasterProduct ?? masterProduct}
                    isActionButtonsAlwaysVisible={isActionButtonsAlwaysVisible}
                />
            }
        >
            {productEntity && (
                <>
                    <Typography>
                        {product && `GTIN ${product.gtin ?? '-'}`}
                    </Typography>
                    <Price productEntity={productEntity} />
                </>
            )}
            {minimalProductEntity && (
                <Typography title={productDifferencesName}>
                    {productDifferencesName}
                </Typography>
            )}
            {combinedMasterProduct && (
                <Typography variant="body2">
                    {combinedMasterProduct.isConfigurable
                        ? t('productCard.configurable')
                        : t('dataSelector.numberOfVariants', {
                              count: combinedMasterProduct.products.length,
                          })}
                </Typography>
            )}
        </ProductCard>
    );
};

export default ProductEntityCard;
