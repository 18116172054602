import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { HelpIcon, IconButton, Image, Typography } from '@xeris/components';

export const Tutorial = (): ReactElement => {
    const { t } = useTranslation('dataset');
    const [open, setOpen] = useState(false);
    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={(): void => setOpen(true)}>
                <HelpIcon />
            </IconButton>

            <Dialog onClose={handleClose} open={open} maxWidth={'sm'}>
                <DialogTitle>
                    {t('categorization.categorizeDatasetProducts')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ marginBottom: 1 }}>
                        <Image
                            src="/assets/img/categorization/tutorial_cropped.png"
                            alt={t('categorization.tutorial')}
                        />
                    </Box>
                    <Typography>
                        {t('categorization.weNeedToKnowPlacement')}
                    </Typography>
                    <Typography>{t('categorization.grabOnRight')}</Typography>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button variant="contained" onClick={handleClose}>
                            {t('categorization.gotIt')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};
