import { memo, type ReactElement, useEffect, useMemo } from 'react';

import { Box } from '@mui/material';

import type { FeatureGroup, MasterProduct } from '@xeris/pages/product/types';
import { useURLState } from '@xeris/pages/product/variantGenerator/hooks';
import {
    BasicPanel,
    MaterialPanel,
} from '@xeris/pages/product/variantGenerator/Panels/Configure';
import { useAppDispatch } from '@xeris/reducers';

import Navigation from '../Navigation/Navigation';
import Header from '../Panels/Header';
import { setMaterialInfo } from '../reducer';

import InlineNavigation from './InlineNavigation';
import RightPanel from './RightPanel';

import styles from './Configure.module.scss';

type ConfigPanelsType = {
    featureGroups: FeatureGroup[];
};

const ConfigPanels = ({
    featureGroups,
}: ConfigPanelsType): ReactElement | null => {
    const { featureGroupId } = useURLState();

    const activeFeatureGroup = useMemo(
        () =>
            featureGroups.find(({ id }) => id === featureGroupId) ??
            featureGroups[0],
        [featureGroups, featureGroupId]
    );

    if (!activeFeatureGroup) {
        return null;
    }

    return (
        <Box sx={{ scrollBehavior: 'smooth' }}>
            {activeFeatureGroup.features.map((feature) => {
                if (feature.__typename === 'BasicFeature') {
                    return (
                        <Box
                            key={feature.id}
                            sx={{
                                gridArea: 'panels',
                                background: 'white',
                                padding: '1rem 2rem',
                            }}
                        >
                            <BasicPanel feature={feature} />
                        </Box>
                    );
                }
                if (feature.__typename === 'MaterialFeature') {
                    return (
                        <Box
                            key={feature.id}
                            sx={{
                                gridArea: 'panels',
                                background: 'white',
                                padding: '1rem 2rem',
                            }}
                        >
                            <MaterialPanel key={feature.id} feature={feature} />
                        </Box>
                    );
                }

                return null;
            })}
            <InlineNavigation featureGroups={featureGroups} />
        </Box>
    );
};

type ConfigureProps = {
    masterProduct: MasterProduct<
        'name' | 'brand' | 'images' | 'featureConfiguration'
    >;
};

const Configure = ({ masterProduct }: ConfigureProps): ReactElement | null => {
    const dispatch = useAppDispatch();

    // This has to run only when the components unmount
    // For example moving to summary, else the sidebar stays open
    useEffect(() => {
        return (): void => {
            dispatch(setMaterialInfo({ masterProductId: null }));
        };
    }, [dispatch]);

    if (!masterProduct.featureConfiguration?.featureGroups) {
        return null;
    }

    return (
        <>
            <Header type="configure" />
            <div className={styles.configure}>
                <Navigation
                    totalVariantCount={
                        masterProduct.featureConfiguration.variantCount
                    }
                    featureGroups={
                        masterProduct.featureConfiguration.featureGroups
                    }
                />
                <ConfigPanels
                    featureGroups={
                        masterProduct.featureConfiguration.featureGroups
                    }
                />
                <RightPanel masterProduct={masterProduct} />
            </div>
        </>
    );
};

export default memo(Configure);
