import { type ReactElement, useMemo } from 'react';

import type { FeatureGroup, MasterProduct } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { BasicPanel, MaterialPanel } from '../Panels/Summary';
import { variantGeneratorSelectors } from '../reducer';

import InlineNavigation from './InlineNavigation';

import styles from './PanelsWrapper.module.scss';

type FeatureGroupPanelProps = {
    featureGroup: FeatureGroup;
};

const FeatureGroupPanel = ({
    featureGroup,
}: FeatureGroupPanelProps): ReactElement | null => {
    const featureIds = useMemo(
        () => featureGroup.features.map(({ id }) => id),
        [featureGroup.features]
    );

    const { isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatuses(state, featureIds)
    );

    if (!isSelected) {
        return null;
    }

    return (
        <div className={styles.featureGroup} key={featureGroup.id}>
            <div className={styles.header}>
                <span className={styles.name}>{featureGroup.name}</span>
            </div>

            {featureGroup.features.map((feature) => {
                if (feature.__typename === 'BasicFeature') {
                    return <BasicPanel key={feature.id} feature={feature} />;
                }
                if (feature.__typename === 'MaterialFeature') {
                    return <MaterialPanel key={feature.id} feature={feature} />;
                }
            })}
        </div>
    );
};

type PanelsWrapperProps = {
    masterProduct: MasterProduct<'featureConfiguration'>;
};

const PanelsWrapper = ({ masterProduct }: PanelsWrapperProps): ReactElement => {
    return (
        <div className={styles.panelsWrapper}>
            {masterProduct.featureConfiguration?.featureGroups.map(
                (featureGroup) => (
                    <FeatureGroupPanel
                        featureGroup={featureGroup}
                        key={featureGroup.id}
                    />
                )
            )}
            <InlineNavigation
                featureGroups={
                    masterProduct.featureConfiguration?.featureGroups ?? []
                }
            />
        </div>
    );
};

export default PanelsWrapper;
