import { gql } from 'graphql-request';

export const brandQueryFields = gql`
    fragment BrandQueryFields on Brand {
        id
        name
        brand_owner {
            id
        }
        theme {
            logo
            feature_image
            colors {
                primary
            }
        }
        product_counts {
            configurable_products
            master_products
            products
        }
    }
`;

export const getAdminBrandsQuery = gql`
    query GetAdminBrands {
        brands {
            id
            name
        }
    }
`;

export const getAdminBrandsForOrganizationQuery = gql`
    query GetAdminBrandsForOrganization($organizationId: String!) {
        organization(id: $organizationId) {
            brands {
                id
                name
                productCounts: product_counts {
                    masterProducts: master_products
                    configurable_products: configurable_products
                    products
                }
            }
        }
    }
`;

export const addBrandMutation = gql`
    mutation AddBrand($id: String!, $name: String!, $brandOwner: String!) {
        brand {
            create(id: $id, name: $name, owner: $brandOwner) {
                ...BrandQueryFields
            }
        }
    }
    ${brandQueryFields}
`;

export const editBrandMutation = gql`
    mutation EditBrand($id: String!, $name: String!) {
        brand {
            edit(brandId: $id, name: $name) {
                ...BrandQueryFields
            }
        }
    }
    ${brandQueryFields}
`;

export const editBrandThemeMutation = gql`
    mutation EditBrandTheme($brandId: String!, $newTheme: InputTheme!) {
        brand {
            set_theme(brandId: $brandId, newTheme: $newTheme) {
                id
            }
        }
    }
`;

export const deleteBrandMutation = gql`
    mutation DeleteBrand($id: String!) {
        brand {
            delete(id: $id) {
                id
                deleted
            }
        }
    }
`;
