import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryParams } from '@xeris/hooks';

import SearchBar from '../../common/SearchBar';

import BrandList from './BrandList';

const Brands = (): ReactElement => {
    const { t } = useTranslation('products');
    const brandFilter = useQueryParams('searchTerm') ?? '';

    return (
        <>
            <SearchBar
                sx={{
                    maxWidth: 440,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 3,
                    marginBottom: 3,
                }}
                debounceTime={100}
                searchKey={'searchTerm'}
                label={t('common.searchForBrand')}
            />
            <BrandList brandFilter={brandFilter} />
        </>
    );
};

export default Brands;
