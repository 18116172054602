import { type ReactElement, type SyntheticEvent } from 'react';

import { type IconFactoryProps, SortDefaultIcon } from '@xeris/components';

import { SortAscendingIcon, SortDescendingIcon } from '..';

type SortOrderType = 'Ascending' | 'Descending' | 'Unsorted';

export type SortAscDescIconProps = Omit<IconFactoryProps, 'handleClick'> & {
    handleClick?: (event: SyntheticEvent, sortOrder: SortOrderType) => void;
    sortOrder: SortOrderType;
};

type IconStateLookupType = Record<
    'Unsorted' | 'Descending' | 'Ascending',
    {
        icon:
            | typeof SortAscendingIcon
            | typeof SortDescendingIcon
            | typeof SortDefaultIcon;
        newSortOrder: 'Unsorted' | 'Descending' | 'Ascending';
    }
>;

const iconStateLookup: IconStateLookupType = {
    Unsorted: { icon: SortDefaultIcon, newSortOrder: 'Descending' },
    Descending: { icon: SortDescendingIcon, newSortOrder: 'Unsorted' },
    Ascending: { icon: SortAscendingIcon, newSortOrder: 'Ascending' },
};

const SortAscDescIcon = ({
    sortOrder = 'Unsorted',
    handleClick,
    ...otherProps
}: SortAscDescIconProps): ReactElement => {
    const iconState = iconStateLookup[sortOrder];

    return (
        <iconState.icon
            {...otherProps}
            onClick={(event): void => {
                if (handleClick) {
                    handleClick(event, iconState.newSortOrder);
                }
            }}
        />
    );
};

export default SortAscDescIcon;
