import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import {
    ArrowLeftIcon,
    ArrowRightIcon,
    PlusFolderIcon,
} from '@xeris/components';

import { type Stage } from '../../VariantGeneratorWrapper/VariantGeneratorWrapper';

type ToNextStagePanelProps = {
    description: string;
    nextStageName: string;
    nextStageType: Stage;
    hasPreviousFeatureGroup: boolean;
    previousFeatureGroupPath: string;
    previousStageType: string;
    title: string;
};

const ToNextStagePanel = ({
    description,
    nextStageName,
    nextStageType,
    hasPreviousFeatureGroup,
    previousFeatureGroupPath,
    previousStageType,
    title,
}: ToNextStagePanelProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Stack
            direction={'column'}
            justifyContent={'center'}
            spacing={2}
            padding={6}
        >
            <PlusFolderIcon fontSize={'large'} color={'primary'} />

            <Typography variant={'h3'}>{title}</Typography>
            <Typography variant={'body1'}>{description}</Typography>
            <Link to={{}} state={{ page: nextStageType }}>
                <Button
                    variant={'contained'}
                    sx={{ borderRadius: '50px' }}
                    endIcon={<ArrowRightIcon />}
                >
                    {t('variantGenerator.stages.next')}: {nextStageName}
                </Button>
            </Link>
            {hasPreviousFeatureGroup && (
                <Link
                    to={{}}
                    state={{
                        page: previousStageType,
                        featureGroup: previousFeatureGroupPath,
                    }}
                >
                    <Button
                        variant={'text'}
                        color={'secondary'}
                        startIcon={<ArrowLeftIcon />}
                    >
                        {t('variantGenerator.stages.back')}
                    </Button>
                </Link>
            )}
        </Stack>
    );
};

export default ToNextStagePanel;
