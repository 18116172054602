import {
    memo,
    type ReactElement,
    type ReactNode,
    type SyntheticEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    Badge,
    type ButtonProps,
    type IconButtonProps,
    type SxProps,
} from '@mui/material';

import { EyeIcon, MuiButton, Tooltip } from '@xeris/components';

type PreviewButtonIconProps = {
    className?: string;
    onClick: (event: SyntheticEvent<Element, Event>) => void;
    isPreviewActive?: boolean;
    isDisabled?: boolean;
    size?: ButtonProps['size'];
    color?: ButtonProps['color'];
    sx?: SxProps;
};

const PreviewButtonIcon = ({
    onClick,
    isPreviewActive,
    isDisabled,
    size,
    className,
}: PreviewButtonIconProps): ReactElement => {
    const iconProps = {
        className: className,
        size,
        round: true,
        onClick,
    };
    if (isPreviewActive) {
        return (
            <MuiButton variant={'contained'} {...iconProps}>
                <EyeIcon />
            </MuiButton>
        );
    }

    if (isDisabled) {
        return (
            <MuiButton variant={'outlined'} disabled {...iconProps}>
                <EyeIcon />
            </MuiButton>
        );
    }

    return (
        <MuiButton variant={'outlinedSolid'} sx={{ opacity: 0 }} {...iconProps}>
            <EyeIcon />
        </MuiButton>
    );
};

type PreviewButtonProps = {
    isAlwaysShown?: boolean;
    className?: string;
    onSelectPreview: (event: SyntheticEvent<Element, Event>) => void;
    isDisabled?: boolean;
    isPreviewActive?: boolean;
    edge?: IconButtonProps['edge'];
    onClick?: (event: SyntheticEvent<Element, Event>) => void;
    size?: ButtonProps['size'];
    color?: ButtonProps['color'];
    sx?: SxProps;
    badgeContent?: ReactNode;
};

const PreviewButton = ({
    className,
    onSelectPreview,
    isDisabled,
    isPreviewActive,
    badgeContent,
    sx,
    ...iconProps
}: PreviewButtonProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Tooltip
            title={
                isDisabled && t('variantGenerator.optionNotAvailablePreview')
            }
        >
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={badgeContent}
            >
                <PreviewButtonIcon
                    className={className}
                    isPreviewActive={isPreviewActive}
                    onClick={onSelectPreview}
                    isDisabled={isDisabled}
                    {...iconProps}
                    sx={{
                        'opacity': 0,
                        ...sx,
                        '&:hover': {
                            ...(isPreviewActive && {
                                opacity: 1,
                            }),
                            opacity: 1,
                        },
                        '&:focus': { opacity: 1 },
                    }}
                />
            </Badge>
        </Tooltip>
    );
};

export default memo(PreviewButton);
