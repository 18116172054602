import { type ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import BrandSettings from './BrandSettings/BrandSettings';
import BrandsList from './BrandsList/BrandsList';

const Brands = (): ReactElement => {
    return (
        <Routes>
            <Route path=":brandId" element={<BrandSettings />} />
            <Route path={''} element={<BrandsList />} />
        </Routes>
    );
};

export default Brands;
