import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@xeris/components';

import styles from './BrokenLinkMessage.module.scss';

type VariantType = 'reset-password' | 'invite';

const BackToResetPassword = (): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <Link to="/login/?action=forgot-password">
            <Button
                uppercase={false}
                className={styles.forgottenPasswordButton}
                dataTestId="forgot-password-button"
            >
                {t('common.tryAgain')}
            </Button>
        </Link>
    );
};

type BrokenLinkMessageProps = {
    variant: VariantType;
};

const BrokenLinkMessage = ({
    variant,
}: BrokenLinkMessageProps): ReactElement => {
    const { t } = useTranslation('login');

    const informationMessageLoookup: Record<VariantType, string> = {
        'reset-password': t('common.tryResettingPassword'),
        'invite': '',
    };

    const contactMessageLookup: Record<VariantType, string | ReactElement> = {
        'reset-password': t('resetPassword.contactSupport'),
        'invite': t('invite.contactSupport'),
    };

    return (
        <div className={styles.brokenLinkMessage}>
            <span className={styles.title}>
                {t('common.somethingWentWrong')}
            </span>

            <p>
                {t('common.linkIsInvalidOrExpired')}{' '}
                {informationMessageLoookup[variant]}
            </p>

            {variant === 'reset-password' && <BackToResetPassword />}

            <p className={styles.help}>
                {contactMessageLookup[variant]}{' '}
                <a
                    href="mailto:support@xeris.no"
                    target="_blank"
                    rel="noreferrer"
                >
                    support@xeris.no
                </a>
            </p>
        </div>
    );
};

export default BrokenLinkMessage;
