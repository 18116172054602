/*
Function for conditional strings. str is mandatory, condition and fallback is optional.
If no fallback is set, it defaults to ''
If no condition is given: returns str is truthy and fallback if not.
If str is the number zero and no condition is given, the string '0' is returned.
If condition is given, returns str if condition is true, fallback if not.
If condition is given, str is parsed as a string, with no consideration to truthiness.

Examples:
conditionalString('test') should return 'test'
conditionalString(undefined) should return ''
conditionalString(undefined, undefined, 'test') should return 'test'
conditionalString('test', true) should return test
conditionalString('test', true, 'foo') should return test
conditionalString('test', false) should return ''
conditionalString('test', false, 'foo') should return 'foo'
*/

import MissingImage from '@xeris/assets/img/core/Missing-Image.jpg';

export const verifyImageIUrl = (url?: string): boolean => {
    return !!url;
};

export const missingImageLink = MissingImage;
export const withoutOptionImageLink = MissingImage;

export const conditionalString = (
    str: string | number | undefined | null,
    condition?: boolean,
    fallback = ''
): string => {
    let cc = condition === undefined ? str : condition;
    if (typeof str === 'number' && condition === undefined) cc = true; //this line is to handle 0 as not falsy
    return cc ? `${str}` : `${fallback}`;
};

const imageDefaultTypeLookup = {
    picture: missingImageLink, //Looks like a framed picture
    icon: withoutOptionImageLink, //Looks like a 'cancel' icon
};

export const conditionalImage =
    (missingImageSource?: string) =>
    (imageSource?: string | null, type: 'picture' | 'icon' = 'picture') => {
        const defaultSource =
            imageDefaultTypeLookup[type] ?? missingImageSource;

        if (!imageSource || imageSource === '') {
            return defaultSource;
        }

        return imageSource;
    };

export const ensuredImageSource = conditionalImage(missingImageLink);

export default conditionalString;
