import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

import { type MasterProductWithIds } from '@xeris/pages/product/Common';

import { type EmptyData, type ProductListData } from '../types';

type CardRowProps<MP extends MasterProductWithIds> = {
    data: (ProductListData<MP> | EmptyData)[];
    card: (product: ProductListData<MP>) => ReactNode;
    hasSections: boolean;
    width: number;
    isAfterRow: boolean;
};

export const CardRow = <MP extends MasterProductWithIds>({
    width,
    data,
    card,
    hasSections,
    isAfterRow,
}: CardRowProps<MP>): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: hasSections ? '0 20px' : '0 20px 0 0',
                borderRight: hasSections ? 1 : 0,
                borderLeft: hasSections ? 1 : 0,
                borderColor: 'divider',
                gap: '20px',
            }}
        >
            {data.map((element, index) =>
                element.type === 'empty' ? (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexGrow: 0,
                            flexBasis: width + 'px',
                            height: '200px',
                        }}
                    />
                ) : (
                    <Box
                        key={element.id + index}
                        sx={{
                            marginTop: isAfterRow ? '0px' : '20px',
                            marginBottom: '20px',
                        }}
                    >
                        {card(element)}
                    </Box>
                )
            )}
        </Box>
    );
};
