import { memo, type ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, UpDownChevronIcon } from '@xeris/components';
import { type Material } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../../reducer';

import MaterialWrapper from './MaterialWrapper';

import styles from './PriceGroupWrapper.module.scss';

type PriceGroupHeaderProps = {
    handleToggleCollapse: () => void;
    isCollapsed: boolean;
    featureId: string;
    priceGroupId: string;
    materials: Material[];
};

const PriceGroupHeader = ({
    handleToggleCollapse,
    featureId,
    isCollapsed,
    materials,
    priceGroupId,
}: PriceGroupHeaderProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const optionIds = useMemo(
        () =>
            materials.flatMap(
                (material) => material.options.map(({ id }) => id),
                [materials]
            ),
        [materials]
    );

    const { available } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCountByIds(
            state,
            featureId,
            optionIds
        )
    );

    return (
        <div
            className={styles.groupHeaderWrapper}
            onClick={handleToggleCollapse}
        >
            <div className={styles.groupHeader}>
                <div className={styles.nameWrapper}>
                    <UpDownChevronIcon isCollapsed={isCollapsed} />
                    <span> {priceGroupId} </span>
                </div>
                <Typography
                    variant={'overline'}
                    sx={(theme) => ({
                        fontWeight: 400,
                        color: theme.palette.text.secondary,
                    })}
                >
                    {t('variantGenerator.options', { optionCount: available })}
                </Typography>
            </div>
        </div>
    );
};

type PriceGroupContentProps = {
    isCollapsed: boolean;
    featureId: string;
    materials: Material[];
};

export const PriceGroupContent = ({
    isCollapsed,
    materials,
    featureId,
}: PriceGroupContentProps): ReactElement | null => {
    const classNames = [styles.groupsWrapper];

    if (isCollapsed) {
        classNames.push(styles.hide);
    }

    return (
        <div className={classNames.join(' ')}>
            {materials.map((material) => {
                return (
                    <MaterialWrapper
                        key={material.id}
                        material={material}
                        featureId={featureId}
                    />
                );
            })}
        </div>
    );
};

type PriceGroupWrapperProps = {
    priceGroupId: string;
    featureId: string;
    materials: Material[];
};

const PriceGroupWrapper = ({
    priceGroupId,
    featureId,
    materials,
}: PriceGroupWrapperProps): ReactElement | null => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = (): void => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={styles.groupsWrapper}>
            <PriceGroupHeader
                handleToggleCollapse={handleToggleCollapse}
                isCollapsed={isCollapsed}
                priceGroupId={priceGroupId}
                materials={materials}
                featureId={featureId}
            />
            <PriceGroupContent
                isCollapsed={isCollapsed}
                materials={materials}
                featureId={featureId}
            />
        </div>
    );
};

export default memo(PriceGroupWrapper);
