import {
    type ReactElement,
    type SyntheticEvent,
    useCallback,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
} from '@mui/material';

import {
    ArrowRightBoldIcon,
    DeleteIcon,
    DotsHorizontalIcon,
    DuplicateIcon,
    EditIcon,
    IconButton,
    RadioSetAsActiveIcon,
    ShareIcon,
} from '@xeris/components';
import { useFeatureFlag, useWhoami } from '@xeris/hooks';
import CreateExportJob from '@xeris/pages/exportJob/forms/CreateExportJob';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import {
    DeleteDatasetForm,
    DuplicateDatasetForm,
    EditDatasetForm,
    RemoveSharedDatasetForm,
    ShareDatasetForm,
} from '../forms';
import { datasetActions, datasetSelectors } from '../reducers';
import type { DatasetSharedWith } from '../types/datasetTypes';

type Dialog = 'edit' | 'delete' | 'export' | 'duplicate' | 'share' | 'remove';

type DatasetActionsMenuProps = {
    dataset: {
        id: string;
        name: string;
        description: string | null;
        totalCount: number;
        owner: {
            username: string;
        };
        sharedWith: DatasetSharedWith[];
    };
};

export const DatasetActionsMenu = ({
    dataset,
}: DatasetActionsMenuProps): ReactElement | null => {
    const { t } = useTranslation('dataset');
    const [shareDatasetVisible] = useFeatureFlag('shareDataset');
    const dispatch = useAppDispatch();
    const { username } = useWhoami();

    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

    const [selectedDialog, setSelectedDialog] = useState<Dialog | null>(null);

    const handleClose = useCallback((): void => {
        setSelectedDialog(null);
    }, []);

    const handleSetActive = (): void => {
        dispatch(datasetActions.changeActiveDataset(dataset.id));
    };

    const activeDatasetId = useAppSelector(
        datasetSelectors.active.selectActiveId
    );

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);

    const setDialog = (dialog: Dialog): void => {
        setSelectedDialog(dialog);
        setMenuAnchorEl(null);
    };

    const isOwner = username === dataset.owner.username;

    return (
        <>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    if (menuAnchorEl) {
                        setMenuAnchorEl(null);
                    } else {
                        setMenuAnchorEl(event.currentTarget);
                    }
                }}
                edge={'end'}
                size={'small'}
                rounded
                aria-label={t('datasetList.openMoreActionsMenuFor', {
                    datasetName: dataset.name,
                })}
            >
                <DotsHorizontalIcon />
            </IconButton>
            <Menu
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                onClose={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setMenuAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ListItemButton
                    onClick={() => handleSetActive()}
                    disabled={activeDatasetId === dataset.id}
                    dense
                >
                    <ListItemIcon>
                        <RadioSetAsActiveIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText>{t('common.setAsActive')}</ListItemText>
                </ListItemButton>

                {isOwner && (
                    <ListItemButton onClick={() => setDialog('edit')} dense>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('datasetList.edit')}</ListItemText>
                    </ListItemButton>
                )}

                {shareDatasetVisible && isOwner && (
                    <ListItemButton onClick={() => setDialog('share')} dense>
                        <ListItemIcon>
                            <ShareIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('datasetList.share')}</ListItemText>
                    </ListItemButton>
                )}

                <ListItemButton onClick={() => setDialog('duplicate')} dense>
                    <ListItemIcon>
                        <DuplicateIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.duplicate')}</ListItemText>
                </ListItemButton>

                <ListItemButton onClick={() => setDialog('export')} dense>
                    <ListItemIcon>
                        <ArrowRightBoldIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('datasetList.export')}</ListItemText>
                </ListItemButton>

                {shareDatasetVisible && !isOwner && (
                    <ListItemButton onClick={() => setDialog('remove')} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('datasetList.remove')}</ListItemText>
                    </ListItemButton>
                )}

                {isOwner && (
                    <ListItemButton onClick={() => setDialog('delete')} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('datasetList.delete')}</ListItemText>
                    </ListItemButton>
                )}
            </Menu>
            <EditDatasetForm
                key={dataset.id + 'edit'}
                isOpen={selectedDialog === 'edit'}
                handleClose={handleClose}
                dataset={dataset}
                datasetNames={datasetList.map(({ name }) => name)}
            />
            <CreateExportJob
                isOpen={selectedDialog === 'export'}
                handleCloseDialog={handleClose}
                datasetId={dataset.id}
            />
            <DuplicateDatasetForm
                key={dataset.id + 'duplicate'}
                isOpen={selectedDialog === 'duplicate'}
                handleClose={handleClose}
                dataset={dataset}
                datasetNames={datasetList.map(({ name }) => name)}
            />
            <DeleteDatasetForm
                key={dataset.id + 'delete'}
                isOpen={selectedDialog === 'delete'}
                handleClose={handleClose}
                dataset={dataset}
            />
            <ShareDatasetForm
                key={dataset.id + 'share'}
                isOpen={selectedDialog === 'share'}
                handleClose={handleClose}
                dataset={dataset}
            />
            <RemoveSharedDatasetForm
                key={dataset.id + 'remove'}
                isOpen={selectedDialog === 'remove'}
                handleClose={handleClose}
                dataset={dataset}
            />
        </>
    );
};
