import { memo, type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { FeedbackCard, SearchBar, Typography } from '@xeris/components';
import { type MaterialFeature } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { setMaterialFilter, variantGeneratorSelectors } from '../../../reducer';
import { groupMaterialsByPriceGroup } from '../../../utilities/groupMaterialsByPriceGroup';

import PriceGroupWrapper, { PriceGroupContent } from './PriceGroupWrapper';

type FeatureHeaderProps = {
    feature: MaterialFeature;
};

type MaterialPanelProps = {
    feature: MaterialFeature;
};

const FeatureHeader = ({ feature }: FeatureHeaderProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', marginBottom: 3 }}
            id={feature.id}
        >
            <Typography variant={'h3'} component={'p'} gutterBottom>
                {feature.name}
            </Typography>
            <Typography variant={'body2'} marginBottom={1.5}>
                {feature.description}
            </Typography>

            <SearchBar
                inputLabel={t('variantGenerator.searchForMaterial')}
                onChange={(value) =>
                    dispatch(
                        setMaterialFilter({
                            featureId: feature.id,
                            filter: value,
                        })
                    )
                }
            />
        </Box>
    );
};

const MaterialPanel = ({
    feature,
}: MaterialPanelProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const { isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    const hasPriceGroups = useMemo(
        () => feature.materials.some((material) => material.priceGroup),
        [feature.materials]
    );

    const visibleOptionIds = useAppSelector((state) =>
        variantGeneratorSelectors.selectFilteredOptions(state, feature.id)
    );

    if (!isVisible) {
        return null;
    }

    const groups = groupMaterialsByPriceGroup(
        feature.materials,
        visibleOptionIds
    );

    return (
        <Box sx={{ marginBottom: 3 }}>
            <FeatureHeader feature={feature} />
            <FeedbackCard isDisplayed={groups.length === 0} severity={'info'}>
                {t('variantGenerator.noMatchingResults')}
            </FeedbackCard>
            {groups.map(({ priceGroupId, materials }) =>
                hasPriceGroups ? (
                    <PriceGroupWrapper
                        key={priceGroupId}
                        priceGroupId={priceGroupId}
                        featureId={feature.id}
                        materials={materials}
                    />
                ) : (
                    <PriceGroupContent
                        key={priceGroupId}
                        isCollapsed={false}
                        materials={materials}
                        featureId={feature.id}
                    />
                )
            )}
        </Box>
    );
};

export default memo(MaterialPanel);
