import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { ProductCard, Skeleton } from '@xeris/components';
import { ProductListItem } from '@xeris/components/ProductListItem/ProductListItem';
import { productApi } from '@xeris/pages/product/api';
import { masterProductUrl } from '@xeris/utilities/urls';

import { MultipleMasterProductsSelector, ProductListSkeleton } from '../Common';
import { GroupedProductList } from '../Common/GroupedProductList/GroupedProductList';
import ActionButtons from '../Common/ProductEntityCard/ActionButtons/ActionButtons';
import { productHooks } from '../hooks/productHooks';

const SkeletonLoad = (): ReactElement => {
    return (
        <div>
            <Skeleton height={100} />
            <ProductListSkeleton />
        </div>
    );
};

const MasterProducts = (): ReactElement => {
    const { t } = useTranslation('product');

    const { brandId = '' } = useParams();

    const getMasterProductResult =
        productHooks.api.queries.useGetAllMasterProductsForBrand({ brandId });

    const masterProductList =
        getMasterProductResult.data?.brand?.master_products ?? [];

    const getBrandResult = productApi.brand.useGetBrandByIdQuery({
        id: brandId,
    });

    const groupTypes = getBrandResult.data?.brand?.groupTypes ?? [];
    const markets = getBrandResult.data?.brand?.markets ?? [];

    if (getMasterProductResult.isLoading || getMasterProductResult.isLoading) {
        return <SkeletonLoad />;
    }

    return (
        <GroupedProductList
            brandId={brandId}
            products={masterProductList}
            groupTypes={groupTypes}
            markets={markets}
            cardHeight={380}
            cardMinWidth={210}
            listItem={(product) => (
                <ProductListItem
                    key={product.id}
                    images={product.images}
                    url={masterProductUrl(product)}
                    actions={
                        <ActionButtons
                            masterProduct={product}
                            isActionButtonsAlwaysVisible={false}
                        />
                    }
                >
                    <Typography>{product.name}</Typography>
                    <Typography variant="body2">
                        {product.isConfigurable
                            ? t('productCard.configurable')
                            : t('dataSelector.numberOfVariants', {
                                  count: product.products.length,
                              })}
                    </Typography>
                </ProductListItem>
            )}
            card={(product) => (
                <Box
                    key={product.id}
                    sx={{
                        height: '340px',
                        width: '210px',
                        flexGrow: 0,
                        flexBasis: '220px',
                    }}
                >
                    <ProductCard
                        images={product.images}
                        url={masterProductUrl(product)}
                        actions={
                            <ActionButtons
                                masterProduct={product}
                                isActionButtonsAlwaysVisible={false}
                            />
                        }
                    >
                        <Typography>{product.name}</Typography>
                        <Typography variant="body2">
                            {product.isConfigurable
                                ? t('productCard.configurable')
                                : t('dataSelector.numberOfVariants', {
                                      count: product.products.length,
                                  })}
                        </Typography>
                    </ProductCard>
                </Box>
            )}
            sectionActions={(section) => (
                <MultipleMasterProductsSelector
                    showCounters={false}
                    masterProductList={section.products}
                />
            )}
            toolbar={(products) => (
                <MultipleMasterProductsSelector
                    showCounters={false}
                    masterProductList={products}
                />
            )}
        />
    );
};

export default MasterProducts;
