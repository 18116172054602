import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Divider,
    MenuItem,
    TextField,
    type TextFieldProps,
} from '@mui/material';

import { PlusIcon } from '@xeris/components';
import { datasetActions } from '@xeris/pages/dataset/reducers';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

import styles from './SelectDataset.module.scss';

type NewDatasetProps = {
    name: string;
    showAddDataset: boolean;
};

const NewDataset = ({
    name,
    showAddDataset,
}: NewDatasetProps): ReactElement => {
    const { t } = useTranslation('product');
    const datasetName = name === '' ? t('dataSelector.newDataset') : name;
    if (showAddDataset) {
        return <>{datasetName}</>;
    }
    return (
        <div className={styles.addDataset}>
            <PlusIcon /> {t('dataSelector.newDataset')}
        </div>
    );
};

type SelectDatasetProps = {
    handleSetShowAddDataset: (showAddDataset: boolean) => void;
    name: string;
    showAddDataset: boolean;
    disabled?: boolean;
};

const SelectDataset = ({
    handleSetShowAddDataset,
    name,
    showAddDataset,
    disabled,
}: SelectDatasetProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);
    const activeDatasetId = useAppSelector(
        datasetSelectors.active.selectActiveId
    );

    const handleSelectDataset: TextFieldProps['onChange'] = (event) => {
        const datasetId = event.target.value;
        if (datasetId === 'new') {
            handleSetShowAddDataset(true);
        } else {
            handleSetShowAddDataset(false);
            dispatch(datasetActions.changeActiveDataset(datasetId));
        }
    };

    return (
        <div className={styles.selectDataset}>
            <TextField
                className={styles.menu}
                select
                label={t('common.selectDataset')}
                value={showAddDataset ? 'new' : activeDatasetId}
                onChange={handleSelectDataset}
                variant="outlined"
                disabled={disabled}
            >
                <MenuItem key="new" value="new">
                    <NewDataset showAddDataset={showAddDataset} name={name} />
                </MenuItem>
                <Divider />
                {datasetList.map((dataset) => (
                    <MenuItem key={dataset.id} value={dataset.id}>
                        {dataset.name}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default SelectDataset;
