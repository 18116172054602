import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@mui/material';

import { fileUploadApi } from '@xeris/api/fileUploadApi';
import { ProgressOverlay } from '@xeris/components';

import { UploadButton, UploadSnackbar } from '../../Common';
import { type Importer } from '../types';

import { SubmitImportFile } from './index';

import '../index.scss';

const ImportFile = (): ReactElement => {
    const { t } = useTranslation('administration');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [importFormat, setImportFormat] = useState<Importer | null>(null);

    const [getSignedUploadUrl] = fileUploadApi.useGetSignedUploadUrlMutation();
    const [uploadFile, { isLoading: uploadInProgress }] =
        fileUploadApi.useUploadFileMutation();

    const [uploadFileStatus, setUploadFileStatus] = useState<string | null>(
        null
    );
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const uploadButtonId = 'importfile';

    const fileCommitHandler = async (
        file: File,
        uploadFormat: string
    ): Promise<void> => {
        const formatUrl = file.name.split(' ').join('_');

        const { url } = await getSignedUploadUrl({
            uploadFormat,
            formatUrl,
        }).unwrap();

        await uploadFile({ url, file }).unwrap();
    };

    const submitFileHandler = (): void => {
        if (!importFormat) {
            setUploadFileStatus(t('imports.pleaseSelectUploadFormat'));
            setOpenSnackbar(true);
        } else {
            if (!selectedFile || !importFormat) return;

            const fileExtension = selectedFile.name?.split('.').pop();
            const accepted = importFormat.accept.find(
                (format) => format === `.${fileExtension}`
            );

            if (accepted) {
                fileCommitHandler(selectedFile, importFormat.id);
                setSelectedFile(null);
            } else {
                setUploadFileStatus(
                    t('imports.fileExtensionDoesNotMatch', {
                        name: importFormat?.name,
                        extensions: importFormat?.accept?.join(', '),
                    })
                );
                setOpenSnackbar(true);
            }
        }
    };

    const abortUploadHandler = (): void => {
        setSelectedFile(null);
    };

    function handleFiles(this: HTMLInputElement): void {
        const files = this.files;

        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        }
    }

    const selectFormatHandler = (format: Importer | null): void => {
        setImportFormat(format);
    };

    const formInput = document.getElementById(uploadButtonId);

    if (formInput) {
        formInput.addEventListener('change', handleFiles, false);
    }

    return (
        <div className="import-form-wrapper">
            <form>
                {selectedFile ? (
                    <SubmitImportFile
                        fileDetails={selectedFile}
                        submitFileHandler={submitFileHandler}
                        abortUploadHandler={abortUploadHandler}
                        selectFormatHandler={selectFormatHandler}
                        importFormat={importFormat}
                    />
                ) : (
                    <UploadButton
                        uploadButtonId={uploadButtonId}
                        name={t('imports.selectFile')}
                        acceptTypes="*"
                        handleFiles={(): void => {}}
                    />
                )}
            </form>
            <Divider sx={{ marginTop: 2 }} />
            <UploadSnackbar
                errorMessage={uploadFileStatus}
                setIfOpenSnackbar={() => setOpenSnackbar(false)}
                snackBarIsOpen={openSnackbar}
            />
            {uploadInProgress && <ProgressOverlay />}
        </div>
    );
};

export default ImportFile;
