import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, PlusIcon } from '@xeris/components';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import { InviteUserForm } from '../forms/InviteUserForm';

export const InviteUserButton = (): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [isOpen, setIsOpen] = useState(false);

    const activeOrganization = useActiveOrganization();

    if (!activeOrganization) {
        return null;
    }

    return (
        <>
            <Button startIcon={<PlusIcon />} onClick={() => setIsOpen(true)}>
                {t(`users.addNewUser`)}
            </Button>
            <InviteUserForm
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
            />
        </>
    );
};
