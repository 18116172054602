import { type ReactElement } from 'react';

import { Box, Stack } from '@mui/material';

import { Typography } from '@xeris/components';
import { numberOrDash } from '@xeris/components/utilities';

type FeatureHeaderProps = {
    hash: string;
    name: string;
    description?: string | null;
    selectedCount: number;
    availableCount: number;
    show?: boolean;
};

export const BasicFeatureHeader = ({
    hash,
    name,
    description,
    selectedCount,
    availableCount,
}: FeatureHeaderProps): ReactElement | null => {
    return (
        <Stack id={hash} spacing={1} marginBottom={1} alignItems={'flex-end'}>
            <div>
                <Typography variant={'h3'} gutterBottom>
                    {name}
                </Typography>
                <Typography variant={'body2'}>{description}</Typography>
            </div>
            <Box
                sx={{
                    minWidth: '80px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '0.4rem',
                }}
            >
                <Typography textAlign={'right'} fontWeight={'fontWeightMedium'}>
                    {numberOrDash(selectedCount)}
                </Typography>
                <Typography>{`/ ${availableCount}`}</Typography>
            </Box>
        </Stack>
    );
};
