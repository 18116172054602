import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { languageApi } from './api/languageApi';
import { setDataLanguage } from './reducer';

const DataLanguageSelector = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');

    const { data } = languageApi.useGetDataLanguagesQuery({});

    const { dataLanguage } = useAppSelector((state) => state.languages);

    const dataLanguages =
        data?.languages.map((language) => ({
            ...language,
            name: `${language.flag} ${language.language}`,
        })) ?? [];

    return (
        <FormControl>
            <InputLabel>
                {t('components.languageSelector.dataLanguage')}
            </InputLabel>
            <Select
                label={t('components.languageSelector.dataLanguage')}
                value={
                    // Makes sure the current language is one of the allowed options
                    dataLanguages.some(({ id }) => id === dataLanguage?.id)
                        ? dataLanguage?.id ?? ''
                        : ''
                }
                sx={{ width: 160 }}
                size={'small'}
                aria-label={t('components.languageSelector.selectDataLanguage')}
                renderValue={(value): ReactElement => (
                    <Typography variant="inherit" noWrap>
                        {
                            dataLanguages.find(
                                (language) => language.id === value
                            )?.language
                        }
                    </Typography>
                )}
                onChange={(event): void => {
                    dispatch(
                        // @ts-expect-error
                        setDataLanguage(
                            dataLanguages.find(
                                (language) => language.id === event.target.value
                            ) ?? dataLanguages[0]
                        )
                    );
                }}
            >
                <Typography
                    variant={'overline'}
                    sx={{ paddingInline: 1.5 }}
                    noWrap
                >
                    {t('components.languageSelector.selectDataLanguage')}
                </Typography>
                {dataLanguages.map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                        <ListItemIcon>{`${language.flag}`}</ListItemIcon>

                        <Typography variant="inherit" noWrap>
                            {language.language}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DataLanguageSelector;

export * from './reducer';
