import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ErrorBoundary } from '@xeris/components';

import App from './App';
import { Loading } from './components';
import createStore from './reducers';
import Root from './Root';

import './i18n/i18n';

Sentry.init({
    dsn: 'https://566f1f78d7e348f1827e2131deb49fc8@o4504870442237952.ingest.sentry.io/4504870624755712',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    release: import.meta.env.VITE_VERSION || 'local',
    environment: import.meta.env.VITE_ENVIRONMENT || 'local',
    normalizeDepth: 5,
    enabled: import.meta.env.PROD,
});

const store = createStore();

type RenderFn = (Component: typeof Root, _store: typeof store) => void;

const render: RenderFn = (Component, _store) => {
    const rootElement = document.getElementById('root');

    if (!rootElement) {
        throw Error('No root found to mount app in');
    }

    const root = createRoot(rootElement);

    root.render(
        <Provider store={_store}>
            <Component>
                <Router>
                    <ErrorBoundary>
                        <Suspense fallback={<Loading loading={true} />}>
                            <App />
                        </Suspense>
                    </ErrorBoundary>
                </Router>
            </Component>
        </Provider>
    );
};

render(Root, store);
