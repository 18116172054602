import { type ReactElement } from 'react';

import { Image, Link, Typography } from '@xeris/components';
import { verifyImageIUrl } from '@xeris/utilities';

import styles from './BrandCard.module.scss';

type BrandCardProps = {
    name: string;
    featureImage?: string;
    logo?: string;
    href: string;
};

const BrandCard = ({
    name,
    featureImage,
    logo,
    href,
}: BrandCardProps): ReactElement => {
    const hasFeatureImage = verifyImageIUrl(featureImage);
    const hasLogo = verifyImageIUrl(logo);

    return (
        <Link to={href} color={'secondary'}>
            <div className={styles.basicTileWrapper}>
                {hasFeatureImage && (
                    <Image
                        className={styles.featureImage}
                        src={featureImage}
                        alt={name}
                    />
                )}
                <div className={styles.marketBrandLogoWrapper}>
                    {hasLogo ? (
                        <Image
                            className={styles.marketBrandLogoImage}
                            src={logo}
                            alt={name}
                        />
                    ) : (
                        <Typography
                            variant={'h4'}
                            component={'p'}
                            marginRight={2}
                            maxLines={2}
                        >
                            {name}
                        </Typography>
                    )}
                </div>
            </div>
        </Link>
    );
};

export default BrandCard;
