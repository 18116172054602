import {
    createEntityAdapter,
    createSlice,
    type PayloadAction,
} from '@reduxjs/toolkit';

import { storeLocalState } from '@xeris/utilities';

import { type DatasetTypes } from '../types';
import { type ActiveDatasetType } from '../types/datasetTypes';

import { datasetProductsExtraReducers } from './extraReducers/datasetProductsExtraReducers';
import {
    datasetsExtraReducers,
    sharedDatasetExtraReducers,
} from './extraReducers';

export const ACTIVE_DATASET_ID = 'activeDatasetId';

export const datasetsAdapter =
    createEntityAdapter<DatasetTypes.MinimalDatasetType>({});

const initialState: DatasetTypes.DatasetSliceType = {
    activeId: undefined,
    errorMessage: undefined,
    datasets: datasetsAdapter.getInitialState(),
    activeDataset: undefined,
};

export function setActiveDataset(
    state: DatasetTypes.DatasetSliceType,
    datasetId?: string
): void {
    if (!datasetId) {
        state.activeDataset = undefined;
        state.activeId = storeLocalState(ACTIVE_DATASET_ID, {
            activeId: undefined,
        }).activeId;

        return;
    }

    const newDataset = state.datasets.entities[datasetId];

    if (!newDataset) {
        state.activeDataset = undefined;
        state.activeId = storeLocalState(ACTIVE_DATASET_ID, {
            activeId: undefined,
        }).activeId;

        return;
    }

    state.activeId = storeLocalState(ACTIVE_DATASET_ID, {
        activeId: newDataset.id,
    }).activeId;
}

export const datasetSlice = createSlice({
    name: 'dataset',
    initialState,
    reducers: {
        setActiveId(state, action: PayloadAction<{ activeId?: string }>) {
            state.activeId = storeLocalState(
                ACTIVE_DATASET_ID,
                action.payload
            ).activeId;
        },
        changeActiveDataset(state, action: PayloadAction<string | null>) {
            setActiveDataset(state, action.payload ?? undefined);
        },
        setActiveDataset(
            state,
            action: PayloadAction<ActiveDatasetType | undefined>
        ) {
            if (action.payload) {
                state.activeDataset = action.payload;
            } else {
                state.activeDataset = undefined;
            }
        },
        resetDatasetState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        datasetProductsExtraReducers(builder);
        datasetsExtraReducers(builder);
        sharedDatasetExtraReducers(builder);
    },
});

export const datasetActions = datasetSlice.actions;

export const datasetReducer = datasetSlice.reducer;
