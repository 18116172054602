import { type ReactElement, type ReactNode } from 'react';

import { Badge, IconButton, Stack } from '@mui/material';

import { CloseIcon, Image, Typography } from '@xeris/components';

type OptionCardSummaryProps = {
    image: string;
    name: string;
    isPreviewed?: boolean;
    isPreviewable?: boolean;
    handleSelectPreview: () => void;
    handleRemoveOption: () => void;
    children: ReactNode;
};

export const BasicOptionSummaryCard = ({
    name,
    image,
    isPreviewed,
    isPreviewable,
    handleSelectPreview,
    handleRemoveOption,
    children,
}: OptionCardSummaryProps): ReactElement => {
    return (
        <Stack
            spacing={2}
            padding={1}
            onClick={handleSelectPreview}
            sx={(theme) => ({
                '&:hover ': {
                    '.preview': { opacity: 1 },
                    'backgroundColor': 'background.default',
                },
                '&:focus-within': { '.preview': { opacity: 1 } },
                'cursor': isPreviewable ? 'pointer' : 'initial',
                'backgroundColor': isPreviewed
                    ? 'primary.soft'
                    : 'background.paper',
                'borderRadius': `${theme.shape.borderRadius}px`,
            })}
        >
            <Badge
                invisible={!isPreviewed}
                color={'primary'}
                variant={'standard'}
                badgeContent={''}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiBadge-badge': {
                        border: '1px solid',
                        borderColor: 'white',
                        padding: '0',
                        height: 12,
                        minWidth: 12,
                    },
                }}
            >
                <Image
                    src={image}
                    style={{
                        width: 40,
                        height: 40,
                        border: '1px solid #d5d9e0',
                        borderRadius: '6px',
                        verticalAlign: 'top',
                        objectFit: 'cover',
                    }}
                />
            </Badge>
            <Stack spacing={4} flexGrow={1}>
                <Typography
                    variant={'h6'}
                    component={'p'}
                    title={name}
                    maxLines={2}
                    lineHeight={1.25}
                >
                    {name}
                </Typography>
                {children}
            </Stack>
            <IconButton onClick={handleRemoveOption} size={'small'}>
                <CloseIcon color="icons" fontSize="small" />
            </IconButton>
        </Stack>
    );
};
