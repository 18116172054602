import type {
    SelectedDocumentsDataType,
    SelectedImagesDataType,
    SelectedModelsDataType,
} from '@xeris/pages/product/ProductDataSelector/types';
import { type MasterProduct } from '@xeris/pages/product/types';

type DataSelection = {
    isSelected: boolean;
    images: {
        list: SelectedImagesDataType[];
        primaryImageId: string | null;
    };
    documents: SelectedDocumentsDataType[];
    models: SelectedModelsDataType[];
};

export const getDataSelection = <
    I extends { id: string },
    D extends { id: string },
    M extends { id: string },
>(
    masterProduct: {
        images: I[] | null;
        documents: D[] | null;
        models: M[] | null;
    },
    selection?: {
        images?: { id: string }[] | null;
        documents?: { id: string }[] | null;
        models?: { id: string }[] | null;
    } | null,
    isAnySelected = false
): {
    isSelected: boolean;
    images: {
        list: (I & { isSelected: boolean })[];
        primaryImageId: string | null;
    };
    documents: (D & { isSelected: boolean })[];
    models: (M & { isSelected: boolean })[];
} => {
    const data = {
        images: masterProduct.images ?? [],
        documents: masterProduct.documents ?? [],
        models: masterProduct.models ?? [],
    };

    if (selection) {
        return {
            isSelected: isAnySelected,
            images: {
                primaryImageId:
                    selection.images?.[0]?.id ?? data.images[0]?.id ?? null,
                list: data.images.map((image) => ({
                    ...image,
                    isSelected:
                        !selection.images ||
                        selection.images.some((i) => image.id === i.id),
                })),
            },
            documents: data.documents.map((document) => ({
                ...document,
                isSelected:
                    !selection.documents ||
                    selection.documents.some((d) => document.id === d.id),
            })),
            models: data.models.map((model) => ({
                ...model,
                isSelected:
                    !selection.models ||
                    selection.models.some((m) => model.id === m.id),
            })),
        };
    }

    return {
        isSelected: !isAnySelected,
        images: {
            primaryImageId: data.images[0]?.id ?? null,
            list: data.images.map((image) => ({
                ...image,
                isSelected: true,
            })),
        },
        documents: data.documents.map((document) => ({
            ...document,
            isSelected: true,
        })),
        models: data.models.map((model) => ({
            ...model,
            isSelected: true,
        })),
    };
};

export const getMasterProductDataSelection = (
    masterProduct?: MasterProduct<
        'selectedData' | 'images' | 'documents' | 'models'
    > | null,
    configurationSetId?: string
): { [key: string]: DataSelection } => {
    if (!masterProduct) {
        return {};
    }

    const configurationSet = masterProduct.selectedData?.configurationSets.find(
        (configurationSet) => configurationSet.id === configurationSetId
    );

    return {
        [masterProduct.id]: getDataSelection(masterProduct, configurationSet),
    };
};

export const getProductsDataSelection = (
    products?:
        | (Pick<MasterProduct, 'id' | 'images' | 'documents' | 'models'> & {
              selectedData?: Omit<
                  MasterProduct['selectedData'],
                  'configurationSets'
              > | null;
          })[]
        | null
): { [key: string]: DataSelection } => {
    if (!products) {
        return {};
    }

    const isAnySelected = products.some((p) => !!p.selectedData);

    const data = products.map((product) => [
        product.id,
        getDataSelection(product, product.selectedData, isAnySelected),
    ]);

    return Object.fromEntries(data);
};
