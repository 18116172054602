import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = <T extends string>(key: string): T => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    return queryParams.get(key) as T;
};

export default useQueryParams;
