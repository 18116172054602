import { type ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Tooltip } from '@mui/material';

import { EditIcon, MuiButton, SettingsIcon } from '@xeris/components';
import { datasetProductsApi } from '@xeris/pages/dataset/api';
import { useGetSelectedStatus } from '@xeris/pages/product/Common/ProductEntityCard/ProductEntityCard';
import VariantProductDataSelector from '@xeris/pages/product/ProductDataSelector/VariantProductDataSelector';
import { type MasterProduct, type Product } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';
import { urls } from '@xeris/utilities';

import ConfigurableProductDataSelector from '../../../ProductDataSelector/ConfigurableProductDataSelector';
import IconSelectButton, {
    type HandleSelectActionType,
    type SelectedStatusType,
} from '../../IconSelectButton/IconSelectButton';
import type { EditActionTypesType, HandleEditActionType } from '../types';
import { getProductIds } from '../utilities';

import { DeleteConfigurationDialog } from './DeleteConfigurationDialog';
import EditConfigurationMenu from './EditConfigurationMenu';

type EditActionsProps = {
    productId: string;
    brandId: string;
    isConfigurable: boolean;
    selectedStatus: SelectedStatusType;
    handleEditAction: HandleEditActionType;
    setIsDataSelectionDialogOpen: (value: boolean) => void;
    isActionButtonsAlwaysVisible?: boolean;
};

const EditActions = ({
    productId,
    brandId,
    isConfigurable,
    selectedStatus,
    handleEditAction,
    isActionButtonsAlwaysVisible,
}: EditActionsProps): ReactElement | null => {
    const { t } = useTranslation('product');

    type ActionOptionsType = {
        type: EditActionTypesType;
        tooltip?: string;
        icon: ReactElement | null;
    };
    const actionLookup: Record<
        number,
        Record<SelectedStatusType, ActionOptionsType>
    > = {
        // !isConfigurable
        0: {
            unselected: { type: 'indeterminant', icon: null },
            partiallySelected: {
                type: 'edit',
                icon: <EditIcon />,
            },
            fullySelected: {
                type: 'edit',
                icon: <EditIcon />,
            },
        },
        // isConfigurable
        1: {
            unselected: {
                type: 'configure',
                tooltip: t('productCard.configure'),

                icon: <SettingsIcon />,
            },
            partiallySelected: {
                type: 'editMenu',
                icon: (
                    <EditConfigurationMenu
                        masterProductId={productId}
                        brandId={brandId}
                    />
                ),
            },
            fullySelected: {
                type: 'editMenu',
                icon: (
                    <EditConfigurationMenu
                        masterProductId={productId}
                        brandId={brandId}
                    />
                ),
            },
        },
    };
    const action = actionLookup[+isConfigurable][selectedStatus];
    if (action.type === 'editMenu') {
        return (
            <Box
                className={'action'}
                sx={{ opacity: isActionButtonsAlwaysVisible ? 1 : 0 }}
            >
                {action.icon}
            </Box>
        );
    }
    return (
        action.icon && (
            <Tooltip title={action.tooltip}>
                <MuiButton
                    variant={'outlinedSolid'}
                    onClick={(event): void =>
                        handleEditAction(event, action.type)
                    }
                    round
                    size={'large'}
                    className={'action'}
                    sx={{ opacity: isActionButtonsAlwaysVisible ? 1 : 0 }}
                >
                    {action.icon}
                </MuiButton>
            </Tooltip>
        )
    );
};

type ActionButtonsProps = {
    isActionButtonsAlwaysVisible?: boolean;
    masterProduct?:
        | (MasterProduct<'brand' | 'isConfigurable'> & {
              products: { id: string }[];
          })
        | null;
    product?: Product<'brand' | 'masterProduct'> | null;
};

const ActionButtons = ({
    masterProduct,
    product,
    isActionButtonsAlwaysVisible,
}: ActionButtonsProps): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation('product');

    const [isDataSelectionDialogOpen, setIsDataSelectionDialogOpen] =
        useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const activeDataSetId = useAppSelector(
        datasetSelectors.active.selectActiveId
    );

    const [removeProductEntitiesFromDataset] =
        datasetProductsApi.useRemoveProductsFromDatasetMutation();

    const productEntitySheetLink = urls.datasheet(masterProduct, product);

    const productIdList = useMemo(
        () => getProductIds(masterProduct, product),
        [masterProduct, product]
    );

    const configurableProductId = masterProduct?.isConfigurable
        ? masterProduct.id
        : undefined;

    const selectedStatus: SelectedStatusType = useGetSelectedStatus(
        productIdList,
        configurableProductId
    );

    const handleSelectAction: HandleSelectActionType = (
        event,
        selectAction
    ) => {
        event.stopPropagation();

        if (selectAction === 'add') {
            setIsDataSelectionDialogOpen(true);
        } else if (selectAction === 'remove') {
            removeProductEntitiesFromDataset({
                datasetId: activeDataSetId ?? '',
                productIds: productIdList,
                masterProductIds: configurableProductId
                    ? [configurableProductId]
                    : null,
            });
        }
    };

    const handleEditAction: HandleEditActionType = (
        event,
        actionType
    ): void => {
        event.stopPropagation();

        switch (actionType) {
            case 'edit':
                if (
                    configurableProductId &&
                    selectedStatus === 'partiallySelected'
                ) {
                    navigate(`${productEntitySheetLink}/Configure`, {
                        state: { page: 'selectData' },
                    });
                } else {
                    setIsDataSelectionDialogOpen(true);
                }
                return;
            case 'configure':
                navigate(`${productEntitySheetLink}/Configure`);
                return;
            case 'remove':
                handleSelectAction(event, 'remove');
                return;
            default:
                return;
        }
    };

    const productIds = useMemo(
        () =>
            masterProduct?.products.map(({ id }) => id) ??
            (product ? [product.id] : []),
        [masterProduct, product]
    );

    const handleSelectionClose = useCallback((): void => {
        setIsDataSelectionDialogOpen(false);
    }, [setIsDataSelectionDialogOpen]);

    return (
        <>
            <EditActions
                handleEditAction={handleEditAction}
                isConfigurable={!!configurableProductId}
                selectedStatus={selectedStatus}
                productId={masterProduct?.id ?? ''}
                brandId={masterProduct?.brand?.id ?? ''}
                setIsDataSelectionDialogOpen={setIsDataSelectionDialogOpen}
                isActionButtonsAlwaysVisible={isActionButtonsAlwaysVisible}
            />
            {/*TODO: Switch with SelectButton-component*/}
            <IconSelectButton
                handleSelectAction={handleSelectAction}
                selectedStatus={selectedStatus}
                showDeleteDialog={() => setDeleteDialogOpen(true)}
                masterProductId={masterProduct?.id ?? ''}
                isActionButtonsAlwaysVisible={isActionButtonsAlwaysVisible}
            />

            <DeleteConfigurationDialog
                isOpen={deleteDialogOpen}
                handleCloseDialog={() => setDeleteDialogOpen(false)}
                configurationSetName={t(
                    'common.deleteDialog.allConfigurations'
                )}
                masterProductId={masterProduct?.id ?? ''}
            />
            {isDataSelectionDialogOpen &&
                (masterProduct && masterProduct.isConfigurable ? (
                    <ConfigurableProductDataSelector
                        handleSelectionClose={handleSelectionClose}
                        masterProductId={masterProduct.id}
                    />
                ) : (
                    <VariantProductDataSelector
                        productIds={productIds}
                        handleSelectionClose={handleSelectionClose}
                    />
                ))}
        </>
    );
};

export default ActionButtons;
