import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import {
    ArrowLeftIcon,
    ArrowRightIcon,
    CloseFolderIcon,
} from '@xeris/components';
import { type FeatureGroup } from '@xeris/pages/product/types';

type MissingSelectionsProps = {
    isConfigurePage?: boolean;
    hasPreviousFeatureGroup: boolean;
    missingFeatures: {
        id: string;
        name: string;
        featureGroupId: string;
        optionIds: { id: string }[];
    }[];
    previousFeatureGroup: FeatureGroup;
};

const MissingSelections = ({
    isConfigurePage = false,
    hasPreviousFeatureGroup,
    missingFeatures,
    previousFeatureGroup,
}: MissingSelectionsProps): ReactElement | null => {
    const { t } = useTranslation('product');

    if (missingFeatures.length === 0) {
        return null;
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            paddingY={8}
        >
            <CloseFolderIcon fontSize="large" color="warning" />
            <Typography variant={'h3'}>
                {t('variantGenerator.missingSomething')}
            </Typography>
            <Typography variant={'subtitle2'}>
                {t('variantGenerator.selectFeaturesToComplete')}
            </Typography>
            <ul>
                {missingFeatures.map((missingFeature) => {
                    return (
                        <li key={missingFeature.id}>
                            <Link
                                to={{ hash: missingFeature.optionIds[0]?.id }}
                                state={{
                                    page: 'configure',
                                    featureGroup: missingFeature.featureGroupId,
                                }}
                            >
                                {missingFeature.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
            {hasPreviousFeatureGroup && (
                <Link
                    to={{
                        hash: previousFeatureGroup?.features[0]?.id,
                    }}
                    state={{
                        page: 'configure',
                        featureGroup: previousFeatureGroup?.id,
                    }}
                >
                    <Button
                        variant={'contained'}
                        sx={{ borderRadius: '50px' }}
                        startIcon={<ArrowLeftIcon />}
                    >
                        <span>
                            {t('variantGenerator.stages.back')}:{' '}
                            {previousFeatureGroup?.name}
                        </span>
                    </Button>
                </Link>
            )}

            {isConfigurePage && (
                <Link to={{}} state={{ page: 'summary' }}>
                    <Button
                        variant={'contained'}
                        sx={{ borderRadius: '50px' }}
                        endIcon={<ArrowRightIcon />}
                    >
                        {t('variantGenerator.nextSummary')}
                    </Button>
                </Link>
            )}
        </Stack>
    );
};

export default MissingSelections;
