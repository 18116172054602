import { type ReactElement } from 'react';

import { Collapse } from '@mui/material';

import { InfoBox, ShowMore } from '@xeris/components';
import { type Material } from '@xeris/pages/product/types';

import { MaterialSwatchWrapper } from './MaterialSwatchWrapper';

import styles from './MaterialWrapper.module.scss';

type MaterialOptionsProps = {
    isCollapsed: boolean;
    material: Material;
    featureId: string;
};

export const MaterialOptions = ({
    isCollapsed,
    featureId,
    material,
}: MaterialOptionsProps): ReactElement | null => {
    return (
        <Collapse in={!isCollapsed} unmountOnExit>
            <div className={styles.materialOptionsWrapper}>
                {!!material.description && (
                    <InfoBox>
                        <ShowMore
                            text={material.description}
                            maxCharacters={175}
                        />
                    </InfoBox>
                )}
                <div className={styles.materialOptions}>
                    {material.options.map((option) => (
                        <MaterialSwatchWrapper
                            key={option.id}
                            option={option}
                            featureId={featureId}
                        />
                    ))}
                </div>
            </div>
        </Collapse>
    );
};
