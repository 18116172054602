import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoginFormControl } from '..';

type UserNameInputProps = {
    value: string;
    handleChange?: (userName: string) => void;
    disabled?: boolean;
    showAdornment?: boolean;
    adornment?: ReactElement;
};

const UserNameInput = ({
    value,
    handleChange,
    disabled,
    showAdornment,
    adornment,
}: UserNameInputProps): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <LoginFormControl
            autoComplete="username"
            autoFocus={true}
            formId="outlined-adornment-email"
            formLabel={t('common.email')}
            formType="email"
            formValue={value}
            name="email"
            required={true}
            setFunction={handleChange}
            disabled={disabled}
            showAdornment={showAdornment}
            dataTestId="username-input"
            adornment={adornment}
        />
    );
};

export default UserNameInput;
