import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Typography,
} from '@mui/material';

import { ProductListItem } from '@xeris/components';

import { type MasterProductWithIds } from '../types';

type ProductSelectorProps = {
    open: boolean;
    handleClose: () => void;
    masterProductWithIds: MasterProductWithIds | null;
};

export const VariantDrawer = ({
    open,
    handleClose,
    masterProductWithIds,
}: ProductSelectorProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    if (!masterProductWithIds) return null;

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}
        >
            <DialogTitle>{masterProductWithIds.name}</DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px', width: '100vw' }}>
                <Box>
                    {masterProductWithIds.products.map((product) => (
                        <ProductListItem
                            key={product.id}
                            images={product.images}
                        >
                            <Typography>{product.name}</Typography>
                        </ProductListItem>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('common.close')}</Button>
            </DialogActions>
        </Drawer>
    );
};
