import { useCallback } from 'react';

import {
    settingsActions,
    settingsSelectors,
} from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

const featureFlags = [
    { key: 'shareDataset', name: 'Share dataset' },
    { key: 'ecoSearch', name: 'Eco search' },
    { key: 'brandVisibility', name: 'Brand visibility' },
    { key: 'connections', name: 'Connections' },
    { key: 'newImport', name: 'New import' },
] as const;

const featureFlagKeys = featureFlags.map(({ key }) => key);

type FeatureId = (typeof featureFlagKeys)[number];

export const useAllFeatureFlags = (): {
    allFeatureFlags: { featureId: FeatureId; value: boolean; name: string }[];
    allFeatureFlagsVisible: boolean;
    toggleFeatureFlag: (featureId: FeatureId) => void;
    toggleAllFeatureFlags: (value: boolean) => void;
} => {
    const dispatch = useAppDispatch();

    const allFeatureFlags = useAppSelector((state) =>
        settingsSelectors.selectAllFeatureFlags(state, featureFlagKeys)
    );

    const toggleFeatureFlag = useCallback(
        (featureId: FeatureId) => {
            dispatch(settingsActions.toggleFeatureFlag(featureId));
        },
        [dispatch]
    );

    const toggleAllFeatureFlags = useCallback(
        (value: boolean) => {
            dispatch(
                settingsActions.setFeatureFlags({
                    featureIds: featureFlagKeys,
                    value,
                })
            );
        },
        [dispatch]
    );

    return {
        allFeatureFlags: featureFlags.map(({ key, name }) => ({
            featureId: key,
            value: allFeatureFlags[key],
            name: name,
        })),
        allFeatureFlagsVisible: Object.values(allFeatureFlags).every(
            (featureStatus) => featureStatus
        ),
        toggleFeatureFlag,
        toggleAllFeatureFlags,
    };
};

type Return = [boolean, (featureId: FeatureId) => void];

export const useFeatureFlag = (featureId: FeatureId): Return => {
    const dispatch = useAppDispatch();

    const featureFlagVisible = useAppSelector((state) =>
        settingsSelectors.selectFeatureFlag(state, featureId)
    );

    const toggleFeatureFlag = useCallback(
        (featureId: FeatureId) => {
            dispatch(settingsActions.toggleFeatureFlag(featureId));
        },
        [dispatch]
    );

    return [featureFlagVisible, toggleFeatureFlag];
};
