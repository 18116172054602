import { type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { EditIcon } from '@xeris/components';

import type { AccessRuleType } from '../types';

type ProductInfoProps = {
    activeType: AccessRuleType | null;
    setShowEdit: Dispatch<SetStateAction<'filter' | 'products' | null>>;
    tradingPartnerName: string;
};

export const ProductInfo = ({
    activeType,
    setShowEdit,
    tradingPartnerName,
}: ProductInfoProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Typography variant={'subtitle1'}>
                    {t('connections.brandAccess.products')}
                </Typography>
                {activeType === 'BrandProductAccessRule' && (
                    <Button
                        startIcon={<EditIcon />}
                        size={'small'}
                        onClick={() => setShowEdit('products')}
                    >
                        {t('connections.brandAccess.editProductAccess')}
                    </Button>
                )}
            </Box>
            <Typography gutterBottom>
                {t('connections.brandAccess.accessToProducts', {
                    tradingPartnerName,
                })}
            </Typography>
        </>
    );
};
