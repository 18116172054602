import { type ReactElement, type ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Divider,
    FormControl,
    FormHelperText,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    type SelectProps,
    Typography,
} from '@mui/material';

import { InputLabelTop } from '@xeris/components/forms';

type Option = {
    id: string;
    isDivider?: boolean;
    disabled?: boolean;
    label?: string;
    secondaryLabel?: ReactNode;
};

type SelectInputProps<TForm extends FieldValues, TOption extends Option> = {
    fieldName: Path<TForm>;
    label?: ReactNode;
    showError?: boolean;
    control: Control<TForm>;
    size?: SelectProps['size'];
    optionLabelField?: keyof TOption;
    disabled?: boolean;
    options: TOption[];
    errorMap?: { [key: string]: ReactNode };
};

export const SelectInput = <TForm extends FieldValues, TOption extends Option>({
    fieldName,
    label,
    showError = false,
    control,
    size,
    optionLabelField = 'label',
    disabled,
    options,
    errorMap,
}: SelectInputProps<TForm, TOption>): ReactElement => {
    const { t } = useTranslation('common');

    const {
        field: { ...field },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <FormControl fullWidth disabled={disabled} error={!!error}>
            <InputLabelTop
                labelPlacement={'top'}
                label={label}
                control={
                    <Select
                        size={size}
                        fullWidth
                        {...field}
                        value={
                            options.find((option) => option.id === field.value)
                                ?.id ?? ''
                        }
                        renderValue={(value) =>
                            options
                                .find((option) => option.id === value)
                                ?.[optionLabelField]?.toString() ?? value
                        }
                    >
                        {options.map((option) =>
                            option.isDivider ? (
                                <Divider key={option.id} />
                            ) : (
                                <MenuItem
                                    key={option.id}
                                    disabled={option.disabled}
                                    value={option.id}
                                    data-testid="selectMenuItem"
                                >
                                    <ListItem
                                        component={'div'}
                                        sx={{ padding: 0 }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="inherit"
                                                    noWrap
                                                >
                                                    {(
                                                        option[
                                                            optionLabelField
                                                        ] ?? option.id
                                                    )?.toString()}
                                                </Typography>
                                            }
                                            secondary={option.secondaryLabel}
                                            secondaryTypographyProps={{
                                                variant: 'caption',
                                            }}
                                        />
                                    </ListItem>
                                </MenuItem>
                            )
                        )}
                    </Select>
                }
            ></InputLabelTop>
            {!!(showError && error?.type) &&
                (errorMap && errorMap[error.type] ? (
                    errorMap[error.type]
                ) : (
                    <FormHelperText>
                        {error?.type &&
                            t(`formErrors.${error.type}`, {
                                defaultValue: '',
                                fieldName: fieldName,
                            })}
                    </FormHelperText>
                ))}
        </FormControl>
    );
};
