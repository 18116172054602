const isShallowEqual = <T, K extends T>(obj1: T, obj2: K): boolean => {
    for (const key in obj1) {
        const obj1Value = obj1[key];
        const obj2Value = obj2[key];

        if (obj1Value !== obj2Value) return false;
    }

    return true;
};

export default isShallowEqual;
