import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CheckIcon,
    CloseIcon,
    PlusIcon,
    SelectButton as SelectButtonComponent,
    TextAndIcon,
} from '@xeris/components';

import { type HandleSelectModalType } from '../MultipleMasterProductsSelector';

import styles from './SelectActions.module.scss';

type SelectButtonVariantProps = {
    className?: string;
    selectedCount: number;
    totalCount: number;
    handleSelectModal: HandleSelectModalType;
};

const RegularSelectButton = ({
    selectedCount,
    totalCount,
    handleSelectModal,
}: SelectButtonVariantProps): ReactElement => {
    const { t } = useTranslation('product');
    if (selectedCount === 0) {
        return (
            <Button
                onClick={(): void => {
                    handleSelectModal('add');
                }}
                shape="rounded"
                variant="outlined"
                minWidth={150}
                minHeight={40}
                dataTestId="selectMultipleMasterproductsButton"
                startIcon={<PlusIcon />}
            >
                {t('dataSelector.addAll', {
                    count: totalCount,
                })}
            </Button>
        );
    }

    const isAllSelected = selectedCount === totalCount;

    const counter = isAllSelected
        ? selectedCount
        : t('dataSelector.numberOfNumber', {
              numSelected: selectedCount,
              total: totalCount,
          });

    return (
        <Button
            className={styles.selectActions}
            onClick={(): void => {
                handleSelectModal('remove');
            }}
            shape="rounded"
            variant={isAllSelected ? 'solid' : 'solidNeutral'}
            minWidth={200}
            minHeight={40}
        >
            <>
                <TextAndIcon
                    className={styles.showOnHover}
                    justifyContent="center"
                    leftIcon={<CloseIcon />}
                    text={t('dataSelector.remove', { counter })}
                />
                <TextAndIcon
                    className={styles.hideOnHover}
                    justifyContent="center"
                    leftIcon={<CheckIcon />}
                    text={t('dataSelector.inDataset', { counter })}
                />
            </>
        </Button>
    );
};

const IconSelectButton = ({
    className,
    selectedCount,
    totalCount,
    handleSelectModal,
}: SelectButtonVariantProps): ReactElement => {
    return (
        <SelectButtonComponent
            className={className}
            isSelected={selectedCount === totalCount}
            isPartiallySelected={selectedCount > 0}
            onToggleOption={(event) => {
                event.stopPropagation();

                if (selectedCount === 0) {
                    return handleSelectModal('add');
                }

                return handleSelectModal('remove');
            }}
        />
    );
};

type SelectButtonProps = {
    className?: string;
    handleSelectModal: HandleSelectModalType;
    totalCount: number;
    selectedCount: number;
    showIconVersion: boolean;
};

const SelectButton = ({
    className,
    handleSelectModal,
    totalCount,
    selectedCount,
    showIconVersion,
}: SelectButtonProps): ReactElement => {
    if (showIconVersion) {
        return (
            <IconSelectButton
                className={className}
                selectedCount={selectedCount}
                totalCount={totalCount}
                handleSelectModal={handleSelectModal}
            />
        );
    }
    return (
        <RegularSelectButton
            className={className}
            selectedCount={selectedCount}
            totalCount={totalCount}
            handleSelectModal={handleSelectModal}
        />
    );
};

export default SelectButton;
