import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
    Alert,
    Box,
    Chip,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { BackButton, EditIcon, ErrorPage, SelectMenu } from '@xeris/components';
import { brandApi } from '@xeris/pages/admin/Brands/api';
import { accessApi } from '@xeris/pages/admin/Connections/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

export const ConnectionDetails = (): ReactElement => {
    const { t } = useTranslation('administration');
    const { tradingPartnerId = '' } = useParams();
    const navigate = useNavigate();
    const organizationId = useActiveOrganizationId();

    const { data: brandData } = brandApi.useGetAdminBrandsForOrganizationQuery({
        organizationId: organizationId ?? '',
    });

    const {
        data: accessData,
        isError,
        isLoading,
    } = accessApi.useGetBrandAccessPoliciesQuery({
        consumerId: tradingPartnerId,
        producerId: organizationId ?? '',
    });

    const data =
        brandData?.organization?.brands.map((brand) => ({
            ...brand,
            access:
                accessData?.brandAccessPolicies.find(
                    (policy) => policy.brand.id === brand.id
                ) ?? null,
        })) ?? [];

    if (isLoading) {
        return <Skeleton height={400} />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('common.errors.failedToLoad')}
                to={'..'}
                actionText={t('connections.goBackToAll')}
            />
        );
    }

    return (
        <Box sx={{ maxWidth: '1440px', margin: '0 auto' }}>
            <BackButton to={'..'}>{t('connections.allConnections')}</BackButton>
            <Box
                display={'flex'}
                gap={1}
                alignItems={'center'}
                marginBottom={2}
                marginTop={1}
            >
                <Typography variant={'h1'}>
                    {accessData?.tradingPartner?.name}
                </Typography>
                <Chip
                    size={'small'}
                    label={'Connection'}
                    sx={{ marginLeft: 1 }}
                />
            </Box>
            {data.length === 0 ? (
                <Alert severity={'info'}>{t('connections.noBrands')}</Alert>
            ) : (
                <DataGrid
                    rows={data}
                    disableColumnMenu
                    pagination={undefined}
                    hideFooter
                    disableRowSelectionOnClick
                    columns={[
                        {
                            field: 'name',
                            headerName: t('connections.brandName'),
                            flex: 1,
                            renderCell: ({ row }) => (
                                <Typography
                                    component={Link}
                                    to={`Brands/${row.id}`}
                                    noWrap
                                >
                                    {row.name}
                                </Typography>
                            ),
                        },
                        {
                            field: 'type',
                            headerName: t('connections.accessType'),
                            width: 150,
                            valueGetter: (value, row) =>
                                t(
                                    `connections.accessTypes.${
                                        row.access?.rule[0]?.__typename ??
                                        'None'
                                    }.title`
                                ),
                        },
                        {
                            field: 'count',
                            headerName: t('connections.productAccess'),
                            width: 150,
                            valueGetter: (_, row) =>
                                `${row.access?.productCount ?? 0} / ${row.productCounts.products}`,
                        },
                        {
                            field: 'id',
                            headerName: ' ',
                            align: 'right',
                            sortable: false,
                            width: 40,
                            renderCell: ({ row }) => (
                                <SelectMenu
                                    menuItemList={[
                                        {
                                            key: 'edit',
                                            handleClick: () =>
                                                navigate(`Brands/${row.id}`),
                                            content: (
                                                <>
                                                    <ListItemIcon>
                                                        <EditIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t(
                                                            'connections.changeAccess'
                                                        )}
                                                    />
                                                </>
                                            ),
                                        },
                                    ]}
                                    ariaLabel={t(
                                        'connections.openActionsMenuFor',
                                        {
                                            tradingPartner: row.name,
                                        }
                                    )}
                                />
                            ),
                        },
                    ]}
                />
            )}
        </Box>
    );
};
