import { type ReactElement, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListItemIcon, ListItemText } from '@mui/material';

import { type TFunction } from 'i18next';

import {
    DeleteIcon,
    EditIcon,
    SelectMenu,
    SettingsIcon,
} from '@xeris/components';
import { type SelectMenuItemType } from '@xeris/components/SelectMenu/SelectMenu';

type Action = 'editBrand' | 'goToBrandSettings' | 'deleteBrand';

type Brand = {
    id: string;
    name: string;
};

const getActionMenuList = (
    t: TFunction<'administration'>
): SelectMenuItemType<Action>[] => {
    return [
        {
            key: 'editBrand',
            value: 'editBrand',
            content: (
                <>
                    <ListItemIcon>
                        <EditIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={t('brand.editBrand.editBrand')} />
                </>
            ),
        },
        {
            key: 'goToBrandSettings',
            value: 'goToBrandSettings',
            content: (
                <>
                    <ListItemIcon>
                        <SettingsIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={t('brand.common.brandSettings')} />
                </>
            ),
        },
        {
            key: 'deleteBrand',
            value: 'deleteBrand',
            content: (
                <>
                    <ListItemIcon>
                        <DeleteIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('brand.deleteBrand.deleteBrand')}
                    />
                </>
            ),
        },
    ];
};

type BrandOwnerMenuProps = {
    brand: Brand;
    setSelectedBrand: (brand: Brand) => void;
    setSelectedDeleteBrand: (brand: Brand) => void;
};

const BrandActionsMenu = ({
    brand,
    setSelectedBrand,
    setSelectedDeleteBrand,
}: BrandOwnerMenuProps): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation('administration');

    const handleMenuAction = (event: SyntheticEvent, value?: Action): void => {
        event.stopPropagation();
        if (value === 'editBrand') {
            setSelectedBrand(brand);
        }
        if (value === 'goToBrandSettings') {
            navigate(`/Admin/Brands/${brand.id}`);
        }
        if (value === 'deleteBrand') {
            setSelectedDeleteBrand(brand);
        }
    };

    const actionMenuList = getActionMenuList(t);

    return (
        <>
            <SelectMenu
                menuItemList={actionMenuList}
                handleSelect={handleMenuAction}
                dataTestId={`brand-owner-menu-button-${brand.id}`}
                ariaLabel={t('brand.openBrandActionsMenu', {
                    brandName: brand.name,
                })}
            />
        </>
    );
};

export default BrandActionsMenu;
