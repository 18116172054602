import {
    type ReactElement,
    type SyntheticEvent,
    useMemo,
    useState,
} from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';

import {
    CloseIcon,
    FormattedPrice,
    IconButton,
    PreviewButton,
    SortAscDescIcon,
} from '@xeris/components';
import {
    type Material,
    type MaterialFeature,
} from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { selectOptions, variantGeneratorSelectors } from '../../../reducer';

import MaterialOptions from './MaterialOptions';

import styles from './MaterialPanel.module.scss';

type SortOrderType = 'Ascending' | 'Descending' | 'Unsorted';

type MaterialRowProps = {
    material: Material;
    featureId: string;
};

type MaterialPanelProps = {
    feature: MaterialFeature;
};

type HandleToggleAllOptionsType = (event: SyntheticEvent) => void;

const sortAlphabetical = (a?: string | number, b?: string | number): number =>
    (a ?? 0) > (b ?? 0) ? 1 : -1;

const HEADER_LOOKUP = {
    preview: {
        content: '',
        align: 'left',
        sortFn: undefined,
    },
    name: {
        content: 'Name',
        align: 'left',
        sortFn: (a: Material, b: Material): number =>
            sortAlphabetical(a.name, b.name),
    },
    supplier: {
        content: 'Supplier',
        align: 'left',
        sortFn: (a: Material, b: Material): number =>
            sortAlphabetical(a.supplier?.name, b.supplier?.name),
    },
    counter: {
        content: 'Selected',
        align: 'left',
        sortFn: undefined,
    },
    price: {
        content: 'Price',
        align: 'center',
        sortFn: (a: Material, b: Material): number =>
            sortAlphabetical(a.price?.value, b.price?.value),
    },
    close: {
        content: '',
        align: 'left',
        sortFn: undefined,
    },
} as const;

const HEADER = [
    'preview',
    'name',
    'supplier',
    'counter',
    'price',
    'close',
] as const;

type HeaderColumnType = (typeof HEADER)[number];

const MaterialRow = ({
    material,
    featureId,
}: MaterialRowProps): ReactElement | null => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const optionIds = useMemo(
        () => material.options.map(({ id }) => id),
        [material.options]
    );

    const { isPreviewed, isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatuses(state, featureId, optionIds)
    );

    const handleMaterialClick = (): void => {
        setIsExpanded((value) => !value);
    };

    const handleToggleAllOptions: HandleToggleAllOptionsType = (event) => {
        event.stopPropagation();

        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: material.options.map((option) => option.id),
                newValue: false,
            })
        );
    };

    if (!isSelected) {
        return null;
    }

    const tableRows = [
        <TableRow
            key={material.id}
            sx={{ '&:hover ': { '.preview': { opacity: 1 } } }}
        >
            <TableCell className={styles.preview}>
                <PreviewButton
                    className={'preview'}
                    size={'small'}
                    sx={{ opacity: isPreviewed ? 1 : 0 }}
                    isPreviewActive={isPreviewed}
                    onSelectPreview={(): void => handleMaterialClick()}
                />
            </TableCell>
            <TableCell
                className={`${styles.name} ${styles.pointer}`}
                onClick={(): void => handleMaterialClick()}
            >
                {material.name}
            </TableCell>
            <TableCell className={styles.supplier}>
                {material.supplier && material.supplier.name}
            </TableCell>
            <TableCell className={styles.counter}>
                {`${material.options.length}`}
            </TableCell>
            <TableCell className={styles.price}>
                <FormattedPrice
                    value={material.price?.value ?? null}
                    currency={material.price?.currency}
                />
            </TableCell>
            <TableCell>
                <IconButton onClick={handleToggleAllOptions} size="large">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </TableCell>
        </TableRow>,
    ];

    if (isExpanded) {
        tableRows.push(
            <TableRow key={`expanded-${material.id}`}>
                <TableCell colSpan={HEADER.length}>
                    <MaterialOptions
                        featureId={featureId}
                        options={material.options}
                    />
                </TableCell>
            </TableRow>
        );
    }

    return <>{tableRows}</>;
};

const MaterialPanel = ({
    feature,
}: MaterialPanelProps): ReactElement | null => {
    const [headerSortingId, setHeaderSortingId] =
        useState<HeaderColumnType>('name');
    const [sortingDirection, setSortingDirection] =
        useState<SortOrderType>('Ascending');

    const { isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    if (!isSelected) {
        return null;
    }

    const handleSortClick = (headerId: HeaderColumnType): void => {
        if (headerId === headerSortingId) {
            if (sortingDirection === 'Descending') {
                setSortingDirection('Ascending');
            } else {
                setSortingDirection('Descending');
            }
        } else {
            setHeaderSortingId(headerId);
            if (sortingDirection !== 'Ascending')
                setSortingDirection('Ascending');
        }
    };

    const selectedHeader = HEADER_LOOKUP[headerSortingId];
    const sortedMaterials = Object.values(feature.materials);

    if (selectedHeader && selectedHeader.sortFn) {
        sortedMaterials.sort(selectedHeader.sortFn);
        if (sortingDirection === 'Descending') sortedMaterials.reverse();
    }

    const tableHeader = HEADER.map((headerKey) => {
        const { sortFn, content, align } = HEADER_LOOKUP[headerKey];

        const cellClassNames: string[] = [headerKey];
        if (sortFn) cellClassNames.push('pointer');

        return (
            <TableCell
                key={headerKey}
                className={cellClassNames.join(' ')}
                align={align}
            >
                <TableSortLabel
                    hideSortIcon
                    onClick={
                        sortFn
                            ? (): void => handleSortClick(headerKey)
                            : undefined
                    }
                >
                    {content}
                    {headerSortingId === headerKey && (
                        <SortAscDescIcon
                            className={styles.sortAscDescIcon}
                            sortOrder={sortingDirection}
                            fontSize={'small'}
                            handleClick={(): void => {}}
                        />
                    )}
                </TableSortLabel>
            </TableCell>
        );
    });

    return (
        <div className={styles.materialPanel}>
            <span className={styles.featureName}>{feature.name}</span>

            <div className={styles.featureOptionList}>
                <Table>
                    <TableHead>
                        <TableRow>{tableHeader}</TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedMaterials.map((material) => (
                            <MaterialRow
                                key={material.id}
                                featureId={feature.id}
                                material={material}
                            />
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default MaterialPanel;
