import {
    loginFailed,
    loginSuccess,
    type LoginType,
    refreshStarted,
} from '@xeris/pages/login/reducers';
import { type AppDispatch } from '@xeris/reducers';

import { loginUrl } from './constants';

const refreshLoginToken = async (dispatch: AppDispatch): Promise<void> => {
    dispatch(refreshStarted());

    const response = await fetch(loginUrl, {
        method: 'get',
    });

    if (!response.ok) {
        const error = await response.text();

        dispatch(loginFailed({ noCredentials: error.includes('No cookie') }));

        return;
    }

    const refreshLogin: LoginType = await response.json();

    dispatch(loginSuccess(refreshLogin));
};

export default refreshLoginToken;
