import { type ReactElement, type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText } from '@mui/material';

import { impersonate } from '@xeris/api';
import {
    AccountRemoveIcon,
    EmailIcon,
    ImpersonateUserIcon,
    ResetPasswordIcon,
    SelectMenu,
} from '@xeris/components';
import { userApi } from '@xeris/pages/admin/api';
import { useAppDispatch } from '@xeris/reducers';

import DeleteUserDialog from './DeleteUserDialog';

type User = {
    id: string;
    username: string;
    registrationStatus: string;
};

type UserActionsMenuProps<T extends User> = {
    user: T | null;
    canImpersonate?: boolean;
    resetUserPassword: (username: string) => void;
    setResendInviteUser: (user: T) => void;
};

const UserActionsMenu = <T extends User>({
    user,
    canImpersonate = false,
    resetUserPassword,
    setResendInviteUser,
}: UserActionsMenuProps<T>): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('administration');

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);

    const [deleteUserMutation] = userApi.useDeleteUserMutation();

    if (!user) {
        return null;
    }

    const handleResetPassword = (): void => {
        resetUserPassword(user.username);
    };

    const handleDeleteUser = (event: SyntheticEvent): void => {
        event.stopPropagation();
        setIsDeleteDialogOpen(true);
    };
    const handleConfirmDeleteUser = (): void => {
        deleteUserMutation({ username: user.username });
        setIsDeleteDialogOpen(false);
    };

    const handleImpersonateUser = (): void => {
        // TODO: Move to separate API
        // @ts-expect-error
        dispatch(impersonate(user.id));
    };

    const userMenuItems = [];

    if (
        user.registrationStatus === 'Invited' ||
        user.registrationStatus === 'Added'
    ) {
        userMenuItems.push({
            key: 'resendInvite',
            dataTestId: 'resendInvite',
            handleClick: () => setResendInviteUser(user),
            content: (
                <>
                    <ListItemIcon>
                        <EmailIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText>{t('users.resendInvite')}</ListItemText>
                </>
            ),
        });
    } else {
        userMenuItems.push({
            key: 'resetPassword',
            dataTestId: 'resetPassword',
            handleClick: handleResetPassword,
            content: (
                <>
                    <ListItemIcon>
                        <ResetPasswordIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText>{t('users.resetPassword')}</ListItemText>
                </>
            ),
        });
    }

    if (canImpersonate) {
        userMenuItems.push({
            key: 'impersonateUser',
            dataTestId: 'impersonateUser',
            handleClick: handleImpersonateUser,
            content: (
                <>
                    <ListItemIcon>
                        <ImpersonateUserIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText>{t('users.impersonateUser')}</ListItemText>
                </>
            ),
        });
    }

    userMenuItems.push({
        key: 'deleteUser',
        dataTestId: 'deleteUser',
        handleClick: handleDeleteUser,
        content: (
            <>
                <ListItemIcon>
                    <AccountRemoveIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText>{t('users.deleteUser')}</ListItemText>
            </>
        ),
    });

    return (
        <>
            <SelectMenu
                menuItemList={userMenuItems}
                dataTestId={user.username}
            />
            <DeleteUserDialog
                user={user}
                isOpen={isDeleteDialogOpen}
                handleClose={(): void => setIsDeleteDialogOpen(false)}
                handleConfirm={handleConfirmDeleteUser}
            />
        </>
    );
};

export default UserActionsMenu;
