import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedPrice } from '@xeris/components';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import {
    type PriceType,
    type ProductData,
} from '@xeris/pages/product/types/product';
import { useAppSelector } from '@xeris/reducers';

type GetPriceProps = {
    price: PriceType | null;
    prices: PriceType[];
    marketId: string | null;
};

const getPrice = ({
    price,
    prices,
    marketId,
}: GetPriceProps): PriceType | null => {
    if (!price) {
        return null;
    }
    if (!marketId) {
        return price;
    }

    return prices.find((price) => price.markets?.includes(marketId)) ?? price;
};

type PriceProps = {
    productEntity: ProductData<'brand' | 'price' | 'prices'>;
};

const Price = ({ productEntity }: PriceProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const selectedExternalMarketId = useAppSelector((state) =>
        settingsSelectors.selectExternalMarket(state, productEntity.brand.id)
    );

    const price = getPrice({
        price: productEntity.price,
        prices: productEntity.prices,
        marketId: selectedExternalMarketId,
    });

    if (!price) {
        return null;
    }

    return (
        <>
            <FormattedPrice currency={price.currency} value={price.value} />
            <small>
                {price.priceUnit
                    ? t(`priceUnits.${price.priceUnit}`, price.priceUnit)
                    : null}
            </small>
        </>
    );
};

export default Price;
