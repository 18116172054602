import { type ReactElement } from 'react';

import { Box } from '@mui/material';

import { Image, Typography } from '@xeris/components';

type MaterialSwitchTooltipProps = {
    optionName: string;
    image: string | null;
    description?: string | null;
    colorCode?: string | null;
};

const MaterialSwitchTooltip = ({
    optionName,
    image,
    description,
    colorCode,
}: MaterialSwitchTooltipProps): ReactElement | null => {
    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <Image
                alt={''}
                src={image ?? undefined}
                style={{
                    borderRadius: '12px 12px 20px 20px',
                    width: '100%',

                    aspectRatio: '1',
                    objectFit: 'cover',
                    overflow: 'hidden',
                }}
            />
            <Typography
                variant={'h6'}
                component={'p'}
                title={optionName}
                gutterBottom
                paddingTop={1}
            >
                {optionName}
            </Typography>
            <Typography variant={'body2'} gutterBottom>
                {description}
            </Typography>
            <Typography variant={'body1'}>{colorCode}</Typography>
        </Box>
    );
};

export default MaterialSwitchTooltip;
