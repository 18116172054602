import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Toolbar, Typography } from '@mui/material';
import { GridActionsCellItem, type GridRowParams } from '@mui/x-data-grid';

import { type TFunction } from 'i18next';

import {
    DataGrid,
    DeleteIcon,
    EditIcon,
    ErrorPage,
    SearchBar,
    type TypeSafeColDef,
} from '@xeris/components';
import { organizationsApi } from '@xeris/pages/admin/api';
import { type ApiSubscriptionType } from '@xeris/types';

import { AddOrganization } from '../forms/AddOrganization/AddOrganization';
import { DeleteOrganization } from '../forms/DeleteOrganization/DeleteOrganization';
import { EditOrganization } from '../forms/EditOrganization/EditOrganization';
import { type OrganizationType } from '../types/organizationsTypes';

type RowData = OrganizationType & {
    brandCount: number;
    subscriptionType: ApiSubscriptionType;
    connectionCount: number;
};

const getColumns = (
    t: TFunction<'administration'>,
    setSelectedOrganization: (organization: OrganizationType) => void,
    setSelectedDeleteOrganization: (organization: OrganizationType) => void
): TypeSafeColDef<RowData>[] => [
    {
        field: 'name',
        headerName: t('organization.name'),
        flex: 1,
        minWidth: 200,
    },
    {
        field: 'id',
        headerName: t('organization.id'),
        width: 200,
    },
    {
        field: 'brandCount',
        headerName: t('organization.brands'),
        width: 120,
    },
    {
        field: 'subscriptionType',
        headerName: t('organization.subscriptionPlan'),
        width: 160,
    },
    {
        field: 'type',
        headerName: t('organization.type'),
        width: 180,
    },
    {
        field: 'connectionCount',
        headerName: t('organization.connections'),
        width: 160,
        renderCell: (params): ReactElement => {
            return (
                <div>
                    {params.value} {' / '}
                    {params.row.subscriptionPlan?.totalConnectionsCount ?? 0}
                </div>
            );
        },
    },
    {
        field: 'actions',
        headerName: ' ',
        sortable: false,
        width: 80,
        type: 'actions',
        getActions: ({ row }: GridRowParams<RowData>) => [
            <GridActionsCellItem
                key={'edit'}
                label={t('organization.editOrganization.editOrganization')}
                onClick={() => setSelectedOrganization(row)}
                icon={<EditIcon fontSize={'small'} />}
                showInMenu
            />,
            <GridActionsCellItem
                key={'delete'}
                label={t('organization.deleteOrganization.deleteOrganization')}
                onClick={() => setSelectedDeleteOrganization(row)}
                icon={<DeleteIcon fontSize={'small'} />}
                showInMenu
            />,
        ],
    },
];

export const OrganizationList = (): ReactElement | null => {
    const { t } = useTranslation(['administration', 'common']);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState<
        OrganizationType | undefined
    >(undefined);
    const [selectedDeleteOrganization, setSelectedDeleteOrganization] =
        useState<OrganizationType | undefined>(undefined);

    const { data, isError, isLoading, refetch } =
        organizationsApi.useGetOrganizationsQuery({});

    if (isLoading) {
        return <Skeleton height={300} />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('apiErrors.anErrorOccurred', { ns: 'common' })}
                onClick={refetch}
                actionText={t('apiErrors.tryAgain', { ns: 'common' })}
            />
        );
    }

    if (!data) {
        return <ErrorPage title={t('apiErrors.notFound', { ns: 'common' })} />;
    }

    const organizations: RowData[] = data.organizations.map((organization) => ({
        ...organization,
        subscriptionType:
            organization.subscriptionPlan?.subscriptionType ?? 'FREE',
        brandCount: organization.brands.length,
        connectionCount:
            organization.feeds.length + organization.channels.length,
    }));

    return (
        <div>
            <Typography variant={'h1'} gutterBottom>
                {t('organization.organizations', { ns: 'administration' })}
            </Typography>
            <Toolbar disableGutters>
                <SearchBar
                    filter={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                    inputLabel={t('search', { ns: 'common' })}
                />
                <Box sx={{ flexGrow: 1 }} />
                <AddOrganization />
            </Toolbar>

            <DataGrid
                rows={organizations}
                columns={getColumns(
                    t,
                    setSelectedOrganization,
                    setSelectedDeleteOrganization
                )}
                disableRowSelectionOnClick
                disableColumnFilter={true}
                ignoreDiacritics={true}
                filterModel={{
                    items: [
                        {
                            field: 'name',
                            operator: 'contains',
                            value: searchTerm,
                        },
                    ],
                }}
            />
            <EditOrganization
                isOpen={!!selectedOrganization}
                organizationData={selectedOrganization}
                handleClose={() => setSelectedOrganization(undefined)}
            />
            <DeleteOrganization
                isOpen={!!selectedDeleteOrganization}
                handleClose={() => setSelectedDeleteOrganization(undefined)}
                organization={selectedDeleteOrganization}
            />
        </div>
    );
};
