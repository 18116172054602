import { type ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@mui/material';

import { ErrorPage } from '@xeris/components';
import { accessApi } from '@xeris/pages/admin/Connections/api';
import { VariantDrawer } from '@xeris/pages/admin/Connections/pages/BrandAccess/components/VariantDrawer';
import type {
    BrandGroupMarketAccessRule,
    BrandProductAccessRule,
    MasterProductWithIds,
} from '@xeris/pages/admin/Connections/pages/BrandAccess/types';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import { GroupedProductList } from '@xeris/pages/product/Common';

import { FilterInfo } from './components/FilterInfo';
import { Header } from './components/Header';
import { InactiveProductCard } from './components/InactiveProductCard';
import { InactiveProductList } from './components/InactiveProductList';
import { ProductInfo } from './components/ProductInfo';
import { TypeInfo } from './components/TypeInfo';
import { FilterSelector } from './forms/AccessSelector/FilterSelector';
import { ProductSelector } from './forms/AccessSelector/ProductSelector';
import { TypeSelector } from './forms/TypeSelector/TypeSelector';

export const BrandAccess = (): ReactElement => {
    const { t } = useTranslation(['product', 'common']);
    const { tradingPartnerId = '', brandId = '' } = useParams();
    const organizationId = useActiveOrganizationId();

    const [showChangeType, setShowChangeType] = useState(false);
    const [showEdit, setShowEdit] = useState<'products' | 'filter' | null>(
        null
    );
    const [masterProductToShow, setMasterProductToShow] =
        useState<MasterProductWithIds | null>(null);
    const [showMasterProduct, setShowMasterProduct] = useState(false);

    const { data, isLoading, isError } = accessApi.useGetBrandAccessPolicyQuery(
        {
            consumerId: tradingPartnerId,
            producerId: organizationId ?? '',
            brandId: brandId,
        }
    );

    const activeType = data?.brandAccessPolicy?.rule[0]?.__typename ?? null;

    const filterRule = useMemo(
        () =>
            data?.brandAccessPolicy?.rule.find(
                (rule): rule is BrandGroupMarketAccessRule =>
                    rule.__typename === 'BrandGroupMarketAccessRule'
            ) ?? null,
        [data]
    );

    const productRule = useMemo(
        () =>
            data?.brandAccessPolicy?.rule.find(
                (rule): rule is BrandProductAccessRule =>
                    rule.__typename === 'BrandProductAccessRule'
            ) ?? null,
        [data]
    );

    const ruleIds = useMemo(
        () => data?.brandAccessPolicy?.rule.map(({ id }) => id) ?? [],
        [data]
    );

    if (isLoading) {
        return <Skeleton height={400} />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('apiErrors.anErrorOccurred', { ns: 'common' })}
            />
        );
    }

    if (!data || !data.brand || !data.tradingPartner) {
        return <ErrorPage title={t('apiErrors.notFound', { ns: 'common' })} />;
    }

    const noMarketsOrGroups =
        data.brand.groupTypes.length === 0 && data.brand.markets.length === 0;

    return (
        <>
            <Header
                brandName={data.brand.name}
                tradingPartner={data.tradingPartner}
            />
            <TypeInfo
                activeType={activeType}
                setShowChangeType={setShowChangeType}
                tradingPartnerName={data.tradingPartner.name}
            />
            <FilterInfo
                setShowEdit={setShowEdit}
                brand={data.brand}
                rule={filterRule}
            />
            <ProductInfo
                activeType={activeType}
                setShowEdit={setShowEdit}
                tradingPartnerName={data.tradingPartner.name}
            />
            <GroupedProductList
                brandId={data.brand.id}
                products={data.brandAccessPolicy?.matchingProducts ?? []}
                groupTypes={data.brand.groupTypes}
                markets={data.brand.markets}
                card={(product) => (
                    <InactiveProductCard
                        product={product}
                        onClick={() => {
                            setShowMasterProduct(true);
                            setMasterProductToShow(product);
                        }}
                    />
                )}
                listItem={(product) => (
                    <InactiveProductList
                        product={product}
                        onClick={() => {
                            setShowMasterProduct(true);
                            setMasterProductToShow(product);
                        }}
                    />
                )}
                cardHeight={380}
                cardMinWidth={210}
                disableMarketFilter
            />
            <TypeSelector
                open={showChangeType}
                handleClose={() => setShowChangeType(false)}
                brandId={data.brand.id}
                activeType={activeType}
                ruleIds={ruleIds}
                tradingPartner={data.tradingPartner}
                setShowEdit={setShowEdit}
                setShowEditType={setShowChangeType}
                productCount={data.brand.productCounts.masterProducts ?? 0}
                noMarketsOrGroups={noMarketsOrGroups}
            />
            <FilterSelector
                key={data.tradingPartner.id + 'filter'}
                open={showEdit === 'filter'}
                handleClose={() => setShowEdit(null)}
                tradingPartner={data.tradingPartner}
                brand={data.brand}
                rule={filterRule}
                ruleIds={ruleIds}
            />
            <ProductSelector
                key={data.tradingPartner.id + 'products'}
                open={showEdit === 'products'}
                handleClose={() => setShowEdit(null)}
                tradingPartner={data.tradingPartner}
                brand={data.brand}
                rule={productRule}
                ruleIds={ruleIds}
            />
            <VariantDrawer
                open={showMasterProduct}
                handleClose={() => setShowMasterProduct(false)}
                masterProductWithIds={masterProductToShow}
            />
        </>
    );
};
