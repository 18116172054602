import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';

import { Box } from '@mui/material';

import darkerWaves from '@xeris/assets/img/darker_waves.jpg';
import { useQueryParams } from '@xeris/hooks';
import LoginForm from '@xeris/pages/login/LoginForm/LoginForm';

import AcceptInviteForm from './AcceptInviteForm/AcceptInviteForm';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Logo from './svgs/logo';
import { type LoginPageType } from './reducers';

import styles from './Login.module.scss';

type LoginWrapperProps = {
    children: ReactNode;
};

const LoginWrapper = ({ children }: LoginWrapperProps): ReactElement => {
    const { t } = useTranslation('login');
    const currentYear = new Date().getFullYear();
    return (
        <div
            className={styles.loginContainer}
            style={{
                backgroundImage: `url(${darkerWaves})`,
            }}
        >
            <div className={styles.internalLogin}>
                <a href="/login/google">{t('internalLogin')}</a>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <div className={styles.logoContainer}>
                        <Logo />
                    </div>
                    <Box
                        className={styles.mainContainer}
                        sx={{ textAlign: 'center' }}
                    >
                        {children}
                    </Box>
                </div>
                <aside className={styles.links}>
                    <ul>
                        <li>
                            <Link
                                to={{
                                    pathname: 'https://no.xeris.no/contact',
                                }}
                                target="_blank"
                            >
                                {t('support')}
                            </Link>
                        </li>

                        <li>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.xeris.no/legal/privacy-policy',
                                }}
                                target="_blank"
                            >
                                {t('privacy')}
                            </Link>
                        </li>

                        <li>
                            <Link
                                to={{
                                    pathname:
                                        'https://www.xeris.no/legal/terms-and-conditions',
                                }}
                                target="_blank"
                            >
                                {t('legal')}
                            </Link>
                        </li>
                    </ul>
                    <div>© Xeris AS {currentYear} </div>
                </aside>
            </div>
        </div>
    );
};

export const ResetPasswordPage = (): ReactElement => {
    return (
        <LoginWrapper>
            <ResetPassword />
        </LoginWrapper>
    );
};

export const AcceptInvitation = (): ReactElement => {
    return (
        <LoginWrapper>
            <AcceptInviteForm />
        </LoginWrapper>
    );
};

const Login = (): ReactElement => {
    const action = useQueryParams<LoginPageType>('action');

    if (action === 'invite') {
        return <AcceptInvitation />;
    }

    if (action === 'reset-password') {
        return <ResetPasswordPage />;
    }

    if (action === 'forgot-password') {
        return (
            <LoginWrapper>
                <ForgotPassword />
            </LoginWrapper>
        );
    }

    return (
        <LoginWrapper>
            <Routes>
                <Route path="*" element={<LoginForm />} />
            </Routes>
        </LoginWrapper>
    );
};

export default Login;
