import { type ReactElement, type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText } from '@mui/material';

import { type TFunction } from 'i18next';

import {
    Button,
    CloseIcon,
    DownIcon,
    EditIcon,
    PlusIcon,
    SelectMenu,
    type SelectMenuItemType,
    TextAndIcon,
} from '@xeris/components';

import {
    type HandleSelectModalType,
    type ModalTypesType,
} from '../MultipleMasterProductsSelector';

import styles from './SelectActions.module.scss';

const getMenuItemList = (
    t: TFunction<'product'>,
    unselectedVariantsCount: number
): SelectMenuItemType<ModalTypesType>[] => [
    {
        key: 'addRemaining',
        value: 'addRemaining',
        content: (
            <>
                <ListItemIcon>
                    <PlusIcon />
                </ListItemIcon>
                <ListItemText>
                    {t('dataSelector.addRemaining', {
                        count: unselectedVariantsCount,
                    })}
                </ListItemText>
            </>
        ),
    },
    {
        key: 'edit',
        value: 'edit',
        divider: true,
        content: (
            <>
                <ListItemIcon>
                    <EditIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText>
                    {t('dataSelector.editDataSelection')}
                </ListItemText>
            </>
        ),
    },
    {
        key: 'remove',
        value: 'remove',
        content: (
            <>
                <ListItemIcon>
                    <CloseIcon fontSize={'small'} />
                </ListItemIcon>
                <ListItemText>
                    {t('dataSelector.removeAllFromDataset')}
                </ListItemText>
            </>
        ),
    },
];

type EditMenuProps = {
    className?: string;
    handleSelectModal: HandleSelectModalType;
    unselectedVariantsCount: number;
    showIconVersion: boolean;
};

const EditMenu = ({
    className,
    handleSelectModal,
    unselectedVariantsCount,
    showIconVersion,
}: EditMenuProps): ReactElement => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslation('product');

    const handleMenuToggle = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        if (menuAnchorEl) setMenuAnchorEl(null);
        else {
            setMenuAnchorEl(event.currentTarget);
        }
    };
    const classNames = [];
    if (showIconVersion) {
        classNames.push('edit-master-product-selection');
    }

    const menuItemList = getMenuItemList(t, unselectedVariantsCount);

    const filteredMenuItemList =
        unselectedVariantsCount > 0
            ? menuItemList
            : menuItemList.filter((item) => item.key !== 'addRemaining');

    return (
        <SelectMenu
            closedClassName={className}
            menuItemList={filteredMenuItemList}
            handleSelect={(event: SyntheticEvent, value): void =>
                handleSelectModal(value)
            }
            CustomButton={
                <Button
                    onClick={handleMenuToggle}
                    variant="outlined"
                    shape="rounded"
                    className={classNames.join(' ')}
                >
                    {showIconVersion ? (
                        <div className={styles.miniActionButtons}>
                            <EditIcon />
                            <DownIcon />
                        </div>
                    ) : (
                        <TextAndIcon
                            leftIcon={<EditIcon />}
                            text={t('common.button.edit')}
                            rightIcon={<DownIcon />}
                        />
                    )}
                </Button>
            }
        />
    );
};

export default EditMenu;
