import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './BackToLoginButton.module.scss';

const BackToLoginButton = (): ReactElement => {
    const { t } = useTranslation('login');

    return (
        <div className={styles.passwordSentButtonContainer}>
            <Link to="/login">{t('common.backToLogin')}</Link>
        </div>
    );
};

export default BackToLoginButton;
