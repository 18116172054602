import { type ChangeEvent, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { UploadFileForm } from '../../../Common';
import { type SetNewThemeType, type ThemeWithFilesType } from '../types';

type BrandLogoSelectorProps = {
    newTheme: ThemeWithFilesType;
    setNewTheme: SetNewThemeType;
    save: () => void;
    isLoading: boolean;
};

const BrandLogoSelector = ({
    newTheme,
    setNewTheme,
    isLoading,
    save,
}: BrandLogoSelectorProps): ReactElement => {
    const { t } = useTranslation('administration');

    const uploadButtonId = 'brandLogoSelector';

    const handleFiles = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files?.[0]) {
            setNewTheme({ ...newTheme, logo: event.target.files[0] });
        }
    };

    const abortUploadHandler = (): void => {
        setNewTheme({ ...newTheme, logo: null });
    };

    return (
        <Stack direction={'column'} alignItems={'flex-start'} spacing={1}>
            <Typography variant={'h4'} component={'h2'}>
                {t('brand.identity.brandLogo')}
            </Typography>
            <Typography maxWidth={'60ch'}>
                {t('brand.identity.logoCanBeSVGOrPNG')}
            </Typography>
            <UploadFileForm
                abortUploadHandler={abortUploadHandler}
                save={save}
                acceptTypes="image/*"
                uploadButtonId={uploadButtonId}
                uploadFile={newTheme.logo}
                handleFiles={handleFiles}
                isLoading={isLoading}
            />
        </Stack>
    );
};

export default BrandLogoSelector;
