import { createApi } from '@reduxjs/toolkit/query/react';
import { type BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient, type RequestDocument } from 'graphql-request';

import { getToken } from '@xeris/api';
import type { StateType } from '@xeris/types';

const requestUrl = new URL('/graphql', window.location.origin);

export const client = new GraphQLClient(requestUrl.toString());

const graphQLBaseQuery = graphqlRequestBaseQuery({
    client,
    url: requestUrl.toString(),
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as StateType).login.token;

        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Accept', 'application/json');

        return headers;
    },
});

const baseQuery: BaseQueryFn<{
    document: RequestDocument;
    variables?: Record<string, unknown>;
}> = async (args, apiMethods, extraOptions) => {
    const { dispatch, getState } = apiMethods;

    // Ensure token is valid and ready to be used in prepareHeaders
    await getToken(dispatch, getState as () => StateType);

    const language = (getState() as StateType).languages.dataLanguage.locale;

    const requestUrl = new URL('/graphql', window.location.origin);

    requestUrl.searchParams.set('locale', language);

    client.setEndpoint(requestUrl.href);

    return graphQLBaseQuery(args, apiMethods, extraOptions);
};

export const baseRtkApi = createApi({
    baseQuery: baseQuery,
    tagTypes: [
        'languageDependant',
        'dataset',
        'connections',
        'exports',
        'brands',
        'organizations',
        'exportJobs',
        'users',
    ],
    endpoints: () => ({}),
});
