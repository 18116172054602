import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

import { Loading } from '@xeris/components';

import './index.scss';

type ProgressOverlayProps = {
    message?: string;
};

const ProgressOverlay = ({ message }: ProgressOverlayProps): ReactElement => {
    const { t } = useTranslation('common');

    return (
        <div>
            <div className="progress-overlay"></div>
            <div className="progress-cover-page">
                <div className="progress-indicator">
                    <Paper className="progress-paper">
                        <Loading loading={true} />
                        <div className="progress-text">
                            {message
                                ? message
                                : t('components.pleaseGiveAMoment')}
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default ProgressOverlay;
