import { type ReactElement } from 'react';

import { type TypographyProps } from '@mui/material';

import { Typography } from '@xeris/components';
import { useLocale } from '@xeris/hooks/useLocale';

type FormattedPriceProps = {
    value: number | null;
    currency?: string | null;
    defaultValue?: string;
    typographyProps?: TypographyProps;
};

const getValue = (
    value: number | null,
    currency: string | undefined | null,
    locale: string,
    defaultValue: string
): string => {
    if (!value) {
        return defaultValue;
    }

    const formatter = new Intl.NumberFormat(locale, {
        style: currency ? 'currency' : undefined,
        currency: currency ?? undefined,
        minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(value);
};

const FormattedPrice = ({
    value,
    currency,
    defaultValue = ' - ',
    typographyProps,
}: FormattedPriceProps): ReactElement => {
    const locale = useLocale();

    return (
        <Typography textAlign={'right'} {...typographyProps}>
            {getValue(value, currency, locale, defaultValue)}
        </Typography>
    );
};

export default FormattedPrice;
