export const getUniqueListBy = <
    V,
    K extends string,
    T extends { [key in K]: V },
>(
    arr: T[],
    key: K
): T[] => {
    if (!Array.isArray(arr)) {
        return [];
    }

    return [
        ...new Map(
            arr
                .filter((item) => item !== undefined)
                .map((item) => [item[key], item])
        ).values(),
    ];
};
