import { type ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { type MasterProduct, type Product } from '@xeris/pages/product/types';

import { productHooks } from '../../hooks';
import { NoProductsFound } from '../NoProductsFound';
import ProductEntityCard from '../ProductEntityCard/ProductEntityCard';
import ProductEntityListHeader from '../ProductEntityListHeader/ProductEntityListHeader';
import { ProductListSkeleton } from '../ProductListSkeleton/ProductListSkeleton';

import styles from './ProductEntityList.module.scss';

type ProductEntityListProps = {
    isLoading?: boolean;
    hideHeader?: boolean;
    minimalProductList?: Product<
        | 'id'
        | 'name'
        | 'markets'
        | 'idNumbers'
        | 'brand'
        | 'images'
        | 'distinguishingFeatures'
        | 'masterProduct'
    >[];
    minimalMasterProductList?: MasterProduct<
        | 'id'
        | 'name'
        | 'markets'
        | 'idNumbers'
        | 'brand'
        | 'images'
        | 'products'
        | 'isConfigurable'
    >[];
};

const ProductEntityList = ({
    isLoading = false,
    hideHeader = false,
    minimalProductList,
    minimalMasterProductList,
}: ProductEntityListProps): ReactElement | null => {
    const { brandId = '' } = useParams();

    const sortedMinimalProductList = productHooks.useFilterProductEntityList(
        minimalProductList ?? [],
        brandId
    );
    const sortedMinimalMasterProductList =
        productHooks.useFilterProductEntityList(
            minimalMasterProductList ?? [],
            brandId
        );

    const noProductsFound =
        sortedMinimalProductList.length === 0 &&
        sortedMinimalMasterProductList.length === 0;

    if (isLoading) {
        return <ProductListSkeleton />;
    }

    if (!minimalProductList && !minimalMasterProductList) {
        console.error('Warning: No product- or masterProductList provided');
        return <NoProductsFound />;
    }

    return (
        <>
            {!hideHeader && (
                <ProductEntityListHeader isGroupSelectorVisible={false} />
            )}
            {noProductsFound ? (
                <NoProductsFound />
            ) : (
                <div className={styles.productEntityList}>
                    {sortedMinimalProductList.map((minimalProduct) => {
                        return (
                            <ProductEntityCard
                                key={minimalProduct.id}
                                minimalProduct={minimalProduct}
                            />
                        );
                    })}
                    {sortedMinimalMasterProductList.map(
                        (minimalMasterProduct) => {
                            return (
                                <ProductEntityCard
                                    key={minimalMasterProduct.id}
                                    minimalMasterProduct={minimalMasterProduct}
                                />
                            );
                        }
                    )}
                </div>
            )}
        </>
    );
};

export default ProductEntityList;
