import { type ReactElement } from 'react';

import { Skeleton } from '@xeris/components';

import styles from './ProductListSkeleton.module.scss';

export const ProductListSkeleton = (): ReactElement => {
    const productCardPlaceholderList = Array.from(Array(12).keys());

    return (
        <div className={styles.skeletonLoad}>
            <div className={styles.productList}>
                {productCardPlaceholderList.map((index) => (
                    <Skeleton key={index} height={312} />
                ))}
            </div>
        </div>
    );
};
