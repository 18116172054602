import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    getUsersToShareDatasetWithQuery,
    shareDatasetMutation,
    unshareDatasetMutation,
} from './sharedDatasetsQueries';
import {
    type ApiGetUsersToShareDatasetWithQuery,
    type ApiGetUsersToShareDatasetWithQueryVariables,
    type ApiShareDatasetMutation,
    type ApiShareDatasetMutationVariables,
    type ApiUnshareDatasetMutation,
    type ApiUnshareDatasetMutationVariables,
} from './sharedDatasetsQueries.gql';

export const sharedDatasetsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsersToShareDatasetWith: builder.query<
            ApiGetUsersToShareDatasetWithQuery,
            ApiGetUsersToShareDatasetWithQueryVariables
        >({
            query: (variables) => ({
                document: getUsersToShareDatasetWithQuery,
                variables,
            }),
        }),
        shareDataset: builder.mutation<
            {
                data: ApiShareDatasetMutation;
                variables: ApiShareDatasetMutationVariables;
            },
            ApiShareDatasetMutationVariables
        >({
            query: (variables) => ({
                document: shareDatasetMutation,
                variables: variables,
            }),
            transformResponse(data: ApiShareDatasetMutation, _, variables) {
                return { data, variables };
            },
            invalidatesTags: (data, error, variables) => [
                { type: 'dataset', id: variables.datasetId },
            ],
        }),
        unshareDataset: builder.mutation<
            {
                data: ApiUnshareDatasetMutation;
                variables: ApiUnshareDatasetMutationVariables & {
                    removeYourself: boolean;
                };
            },
            ApiUnshareDatasetMutationVariables & { removeYourself: boolean }
        >({
            query: (variables) => ({
                document: unshareDatasetMutation,
                variables: variables,
            }),
            transformResponse(data: ApiUnshareDatasetMutation, _, variables) {
                return { data, variables };
            },
            invalidatesTags: (data, error, variables) => [
                { type: 'dataset', id: variables.datasetId },
            ],
        }),
    }),
});
