import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ArrowLeftIcon, ArrowRightIcon, Tooltip } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../reducer';
import { type Stage } from '../../VariantGeneratorWrapper/VariantGeneratorWrapper';

import './index.scss';

export type StageType = {
    pathFraction: Stage;
    title: string;
};

const StagesLookup: Record<Stage, StageType> = {
    configure: { pathFraction: 'configure', title: 'Select Options ' },
    summary: { pathFraction: 'summary', title: 'Summary' },
    selectData: { pathFraction: 'selectData', title: 'Select Data ' },
};

type PageStagesType = {
    previous: StageType;
    current: StageType;
    next: StageType;
};

type ConfigurePageStagesType = Omit<PageStagesType, 'previous'> & {
    previous: undefined;
};

type SelectDataPageStagesType = Omit<PageStagesType, 'next'> & {
    next: undefined;
};

type AllStagesType = {
    configure: ConfigurePageStagesType;
    summary: PageStagesType;
    selectData: SelectDataPageStagesType;
};

export const AllStages: AllStagesType = {
    configure: {
        previous: undefined,
        current: StagesLookup.configure,
        next: StagesLookup.summary,
    },
    summary: {
        previous: StagesLookup.configure,
        current: StagesLookup.summary,
        next: StagesLookup.selectData,
    },
    selectData: {
        previous: StagesLookup.summary,
        current: StagesLookup.selectData,
        next: undefined,
    },
};

type StageLinkProps = {
    stage?: StageType;
    stageType: 'previous' | 'next';
};

const StageLink = ({ stage, stageType }: StageLinkProps): ReactElement => {
    const { t } = useTranslation('product');

    const missingFeatures = useAppSelector(
        variantGeneratorSelectors.selectMissingFeatures
    );

    if (!stage) {
        return <div className="link" />;
    }

    const iconLookup = {
        previous: <ArrowLeftIcon />,
        next: <ArrowRightIcon />,
    };

    const disabled =
        stage.pathFraction === 'selectData' && missingFeatures.length > 0;

    const tooltip = disabled
        ? t('variantGenerator.selectAtLeastOneOption')
        : '';

    const renderedLinkChildren = (
        <>
            {stageType === 'previous' ? iconLookup[stageType] : null}
            {t(`variantGenerator.stages.${stage.pathFraction}`)}
            {stageType === 'next' ? iconLookup[stageType] : null}
        </>
    );

    if (disabled) {
        return (
            <Tooltip title={tooltip}>
                <div className="link">{renderedLinkChildren}</div>
            </Tooltip>
        );
    }

    return (
        <Link className="link" to={{}} state={{ page: stage.pathFraction }}>
            {renderedLinkChildren}
        </Link>
    );
};

type HeaderProps = {
    type: Stage;
};

const Header = ({ type }: HeaderProps): ReactElement => {
    const { t } = useTranslation('product');
    const stages = AllStages[type];

    return (
        <>
            <div className="panels-header-wrapper">
                <span className={`${stages.previous ?? 'hide'}`} />
                <div className="previous">
                    <StageLink stage={stages.previous} stageType="previous" />
                </div>
                <span className={`${stages.previous ?? 'hide'}`} />
                <div className="panel-title current">
                    {t(
                        `variantGenerator.stages.${stages.current?.pathFraction}`
                    )}
                </div>
                <span className={`${stages.next ?? 'hide'}`} />
                <div className="next">
                    <StageLink stage={stages.next} stageType="next" />
                </div>
                <span className={`${stages.next ?? 'hide'}`} />
            </div>
        </>
    );
};

export default Header;
