import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

import {
    IconButton,
    Image,
    StatusTag,
    UpDownChevronIcon,
} from '@xeris/components';
import { type StatusTagProps } from '@xeris/components/StatusTag/StatusTag';
import { masterProductUrl, productUrl } from '@xeris/utilities/urls';

import { type ProductEntity, type ProductExportStatus } from '../../types';

import styles from './ProductEntityStatuses.module.scss';

const Header = (): ReactElement => {
    const { t } = useTranslation('exports');

    const columnList = [
        { id: 'name', Label: <>{t('common.name')}</>, className: '' },
        {
            id: 'brand',
            Label: <>{t('common.brand')}</>,
            className: styles.brandName,
        },
        {
            id: 'status',
            Label: <div className={styles.status}>{t('common.status')}</div>,
            className: styles.statusCell,
        },
    ];

    return (
        <TableHead className={styles.header}>
            <TableRow>
                {columnList.map((column) => {
                    return (
                        <TableCell key={column.id} className={column.className}>
                            {column.Label}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

type ProductEntityRowProps = {
    productEntity: ProductEntity;
};

const ProductEntityRow = ({
    productEntity,
}: ProductEntityRowProps): ReactElement => {
    const { t } = useTranslation('exports');

    const [isDropDownClosed, setIsDropDownClosed] = useState(true);

    const statusLookupId = {
        PROCESSING: { type: 'processing', title: t('common.processing') },
        SUCCESSFUL: { type: 'success', title: t('common.completed') },
        FAILED: { type: 'error', title: t('common.failed') },
        SKIPPED: { type: 'indeterminate', title: t('common.skipped') },
    } satisfies Record<ProductExportStatus, StatusTagProps>;

    const product =
        productEntity.type === 'MasterProduct'
            ? productEntity.masterProduct
            : productEntity.product;

    const productEntityStatus = statusLookupId[productEntity.status];
    const showErrorMessage =
        productEntity.status === 'FAILED' && productEntity.message;
    const image = product.images?.[0];

    const productLink =
        productEntity.type === 'MasterProduct'
            ? masterProductUrl(productEntity.masterProduct)
            : productUrl(productEntity.product);

    return (
        <>
            <TableRow>
                <TableCell>
                    <Link to={productLink}>
                        <div className={styles.productEntity}>
                            <Image
                                src={image?.url}
                                alt={image?.title ?? t('common.illustration')}
                                objectFit={image?.objectFit}
                            />
                            <span>{product.name}</span>
                        </div>
                    </Link>
                </TableCell>
                <TableCell className={styles.brandName}>
                    {product.brand.name}
                </TableCell>
                <TableCell className={styles.statusCell}>
                    <div className={styles.status}>
                        <StatusTag
                            type={productEntityStatus.type}
                            title={productEntityStatus.title}
                        />

                        {showErrorMessage && (
                            <IconButton
                                size="large"
                                onClick={(): void =>
                                    setIsDropDownClosed(!isDropDownClosed)
                                }
                            >
                                <UpDownChevronIcon
                                    isCollapsed={isDropDownClosed}
                                />
                            </IconButton>
                        )}
                    </div>
                </TableCell>
            </TableRow>
            {showErrorMessage && !isDropDownClosed && (
                <TableRow>
                    <TableCell colSpan={3}>
                        <div className={styles.errorMessage}>
                            {productEntity.message}
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

type ProductEntityStatusProps = {
    productEntityList: ProductEntity[];
};

const ProductEntityStatuses = ({
    productEntityList,
}: ProductEntityStatusProps): ReactElement => {
    return (
        <div className={styles.productEntityStatuses}>
            <Table className={styles.table}>
                <Header />
                <TableBody>
                    {productEntityList.map((productEntity) => (
                        <ProductEntityRow
                            key={
                                productEntity.type === 'MasterProduct'
                                    ? productEntity.masterProduct.id
                                    : productEntity.product.id
                            }
                            productEntity={productEntity}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default ProductEntityStatuses;
