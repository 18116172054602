import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { StatusTag, type StatusTagProps, UploadIcon } from '@xeris/components';

import { isUpdatedSinceLastExport } from '../../utilities';

type DatasetStatusProps = {
    dataset: {
        lastExported: string | null;
        lastProductUpdate: string | null;
    };
    hideIfUpToDate?: boolean;
};
const DatasetStatus = ({
    dataset,
    hideIfUpToDate,
}: DatasetStatusProps): ReactElement | null => {
    const { t } = useTranslation('dataset');

    const statusLookup: Record<string, StatusTagProps> = {
        pendingUpdates: {
            type: 'pending',
            title: t('common.newUpdate'),
            icon: <UploadIcon color="pending" />,
        },
        upToDate: {
            type: 'success',
            title: t('common.upToDate'),
        },
    };

    if (hideIfUpToDate && !isUpdatedSinceLastExport(dataset)) {
        return null;
    }

    if (!dataset.lastExported) {
        return <Typography variant={'caption'}>-</Typography>;
    }

    const getStatus = (): StatusTagProps => {
        if (isUpdatedSinceLastExport(dataset)) {
            return statusLookup['pendingUpdates'];
        }

        return statusLookup['upToDate'];
    };

    const status = getStatus();

    return (
        <StatusTag
            type={status.type}
            title={status.title}
            icon={status.icon}
            hideIcon={status.hideIcon}
        />
    );
};
export default DatasetStatus;
