import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { ListItemIcon, ListItemText } from '@mui/material';

import { type TFunction } from 'i18next';

import { DeleteIcon, EditIcon, SelectMenu } from '@xeris/components';
import type { SelectMenuItemType } from '@xeris/components/SelectMenu/SelectMenu';

const getActionMenuList = (
    t: TFunction<'administration'>,
    handleEditConnection: () => void,
    handleDeleteConnection: () => void,
    tradingPartnerId: string,
    navigate: NavigateFunction,
    isProducer: boolean
): SelectMenuItemType<string>[] => {
    const selectMenuItems: SelectMenuItemType<string>[] = [
        {
            key: 'deleteConnection',
            value: 'deleteConnection',
            handleClick: handleDeleteConnection,
            content: (
                <>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('connections.deleteConnection')} />
                </>
            ),
        },
    ];

    if (isProducer) {
        selectMenuItems.unshift(
            {
                key: 'editAccess',
                value: 'editAccess',
                handleClick: () => navigate(tradingPartnerId),
                content: (
                    <>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('connections.editAccess')} />
                    </>
                ),
            },
            {
                key: 'editConnection',
                value: 'editConnection',
                handleClick: handleEditConnection,
                content: (
                    <>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('connections.editConnection')}
                        />
                    </>
                ),
            }
        );
    }

    return selectMenuItems;
};

type ActionMenuProps = {
    connectionId: string;
    tradingPartner: { id: string; name: string };
    setSelectedConnection: (connectionId: string | null) => void;
    setSelectedDeleteConnection: (connectionId: string | undefined) => void;
    isProducer: boolean;
};

const ActionMenu = ({
    connectionId,
    setSelectedConnection,
    setSelectedDeleteConnection,
    tradingPartner,
    isProducer,
}: ActionMenuProps): ReactElement | null => {
    const { t } = useTranslation('administration');
    const navigate = useNavigate();

    const handleEditConnection = (): void => {
        setSelectedConnection(connectionId);
    };

    const handleDeleteConnection = (): void => {
        setSelectedDeleteConnection(connectionId);
    };

    return (
        <SelectMenu
            menuItemList={getActionMenuList(
                t,
                handleEditConnection,
                handleDeleteConnection,
                tradingPartner.id,
                navigate,
                isProducer
            )}
            dataTestId="channel-menu-button"
            ariaLabel={t('connections.openActionsMenuFor', {
                tradingPartner: tradingPartner,
            })}
        />
    );
};

export default ActionMenu;
