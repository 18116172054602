import {
    type FormEventHandler,
    type ReactElement,
    type ReactNode,
} from 'react';

import { LoadingButton } from '@mui/lab';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
} from '@mui/material';

type FormDrawerProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: FormEventHandler<HTMLDivElement>;
    title: string;
    children: ReactNode;
    isLoading?: boolean;
    cancelText: string;
    saveText: string;
};

export const FormDrawer = ({
    open,
    onClose,
    onSubmit,
    title,
    children,
    isLoading,
    cancelText,
    saveText,
}: FormDrawerProps): ReactElement => {
    return (
        <Drawer
            open={open}
            onClose={onClose}
            PaperProps={{ 'component': 'form', 'aria-label': title }}
            onSubmit={onSubmit}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px', width: '100vw' }}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={onClose}>
                    {cancelText}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    type={'submit'}
                    loading={isLoading}
                >
                    {saveText}
                </LoadingButton>
            </DialogActions>
        </Drawer>
    );
};
