import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, IconButton, Stack } from '@mui/material';

import { CloseIcon, CollapsePanel } from '@xeris/components';
import { type CertificateType } from '@xeris/pages/product/types';
import { useAppDispatch } from '@xeris/reducers';

import ProductDataFactory from '../../Common/Datasheet/ProductData/ProductDataFactory/ProductDataFactory';
import { setMaterialInfo } from '../reducer';

import { type MasterProductForInfoBox } from './api/types';

type MaterialInfoBoxContentProps = {
    masterProduct?: MasterProductForInfoBox | null;
};

type MaterialCollapseBoxProps = {
    label: string;
    description?: string;
    children?: JSX.Element[];
};

const MaterialCollapseBox = ({
    label,
    description,
    children,
}: MaterialCollapseBoxProps): ReactElement | null => {
    const [isOpen, setIsOpen] = useState(true);

    if (!children) return null;

    return (
        <CollapsePanel
            label={<span>{label}</span>}
            isCollapsed={isOpen}
            handleCollapsePanel={(): void => setIsOpen(!isOpen)}
            description={description}
            className="info-collapse-panel"
            contentClassName="info-collapse-panel-content"
            headerClassName="info-collapse-panel-header"
        >
            <div className="text">{children}</div>
        </CollapsePanel>
    );
};

const MaterialInfoBoxContentOverlay = ({
    masterProduct,
}: MaterialInfoBoxContentProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const handleCloseClick = (): void => {
        dispatch(setMaterialInfo({ masterProductId: null }));
    };

    if (!masterProduct) return null;

    return (
        <div className="material-info-box-content">
            <Stack>
                {t('variantGenerator.information', {
                    name: masterProduct?.name ?? '',
                })}

                <IconButton onClick={handleCloseClick} edge={'end'}>
                    <CloseIcon />
                </IconButton>
            </Stack>

            <div className="material-info-box-content-product-header">
                <div className="supplier">{masterProduct.brand.name}</div>
                <div className="product-name">{masterProduct.name}</div>
            </div>

            {!!masterProduct.certifications?.length && (
                <div>
                    <ProductDataFactory
                        productData={
                            {
                                hasData:
                                    masterProduct.certifications.length > 0,
                                type: 'environmental',
                                data: masterProduct.certifications.map(
                                    ({ name }) => name
                                ) as CertificateType[],
                                count: masterProduct.certifications.length,
                            } as const
                        }
                    />
                </div>
            )}

            <MaterialCollapseBox
                label="ID Numbers"
                description="identification"
            >
                {masterProduct.idNumbers?.map((idNumber, index) => (
                    <Grid container key={index}>
                        <Grid item xs={4}>
                            {idNumber.name}
                        </Grid>
                        <Grid item xs={8}>
                            {idNumber.value}
                        </Grid>
                    </Grid>
                ))}
            </MaterialCollapseBox>

            <MaterialCollapseBox label="Descriptions">
                {masterProduct.descriptions?.map((description) => (
                    <div key={description.id}>{description.value}</div>
                ))}
            </MaterialCollapseBox>

            <MaterialCollapseBox label="Specifications">
                {masterProduct.specifications?.map((specification, index) => (
                    <Grid container key={index}>
                        <Grid item xs={4} className="specification-name">
                            {specification.name}
                        </Grid>
                        <Grid item xs={8}>
                            {specification.value}
                        </Grid>
                    </Grid>
                ))}
            </MaterialCollapseBox>
        </div>
    );
};

export default MaterialInfoBoxContentOverlay;
