import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import {
    MuiButton,
    ProductListItem,
    RightPanelOpenIcon,
} from '@xeris/components';
import type { SimpleImageType } from '@xeris/components/ProductCard/ProductCard';

type InactiveProductListProps = {
    product: {
        id: string;
        name: string;
        isConfigurable: boolean | null;
        images: SimpleImageType[] | null;
        products: {
            id: string;
        }[];
    };
    onClick?: () => void;
};

export const InactiveProductList = ({
    product,
    onClick,
}: InactiveProductListProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <ProductListItem
            key={product.id}
            images={product.images}
            actions={
                !product.isConfigurable && (
                    <MuiButton
                        variant={'outlinedSolid'}
                        color={'secondary'}
                        aria-label={t('connections.showVariants')}
                        round
                        onClick={() => {
                            if (!product.isConfigurable && onClick) {
                                onClick();
                            }
                        }}
                    >
                        <RightPanelOpenIcon color={'secondary'} />
                    </MuiButton>
                )
            }
        >
            <Typography>{product.name}</Typography>
            <Typography variant="body2">
                {product.isConfigurable
                    ? t('connections.configurable')
                    : t('connections.numberOfVariants', {
                          count: product.products.length,
                      })}
            </Typography>
        </ProductListItem>
    );
};
