import { type ReactElement, Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
    AppBar as MuiAppBar,
    type AppBarProps as MuiAppBarProps,
    Box,
    Drawer,
    styled,
} from '@mui/material';

import { ErrorBoundary, Loading } from '@xeris/components';
import { useIsMobile } from '@xeris/hooks';

import Header from './Header/Header';
import Menu from './Menu/Menu';

const drawerWidth = 300;
const appBarHeight = 64;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    height: '100%',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

type AppBarProps = MuiAppBarProps & {
    open?: boolean;
};

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    height: appBarHeight,
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    display: 'flex',
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    position: 'sticky',
    top: 0,
}));

const ThemeWrapper = (): ReactElement => {
    const [open, setOpen] = useState(false);
    const isMobile = useIsMobile();

    const location = useLocation();

    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                open={open || !isMobile}
                color="default"
                elevation={0}
                position="fixed"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Header setOpen={setOpen} />
            </AppBar>
            <Drawer
                sx={{
                    'zIndex': (theme) => theme.zIndex.appBar - 1,
                    'width': drawerWidth,
                    'flexShrink': 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: 'background.default',
                    },
                }}
                variant={isMobile ? 'temporary' : 'persistent'}
                anchor="left"
                open={open || !isMobile}
                onClose={() => setOpen(false)}
                // The keepMounted prop only exists as a prop when variant=temporary (from the modal component).
                // It will be passed to the DOM and cause a React warning when variant=persistent
                {...(isMobile && { keepMounted: true })}
            >
                <DrawerHeader />

                <Menu />
            </Drawer>

            <Box
                sx={{
                    width: '100%',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                }}
            >
                <DrawerHeader />
                <Main open>
                    <Suspense fallback={<Loading />}>
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </Suspense>
                </Main>
            </Box>
        </Box>
    );
};

export default ThemeWrapper;
