export const filterProducts = <
    Product extends {
        markets: { id: string }[];
        groups: { id: string; type: { id: string } }[];
    },
>(
    products: Product[],
    markets: string[],
    groups: Record<string, string[]>
): Product[] => {
    return products.filter((masterProduct) =>
        filterProduct(masterProduct, markets, groups)
    );
};

export const filterProduct = <
    Product extends {
        markets: { id: string }[];
        groups: { id: string; type: { id: string } }[];
    },
>(
    product: Product,
    markets: string[],
    groups: Record<string, string[]>
): boolean => {
    return (
        (markets.length === 0 ||
            product.markets.some((market) => markets.includes(market.id))) &&
        Object.entries(groups).every(
            ([groupType, groups]) =>
                groups.length === 0 ||
                product.groups
                    .filter((group) => group.type.id === groupType)
                    .some((group) => groups.includes(group.id))
        )
    );
};
