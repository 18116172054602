import { createSelector } from '@reduxjs/toolkit';

import { type StateType } from '@xeris/types';

import { datasetsAdapter } from '../datasetSlice';

const adapterSelectors = datasetsAdapter.getSelectors(
    (state: StateType) => state.dataset.datasets
);

const selectDatasetNameList = createSelector(
    adapterSelectors.selectAll,
    (minimalDatasetList) => minimalDatasetList.map((dataset) => dataset.name)
);

export const minimalDatasetSelectors = {
    ...adapterSelectors,
    selectDatasetNameList,
};
