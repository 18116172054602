import { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { datasetProductsApi, datasetsApi } from '@xeris/pages/dataset/api';
import { useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

import type { MasterProduct } from '../../types';
import type { NewDatasetType } from '../CreateNewDataset/CreateNewDataset';
import { DeleteConfigurationDialog } from '../ProductEntityCard/ActionButtons/DeleteConfigurationDialog';

import SelectionActions from './SelectActions/SelectionActions';
import SelectProductDataDialog from './SelectProductDataDialog';

export type ModalTypesType =
    | undefined
    | 'add'
    | 'edit'
    | 'remove'
    | 'addRemaining';

export type SelectionType = {
    selectImages: boolean;
    selectDocuments: boolean;
    selectObjects: boolean;
};

export type HandleSelectModalType = (modal: ModalTypesType) => void;

type SelectedProducts = {
    id: string;
    images: null | [];
    documents: null | [];
    models: null | [];
};

const generateSelectedProducts = (
    ids: string[],
    selection: SelectionType
): SelectedProducts[] => {
    return ids.map((id) => ({
        id: id,
        images: selection.selectImages ? null : [],
        documents: selection.selectDocuments ? null : [],
        models: selection.selectObjects ? null : [],
    }));
};

type SelectedMasterProducts = SelectedProducts & { configuration_sets: [] };

const generateSelectedMasterProducts = (
    ids: string[],
    selection: SelectionType
): SelectedMasterProducts[] => {
    return ids.map((id) => ({
        id: id,
        images: selection.selectImages ? null : [],
        documents: selection.selectDocuments ? null : [],
        models: selection.selectObjects ? null : [],
        configuration_sets: [],
    }));
};

function getGenericProductListInDataset(
    productIds: string[],
    nodeList?: { id: string }[]
): string[] {
    if (!nodeList) {
        return [];
    }

    return productIds.flatMap((product) =>
        nodeList.some((node) => node.id === product) ? product : []
    );
}

const getProductIds = (
    ids: string[],
    type: ModalTypesType,
    productsIdsInDataset: string[],
    isNewDataset: boolean
): string[] => {
    if (isNewDataset) {
        return ids;
    }

    if (type === 'add') {
        return ids;
    }

    if (type === 'addRemaining') {
        return ids.filter((id) => !productsIdsInDataset.includes(id));
    }

    if (type === 'edit') {
        return ids.filter((id) => productsIdsInDataset.includes(id));
    }

    return [];
};

const initialNewDataset: NewDatasetType = {
    name: '',
    description: '',
    isNameValid: false,
    attemptedSubmitted: false,
    showForm: false,
};

type MultipleMasterProductsSelectorProps = {
    masterProductList: MasterProduct<'isConfigurable' | 'products'>[];
    showIconVersion?: boolean;
    showCounters?: boolean;
};

const MultipleMasterProductsSelector = ({
    masterProductList,
    showIconVersion = false,
    showCounters = true,
}: MultipleMasterProductsSelectorProps): ReactElement => {
    const { t } = useTranslation('product');
    const [createDataset] = datasetsApi.useCreateDatasetMutation();

    const [removeProductsFromDataset] =
        datasetProductsApi.useRemoveProductsFromDatasetMutation();

    const [addProductsToDataset] =
        datasetProductsApi.useAddProductsToDatasetMutation();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [selectedModal, setSelectedModal] =
        useState<ModalTypesType>(undefined);

    const [newDataset, setNewDataset] =
        useState<NewDatasetType>(initialNewDataset);

    const dataset = useAppSelector(datasetSelectors.active.selectDataset);

    const configurableMasterProducts = masterProductList
        .filter((masterProduct) => masterProduct.isConfigurable)
        .map(({ id }) => id);

    const productIdsList = masterProductList.flatMap(
        (masterProduct) => masterProduct.products.map(({ id }) => id) ?? []
    );

    const variantCount =
        productIdsList.length + configurableMasterProducts.length;

    const productCount = masterProductList.length;

    const selectedProductsInDataset = getGenericProductListInDataset(
        productIdsList,
        dataset?.selectedProductData.nodeList
    );

    const selectedMasterProductsInDataset = getGenericProductListInDataset(
        configurableMasterProducts,
        dataset?.selectedProductData.nodeList
    );

    const selectedVariantCount =
        selectedProductsInDataset.length +
        selectedMasterProductsInDataset.length;

    const handleCloseDialog = (): void => {
        setSelectedModal(undefined);
        setIsDialogOpen(false);
    };

    const handleSelectProducts = useCallback(
        (dataSelection: SelectionType): void => {
            if (newDataset.showForm && !newDataset.isNameValid) {
                setNewDataset({ ...newDataset, attemptedSubmitted: true });
                return;
            }

            const productIdsInDataset =
                dataset?.selectedProductData?.nodeList?.map(({ id }) => id) ??
                [];

            const productIds = getProductIds(
                productIdsList,
                selectedModal,
                productIdsInDataset,
                newDataset.showForm
            );

            const masterProductIds = getProductIds(
                configurableMasterProducts,
                selectedModal,
                productIdsInDataset,
                newDataset.showForm
            );

            if (newDataset.showForm) {
                createDataset({
                    name: newDataset.name,
                    description: newDataset.description,
                    selected_products: generateSelectedProducts(
                        productIds,
                        dataSelection
                    ),
                    selected_master_products: generateSelectedMasterProducts(
                        masterProductIds,
                        dataSelection
                    ),
                });
            } else {
                if (!dataset) {
                    return;
                }

                // Add/change products
                addProductsToDataset({
                    datasetId: dataset.id,
                    removeProducts: false,
                    productIdsToRemove: null,
                    data: {
                        id: dataset.id,
                        selected_products: generateSelectedProducts(
                            productIds,
                            dataSelection
                        ),
                        selected_master_products:
                            generateSelectedMasterProducts(
                                masterProductIds,
                                dataSelection
                            ),
                    },
                });
            }
            setNewDataset({ ...initialNewDataset });
            handleCloseDialog();
        },
        [
            newDataset,
            dataset,
            productIdsList,
            selectedModal,
            configurableMasterProducts,
            createDataset,
            addProductsToDataset,
        ]
    );

    const deselectProductsAndMasterProducts = useCallback((): void => {
        removeProductsFromDataset({
            datasetId: dataset?.id ?? '',
            productIds: selectedProductsInDataset,
            masterProductIds: selectedMasterProductsInDataset,
        });

        handleCloseDialog();
    }, [
        dataset?.id,
        removeProductsFromDataset,
        selectedMasterProductsInDataset,
        selectedProductsInDataset,
    ]);

    useEffect(() => {
        if (!dataset) {
            setNewDataset((previousState) => ({
                ...previousState,
                showForm: true,
            }));
        }
    }, [dataset]);

    useEffect(() => {
        if (selectedModal === 'remove') {
            setIsDeleteDialogOpen(true);
        }
        if (
            selectedModal === 'edit' ||
            selectedModal === 'add' ||
            selectedModal === 'addRemaining'
        ) {
            setIsDialogOpen(true);
        }
    }, [selectedModal, setIsDialogOpen, setIsDeleteDialogOpen]);

    return (
        <>
            <SelectionActions
                handleSelectModal={setSelectedModal}
                productCount={productCount}
                selectedVariantCount={selectedVariantCount}
                variantCount={variantCount}
                showIconVersion={showIconVersion}
                showCounters={showCounters}
            />
            <SelectProductDataDialog
                disabledDatasetMenu={selectedModal !== 'add'}
                newDataset={newDataset}
                handleSetNewDataset={setNewDataset}
                isDialogOpen={isDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleSelectProducts={handleSelectProducts}
                productCount={productCount}
                variantCount={variantCount}
            />
            <DeleteConfigurationDialog
                isOpen={isDeleteDialogOpen}
                handleCloseDialog={() => {
                    setIsDeleteDialogOpen(false);
                    setSelectedModal(undefined);
                }}
                configurationSetName={t(
                    'common.deleteDialog.allConfigurationsAndProducts'
                )}
                titleName={t('common.deleteDialog.titleAll')}
                selectedConfiguration={null}
                masterProductId={undefined}
                handleRemoveAllProducts={() =>
                    deselectProductsAndMasterProducts()
                }
            />
        </>
    );
};

export default MultipleMasterProductsSelector;
