import { type ReactElement, type ReactNode } from 'react';

import { Box, type SxProps, type Theme, Typography } from '@mui/material';

import { TipsIcon } from '@xeris/components';

type TipsBoxProps = {
    title: string;
    children: ReactNode;
    sx?: SxProps<Theme>;
};

const TipsBox = ({ title, children, sx }: TipsBoxProps): ReactElement => {
    return (
        <Box
            sx={{
                textAlign: 'center',
                borderRadius: 1.5,
                border: 1,
                borderColor: 'divider',
                backgroundColor: 'background.default',
                padding: 3,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 1.5,
                    gap: 2,
                }}
            >
                <TipsIcon fontSize={'large'} color={'primary'} />
                <Typography
                    variant={'h6'}
                    component={'p'}
                    sx={{ fontWeight: 500 }}
                >
                    {title}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

export default TipsBox;
