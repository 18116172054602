import { memo, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import {
    CloseIcon,
    ErrorPage,
    IconButton,
    Skeleton,
    Typography,
} from '@xeris/components';
import { type MasterProduct } from '@xeris/pages/product/types';
import { useURLState } from '@xeris/pages/product/variantGenerator/hooks';

import Configure from '../Configure/Configure';
import Header from '../Panels/Header';
import SelectData from '../SelectData/SelectData';
import Summary from '../Summary/Summary';

import configureStyles from '../Configure/Configure.module.scss';
import styles from './VariantGeneratorWrapper.module.scss';

const SkeletonLoad = (): ReactElement => {
    return (
        <>
            <Header type="configure" />
            <div className={configureStyles.configure}>
                <Skeleton className={configureStyles.skeletonLoad} />

                <Skeleton className={configureStyles.skeletonLoad} />

                <Skeleton className={configureStyles.skeletonLoad} />
            </div>
        </>
    );
};

const VariantGeneratorHeader = (): ReactElement => {
    const { t } = useTranslation('product');
    const { brandId = '', masterProductId = '' } = useParams();

    return (
        <div className={styles.header}>
            <Typography variant="h1">{t('common.variantGenerator')}</Typography>
            <Link to={`/Products/${brandId}/MasterProducts/${masterProductId}`}>
                <IconButton>
                    <CloseIcon />
                </IconButton>
            </Link>
        </div>
    );
};

const pages = {
    summary: Summary,
    configure: Configure,
    selectData: SelectData,
} as const;

export type Stage = keyof typeof pages;

type VariantGeneratorWrapperProps = {
    isLoading: boolean;
    isError: boolean;
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    > | null;
};

const VariantGeneratorWrapper = ({
    isLoading,
    isError,
    masterProduct,
}: VariantGeneratorWrapperProps): ReactElement => {
    const { page } = useURLState();

    const Page = pages[page];

    if (!isLoading && isError) {
        return (
            <div className={styles.popupLayout}>
                <VariantGeneratorHeader />
                <ErrorPage
                    title={'An error occurred'}
                    description={'Please try again'}
                    actionText={'Refresh'}
                    onClick={() => window.location.reload()}
                />
            </div>
        );
    }

    if (isLoading || !Page) {
        return (
            <div className={styles.popupLayout}>
                <VariantGeneratorHeader />
                <SkeletonLoad />
            </div>
        );
    }

    if (!masterProduct) {
        return (
            <div className={styles.popupLayout}>
                <VariantGeneratorHeader />
                <ErrorPage
                    title={'Not found'}
                    description={'We could not find what you were looking for'}
                    actionText={'Go back to Products'}
                    to={'/Products'}
                />
            </div>
        );
    }

    return (
        <div className={styles.popupLayout}>
            <VariantGeneratorHeader />
            <Page masterProduct={masterProduct} />
        </div>
    );
};

export default memo(VariantGeneratorWrapper);
