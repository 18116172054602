import { type ReactElement } from 'react';

import type { MasterProduct } from '@xeris/pages/product/types';

import MaterialInfoBoxOverlay from '../MaterialInfoBoxOverlay/MaterialInfoBoxOverlay';
import Preview from '../Preview/Preview';

import styles from './RightPanel.module.scss';

type RightPanelProps = {
    masterProduct: MasterProduct<
        'name' | 'brand' | 'images' | 'featureConfiguration'
    >;
};

const RightPanel = ({ masterProduct }: RightPanelProps): ReactElement => {
    return (
        <div className={styles.rightPanel}>
            <MaterialInfoBoxOverlay />
            <Preview stage="configure" masterProduct={masterProduct} />
        </div>
    );
};

export default RightPanel;
