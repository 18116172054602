import { type ReactElement } from 'react';

import { Backdrop } from '@mui/material';

import XerisSpinner from '../XerisSpinner/XerisSpinner';

import './Loading.scss';

type LoadingProps = {
    dataTestId?: string;
    backdrop?: boolean;
    length?: number;
    loading?: boolean;
};

const Loading = ({
    dataTestId = 'loadingSpinner',
    backdrop,
    //TODO: make true the default value and check all places that it doesn't cause an endless loading screen
    loading,
}: LoadingProps): ReactElement | null => {
    if (loading && backdrop) {
        return (
            <Backdrop className="backdrop-wrapper" open={loading}>
                <XerisSpinner />
            </Backdrop>
        );
    } else if (loading) {
        return (
            <div className="loading-spinner-wrapper" data-testid={dataTestId}>
                <div className="loading-spinner-container">
                    <XerisSpinner />
                </div>
            </div>
        );
    }

    return null;
};

export default Loading;
