import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusTag, Tooltip, UploadIcon } from '@xeris/components';

import { isUpdatedSinceLastExport } from '../../utilities';

type DataSetStatusTagProps = {
    dataset: {
        lastExported: string | null;
        lastProductUpdate: string | null;
    };
    hideIfUpToDate?: boolean;
};

const DatasetProductStatus = ({
    dataset,
    hideIfUpToDate = false,
}: DataSetStatusTagProps): ReactElement | null => {
    const { t } = useTranslation('dataset');

    const isChangedSinceLastExport = isUpdatedSinceLastExport(dataset);

    if (hideIfUpToDate && !isChangedSinceLastExport) {
        return null;
    }

    const statusTitle = isChangedSinceLastExport
        ? t('common.newUpdate')
        : t('common.upToDate');

    const speechBubbleTitle = isChangedSinceLastExport
        ? t('common.productUpdated')
        : t('common.productNotUpdated');

    const statusType = isChangedSinceLastExport ? 'pending' : 'success';

    const statusIcon = isChangedSinceLastExport ? <UploadIcon /> : undefined;

    return (
        <Tooltip title={speechBubbleTitle} placement="top-start">
            <StatusTag
                type={statusType}
                title={statusTitle}
                icon={statusIcon}
            />
        </Tooltip>
    );
};

export default DatasetProductStatus;
