import { type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import { fileUploadApi } from '@xeris/api/fileUploadApi';
import { BackButton } from '@xeris/components';
import { brandApi } from '@xeris/pages/admin/api';
import { productApi } from '@xeris/pages/product/api';

import { BrandIdentity } from './BrandIdentity/BrandIdentity';
import { type ThemeWithFilesType } from './types';

const BrandSettings = (): ReactElement => {
    const { t } = useTranslation('administration');

    const { brandId = '' } = useParams();

    const [editBrandThemeMutation] = brandApi.useEditBrandThemeMutation();
    const [getSignedUploadBrandUrl] =
        fileUploadApi.useGetSignedUploadBrandUrlMutation();
    const [uploadFile] = fileUploadApi.useUploadFileMutation();

    const { data, isLoading } = productApi.brand.useGetBrandByIdQuery({
        id: brandId,
    });

    const [newTheme, setNewTheme] = useState<ThemeWithFilesType>({
        featureImage: null,
        logo: null,
    });

    useEffect(() => {
        setNewTheme({
            featureImage: null,
            logo: null,
        });
    }, [data?.brand]);

    const themeCommitHandler = async (): Promise<void> => {
        const imageUrls: { logo: string | null; featureImage: string | null } =
            { logo: null, featureImage: null };

        if (newTheme.logo) {
            const { url, targetUrl } = await getSignedUploadBrandUrl({
                brandId: brandId,
                imageName: newTheme.logo.name,
            }).unwrap();

            await uploadFile({ url, file: newTheme.logo }).unwrap();

            imageUrls.logo = targetUrl;
        }

        if (newTheme.featureImage) {
            const { url, targetUrl } = await getSignedUploadBrandUrl({
                brandId: brandId,
                imageName: newTheme.featureImage.name,
            }).unwrap();

            await uploadFile({ url, file: newTheme.featureImage }).unwrap();

            imageUrls.featureImage = targetUrl;
        }

        editBrandThemeMutation({
            brandId: brandId,
            newTheme: {
                colors: null,
                logo: imageUrls.logo,
                feature_image: imageUrls.featureImage,
            },
        });
    };

    return (
        <>
            <BackButton to={`/Admin/Brands`}>
                {t('brand.identity.allBrands')}
            </BackButton>
            <Typography variant={'h1'} gutterBottom marginTop={1}>
                {t('brand.common.brandSettings')}
            </Typography>
            <BrandIdentity
                isLoading={isLoading}
                brand={data?.brand}
                newTheme={newTheme}
                setNewTheme={setNewTheme}
                themeCommitHandler={themeCommitHandler}
            />
        </>
    );
};

export default BrandSettings;
