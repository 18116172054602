import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, type LinkProps } from 'react-router-dom';

import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import { SelectButton, Typography } from '@xeris/components';
import {
    type BasicFeature,
    type MaterialFeature,
} from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { selectOptions, variantGeneratorSelectors } from '../reducer';
import { numberOrDash } from '../utilities/numberOrDash';

type FeatureElementProps = {
    isActive: boolean;
    feature: BasicFeature | MaterialFeature;
    to: LinkProps['to'];
    state: LinkProps['state'];
};

type FeatureGroupElementProps = {
    isActive?: boolean;
    featureGroupName: string;
};

export const FeatureElement = ({
    isActive,
    feature,
    to,
    state,
}: FeatureElementProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const { available, selected } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCount(state, feature.id)
    );

    const { isVisible, isSelectable } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    if (!isVisible) {
        return null;
    }

    const hasSelectedAll = available === selected;

    const handleSelectAll = (): void => {
        dispatch(
            selectOptions({
                featureId: feature.id,
                optionIds:
                    feature.__typename === 'BasicFeature'
                        ? feature.options?.map(({ id }) => id) ?? []
                        : feature.materials.flatMap((material) =>
                              material.options.map(({ id }) => id)
                          ),
                newValue: selected === 0,
            })
        );
    };

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={Link}
                to={to}
                state={state}
                selected={isActive}
                dense
                sx={{ paddingInline: 1 }}
            >
                <ListItemIcon>
                    <SelectButton
                        size={'small'}
                        onToggleOption={(event) => {
                            event.preventDefault();
                            handleSelectAll();
                        }}
                        isSelected={hasSelectedAll}
                        isPartiallySelected={selected > 0}
                        disabled={!isSelectable}
                        disabledTooltip={t(
                            'variantGenerator.optionGroupNotAvailableSelect'
                        )}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={feature?.name}
                    primaryTypographyProps={{ variant: 'body1' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                    sx={{ margin: 0, flexGrow: 1 }}
                />
                <Typography
                    variant={'caption'}
                    textAlign={'end'}
                    sx={{
                        flexShrink: 0,
                        minWidth: '42px',
                        marginInlineStart: 1,
                    }}
                >
                    {`${numberOrDash(selected)} / ${available}`}
                </Typography>
            </ListItemButton>
        </ListItem>
    );
};

export const FeatureGroupElement = ({
    isActive,
    featureGroupName,
}: FeatureGroupElementProps): ReactElement => {
    return (
        <Typography
            variant={'h3'}
            component={'p'}
            color={isActive ? 'primary' : 'initial'}
            sx={{ paddingInline: 1 }}
        >
            {featureGroupName}
        </Typography>
    );
};
