import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { baseRtkApi } from '@xeris/reducers/baseApi';
import { type StateType } from '@xeris/types';
import { loadLocalState, storeLocalState } from '@xeris/utilities/localStorage';

import { type LanguagesSliceType, type LanguageType } from './types';

const ENGLISH_OBJECT: LanguageType = {
    id: 'EN',
    language: 'English(US)',
    flag: '🇺🇸',
    description: 'English(US) 🇺🇸',
    locale: 'en',
};

const LOCAL_STATE_KEY = 'languages';

const getInitialDataLanguage = (): LanguageType => {
    const loadedLanguage = loadLocalState(LOCAL_STATE_KEY, ENGLISH_OBJECT);

    if (!Object.keys(ENGLISH_OBJECT).every((key) => key in loadedLanguage)) {
        return ENGLISH_OBJECT;
    }

    return loadedLanguage;
};

export const setDataLanguage = createAsyncThunk(
    'languages/setDataLanguage',
    (languageData: LanguageType, thunkApi) => {
        thunkApi.dispatch(
            baseRtkApi.util.invalidateTags(['languageDependant'])
        );

        return languageData;
    }
);

const initialState: LanguagesSliceType = {
    dataLanguage: getInitialDataLanguage(),
};

const languageSlice = createSlice({
    name: 'languages',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setDataLanguage.fulfilled, (state, action) => {
            const { payload } = action;

            state.dataLanguage = payload;
            storeLocalState(LOCAL_STATE_KEY, payload);
        });
    },
});

export const languagesSelectors = {
    selectActiveDataLanguage: (
        state: StateType
    ): LanguagesSliceType['dataLanguage'] => state.languages.dataLanguage,
};

export default languageSlice.reducer;
