import { type ReactElement, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip } from '@mui/material';

import {
    CheckIcon,
    CloseIcon,
    Image,
    PlusIcon,
    PreviewButton,
} from '@xeris/components';

import MaterialSwitchTooltip from './MaterialSwatchTooltip';

import styles from './MaterialSwatch.module.scss';

type SelectButtonsProps = {
    onToggleSelected: (event: SyntheticEvent) => void;
    isSelected: boolean;
};

const SelectButtons = ({
    onToggleSelected,
    isSelected,
}: SelectButtonsProps): ReactElement => {
    const classNames = [styles.selectOptionWrapper];
    const icons = [];

    if (isSelected) {
        classNames.push(styles.selectedUnselect);
        icons.push({ Icon: CheckIcon, className: styles.isSelectedIcon });
        icons.push({ Icon: CloseIcon, className: styles.unselectIcon });
    } else {
        icons.push({ Icon: PlusIcon, className: styles.selectPreviewIcon });
    }

    return (
        <div className={classNames.join(' ')} onClick={onToggleSelected}>
            {icons.map(({ className, Icon }, index) => (
                <Icon
                    className={className}
                    key={index}
                    sx={{ filter: 'drop-shadow(0 0 1em black)' }}
                />
            ))}
        </div>
    );
};

export type MaterialSwatchProps = {
    onSelectPreview: (event: SyntheticEvent<Element, Event>) => void;
    onToggleSelected: (event: SyntheticEvent<Element, Event>) => void;
    optionName: string;
    image: string | null;
    isSelected: boolean;
    isPreviewed: boolean;
    description?: string | null;
    colorCode?: string | null;
    isSelectable: boolean;
    isPreviewable: boolean;
};

const MaterialSwatch = ({
    isSelected,
    isPreviewed,
    onSelectPreview,
    onToggleSelected,
    optionName,
    image,
    description,
    colorCode,
    isSelectable,
    isPreviewable,
}: MaterialSwatchProps): ReactElement | null => {
    const { t } = useTranslation('product');

    return (
        <Tooltip
            title={
                <MaterialSwitchTooltip
                    optionName={optionName}
                    image={image}
                    description={description}
                    colorCode={colorCode}
                />
            }
            placement={'top'}
            enterNextDelay={100}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: 'background.paper',
                        padding: 0,
                        boxShadow: 3,
                        width: 240,
                        maxWidth: 240,
                    },
                },
            }}
        >
            <Box
                className={styles.option}
                sx={{ '&:hover ': { '.preview': { opacity: 1 } } }}
            >
                <Image
                    className={styles.previewImage}
                    alt={t('variantGenerator.materialPreview', {
                        optionName,
                    })}
                    src={image ?? undefined}
                />
                {isSelectable && (
                    <SelectButtons
                        onToggleSelected={onToggleSelected}
                        isSelected={isSelected}
                    />
                )}
                {isPreviewable && (
                    <div className={styles.previewIconsWrapper}>
                        <PreviewButton
                            className={'preview'}
                            sx={{ opacity: isPreviewed ? 1 : 0 }}
                            onSelectPreview={onSelectPreview}
                            isPreviewActive={isPreviewed}
                            size={'small'}
                        />
                    </div>
                )}
            </Box>
        </Tooltip>
    );
};

export default MaterialSwatch;
