import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography } from '@mui/material';

import { type SetNewThemeType, type ThemeWithFilesType } from '../types';

import { BrandLogoSelector, FeatureImageSelector, MarketTilePreview } from '.';

type BrandSettingsProps = {
    brand?: {
        name: string;
        theme: {
            logo: string | null;
            featureImage: string | null;
        };
    } | null;
    newTheme: ThemeWithFilesType;
    setNewTheme: SetNewThemeType;
    themeCommitHandler: () => void;
    isLoading?: boolean;
};

export const BrandIdentity = ({
    brand,
    newTheme,
    setNewTheme,
    themeCommitHandler,
    isLoading,
}: BrandSettingsProps): ReactElement => {
    const { t } = useTranslation('administration');

    if (!brand) {
        return (
            <Typography variant={'h4'}>
                {t('brand.identity.selectABrand')}
            </Typography>
        );
    }

    return (
        <Box
            gap={4}
            sx={(theme) => ({
                display: 'flex',
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                },
            })}
        >
            <Stack
                direction={'column'}
                alignItems={'flex-start'}
                spacing={4}
                justifyContent={'flex-start'}
            >
                <BrandLogoSelector
                    newTheme={newTheme}
                    setNewTheme={setNewTheme}
                    save={themeCommitHandler}
                    isLoading={!!isLoading}
                />
                <FeatureImageSelector
                    newTheme={newTheme}
                    setNewTheme={setNewTheme}
                    save={themeCommitHandler}
                    isLoading={!!isLoading}
                />
            </Stack>
            <MarketTilePreview brand={brand} />
        </Box>
    );
};
