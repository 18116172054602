import { type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip, Dialog, Typography } from '@mui/material';

import { MutationSnackbars, SelectButton } from '@xeris/components';
import { accessApi } from '@xeris/pages/admin/Connections/api';
import { masterProductApi } from '@xeris/pages/product/api/masterProductApi/masterProductApi';
import { GroupedProductList } from '@xeris/pages/product/Common/GroupedProductList/GroupedProductList';

import {
    type Brand,
    type BrandProductAccessRule,
    type MasterProductWithIds,
} from '../../types';
import { filterProducts } from '../../utilities';
import { getGroupSelectedCounts } from '../../utilities/getSelectedCount';

import { Header } from './components/Header';
import { ProductFilter } from './components/ProductFilter';
import { SelectProductCard } from './components/SelectProductCard';
import { SelectProductListItem } from './components/SelectProductListItem';
import { SplitDialogContent } from './components/SplitDialogContent';
import { VariantDrawer } from './components/VariantDrawer';

type ProductSelectorProps = {
    open: boolean;
    handleClose: () => void;
    tradingPartner: { id: string; name: string };
    ruleIds: string[];
    rule: BrandProductAccessRule | null;
    brand: Brand;
};

export const ProductSelector = ({
    open,
    handleClose,
    tradingPartner,
    brand,
    ruleIds,
    rule,
}: ProductSelectorProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [markets, setMarkets] = useState<string[]>([]);
    const [groups, setGroups] = useState<Record<string, string[]>>({});
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [productToShow, setProductToShow] =
        useState<MasterProductWithIds | null>(null);
    const [showProductDrawer, setShowProductDrawer] = useState(false);

    const { data } = masterProductApi.useGetAllMasterProductsForBrandQuery({
        brandId: brand.id,
    });

    const [setBrandAccess, { isSuccess, isError, isLoading }] =
        accessApi.useSetBrandAccessMutation();

    const products = filterProducts(
        data?.brand?.master_products ?? [],
        markets,
        groups
    );

    const totalProductCount = data?.brand?.master_products.reduce(
        (sum, product) => sum + (product.products.length || 1),
        0
    );

    useEffect(() => {
        setSelectedProducts(rule?.filterProducts ?? []);
    }, [rule]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullScreen>
                <Header
                    accessType={'BrandProductAccessRule'}
                    tradingPartnerName={tradingPartner.name}
                    brandName={brand.name}
                    handleClose={handleClose}
                    isLoading={isLoading}
                    handleSave={async () => {
                        await setBrandAccess({
                            organizationId: tradingPartner.id,
                            ruleIdsToDelete: ruleIds,
                            deleteRules: ruleIds.length > 0,
                            addRule: true,
                            rule: {
                                brandRule: null,
                                groupRule: null,
                                marketRule: null,
                                organizationRule: null,
                                brandProductRule: {
                                    filterProducts: selectedProducts,
                                    targetBrand: brand.id,
                                    action: 'ALLOW',
                                },
                                brandGroupMarketRule: null,
                            },
                        }).unwrap();

                        handleClose();
                    }}
                />
                <SplitDialogContent
                    columnOne={
                        <ProductFilter
                            groupTypes={brand.groupTypes}
                            markets={brand.markets}
                            selectedMarkets={markets}
                            selectedGroups={groups}
                            products={data?.brand?.master_products ?? []}
                            onChange={(selectedMarkets, selectedGroups) => {
                                setMarkets(selectedMarkets);
                                setGroups(selectedGroups);
                            }}
                        />
                    }
                    columnTwo={
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant={'h2'}
                                component={'h3'}
                                gutterBottom
                                marginTop={4}
                            >
                                {t('connections.productCount', {
                                    count: totalProductCount,
                                    selected: selectedProducts.length,
                                })}
                            </Typography>
                            <GroupedProductList
                                brandId={brand.id}
                                cardHeight={380}
                                cardMinWidth={210}
                                products={products}
                                groupTypes={brand.groupTypes}
                                disableMarketFilter
                                card={(product) => (
                                    <SelectProductCard
                                        product={product}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={
                                            setSelectedProducts
                                        }
                                        setProductToShow={setProductToShow}
                                        setShowProductDrawer={
                                            setShowProductDrawer
                                        }
                                    />
                                )}
                                listItem={(product) => (
                                    <SelectProductListItem
                                        product={product}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={
                                            setSelectedProducts
                                        }
                                        setProductToShow={setProductToShow}
                                        setShowProductDrawer={
                                            setShowProductDrawer
                                        }
                                    />
                                )}
                                markets={brand.markets}
                                sectionActions={(section) => {
                                    const {
                                        allSelected,
                                        partiallySelected,
                                        selectedCount,
                                        productCount,
                                        handleToggle,
                                    } = getGroupSelectedCounts(
                                        section.products,
                                        selectedProducts,
                                        setSelectedProducts
                                    );

                                    return (
                                        <Box display={'flex'} gap={10}>
                                            <Chip
                                                label={`${
                                                    selectedCount || '-'
                                                } / ${productCount}`}
                                            />
                                            <SelectButton
                                                isSelected={allSelected}
                                                isPartiallySelected={
                                                    partiallySelected
                                                }
                                                onToggleOption={handleToggle}
                                            />
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    }
                />
            </Dialog>
            {productToShow && (
                <VariantDrawer
                    masterProductWithIds={productToShow}
                    handleClose={() => setShowProductDrawer(false)}
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                    open={showProductDrawer}
                />
            )}
            <MutationSnackbars
                isError={isError}
                errorText={t('connections.brandAccess.updateAccessFailed')}
                isSuccess={isSuccess}
                successText={t('connections.brandAccess.updateAccessSucceeded')}
            />
        </>
    );
};
