import { type ReactElement, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import errorImage from '@xeris/assets/img/oops_something_went_wrong.png';
import wavesImage from '@xeris/assets/img/waves.jpg';
import { Button, Image, RefreshIcon } from '@xeris/components';

import styles from './ErrorBlock.module.scss';

type ErrorBlockProps = {
    handleDismissError: () => void;
};

const ErrorBlock = ({ handleDismissError }: ErrorBlockProps): ReactElement => {
    const location = useLocation();
    const loaded = useRef(false);

    // When client-side navigation occurs, dismiss the error
    useEffect(() => {
        if (loaded.current) {
            handleDismissError();
        } else {
            loaded.current = true;
        }
    }, [handleDismissError, location.pathname]);

    const handleRefresh = (): void => {
        window.location.reload();
    };

    return (
        <Grid
            container
            className={styles.errorBlock}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundImage: `url(${wavesImage}` }}
        >
            <Grid item>
                <Image className={styles.errorImage} src={errorImage} alt="" />
            </Grid>
            <Grid item>
                <h3>Oops, something went wrong</h3>

                <div className={styles.mainMessage}>
                    Please refresh the page or navigate somewhere else.
                </div>
                <div className={styles.mainMessage}>
                    If the problem persists, contact us at{' '}
                    <a
                        href="mailto:support@xeris.no"
                        target="_blank"
                        rel="noreferrer"
                    >
                        support@xeris.no
                    </a>
                </div>

                <div className={styles.buttonContainer}>
                    <Button
                        startIcon={<RefreshIcon fontSize="small" />}
                        variant="textError"
                        onClick={handleRefresh}
                    >
                        Refresh
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default ErrorBlock;
