import { baseRtkApi } from '@xeris/reducers/baseApi';

import { getMasterProductForInfoBoxQuery } from './materialInfoBoxQueries';
import {
    type ApiGetMasterProductForInfoBoxQuery,
    type ApiGetMasterProductForInfoBoxQueryVariables,
} from './materialInfoBoxQueries.gql';

export const materialInfoBoxApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getMasterProductForInfoBox: builder.query<
            ApiGetMasterProductForInfoBoxQuery,
            ApiGetMasterProductForInfoBoxQueryVariables
        >({
            query: ({ id }) => ({
                document: getMasterProductForInfoBoxQuery,
                variables: { id },
            }),
            providesTags: ['languageDependant'],
        }),
    }),
});

export const { useGetMasterProductForInfoBoxQuery } = materialInfoBoxApi;
