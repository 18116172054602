import {
    type FormEvent,
    type ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Loading, LockPrivateIcon } from '@xeris/components';
import { useQueryParams } from '@xeris/hooks';
import { useChangeLanguage } from '@xeris/i18n/useChangeLanguage';
import { userVerificationApi } from '@xeris/pages/admin/api';

import {
    BackToLoginButton,
    BrokenLinkMessage,
    PasswordInput,
    UserNameInput,
} from '../Common';
import { isValidPassword } from '../utillities';

import styles from './ResetPassword.module.scss';

const ResetPassword = (): ReactElement => {
    const { t } = useTranslation('login');

    const handleChangeLanguage = useChangeLanguage();

    const passwordInput = useRef<HTMLInputElement | null>(null);
    const form = useRef<HTMLFormElement | null>(null);

    const token = useQueryParams('token');

    const { data } = userVerificationApi.useGetUserInformationFromTokenQuery(
        { token: token ?? '' },
        {
            skip: !token,
        }
    );

    useEffect(() => {
        handleChangeLanguage(data?.language);
    }, [data, handleChangeLanguage]);

    const [password, setPassword] = useState('');

    const validatePassword = (): void => {
        const message = isValidPassword(password);
        passwordInput.current?.setCustomValidity(message);
    };

    if (!token) {
        return <BrokenLinkMessage variant="reset-password" />;
    }

    if (!data) {
        return <Loading loading backdrop />;
    }

    const handleSubmit = (event: FormEvent): void => {
        validatePassword();
        form.current?.reportValidity();
        //If the passowrd is valid, let the form perform the request, otherwise, stop it
        if (!form.current?.checkValidity()) {
            event.preventDefault();
        }
    };

    return (
        <div className={styles.createNewPassword}>
            <span className={styles.title}>
                {t('resetPassword.createNewPassword')}
            </span>

            <form
                ref={form}
                action={`/login/reset-password?token=${token}`}
                method="POST"
                onSubmit={handleSubmit}
            >
                <UserNameInput
                    value={data.username}
                    disabled
                    showAdornment={true}
                    adornment={<LockPrivateIcon fontSize="small" />}
                />

                <PasswordInput
                    ref={passwordInput}
                    value={password}
                    handleChange={setPassword}
                    name="password"
                />

                <div className={styles.submitButtonContainer}>
                    <Button
                        variant="solid"
                        type="submit"
                        disabled={password.length < 8}
                        tooltipText={
                            password.length < 8
                                ? 'The password needs to be at least 8 chracters long.'
                                : undefined
                        }
                    >
                        {t('resetPassword.createPassword')}
                    </Button>
                </div>
            </form>

            <div>
                <BackToLoginButton />
            </div>
        </div>
    );
};

export default ResetPassword;
