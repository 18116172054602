import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { logout } from '@xeris/api';
import { useAppDispatch } from '@xeris/reducers';

const Logout = (): null => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // TODO: Move to separate API
        // @ts-expect-error
        dispatch(logout());
        navigate('/', { replace: true });
    }, [dispatch, navigate]);

    return null;
};

export default Logout;
