import { Fragment, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox } from '@mui/material';

import { type SelectedDocumentsDataType } from '@xeris/pages/product/ProductDataSelector/types';
import { groupDocuments } from '@xeris/pages/product/utilities';

import SectionWrapper from '../SectionWrapper/SectionWrapper';

import './index.scss';

type DocumentationSectionProps = {
    documents: SelectedDocumentsDataType[];
    setData: (model: SelectedDocumentsDataType[]) => void;
};

const DocumentationSection = ({
    documents,
    setData,
}: DocumentationSectionProps): ReactElement | null => {
    const { t } = useTranslation('product');

    const groupedDocuments = groupDocuments(documents);

    const selectedCount = documents.filter((model) => model.isSelected).length;

    return (
        <SectionWrapper
            selectedCount={selectedCount}
            totalCount={documents.length}
            title={t('common.panelHeaders.documentation')}
            handleToggleAll={() => {
                setData(
                    documents.map((document) => ({
                        ...document,
                        isSelected: selectedCount < documents.length,
                    }))
                );
            }}
        >
            {groupedDocuments.map((documentType) => {
                const selectedCount = documentType.documents.filter(
                    (model) => model.isSelected
                ).length;

                const totalCount = documentType.documents.length;

                return (
                    <Fragment key={documentType.id}>
                        <div>
                            <Checkbox
                                indeterminate={
                                    selectedCount > 0 &&
                                    selectedCount < totalCount
                                }
                                checked={selectedCount === totalCount}
                                onClick={() =>
                                    setData(
                                        documents.map((document) => ({
                                            ...document,
                                            isSelected:
                                                document.type.id ===
                                                documentType.id
                                                    ? selectedCount < totalCount
                                                    : document.isSelected,
                                        }))
                                    )
                                }
                            />
                            {documentType.label}
                        </div>
                        <Box
                            component={'ul'}
                            sx={{
                                listStyle: 'none',
                                paddingLeft: 0,
                                margin: 0,
                            }}
                        >
                            {documentType.documents.map((document, index) => (
                                <Box
                                    component={'li'}
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        checked={document.isSelected}
                                        onChange={(event, data) =>
                                            setData(
                                                documents.map((d) => ({
                                                    ...d,
                                                    isSelected:
                                                        document.id === d.id
                                                            ? data
                                                            : d.isSelected,
                                                }))
                                            )
                                        }
                                    />
                                    <div className="documents-list-icon" />
                                    {document.title || document.fileName}
                                </Box>
                            ))}
                        </Box>
                    </Fragment>
                );
            })}
        </SectionWrapper>
    );
};

export default DocumentationSection;
