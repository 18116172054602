import type { HTMLAttributes, ReactElement, ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Autocomplete,
    type AutocompleteProps,
    FormControl,
    FormHelperText,
    TextField,
} from '@mui/material';
import { type AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

type AutocompleteInputProps<
    T extends FieldValues,
    Option extends { id: string; label: string },
> = {
    fieldName: Path<T>;
    label?: string;
    showError?: boolean;
    control: Control<T>;
    disableCloseOnSelect?: boolean;
    options: Option[];
    size?: AutocompleteProps<T, true, false, false>['size'];
    disablePortal?: AutocompleteProps<T, true, false, false>['disablePortal'];
    groupBy?: (options: Option) => string;
    renderOption?: (
        props: HTMLAttributes<HTMLLIElement>,
        option: Option,
        state: AutocompleteRenderOptionState
    ) => ReactNode;
};

export const AutocompleteMultipleInput = <
    T extends FieldValues,
    Option extends { id: string; label: string },
>({
    fieldName,
    label,
    showError = false,
    control,
    options,
    disableCloseOnSelect,
    size,
    groupBy,
    renderOption,
    disablePortal,
}: AutocompleteInputProps<T, Option>): ReactElement | null => {
    const { t } = useTranslation('common');

    const {
        field: { ref, onChange, onBlur, value, name },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <FormControl fullWidth>
            <Autocomplete
                disablePortal={disablePortal}
                fullWidth
                multiple
                freeSolo={false}
                getOptionLabel={(option): string => option.label}
                onChange={(_, data): void => onChange(data)}
                onBlur={onBlur}
                value={value}
                options={options}
                disableCloseOnSelect={disableCloseOnSelect}
                size={size}
                groupBy={groupBy}
                renderOption={
                    renderOption ??
                    ((props, option) => (
                        <li {...props} key={option.id}>
                            {option.label}
                        </li>
                    ))
                }
                isOptionEqualToValue={(option, value): boolean =>
                    option.id === value.id
                }
                renderInput={(params): ReactElement => (
                    <TextField
                        {...params}
                        name={name}
                        error={Boolean(error)}
                        inputRef={ref}
                        label={label}
                        value={value}
                    />
                )}
            />
            {showError && (
                <FormHelperText>
                    {error?.message &&
                        t(`formErrors.${error.message}`, {
                            defaultValue: '',
                            fieldName: fieldName,
                        })}
                </FormHelperText>
            )}
        </FormControl>
    );
};
