import { type ReactElement, useMemo, useState } from 'react';

import { type Material } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../../reducer';

import { MaterialDetails } from './MaterialDetails';
import { MaterialOptions } from './MaterialOptions';

import styles from './MaterialWrapper.module.scss';

type MaterialWrapperProps = {
    featureId: string;
    material: Material;
};

const MaterialWrapper = ({
    featureId,
    material,
}: MaterialWrapperProps): ReactElement | null => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const optionIds = useMemo(
        () => material.options.map(({ id }) => id),
        [material.options]
    );

    const { isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatuses(state, featureId, optionIds)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.materialCardWrapper}>
            <MaterialDetails
                featureId={featureId}
                handleToggleCollapse={() => setIsCollapsed((v) => !v)}
                material={material}
            />
            <MaterialOptions
                isCollapsed={isCollapsed}
                material={material}
                featureId={featureId}
            />
        </div>
    );
};

export default MaterialWrapper;
