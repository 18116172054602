import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../reducer';

import styles from './VariantsCounter.module.scss';

type VariantsCounterProps = {
    totalVariantCount: string | null;
};

const VariantsCounter = ({
    totalVariantCount,
}: VariantsCounterProps): ReactElement => {
    const { t, i18n } = useTranslation('product');
    const variantCount = useAppSelector(
        variantGeneratorSelectors.selectVariantCount
    );

    const hasSelectedMinimumConfiguration = variantCount > 0;

    const variantsClassnames = [styles.counter];

    return (
        <div className={styles.variantsCounter}>
            <div className={styles.variants}>
                <span className={styles.title}>
                    {t('variantGenerator.variants')}
                </span>
                <span className={variantsClassnames.join(' ')}>
                    {hasSelectedMinimumConfiguration ? (
                        variantCount.toLocaleString(i18n.language)
                    ) : (
                        <Tooltip title={t('variantGenerator.variantCount')}>
                            <>{t('variantGenerator.noValidVariants')}</>
                        </Tooltip>
                    )}
                </span>
            </div>
            <Typography className={styles.allVariants} variant={'body2'}>
                <span> {t('variantGenerator.allVariants')}</span>
                <span>
                    /
                    {BigInt(totalVariantCount ?? 0).toLocaleString(
                        i18n.language
                    )}
                </span>
            </Typography>
        </div>
    );
};

export default VariantsCounter;
