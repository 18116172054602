import { type ReactElement, type ReactNode } from 'react';

import { Grid, type GridProps } from '@mui/material';

import styles from './TextAndIcon.module.scss';

type TextAndIconProps = GridProps & {
    leftIcon?: ReactNode;
    text: ReactNode;
    rightIcon?: ReactNode;
    className?: string;
    dataTestId?: string;
};

const TextAndIcon = ({
    leftIcon,
    text,
    rightIcon,
    className,
    dataTestId,
    ...otherProps
}: TextAndIconProps): ReactElement => {
    const classNames = [styles.textAndIcon];
    if (className) {
        classNames.push(className);
    }
    return (
        <Grid
            {...otherProps}
            data-testid={dataTestId}
            container
            alignItems="center"
            className={classNames.join(' ')}
        >
            {leftIcon && (
                <Grid item className={styles.leftIcon}>
                    {leftIcon}
                </Grid>
            )}
            <Grid item className={styles.text}>
                {text}
            </Grid>
            {rightIcon && (
                <Grid item className={styles.rightIcon}>
                    {rightIcon}
                </Grid>
            )}
        </Grid>
    );
};

export default TextAndIcon;
