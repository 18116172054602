import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    DeleteIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@xeris/components';

import styles from './DeleteUserDialog.module.scss';

type DeleteUserDialogProps = {
    user: {
        username: string;
    };
    isOpen: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
};

const DeleteUserDialog = ({
    user,
    isOpen,
    handleClose,
    handleConfirm,
}: DeleteUserDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Dialog
            open={isOpen}
            className={styles.deleteUserDialog}
            onClose={handleClose}
        >
            <DialogTitle className={styles.deleteDialogTitle}>
                {t('users.deleteUser?')}
            </DialogTitle>
            <DialogContent className={styles.deleteDialogContent}>
                {t('users.deletingTheUser')}
                <span>&quot;{user.username}&quot;</span>{' '}
                {t('users.willDeleteDatasets')}
            </DialogContent>
            <DialogActions className={styles.deleteDialogActions}>
                <Button variant="textNeutral" onClick={handleClose}>
                    {t('brand.common.cancel')}
                </Button>
                <Button
                    variant="solidError"
                    onClick={handleConfirm}
                    dataTestId="confirmDeleteUser"
                >
                    <DeleteIcon /> {t('brand.common.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserDialog;
