import { type MutableRefObject, useLayoutEffect, useRef } from 'react';

type OptionsType = {
    canLoadMore: boolean;
    initialized: boolean;
};

const useInfiniteScroll = (
    callback: () => void,
    options: OptionsType
): MutableRefObject<HTMLDivElement | null> => {
    const { canLoadMore, initialized } = options;

    const ref = useRef(null);
    const observer = useRef<IntersectionObserver | null>(null);

    useLayoutEffect(() => {
        if (!observer.current && initialized) {
            observer.current = new IntersectionObserver(
                ([target]) => {
                    if (target.intersectionRatio <= 0) {
                        return;
                    }
                    if (!canLoadMore) {
                        return;
                    }
                    callback();
                },
                { rootMargin: '100px 0px 0px 0px', threshold: 0 }
            );
            if (ref.current) {
                observer.current.observe(ref.current);
            }
        }

        return (): void => {
            if (observer && observer.current) {
                observer.current.disconnect();
                observer.current = null;
            }
        };
    }, [callback, canLoadMore, ref, initialized]);

    return ref;
};

export default useInfiniteScroll;
