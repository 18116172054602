import { type ReactElement } from 'react';

import { Box } from '@mui/material';

import { Footer } from './Footer';
import { Navigation } from './Navigation';
import { type MenuElementWithLevelType } from './types';

type NavigationRailProps = {
    menuElements: MenuElementWithLevelType[];
};

const NavigationRail = ({
    menuElements,
}: NavigationRailProps): ReactElement => {
    return (
        <Box
            sx={{
                overflow: 'auto',
                display: 'flex',
                height: '100%',
                maxWidth: '100%',
                flexDirection: 'column',
                paddingRight: 1,
                paddingLeft: 1,
            }}
        >
            <Navigation menuElements={menuElements} />
            <Footer />
        </Box>
    );
};

export default NavigationRail;
