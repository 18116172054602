import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@mui/material';

type ImportErrorProps = {
    error?: string;
};

const ImportError = ({ error }: ImportErrorProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <TableRow key={error}>
            <TableCell className="import-table-cell table-details-titles table-details-featured import-error">
                {t('imports.error')}
            </TableCell>
            <TableCell className="import-table-cell import-error">
                {error}
            </TableCell>
        </TableRow>
    );
};

export default ImportError;
