import { type ReactElement, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Box, Toolbar, Typography } from '@mui/material';

import { Image } from '@xeris/components';
import { LanguageSelector } from '@xeris/containers';
import { productApi } from '@xeris/pages/product/api';

const ContentHeader = (): ReactElement | null => {
    const { brandId = '' } = useParams();
    const navigate = useNavigate();

    const { data, isLoading } = productApi.brand.useGetBrandByIdQuery({
        id: brandId,
    });

    // If no brand was found, redirect to brand list page
    useEffect(() => {
        if (!isLoading && !data?.brand) {
            navigate(`/Products`);
        }
    }, [data?.brand, isLoading, navigate]);

    if (!data?.brand) {
        return null;
    }

    return (
        <>
            <Toolbar
                disableGutters
                sx={{
                    gap: 2,
                    marginBottom: 1,
                }}
            >
                <Link to={`/Products/${brandId}`}>
                    <Image
                        src={data.brand.theme?.logo ?? ''}
                        alt={`${data.brand.name} logo`}
                        objectFit={'contain'}
                        style={{
                            height: '100%',
                            width: '100%',
                            maxHeight: '80px',
                            maxWidth: '200px',
                        }}
                    />
                </Link>
                <Typography variant={'h1'}>{data?.brand?.name}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <LanguageSelector />
            </Toolbar>
        </>
    );
};

export default ContentHeader;
