import { type ReactElement } from 'react';

import { Stack } from '@mui/material';

import { LinkIcon, PreviewButton, SelectButton } from '@xeris/components';

type OptionButtonsProps = {
    isPreviewed?: boolean;
    isSelected?: boolean;
    previewDisabled?: boolean;
    selectDisabled?: boolean;
    handleSelectPreview: () => void;
    handleSelect: () => void;
    selectDisabledTooltip: string;
};

export const BasicOptionCardButtons = ({
    isPreviewed,
    isSelected,
    previewDisabled,
    selectDisabled,
    handleSelectPreview,
    handleSelect,
    selectDisabledTooltip,
}: OptionButtonsProps): ReactElement => {
    return (
        <Stack spacing={1}>
            <PreviewButton
                className={'preview'}
                sx={{ opacity: isPreviewed ? 1 : 0 }}
                onSelectPreview={handleSelectPreview}
                isPreviewActive={isPreviewed}
                isDisabled={previewDisabled}
                badgeContent={
                    previewDisabled ? (
                        <LinkIcon color={'icons'} sx={{ minWidth: '20px' }} />
                    ) : undefined
                }
            />

            <SelectButton
                badgeContent={
                    selectDisabled ? (
                        <LinkIcon color={'icons'} sx={{ minWidth: '20px' }} />
                    ) : undefined
                }
                disabledTooltip={selectDisabledTooltip}
                isSelected={isSelected}
                onToggleOption={(event) => {
                    event.stopPropagation();
                    handleSelect();
                }}
                disabled={selectDisabled}
            />
        </Stack>
    );
};
