import { type ComponentType, type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@mui/material';

import {
    Button,
    type ButtonVariantsType,
    type IconFactoryProps,
    PlusIcon,
} from '@xeris/components';
import { useIsMobile } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import { datasetSelectors } from '../../reducers';

import { CreateDatasetForm } from './CreateDatasetForm';

type CreateDatasetProps = {
    showPlusIcon?: boolean;
    variant?: ButtonVariantsType;
    Icon?: ComponentType<IconFactoryProps>;
};

export const CreateDataset = ({
    variant = 'solid',
    showPlusIcon = false,
    Icon,
}: CreateDatasetProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const datasetNames = useAppSelector(
        datasetSelectors.minimal.selectDatasetNameList
    );

    const isMobile = useIsMobile();

    return (
        <>
            {Icon ? (
                <Tooltip
                    title={t('datasetList.createDataset')}
                    className={'menuElementHover'}
                    sx={{ opacity: isMobile ? 1 : 0 }}
                >
                    <IconButton
                        edge={'end'}
                        onClick={(): void => setIsDialogOpen(true)}
                    >
                        <Icon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Button
                    variant={variant}
                    onClick={(): void => setIsDialogOpen(true)}
                    startIcon={showPlusIcon && <PlusIcon fontSize="small" />}
                >
                    {t('datasetList.createDataset')}
                </Button>
            )}
            <CreateDatasetForm
                isOpen={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)}
                datasetNames={datasetNames}
            />
        </>
    );
};
