import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@mui/material';

type ImportNumbersProps = {
    summary: Record<string, string>;
    count: number | null;
};

const ImportNumbers = ({
    summary,
    count,
}: ImportNumbersProps): ReactElement => {
    const { t } = useTranslation('administration');
    return (
        <>
            {summary ? (
                Object.keys(summary).map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell className="import-table-cell table-details-titles">
                                {item}
                            </TableCell>
                            <TableCell className="import-table-cell">
                                {summary[item]}
                            </TableCell>
                        </TableRow>
                    );
                })
            ) : (
                <></>
            )}
            <TableRow>
                <TableCell className="import-table-cell table-details-titles table-details-featured">
                    {t('imports.total')}
                </TableCell>
                <TableCell className="import-table-cell">
                    {count ? count : '-'}
                </TableCell>
            </TableRow>
        </>
    );
};

export default ImportNumbers;
