import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@mui/material';

import { UnfoldLessIcon, UnfoldMoreIcon } from '@xeris/components';
import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';

type ExpandProps = {
    noneExpanded: boolean;
    groups: { id: string; name: string }[];
};

export const Expand = ({ noneExpanded, groups }: ExpandProps): ReactElement => {
    const { t } = useTranslation('products');
    const dispatch = useAppDispatch();

    return (
        <Tooltip
            title={noneExpanded ? t('brand.openAll') : t('brand.closeAll')}
        >
            <Button
                sx={{
                    marginBlock: 1,
                    paddingInline: '5px',
                    minWidth: 0,
                    borderColor: 'divider',
                }}
                color={'secondary'}
                variant={'outlined'}
                onClick={() => {
                    dispatch(
                        settingsActions.setGroupsExpanded({
                            groupIds: [
                                ...groups.map((group) => group.id),
                                '__ungrouped',
                            ],
                            newValue: noneExpanded,
                        })
                    );
                }}
            >
                {noneExpanded ? (
                    <UnfoldMoreIcon color={'icons'} />
                ) : (
                    <UnfoldLessIcon color={'icons'} />
                )}
            </Button>
        </Tooltip>
    );
};
