import { type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Button, Divider, TextField } from '@mui/material';

import { ArrowLeftIcon } from '@xeris/components';
import type { MasterProduct } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import ConfigurableProductDataSelector from '../../ProductDataSelector/ConfigurableProductDataSelector';
import type { StageType } from '../Panels/Header';
import { variantGeneratorSelectors } from '../reducer';

import styles from './SelectDataPanel.module.scss';

type ConfigurationSetHeaderProps = {
    showConfigName?: boolean;
};

const ConfigurationSetHeader = ({
    showConfigName = false,
}: ConfigurationSetHeaderProps): ReactElement | null => {
    const { t } = useTranslation('product');

    if (!showConfigName) return null; //To not handle configName while not handling multiple configurations of a product

    return (
        <div className={styles.configuration}>
            <span className={styles.title}>
                {t('variantGenerator.configurationName')}
            </span>
            <Divider />
            <div className={styles.name}>
                <TextField label="Name" fullWidth />
            </div>
        </div>
    );
};

type BackToSummaryProps = {
    previousStage: StageType;
};

const BackToSummary = ({ previousStage }: BackToSummaryProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <div className={styles.backToSummary}>
            <span>{t('variantGenerator.changeSomething')}</span>
            <Link to={{}} state={{ page: previousStage.pathFraction }}>
                <Button startIcon={<ArrowLeftIcon />}>
                    {t('variantGenerator.backToSummary')}
                </Button>
            </Link>
        </div>
    );
};

type ProductDataSelectorWrapperProps = {
    previousStage: StageType;
    handleSelectionClose: () => void;
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    >;
};

const ProductDataSelectorWrapper = ({
    previousStage,
    handleSelectionClose,
    masterProduct,
}: ProductDataSelectorWrapperProps): ReactElement => {
    const { datasetId, configurationSetId } = useParams();
    const configurationSet = useAppSelector(
        variantGeneratorSelectors.selectFeatureSelection
    );

    const features = useMemo(
        () =>
            Object.entries(configurationSet).map(([featureId, options]) => ({
                id: featureId,
                options: Object.keys(options).map((id) => ({
                    id,
                })),
            })),
        [configurationSet]
    );

    return (
        <ConfigurableProductDataSelector
            handleSelectionClose={handleSelectionClose}
            datasetId={datasetId}
            configurationSetId={configurationSetId}
            masterProduct={masterProduct}
            selectedFeatures={features}
            isInlineDialog={true}
            customContentHeader={<ConfigurationSetHeader />}
            customPanels={<BackToSummary previousStage={previousStage} />}
        />
    );
};

type SelectDataPanelProps = {
    previousStage: StageType;
    handleSelectionClose: () => void;
    masterProduct: MasterProduct<
        | 'name'
        | 'brand'
        | 'images'
        | 'featureConfiguration'
        | 'selectedData'
        | 'documents'
        | 'models'
    >;
};

const SelectDataPanel = ({
    previousStage,
    handleSelectionClose,
    masterProduct,
}: SelectDataPanelProps): ReactElement => {
    return (
        <div className={styles.selectDataPanel}>
            <ProductDataSelectorWrapper
                previousStage={previousStage}
                handleSelectionClose={handleSelectionClose}
                masterProduct={masterProduct}
            />
        </div>
    );
};

export default SelectDataPanel;
