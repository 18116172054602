import { type ReactElement } from 'react';

import { Box, Button, ButtonGroup, Typography } from '@mui/material';

import { SearchBar } from '@xeris/components';

import { type EcoSearchTypes } from '../types';

import styles from './ClassificationSelector.module.scss';

const ClassificationSelectorHeader = (): ReactElement => {
    return (
        <Box marginBottom={1}>
            <Typography variant={'h1'}>X-Eco search</Typography>
        </Box>
    );
};

type ClassificationSearchBarProps = {
    searchTerm: string;
    handleSetSearchTerm: (searchTerm: string) => void;
    activeFilter: EcoSearchTypes.FilterTypesType;
    handleSetActiveFilter: (
        newActiveFilter: EcoSearchTypes.FilterTypesType
    ) => void;
};

const ClassificationSearchBar = ({
    searchTerm,
    handleSetSearchTerm,
    activeFilter,
    handleSetActiveFilter,
}: ClassificationSearchBarProps): ReactElement => {
    //TODO_NOT_THIS_PR: Look into parsing the ts FilterType-type to this list instead of reconstructing it here,
    //and having two copies that will have to be updated independently when we change the amount of filters here
    //Consider moving this one to types-file as it's so closely related to the typing
    const types: EcoSearchTypes.FilterTypesType[] = [
        'show all',
        'segment',
        'family',
        'class',
        'brick',
    ];

    return (
        <div className={styles.classificationBrowser}>
            <div className={styles.searchEl}>
                <SearchBar
                    filter={searchTerm}
                    onChange={handleSetSearchTerm}
                    inputLabel="Search for segment, family, class or brick..."
                />
            </div>
            <div className={styles.searchCategories}>
                <ButtonGroup variant="outlined">
                    {types.map((type) => (
                        <Button
                            key={type}
                            className={`${
                                activeFilter === type && styles.activeFilter
                            }`}
                            onClick={(): void => handleSetActiveFilter(type)}
                        >
                            {type}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
        </div>
    );
};

export const ClassificationHeader = ({
    searchTerm,
    handleSetSearchTerm,
    activeFilter,
    handleSetActiveFilter,
}: ClassificationSearchBarProps): ReactElement => {
    return (
        <section>
            <ClassificationSelectorHeader />
            <ClassificationSearchBar
                activeFilter={activeFilter}
                searchTerm={searchTerm}
                handleSetActiveFilter={handleSetActiveFilter}
                handleSetSearchTerm={handleSetSearchTerm}
            />
        </section>
    );
};
