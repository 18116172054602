import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { DeleteIcon, MutationSnackbars } from '@xeris/components';
import { FormDialog } from '@xeris/components/forms';
import { organizationsApi } from '@xeris/pages/admin/api';

import { type OrganizationType } from '../../types/organizationsTypes';

type DeleteOrganizationProps = {
    isOpen: boolean;
    handleClose: () => void;
    organization?: OrganizationType;
};

export const DeleteOrganization = ({
    isOpen,
    handleClose,
    organization,
}: DeleteOrganizationProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [deleteOrganizationMutation, { isLoading, isSuccess, isError }] =
        organizationsApi.useDeleteOrganizationMutation();

    const handleDeleteOrganization = async (): Promise<void> => {
        if (!organization) {
            return;
        }
        await deleteOrganizationMutation({ id: organization.id }).unwrap();

        handleClose();
    };

    return (
        <>
            <FormDialog
                onClose={handleClose}
                open={isOpen}
                onSubmit={(event) => {
                    event.preventDefault();
                    handleDeleteOrganization();
                }}
                title={t(
                    'organization.deleteOrganization.deleteNamedOrganization',
                    {
                        organizationName: organization?.name,
                    }
                )}
                isLoading={isLoading}
                cancelText={t('brand.common.cancel')}
                saveText={t('brand.common.delete')}
                buttonProps={{ color: 'error', startIcon: <DeleteIcon /> }}
            >
                <Typography>
                    {t(
                        'organization.deleteOrganization.deletingTheOrganization'
                    )}{' '}
                    <strong>{organization?.name}</strong>{' '}
                    {t(
                        'organization.deleteOrganization.willPermanentlyRemoveBrands'
                    )}
                </Typography>
            </FormDialog>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('organization.deleteOrganization.success')}
                isError={isError}
                errorText={t('organization.somethingWentWrong')}
            />
        </>
    );
};
