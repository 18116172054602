import { useEffect, useRef } from 'react';

const useWindowResizeListener = (cb: () => void): void => {
    const timer = useRef(0);

    useEffect(() => {
        const handleResize = (): void => {
            clearTimeout(timer.current);
            timer.current = window.setTimeout(() => {
                cb();
            }, 500);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            clearTimeout(timer.current);
            window.removeEventListener('resize', handleResize);
        };
    });
};

export default useWindowResizeListener;
