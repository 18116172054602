import { type ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    debounce,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IconButton, SearchIcon } from '@xeris/components';

type SearchBarProps = {
    filter?: string;
    onChange?: (value: string) => void;
    onSubmit?: (value: string) => void;
    inputLabel: string;
    debounceTime?: number;
};

const newSearchSchema = yup.object({
    searchTerm: yup.string(),
});

type FormData = yup.InferType<typeof newSearchSchema>;

const SearchBar = ({
    filter = '',
    onChange,
    onSubmit,
    inputLabel,
    debounceTime = 300,
}: SearchBarProps): ReactElement | null => {
    const classNameList = ['searchbar-input'];

    const handleExternalSubmit = (data: FormData): void => {
        onSubmit && onSubmit(data.searchTerm ?? '');
    };

    const { control, handleSubmit, watch } = useForm<FormData>({
        resolver: yupResolver(newSearchSchema),
        defaultValues: {
            searchTerm: filter,
        },
    });

    const updateExternalFilter = useCallback(
        (value: string): void => {
            onChange && onChange(value);
        },
        [onChange]
    );

    const debouncedChangeHandler = useMemo(
        () => debounce(updateExternalFilter, debounceTime),
        [updateExternalFilter, debounceTime]
    );

    useEffect(() => {
        if (onChange) {
            const value = watch('searchTerm') ?? '';

            debouncedChangeHandler(value);
        }
        //eslint-disable-next-line
    }, [watch('searchTerm')]);

    return (
        <form onSubmit={handleSubmit(handleExternalSubmit)}>
            <FormControl fullWidth size="small">
                <Controller
                    name={'searchTerm'}
                    control={control}
                    render={({
                        field: { onChange: onFormChange, ...field },
                    }): ReactElement => (
                        <>
                            <InputLabel htmlFor="searchTerm">
                                {inputLabel}
                            </InputLabel>
                            <OutlinedInput
                                {...field}
                                id={'searchTerm'}
                                className={classNameList.join(' ')}
                                color="primary"
                                type="text"
                                label={inputLabel}
                                onChange={onFormChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            type="submit"
                                            aria-label="Search"
                                            size={'small'}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </>
                    )}
                />
            </FormControl>
        </form>
    );
};

export default SearchBar;
