import { type MutableRefObject, useEffect, useRef } from 'react';

type HtmlElementRefType = MutableRefObject<HTMLElement | null>;

const useResizeObserver = (
    ref: HtmlElementRefType,
    cb: ResizeObserverCallback
): void => {
    const timer = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries, observer) => {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                cb(entries, observer);
            }, 500);
        });

        resizeObserver.observe(ref.current);

        return (): void => {
            if (timer.current) clearTimeout(timer.current);
            resizeObserver.disconnect();
        };
    });
};

export default useResizeObserver;
