import { type ChangeEvent, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { UploadButton } from '..';

import SubmitImportFile from './SubmitImportFile';

type UploadFileFormProps = {
    abortUploadHandler: () => void;
    acceptTypes: string;
    handleFiles: (event: ChangeEvent<HTMLInputElement>) => void;
    save: () => void;
    uploadButtonId: string;
    uploadFile?: { name: string } | null;
    isLoading: boolean;
};

const UploadFileForm = ({
    abortUploadHandler,
    acceptTypes,
    handleFiles,
    uploadButtonId,
    uploadFile,
    isLoading,
    save,
}: UploadFileFormProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'spaceBetween',
                gap: 1,
            }}
        >
            {!!uploadFile && (
                <SubmitImportFile
                    abortUploadHandler={abortUploadHandler}
                    uploadFile={uploadFile}
                />
            )}
            {uploadFile ? (
                <LoadingButton
                    variant={'contained'}
                    type={'submit'}
                    loading={isLoading}
                    onClick={save}
                >
                    {t('brand.common.save')}
                </LoadingButton>
            ) : (
                <UploadButton
                    acceptTypes={acceptTypes}
                    handleFiles={handleFiles}
                    name={t('brand.common.select')}
                    uploadButtonId={uploadButtonId}
                />
            )}
        </Box>
    );
};

export default UploadFileForm;
