import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, Route, Routes, useLocation } from 'react-router-dom';

import { Box, Tab, Tabs, Typography } from '@mui/material';

import { ActivityLog } from '@xeris/pages/imports/pages/ActivityLog/ActivityLog';
import { Mappings } from '@xeris/pages/imports/pages/Mappings/Mappings';
import { Overview } from '@xeris/pages/imports/pages/Overview/Overview';
import { Sources } from '@xeris/pages/imports/pages/Sources/Sources';

function useRouteMatch(patterns: string[]): number {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            const currentTabIndex = patterns.findIndex(
                (path) => path === possibleMatch.pattern.path
            );

            if (currentTabIndex == -1) {
                return 0;
            }

            return currentTabIndex;
        }
    }

    return 0;
}

const tabs = [
    { path: '/', key: 'overview', Component: Overview },
    { path: '/sources', key: 'sources', Component: Sources },
    { path: '/mappings', key: 'mappings', Component: Mappings },
    {
        path: '/activityLog',
        key: 'activityLog',
        Component: ActivityLog,
    },
] as const;

const Header = (): ReactElement => {
    const { t } = useTranslation('imports');

    return <Typography variant={'h1'}>{t('common.imports')}</Typography>;
};

const PageTabs = (): ReactElement => {
    const { t } = useTranslation('imports');

    const currentTabIndex = useRouteMatch(
        tabs.map(({ path }) => '/import' + path)
    );

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTabIndex}>
                {tabs.map(({ key, path }) => (
                    <Tab
                        key={key}
                        label={t(`importsPage.tabs.${key}`)}
                        component={Link}
                        to={'/import' + path}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export const ImportsPage = (): ReactElement => {
    return (
        <>
            <Header />
            <PageTabs />
            <Routes>
                {tabs.map(({ key, path, Component }) => (
                    <Route key={key} path={path} element={<Component />} />
                ))}
            </Routes>
        </>
    );
};
