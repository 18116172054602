import { type ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    CenteredPage,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    Skeleton,
} from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { ecoSearchHooks } from '../hooks';
import { ecoSearchSelectors } from '../selectors';
import { type EcoSearchTypes } from '../types';
import { typeLookup } from '../types/EcoSearchTypes';

import { ClassificationHeader } from './ClassificationHeader';

import styles from './ClassificationSelector.module.scss';

const SkeletonLoad = (): ReactElement => {
    const placeholderList = Array.from(Array(10).keys());
    return (
        <div className={styles.skeletonLoad}>
            <ClassificationHeader
                activeFilter={'show all'}
                searchTerm={''}
                handleSetActiveFilter={(): void => {}}
                handleSetSearchTerm={(): void => {}}
            />
            <div className={styles.list}>
                {placeholderList.map((index) => (
                    <Skeleton key={index} height={42} />
                ))}
            </div>
        </div>
    );
};

const Description = ({
    element,
}: {
    element: EcoSearchTypes.CategoryType;
}): ReactElement | null => {
    const isBrick = element.type === 'gpc_brick';

    if (!isBrick || !element.description) {
        return null;
    }
    //This component is still a TODO as we haven't gotten the data from backend, but I kept it in to keep the styling.
    return (
        <div className={styles.accordion}>
            <dl>
                <dt>Definition:</dt>
                <dd>
                    Includes any products that may be described/observed as the
                    wood from broad-leaved species of trees cut and prepared for
                    use as building material.
                </dd>
            </dl>
            <dl>
                <dt>Definition Excludes:</dt>
                <dd>Excludes products such as softwood lumber, plywood.</dd>
            </dl>
        </div>
    );
};

type BranchProps = {
    element: EcoSearchTypes.CategoryType;
};

const Branch = ({ element }: BranchProps): ReactElement | null => {
    const [isBranchOpen, setIsBranchOpen] = useState(
        element.childrenMatches ?? false
    );

    useEffect(() => {
        //Resets the open/close state if the search changes state of this branch.
        setIsBranchOpen(element.childrenMatches ?? false);
    }, [element.childrenMatches, element.matches]);

    const isBrick = element.type === 'gpc_brick';

    const handleToggleOpen = (): void => {
        if (!isBrick) {
            setIsBranchOpen(!isBranchOpen);
        }
    };

    const ChevronIcon = isBrick
        ? ChevronRightIcon
        : isBranchOpen
          ? ChevronUpIcon
          : ChevronDownIcon;

    return (
        <ul>
            <li>
                <div className={styles.header} onClick={handleToggleOpen}>
                    <div className={styles.groupName}>
                        <span>
                            <ChevronIcon />
                            {typeLookup[element.type]}:
                        </span>
                        <Link to={`/EcoSearch/${element.id}/Products`}>
                            <div className={styles.number}>{element.id}</div>
                            <div className={styles.name}>{element.name}</div>
                        </Link>
                    </div>
                    <div className={styles.selectGroup}>
                        <Link to={`/EcoSearch/${element.id}/Products`}>
                            <ChevronRightIcon />
                        </Link>
                    </div>
                </div>
                <Description element={element} />
                {isBranchOpen &&
                    element.children?.map(
                        (child: EcoSearchTypes.CategoryType) => (
                            <Branch element={child} key={child.id} />
                        )
                    )}
            </li>
        </ul>
    );
};

const categoryTypeMatchesFilterType = (
    filter: EcoSearchTypes.FilterTypesType,
    type: EcoSearchTypes.CategoryTypesType
): boolean => {
    return (
        filter === 'show all' ||
        (filter === 'segment' && type === 'gpc_segment') ||
        (filter === 'family' && type === 'gpc_family') ||
        (filter === 'class' && type === 'gpc_class') ||
        (filter === 'brick' && type === 'gpc_brick')
    );
};

const filterCategoriesRecursively = (
    list: EcoSearchTypes.CategoryType[] | null,
    regExp: RegExp,
    activeFilter: EcoSearchTypes.FilterTypesType
): EcoSearchTypes.CategoryType[] => {
    return (
        (list
            ?.map((element) => {
                const isMatchingRegExp =
                    regExp.test(element.id) ||
                    regExp.test(element.name) ||
                    regExp.test(element.description ?? '');
                if (
                    categoryTypeMatchesFilterType(activeFilter, element.type) &&
                    isMatchingRegExp
                ) {
                    return { ...element, matches: true };
                } else if (element.children !== undefined) {
                    const children = filterCategoriesRecursively(
                        element.children,
                        regExp,
                        activeFilter
                    );
                    if (children.length === 0) {
                        return null;
                    } else {
                        return {
                            ...element,
                            children: children,
                            childrenMatches: true,
                        };
                    }
                } else {
                    return null;
                }
            })
            .filter((el) => !!el) as EcoSearchTypes.CategoryType[]) ?? []
    );
};

const ClassificationSelector = (): ReactElement | null => {
    const [activeFilter, setActiveFilter] =
        useState<EcoSearchTypes.FilterTypesType>('show all');

    const [searchTerm, setSearchTerm] = useState<string>('');

    const { isLoading } = ecoSearchHooks.api.queries.useGetSegmentsQuery({});

    const categoryList = useAppSelector(ecoSearchSelectors.selectGpcSegments);

    if (isLoading || !categoryList) {
        return <SkeletonLoad />;
    }

    const handleSetActiveFilter = (
        newActiveFilter: EcoSearchTypes.FilterTypesType
    ): void => {
        setActiveFilter(newActiveFilter);
    };

    const handleSetSearchTerm = (newSearchTerm: string): void => {
        setSearchTerm(newSearchTerm);
    };

    const activeCategories =
        searchTerm.length < 2
            ? categoryList
            : filterCategoriesRecursively(
                  categoryList,
                  new RegExp(searchTerm, 'i'),
                  activeFilter
              );

    return (
        <CenteredPage>
            <ClassificationHeader
                activeFilter={activeFilter}
                searchTerm={searchTerm}
                handleSetActiveFilter={handleSetActiveFilter}
                handleSetSearchTerm={handleSetSearchTerm}
            />
            <section className={styles.dataPanels}>
                <div>
                    <ul className={styles.list}>
                        {activeCategories.map((element) => (
                            <Branch element={element} key={element.id} />
                        ))}
                    </ul>
                </div>
            </section>
        </CenteredPage>
    );
};

export default ClassificationSelector;
