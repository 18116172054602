import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, type SxProps, Tooltip } from '@mui/material';

import { ChevronDownIcon, ChevronUpIcon, Typography } from '@xeris/components';
import { type SectionListData } from '@xeris/components/ProductList/types';
import { type MasterProductWithIds } from '@xeris/pages/product/Common';

type SectionProps<MP extends MasterProductWithIds> = {
    data: SectionListData<MP>;
    isOpen: boolean;
    actions?: (section: SectionListData<MP>) => ReactNode;
    toggleSectionOpen: () => void;
    sx?: SxProps;
};

export const Section = <MP extends MasterProductWithIds>({
    data,
    actions,
    toggleSectionOpen,
    isOpen,
    sx,
}: SectionProps<MP>): ReactElement => {
    const { t } = useTranslation('common');

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 1.5,
                paddingInlineEnd: 3,
                height: '64px',
                border: 1,
                borderColor: 'divider',
                ...sx,
            }}
        >
            <Tooltip
                title={t(
                    `components.productList.${isOpen ? 'collapse' : 'expand'}`,
                    { sectionName: data.title }
                )}
            >
                <IconButton onClick={toggleSectionOpen}>
                    {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </IconButton>
            </Tooltip>
            <Box
                onClick={toggleSectionOpen}
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexBasis: '100%',
                    cursor: 'pointer',
                    height: '100%',
                }}
            >
                <Typography
                    variant={'subtitle1'}
                    fontWeight={'fontWeightMedium'}
                    component={'h2'}
                >
                    {data.title}
                </Typography>
            </Box>

            <Box sx={{ flexShrink: 0 }}>{actions?.(data)}</Box>
        </Box>
    );
};
