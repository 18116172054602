import { datasetProductsApi } from '../../api';
import { type DatasetTypes } from '../../types';
import { datasetsAdapter } from '../datasetSlice';

const updateDatasetNodeList = (
    datasetId: string,
    nodeList: DatasetTypes.DatasetNodeType[],
    state: DatasetTypes.DatasetSliceType
): void => {
    if (state.activeDataset && datasetId === state.activeDataset.id) {
        state.activeDataset = {
            ...state.activeDataset,
            selectedProductData: {
                nodeList,
            },
            totalCount: nodeList.length,
        };
    }
};

export const datasetProductsExtraReducers = (
    builder: DatasetTypes.ReducerBuilderType
): void => {
    builder.addMatcher(
        datasetProductsApi.endpoints.addProductsToDataset.matchFulfilled,
        (state, action) => {
            const dataset = action.payload?.dataset.addProducts;

            if (!dataset) {
                return;
            }

            const nodeList = dataset.selectedProductData.nodes;

            datasetsAdapter.upsertOne(state.datasets, {
                id: dataset.id,
                totalCount: nodeList.length,
            } as DatasetTypes.MinimalDatasetType);

            updateDatasetNodeList(dataset.id, nodeList, state);
        }
    );
    builder.addMatcher(
        datasetProductsApi.endpoints.removeProductsFromDataset.matchFulfilled,
        (state, action) => {
            const dataset = action.payload?.dataset.removeProducts;

            if (!dataset) {
                return;
            }

            const nodeList = dataset.selectedProductData.nodes;

            datasetsAdapter.upsertOne(state.datasets, {
                id: dataset.id,
                totalCount: nodeList.length,
            } as DatasetTypes.MinimalDatasetType);

            updateDatasetNodeList(dataset.id, nodeList, state);
        }
    );
};
