type ObjectType<T> = Record<string, T>;

export const mapObjectEntries = <T, R>(
    obj: ObjectType<T>,
    cb: (key: string, value: T) => [string, R]
): Record<string, R> => {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => cb(key, value))
    );
};

export const mapObjectValues = <T, R>(
    obj: ObjectType<T>,
    cb: (key: string, value: T) => R
): Record<string, R> => {
    return mapObjectEntries(obj, (key, value) => [key, cb(key, value)]);
};
