import { type ReactElement } from 'react';

import styles from './XerisSpinner.module.scss';

type XerisSpinnerProps = {
    width?: number;
    height?: number;
};

const XerisSpinner = ({ width, height }: XerisSpinnerProps): ReactElement => (
    <div className={styles.xerisSpinner}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles.xerisIcon}
            viewBox="0 0 500 500"
            style={{ width, height }}
            data-testid="xeris-spinner"
        >
            <g className="xeries-inner">
                <path
                    fill="#fcc00e"
                    fillRule="evenodd"
                    d="M373.094 404.618L297.187 404.618 335.131 327.573 373.094 404.618z"
                    className="triangle-right"
                ></path>
                <path
                    fill="#ff5722"
                    fillRule="evenodd"
                    d="M372.425 248.14a37.284 37.284 0 11-37.295-37.285 37.298 37.298 0 0137.295 37.285z"
                    className="circle-right"
                ></path>
                <path
                    fill="#009688"
                    fillRule="evenodd"
                    d="M334.459 167.963c-8.836 0-17.677.02-26.502-.01-7.037-.03-10.712-3.422-10.691-10.62.025-18.012.056-36.014 0-54.025-.006-7.731 3.958-11.006 11.44-10.97q26.5.113 53.015-.02c7.64-.036 11.223 3.614 11.223 11.182q-.045 26.517.072 53.016c.045 8.197-4.142 11.649-12.05 11.543-8.826-.117-17.682-.02-26.507-.02z"
                    className="square-right"
                ></path>
                <path
                    fill="#1d2c48"
                    d="M250.68 431.082a16.198 16.198 0 01-16.197-16.197V82.841a16.197 16.197 0 1132.394 0v332.044a16.198 16.198 0 01-16.197 16.197z"
                    className="main-line"
                ></path>
                <path
                    fill="#fcc00e"
                    fillRule="evenodd"
                    d="M147.066 226.254L77.951 257.643 80.649 171.803 147.066 226.254z"
                    className="triangle-left"
                ></path>
                <path
                    fill="#ff5722"
                    fillRule="evenodd"
                    d="M204.605 130.182a37.277 37.277 0 11-37.28-37.276 37.286 37.286 0 0137.28 37.276z"
                    className="circle-left"
                ></path>
                <path
                    fill="#009688"
                    fillRule="evenodd"
                    d="M147.527 375.123a8103.532 8103.532 0 01-26.087-4.693c-6.91-1.268-9.926-5.263-8.642-12.34q4.827-26.598 9.564-53.177c1.344-7.62 5.84-10.139 13.192-8.77q26.08 4.79 52.187 9.348c7.528 1.317 10.417 5.525 9.06 12.997q-4.716 26.097-9.303 52.195c-1.394 8.06-6.139 10.737-13.9 9.216-8.673-1.663-17.388-3.133-26.087-4.704a.18.18 0 00.016-.072z"
                    className="square-left"
                ></path>
                <path
                    fill="#1d2c48"
                    d="M250 500C112.148 500 0 387.853 0 250.002S112.148 0 250 0s250 112.15 250 250.002S387.852 500 250 500zm0-470.413c-121.536 0-220.414 98.876-220.414 220.415 0 121.535 98.878 220.418 220.414 220.418s220.414-98.883 220.414-220.418c0-121.539-98.878-220.415-220.414-220.415z"
                    className={styles.mainCircle}
                ></path>
            </g>
        </svg>
    </div>
);

export default XerisSpinner;
