export const groupMaterialsByPriceGroup = <
    O extends { id: string },
    T extends { priceGroup: string | null; options: O[] },
>(
    materials: T[],
    visibleOptionIds: string[]
): { priceGroupId: string; materials: T[] }[] => {
    const grouped = materials.reduce<{ [key: string | symbol]: T[] }>(
        (group, material) => {
            group[material.priceGroup ?? 'ungrouped'] = [
                ...(group[material.priceGroup ?? 'ungrouped'] ?? []),
                material,
            ];

            return group;
        },
        {}
    );

    return Object.entries(grouped)
        .map(([priceGroupId, materials]) => ({
            priceGroupId: priceGroupId,
            materials: materials
                .map((material) => ({
                    ...material,
                    options: material.options.filter((option) =>
                        visibleOptionIds.includes(option.id)
                    ),
                }))
                .filter((material) => material.options.length > 0),
        }))
        .filter((group) => group.materials.length > 0);
};
