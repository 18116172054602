import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box } from '@mui/material';

import { BrandCard, ErrorPage, Skeleton } from '@xeris/components';
import { productApi } from '@xeris/pages/product/api';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppSelector } from '@xeris/reducers';

import { filterBrands, sortBrands } from '../../../utilities';

import styles from './BrandList.module.scss';

const SkeletonLoad = (): ReactElement => {
    return (
        <div className={styles.brandListContainer}>
            <Skeleton count={16} height={'250px'} />
        </div>
    );
};

const NoBrandsWarning = (): ReactElement | null => {
    const { t } = useTranslation('products');

    return (
        <Box paddingTop={'2.5em'} width="100%">
            <Alert severity="info">{t('common.noBrandsFound')}</Alert>
        </Box>
    );
};

type BrandCardContainerProps = {
    brandId: string;
    children: ReactNode;
};

const BrandCardContainer = ({
    children,
    brandId,
}: BrandCardContainerProps): ReactNode | null => {
    const isVisible = useAppSelector((state) =>
        settingsSelectors.selectIsBrandVisible(state, brandId)
    );

    if (isVisible) return children;

    return null;
};

type BrandListProps = {
    brandFilter: string;
};

export const BrandList = ({ brandFilter }: BrandListProps): ReactElement => {
    const { t } = useTranslation('products');

    const { data, isLoading, isError, refetch } =
        productApi.brand.useGetBrandsQuery({});

    if (isLoading) {
        return <SkeletonLoad />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('common.failedToLoadBrands')}
                actionText={t('common.tryAgain')}
                onClick={() => refetch()}
            />
        );
    }

    const brands = sortBrands(filterBrands(data?.brands ?? [], brandFilter));

    if (brands.length === 0) {
        return <NoBrandsWarning />;
    }

    return (
        <div className={styles.brandListContainer} data-testid={'brandList'}>
            {brands.map((brand) => (
                <BrandCardContainer key={brand.id} brandId={brand.id}>
                    <BrandCard
                        name={brand.name}
                        href={`/Products/${brand.id}`}
                        featureImage={brand.theme?.featureImage ?? undefined}
                        logo={brand.theme?.logo ?? undefined}
                    />
                </BrandCardContainer>
            ))}
        </div>
    );
};

export default BrandList;
