import { gql } from 'graphql-request';

import {
    baseProductEntityWithoutImagesFields,
    productEntityDataFields,
} from '../productFragments';

import { featureConfigurationFields } from './masterProductFragments';

export const getAllMasterProductsForBrandQuery = gql`
    query GetAllMasterProductsForBrand($brandId: String!) {
        brand(id: $brandId) {
            master_products {
                __typename
                id
                name
                description
                lastUpdated: last_updated
                externalId: external_id
                images {
                    id
                    title
                    objectFit
                    url(sizeFormat: THUMBNAIL)
                    type
                }
                brand {
                    id
                    name
                }
                markets {
                    id
                    name
                }
                idNumbers: id_numbers {
                    name
                    value
                    type
                }
                isConfigurable: is_configurable
                groups {
                    name
                    id
                    description
                    type {
                        id
                    }
                }
                products {
                    id
                    name
                    idNumbers: id_numbers {
                        value
                    }
                    images {
                        id
                        title
                        objectFit
                        url(sizeFormat: THUMBNAIL)
                        type
                    }
                    markets {
                        id
                        name
                        externalId: external_id
                    }
                    groups {
                        name
                        id
                        description
                        type {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const getMasterProductQuery = gql`
    query GetMasterProduct($masterProductId: String!) {
        master_product(id: $masterProductId) {
            __typename
            ...BaseProductEntityWithoutImagesFields
            ...ProductEntityDataFields
            isConfigurable: is_configurable
            source
            price {
                value
                currency
                priceUnit: price_unit
            }
            prices {
                value
                currency
                markets
                priceUnit: price_unit
            }
            products {
                id
                idNumbers: id_numbers {
                    value
                }
                markets {
                    id
                    name
                    externalId: external_id
                }
            }
            relatedMasterProducts: related_master_products {
                id
            }
            groups {
                id
                name
                description
            }
        }
    }
    ${baseProductEntityWithoutImagesFields}
    ${productEntityDataFields}
`;

export const getConfigurableMasterProductQuery = gql`
    query GetConfigurableMasterProduct(
        $datasetId: String!
        $hasDatasetId: Boolean!
        $masterProductId: String!
        $marketId: String
    ) {
        master_product(id: $masterProductId) {
            __typename
            id
            name
            isConfigurable: is_configurable
            brand {
                id
                name
            }
            descriptions {
                id
                name
                value
            }
            documents {
                id
                fileName
                title
                type {
                    id
                    label
                }
                url
            }
            images {
                id
                title
                type
                objectFit
                url(sizeFormat: FULLSIZE)
                id
            }
            models {
                id
                fileName
                type
                title
                url
            }
            price {
                value
                currency
                priceUnit: price_unit
                from_date
                to_date
                markets
            }
            prices {
                value
                currency
                markets
                priceUnit: price_unit
                from_date
                to_date
                markets
            }
            featureConfiguration: feature_configuration_v2 {
                __typename
                ...FeatureConfigurationFields
            }
            selectedData: selected_data(data_set_id: $datasetId)
                @include(if: $hasDatasetId) {
                images {
                    id
                }
                documents {
                    id
                }
                models {
                    id
                }
                configurationSets: configuration_sets {
                    id
                    name
                    images {
                        id
                    }
                    documents {
                        id
                    }
                    models {
                        id
                    }
                    views
                    selectedData: selected_data {
                        images {
                            id
                        }
                        documents {
                            id
                        }
                        models {
                            id
                        }
                    }
                    features {
                        id
                        options {
                            id
                        }
                    }
                }
            }
        }
    }
    ${featureConfigurationFields}
`;

export const getAllProductsForMasterProductQuery = gql`
    query GetAllProductsForMasterProduct($masterProductId: String!) {
        master_product(id: $masterProductId) {
            __typename
            products {
                __typename
                id
                name
                description
                gtin
                lastUpdated: last_updated
                brand {
                    id
                    name
                }
                masterProduct: master_product {
                    id
                    name
                }
                images {
                    id
                    type
                    title
                    objectFit
                    url(sizeFormat: THUMBNAIL)
                }
                markets {
                    id
                    name
                    externalId: external_id
                }
                idNumbers: id_numbers {
                    value
                }
                distinguishingFeatures: distinguishing_features {
                    type
                    value
                    name
                }
            }
        }
    }
`;

export const getMasterProductSourceDataQuery = gql`
    query GetMasterProductSourceData($masterProductId: String!) {
        master_product(id: $masterProductId) {
            __typename
            source_data {
                type
                data
            }
        }
    }
`;

export const getMasterProductDataErrorsQuery = gql`
    query GetMasterProductDataErrors($masterProductId: String!) {
        master_product(id: $masterProductId) {
            __typename
            data_errors {
                type
                message
            }
        }
    }
`;

export const getRelatedMasterProductsQuery = gql`
    query GetRelatedMasterProducts($masterProductId: String!) {
        master_product(id: $masterProductId) {
            __typename
            id
            relatedMasterProducts: related_master_products {
                __typename
                id
                name
                description
                isConfigurable: is_configurable
                lastUpdated: last_updated
                brand {
                    id
                    name
                }
                markets {
                    id
                    name
                    externalId: external_id
                }
                images {
                    title
                    url(sizeFormat: THUMBNAIL)
                    id
                    type
                    objectFit
                }
                idNumbers: id_numbers {
                    value
                }
                groups {
                    id
                    name
                    description
                }
                products {
                    id
                    idNumbers: id_numbers {
                        value
                    }
                    markets {
                        id
                        name
                        externalId: external_id
                    }
                }
            }
        }
    }
`;
