import { useTranslation } from 'react-i18next';

import { EcoIcon, type MenuElementType } from '@xeris/components';
import { useFeatureFlag } from '@xeris/hooks';

export const useEcoSearchMenu = (): MenuElementType[] => {
    const { t } = useTranslation('navigation');
    const [ecoSearchVisible] = useFeatureFlag('ecoSearch');

    if (!ecoSearchVisible) {
        return [];
    }

    return [
        {
            id: 'ecoSearch',
            title: t('menu.ecoSearch'),
            link: '/EcoSearch',
            isAlwaysShown: true,
            startAdornment: <EcoIcon />,
        },
    ];
};
