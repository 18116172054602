import { gql } from 'graphql-request';

export const featureConfigurationFields = gql`
    fragment FeatureConfigurationFields on FeatureConfiguration {
        variantCount: variant_count
        featureClusters: feature_clusters
        conditionalPrice: conditional_price(market: $marketId) {
            conditionalValues: conditional_values {
                value
                condition
            }
            conditional_values {
                value
                condition
            }
            currency
            markets
            priceUnit: price_unit
            price_unit
        }
        conditional_price(market: $marketId) {
            conditional_values {
                value
                condition
            }
            currency
            markets
            price_unit
        }
        conditional_prices {
            conditional_values {
                value
                condition
            }
            currency
            markets
            price_unit
        }
        basePrice: base_price(market: $marketId) {
            currency
            value
            from_date
            to_date
            markets
        }
        basePrices: base_prices {
            currency
            value
            from_date
            to_date
            markets
        }
        featureGroups: feature_groups {
            id
            name
            features {
                id
                name
                description
                __typename
                ... on MaterialFeature {
                    description
                    condition
                    materials {
                        id
                        name
                        description
                        priceGroup: price_group
                        price_group
                        supplier {
                            id
                            name
                        }
                        price(market: $marketId) {
                            value
                            currency
                            from_date
                            to_date
                            markets
                        }
                        prices {
                            value
                            currency
                            from_date
                            to_date
                            markets
                        }
                        options {
                            id
                            name
                            description
                            image
                            colorCode: color_code
                            condition
                        }
                        masterProduct: master_product {
                            id
                        }
                    }
                }
                ... on BasicFeature {
                    description
                    condition
                    options {
                        __typename
                        id
                        name
                        description
                        condition
                        image
                        price(market: $marketId) {
                            value
                            currency
                            from_date
                            to_date
                            markets
                        }
                        prices {
                            value
                            currency
                            from_date
                            to_date
                            markets
                        }
                    }
                }
            }
        }
        preview {
            ... on PreviewConfigurationImage {
                __typename
                views {
                    __typename
                    id
                    name
                    components {
                        __typename
                        id
                        features
                        variants {
                            options
                            image
                        }
                    }
                }
            }
            ... on PreviewConfiguration3D {
                __typename
                views {
                    __typename
                    id
                    name
                    eye {
                        x
                        y
                        z
                    }
                    target {
                        x
                        y
                        z
                    }
                }
                model
                components {
                    id
                    features
                    variants {
                        options
                        texture
                    }
                }
            }
        }
    }
`;
