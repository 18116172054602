import { gql } from 'graphql-request';

export const getMasterProductForInfoBoxQuery = gql`
    query GetMasterProductForInfoBox($id: String!) {
        masterProduct: master_product(id: $id) {
            id
            name
            brand {
                id
                name
            }
            descriptions {
                id
                name
                value
            }
            idNumbers: id_numbers {
                type
                value
                name
            }
            specifications {
                type
                name
                value
            }
            certifications {
                name
            }
        }
    }
`;
