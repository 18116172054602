import { type FormEvent, type ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { login } from '@xeris/api';
import { AlertIcon, Button, Loading } from '@xeris/components';
import PlatformLanguageSelector from '@xeris/containers/LanguageSelector/PlatformLanguageSelector';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { CheckboxFormControl, PasswordInput, UserNameInput } from '../Common';
import { loginSelectors } from '../reducers';

import styles from './LoginForm.module.scss';

const LoginForm = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('login');

    const passwordInput = useRef<HTMLInputElement | null>(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);

    const form = useRef<HTMLFormElement | null>(null);

    const { isLoading, loginFailed, noCredentials } = useAppSelector(
        loginSelectors.selectLoginSlice
    );

    const handleRememberMe = (): void => setRememberMe(!rememberMe);

    const handleSubmitLogin = (event: FormEvent): void => {
        event.preventDefault();

        passwordInput.current?.setCustomValidity(
            password.length >= 1 ? '' : t('form.noPasswordEntered')
        );
        form.current?.reportValidity();
        if (form.current?.checkValidity() === true) {
            // TODO: Move to separate API
            // @ts-expect-error
            dispatch(login(username, password, rememberMe));
        }
    };

    const message = isLoading ? (
        <Loading loading={true} backdrop={true} />
    ) : loginFailed && !noCredentials ? (
        <div className={styles.errorBox}>
            <AlertIcon fontSize="small"></AlertIcon>
            <span>{t('form.loginError')}</span>
        </div>
    ) : null;

    const passwordFormId = 'user-password';

    return (
        <div className={styles.loginForm}>
            <header>
                <span className={styles.title}>
                    {t('form.signInToContinue')}
                </span>
            </header>
            <form
                ref={form}
                onSubmit={(event): void => handleSubmitLogin(event)}
                id="login-form"
            >
                <div className={styles.inputContainer}>
                    <UserNameInput
                        value={username}
                        handleChange={setUsername}
                    />
                    <PasswordInput
                        id={passwordFormId}
                        ref={passwordInput}
                        value={password}
                        handleChange={setPassword}
                    />
                </div>
                <div className={styles.keepSignedIn}>
                    <CheckboxFormControl
                        checked={rememberMe}
                        handleToggle={handleRememberMe}
                        label={t('form.keepSignedIn')}
                        name="rememberMe"
                    />
                </div>

                <Button
                    id="sign-in"
                    uppercase
                    className={styles.signInButton}
                    type="submit"
                    onClick={handleSubmitLogin}
                >
                    {t('form.signIn')}
                </Button>

                <footer className={styles.footerActions}>
                    <div>
                        <Link
                            to="/login/?action=forgot-password"
                            data-testid="forgot-password-button"
                        >
                            {' '}
                            {t('form.forgottenPassword')}
                        </Link>
                    </div>

                    <PlatformLanguageSelector
                        sx={{
                            'marginRight': '-11px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: 0,
                            },
                            '& .MuiSelect-select': {
                                'paddingBlock': 0.5,
                                '&:hover': {
                                    backgroundColor: 'secondary.soft',
                                },
                            },
                        }}
                    />
                </footer>
            </form>

            {message}
        </div>
    );
};

export default LoginForm;
