import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { importApi } from '../api/importApi';
import { type Importer } from '../types';

export type ImportFormatSelectorProps = {
    selectFormatHandler: (format: Importer | null) => void;
    importFormat: Importer | null;
};

const ImportFormatSelector = ({
    selectFormatHandler,
    importFormat,
}: ImportFormatSelectorProps): ReactElement => {
    const { t } = useTranslation('administration');
    const { data: importFormatList } = importApi.useGetImportsQuery({});

    return (
        <FormControl sx={{ flexBasis: '400px' }}>
            <InputLabel>{t('imports.selectUploadFormat')}</InputLabel>
            <Select
                label={t('imports.selectUploadFormat')}
                value={importFormat?.id ?? ''}
                name={'uploadFormat'}
                onChange={(event) =>
                    selectFormatHandler(
                        importFormatList?.importers.find(
                            (format) =>
                                format.id === (event.target.value as string)
                        ) ?? null
                    )
                }
            >
                {importFormatList?.importers.map((format, index) => (
                    <MenuItem
                        className="import-select-format-menu"
                        key={index}
                        value={format.id}
                    >
                        {format.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ImportFormatSelector;
