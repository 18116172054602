import {
    type BaseQueryFn,
    createApi,
    type FetchArgs,
    fetchBaseQuery,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { getToken } from '@xeris/api';
import { type StateType } from '@xeris/types';

const baseQuery = fetchBaseQuery({
    baseUrl: window.location.origin,
    prepareHeaders: (headers, { getState, endpoint }) => {
        headers.set('Accept', 'application/json');

        const token = (getState() as StateType).login.token;

        if (token && endpoint !== 'uploadFile') {
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    },
});

const baseQueryWithToken: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, apiMethods, extraOptions) => {
    const { dispatch, getState } = apiMethods;

    // Ensure token is valid and ready to be used in prepareHeaders
    await getToken(dispatch, getState as () => StateType);

    return baseQuery(args, apiMethods, extraOptions);
};

export const baseRtkRestApi = createApi({
    baseQuery: baseQueryWithToken,
    reducerPath: 'restApi',
    tagTypes: [],
    endpoints: () => ({}),
});
