import { type ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

import { SearchBar } from '@xeris/components';
import { productApi } from '@xeris/pages/product/api';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { marketSortFunction } from '@xeris/pages/product/utilities';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { simpleSortBy } from '@xeris/utilities';

import { productActions } from '../../reducers';

import './index.scss';

const MarketSelector = (): ReactElement | null => {
    const { brandId = '' } = useParams();

    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const { data } = productApi.brand.useGetBrandByIdQuery({
        id: brandId,
    });

    const sortedMarketList = (data?.brand?.markets ?? []).toSorted(
        marketSortFunction
    );

    const selectedMarketId = useAppSelector((state) =>
        settingsSelectors.selectMarket(state, brandId)
    );

    return (
        <FormControl size={'small'}>
            <InputLabel>{t('productList.markets')}</InputLabel>
            <Select
                label={t('productList.markets')}
                value={selectedMarketId ?? '__notSelected'}
                sx={{ width: 160 }}
                size={'small'}
                onChange={(event): void => {
                    dispatch(
                        productActions.settings.setMarket({
                            brandId,
                            externalMarketId:
                                sortedMarketList.find(
                                    (market) => market.id === event.target.value
                                )?.externalId ?? null,
                            market:
                                sortedMarketList.find(
                                    (market) => market.id === event.target.value
                                )?.id ?? null,
                        })
                    );
                }}
            >
                <MenuItem value={'__notSelected'}>
                    <Typography variant="inherit" noWrap>
                        {t('productList.marketNotSet')}
                    </Typography>
                </MenuItem>
                {sortedMarketList.map((market) => (
                    <MenuItem key={market.id} value={market.id}>
                        <Typography variant="inherit" noWrap>
                            {market.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const GroupSelector = (): ReactElement => {
    const { brandId = '' } = useParams();

    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const { data } = productApi.brand.useGetBrandByIdQuery({
        id: brandId,
    });

    const sortedGroupTypeList = simpleSortBy(
        (data?.brand?.groupTypes ?? []).map((groupType) => ({
            ...groupType,
            groupList: groupType.groups ?? [],
        })),
        'name'
    );

    const selectedGroupTypeId = useAppSelector((state) =>
        settingsSelectors.selectGroupType(state, brandId)
    );

    // Ensure a valid group type selection is set in the redux state
    // Normally, I would leave the defaulting logic to the consumer of the state,
    // but since this is persisted in local storage, I want to clean it up when it is outdated
    useEffect(() => {
        // Null means all products
        if (selectedGroupTypeId === null) return;

        if (
            // Undefined means no selection is made in redux state
            selectedGroupTypeId === undefined ||
            // Make sure the selected group is still a valid group
            !sortedGroupTypeList.some(
                (groupType) => groupType.id === selectedGroupTypeId
            )
        ) {
            dispatch(
                productActions.settings.setGroupType({
                    brandId,
                    groupType: sortedGroupTypeList[0]?.id ?? null,
                })
            );
        }
    }, [brandId, dispatch, selectedGroupTypeId, sortedGroupTypeList]);

    return (
        <FormControl size={'small'}>
            <InputLabel>{t('productList.groupMenu.showAs')}</InputLabel>
            <Select
                label={t('productList.groupMenu.showAs')}
                value={selectedGroupTypeId ?? '__Products'}
                sx={{ width: 160 }}
                size={'small'}
                onChange={(event): void => {
                    dispatch(
                        productActions.settings.setGroupType({
                            brandId,
                            groupType:
                                sortedGroupTypeList.find(
                                    (groupType) =>
                                        groupType.id === event.target.value
                                )?.id ?? null,
                        })
                    );
                }}
            >
                {sortedGroupTypeList.map((groupType) => (
                    <MenuItem key={groupType.id} value={groupType.id}>
                        <Typography variant="inherit" noWrap>
                            {groupType.name}
                        </Typography>
                    </MenuItem>
                ))}
                <MenuItem value={'__Products'}>
                    <Typography variant="inherit" noWrap>
                        {t('productList.groupMenu.product')}
                    </Typography>
                </MenuItem>
            </Select>
        </FormControl>
    );
};

type ProductEntityListHeaderProps = {
    isGroupSelectorVisible: boolean;
};

const ProductEntityListHeader = ({
    isGroupSelectorVisible,
}: ProductEntityListHeaderProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const searchTerm = useAppSelector(settingsSelectors.selectSearchTerm);

    const handleSetFilter = (newFilter: string): void => {
        dispatch(productActions.settings.setSearchTerm(newFilter));
    };

    return (
        <div className="product-toolbar" data-testid={'productToolbar'}>
            <div className="filter-controls">
                <SearchBar
                    filter={searchTerm}
                    onChange={handleSetFilter}
                    inputLabel={t('common.searchForProducts')}
                />
            </div>
            <div className="views-controls">
                <MarketSelector />
                {isGroupSelectorVisible && <GroupSelector />}
            </div>
        </div>
    );
};

export default ProductEntityListHeader;
